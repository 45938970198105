import { Navigate, Outlet } from "react-router-dom";
import { useAuthContext } from "../../context/authContext";

export default function ProtectedRoutes() {
    const { isAuthenticated } = useAuthContext();
    // console.log("Protected", isAuthenticated);
    if (!isAuthenticated) {
        return <Navigate to="/login" />;
    }

    return (
        <>
            <Outlet />
        </>
    );
}
