import { AppBar, IconButton, Toolbar, Typography } from "@mui/material";
import { useAuthContext } from "../../../context/authContext";
import { LogoutIcon, MenuIcon } from "../../../icons";
import { useModal } from "../../../hooks";
import { CustomModal } from "../../../components";

const drawerWidth = 240;

export default function PanelAppbar({ onMenuIconClick }) {
    const { logout } = useAuthContext();
    const modal = useModal();

    const handleLogout = () => {
        modal.setConfig({
            open: true,
            type: "alert",
            alertTitle: "Cerrar sesión",
            confirmBtnText: "Si, salir",
            content: (
                <Typography>
                    ¿Realmente quieres salir de la aplicación?
                </Typography>
            ),
        });
    };

    const handleCancel = () => {
        modal.setConfig({ open: false });
    };

    const handleModalAccept = () => {
        modal.setConfig({ type: "progress", progressTitle: "Cerrando sesión" });
        setTimeout(() => {
            logout();
        }, 500);
    };

    return (
        <>
            <AppBar
                elevation={0}
                position="fixed"
                sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                }}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={onMenuIconClick}
                        sx={{ mr: 2, display: { sm: "none" } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ flexGrow: 1 }}
                    >
                        Panel de Administración
                    </Typography>
                    <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleLogout}
                        color="inherit"
                    >
                        <LogoutIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <CustomModal
                open={modal.config.open}
                type={modal.config.type}
                alertTitle={modal.config.alertTitle}
                progressTitle={modal.config.progressTitle}
                showCancelBtn={modal.config.showCancelBtn}
                confirmBtnText={modal.config.confirmBtnText}
                onCancel={handleCancel}
                onAccept={handleModalAccept}
            >
                {modal.config.content}
            </CustomModal>
        </>
    );
}
