import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@mui/material";
import { TextLoading } from "./";

const Tabla = () => {
    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>
                        <TextLoading width={200} height={20} />
                    </TableCell>
                    <TableCell>
                        <TextLoading width={200} height={20} />
                    </TableCell>
                    <TableCell>
                        <TextLoading width={200} height={20} />
                    </TableCell>
                    <TableCell>
                        <TextLoading width={200} height={20} />
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell>
                        <TextLoading width={150} height={15} />
                    </TableCell>
                    <TableCell>
                        <TextLoading width={150} height={15} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );
};
export default Tabla;
