import { Box, Drawer } from "@mui/material";
import PanelDrawer from "./PanelDrawer";
const drawerWidth = 240;

export default function PanelNavigation({ onCloseDrower, mobileOpen }) {
    const window = undefined;
    const container =
        window !== undefined ? () => window().document.body : undefined;

    return (
        <Box
            component="nav"
            sx={{
                width: { sm: drawerWidth },
                flexShrink: { sm: 0 },
            }}
        >
            <Drawer
                container={container}
                variant="temporary"
                open={mobileOpen}
                onClose={onCloseDrower}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: "block", sm: "none" },
                    "& .MuiDrawer-paper": {
                        boxSizing: "border-box",
                        width: drawerWidth,
                    },
                }}
            >
                <PanelDrawer onClick={() => onCloseDrower()} />
            </Drawer>
            <Drawer
                variant="permanent"
                sx={{
                    display: { xs: "none", sm: "block" },
                    "& .MuiDrawer-paper": {
                        boxSizing: "border-box",
                        width: drawerWidth,
                    },
                }}
                open
                PaperProps={{
                    variant: "elevation",
                }}
            >
                <PanelDrawer onClick={() => onCloseDrower()} />
            </Drawer>
        </Box>
    );
}
