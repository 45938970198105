import { useDispatch, useSelector } from "react-redux";
import CustomListItemIconButton from "../../../../../components/CustomListItemIconButton";
import { ToggleOffIcon, ToggleOnIcon } from "../../../../../icons";
import { useFormik } from "formik";
import { cambiarEstadoSorteo } from "../../../../../services/bola";
import { setSorteoSeleccionado } from "../../../../../features/sorteos/sorteosSlice";
import { CustomModal, InputField } from "../../../../../components";
import { Divider, Typography } from "@mui/material";
import * as Yup from "yup";
import { useModal } from "../../../../../hooks";

export default function CambiarEstadoSorteoButton() {
  const { sorteoSeleccionado } = useSelector((state) => state.sorteos);
  let toggleSorteoText = !sorteoSeleccionado.activo
    ? "Desactivado"
    : "Activado";
  const formik = useFormik({
    initialValues: {
      passwordAdmin: "",
    },
    validationSchema: Yup.object().shape({
      passwordAdmin: Yup.string().required("Este campo es requerido"),
    }),
    onSubmit: (validatedData) => {
      if (!modal.config.confirmBtnClicked) {
        cambiar(validatedData);
      }
    },
  });
  const modal = useModal();
  const dispatch = useDispatch();

  const handleOpenModal = () => {
    modal.setConfig({
      open: true,
      type: "alert",
      alertTitle: !sorteoSeleccionado.activo ? "Activar" : "Desactivar",
      contentType: "content",
      showCancelBtn: true,
      confirmBtnText: "aceptar",
    });
  };

  const handleModalCancel = () => {
    modal.setConfig({ open: false });
    formik.handleReset();
  };

  const handleModalAccept = () => {
    if (modal.config.contentType === "content") {
      formik.handleSubmit();
    }
    if (modal.config.contentType === "error") {
      modal.setConfig({ open: false });
      setTimeout(() => {
        formik.handleReset();
      }, 300);
    }
  };
  // CAMBIAR ESTADO SORTEO
  const cambiar = async ({ passwordAdmin }) => {
    try {
      modal.setConfig({
        type: "progress",
        progressTitle: sorteoSeleccionado.activo ? "Desactivando" : "Activando",
        confirmBtnClicked: true,
      });
      const estadoCambiado = await cambiarEstadoSorteo(
        passwordAdmin,
        sorteoSeleccionado
      );
      // SI SE ELIMINO CORRECTAMENTE
      if (estadoCambiado) {
        const newSorteo = { ...sorteoSeleccionado };
        newSorteo.activo = !sorteoSeleccionado.activo;
        dispatch(setSorteoSeleccionado(newSorteo));
        formik.handleReset();
        modal.setConfig({ open: false, confirmBtnClicked: false });
      }
    } catch ({ message }) {
      modal.setConfig({
        type: "alert",
        alertTitle: "Mensaje",
        contentType: "error",
        error: <span>{message}</span>,
        confirmBtnText: "Entendido",
        showCancelBtn: false,
        confirmBtnClicked: false,
      });
    }
  };

  return (
    <>
      <CustomListItemIconButton
        label={toggleSorteoText}
        icon={
          !sorteoSeleccionado.activo ? (
            <ToggleOffIcon />
          ) : (
            <ToggleOnIcon sx={{ color: "green" }} />
          )
        }
        onClick={() => handleOpenModal()}
      />
      <CustomModal
        open={modal.config.open}
        type={modal.config.type}
        alertTitle={modal.config.alertTitle}
        progressTitle={modal.config.progressTitle}
        showCancelBtn={modal.config.showCancelBtn}
        confirmBtnText={modal.config.confirmBtnText}
        onCancel={handleModalCancel}
        onAccept={handleModalAccept}
      >
        {modal.config.contentType === "content" && (
          <ModalCambiarEstadoContent
            formik={formik}
            sorteoSeleccionado={sorteoSeleccionado}
          />
        )}
        {modal.config.contentType === "error" && modal.config.error}
      </CustomModal>
    </>
  );
}

const ModalCambiarEstadoContent = ({ formik, sorteoSeleccionado }) => {
  const message = !sorteoSeleccionado.activo
    ? "Al activar el sorteo se podrá continuar con el registro de boletos."
    : "Al desactivar el sorteo, ya no se podrán registrar más boletos.";
  console.log("modal toggle estado");
  return (
    <>
      {<Typography>{message}</Typography>}
      <Divider sx={{ marginY: 1 }} />
      <Typography>
        Por seguridad te pedimos ingreses tu contraseña para poder continuar con
        el proceso de eliminación.
      </Typography>
      <InputField
        type="password"
        label="Contraseña administrador"
        name="passwordAdmin"
        value={formik.values.passwordAdmin}
        error={formik.errors.passwordAdmin}
        touched={formik.touched.passwordAdmin}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />
    </>
  );
};
