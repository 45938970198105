import { uuid } from "../utils";

const USUARIO_FORM_FIELDS = [
    {
        id: uuid(),
        type: "text",
        label: "Nombre",
        name: "nombre",
    },
    {
        id: uuid(),
        type: "text",
        label: "Apellidos",
        name: "apellidos",
    },
    {
        id: uuid(),
        type: "email",
        label: "Correo",
        name: "correo",
    },
    {
        id: uuid(),
        type: "text",
        label: "Teléfono",
        name: "telefono",
    },
    {
        id: uuid(),
        type: "text",
        label: "Domicilio",
        name: "domicilio",
    },
    {
        id: uuid(),
        type: "text",
        label: "Nombre Comercial",
        name: "nomComercial",
    },
    {
        id: uuid(),
        type: "text",
        label: "Usuario",
        name: "usuario",
    },
    {
        id: uuid(),
        type: "number",
        label: "Limite Venta",
        name: "limiteVenta",
    },
    {
        id: uuid(),
        type: "password",
        label: "Contraseña",
        name: "usuarioPassword",
    },
    {
        id: uuid(),
        type: "password",
        label: "Confirmar contraseña",
        name: "confirmarUsuarioPassword",
    },
];

export default USUARIO_FORM_FIELDS;
