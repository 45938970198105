import { useEffect } from "react";
import { useState } from "react";
import { getPendingTransactions } from "../../../services/taecel";
import { Card, CardContent, TableCell, TableRow } from "@mui/material";
import Tabla from "../../../components/Tabla";
import CustomCircularProgress from "../../../components/CustomCircularProgress";
const HEADER_DATA = "#.Bolsa.Monto.TransID.Fecha.Usuario".split(".");

export default function TransaccionesPendientes() {
  const [cargando, setCargando] = useState(true);
  const [transacciones, setTransacciones] = useState([]);

  useEffect(() => {
    cargarTransacciones();
  }, []);

  const cargarTransacciones = async () => {
    try {
      const _transacciones = await getPendingTransactions();
      setTransacciones(_transacciones);
      setCargando(false);
    } catch ({ message }) {
      console.log(message);
      setCargando(false);
    }
  };

  return (
    <Card>
      <CardContent>
        {cargando && <CustomCircularProgress />}
        {!cargando && (
          <Tabla
            headData={HEADER_DATA}
            data={transacciones}
            renderItem={(item, index) => (
              <FilaTransaccion key={index} txn={item} index={index} />
            )}
          />
        )}
      </CardContent>
    </Card>
  );
}

function FilaTransaccion({ txn, index }) {
  return (
    <TableRow>
      <TableCell>{index + 1}</TableCell>
      <TableCell>{txn.Bolsa}</TableCell>
      <TableCell>{txn.Monto}</TableCell>
      <TableCell>{txn.TransID}</TableCell>
      <TableCell>
        {txn._fecha != undefined ? txn._fecha : txn.Fecha}{" "}
        {txn._hora != undefined ? txn._hora : ""}
      </TableCell>
      <TableCell>
        {txn._usuario != undefined ? txn._usuario : "UNKNOWN USER"}
      </TableCell>
    </TableRow>
  );
}
