import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Money } from "../../../utils";
const TABLE_HEAD =
  "SORTEO.FECHA.REGISTROS.VENTA.PREMIOS.ENCONTRADOS.CANCELADOS.TOTAL".split(
    "."
  );

export default function TablaGeneralTicketPlus({ reporte }) {
  return (
    <>
      <Table stickyHeader size="small">
        <TableHead>
          <TableRow>
            {TABLE_HEAD.map((name, index) => (
              <TableCell key={name} align={index === 0 ? "left" : "right"}>
                {name}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {reporte && (
          <TableBody>
            {reporte?.rows.map((item) => (
              <TableRow key={item.fecha}>
                {TABLE_HEAD.map((name, index) => (
                  <TableCell key={name} align={index === 0 ? "left" : "right"}>
                    {["SORTEO", "FECHA", "REGISTROS"].includes(name)
                      ? item[name.toLocaleLowerCase()]
                      : Money(item[name.toLocaleLowerCase()])}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>
      <Box
        sx={{
          marginTop: 2,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        {/* RECARGAS Y SERVICIOS */}
        <Table
          size="small"
          sx={{ backgroundColor: "#121212", maxWidth: "40%" }}
        >
          <TableBody>
            <TableRow>
              <TableCell>RECARGAS</TableCell>
              <TableCell align="right">
                {Money(reporte?.general.recargas)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>SERVICIOS</TableCell>
              <TableCell align="right">
                {Money(reporte?.general.servicios)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>TICKET PLUS</TableCell>
              <TableCell align="right">
                {Money(reporte?.general.ticketPlus)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>SUMA TOTALES</TableCell>
              <TableCell align="right">
                {Money(reporte?.general.sumaTotales)}
              </TableCell>
            </TableRow>
            {/* <TableRow> */}
            {/* DINAMICO EXCEDENTE | FALTANTE */}
            {/* <TableCell>
                {reporte?.general.resto >= 0 ? "EXCEDENTE" : "FALTANTE"}
              </TableCell>
              <TableCell align="right">
                {Money(reporte?.general.resto)}
              </TableCell> */}
            {/* </TableRow> */}
          </TableBody>
        </Table>
        {/* TICKET PLUS */}
        <Table
          size="small"
          sx={{ backgroundColor: "#121212", maxWidth: "40%" }}
        >
          <TableBody>
            <TableRow>
              <TableCell>VENTAS</TableCell>
              <TableCell align="right">
                {Money(reporte?.sumaTotalVentasSorteos)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>COMISIONES</TableCell>
              <TableCell align="right">
                {Money(reporte?.comisionSemanal)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>ENCOTRADOS</TableCell>
              <TableCell align="right">
                {Money(reporte?.premiosEncontrados)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>PREMIOS PAGADOS</TableCell>
              <TableCell align="right">
                {Money(reporte?.sumaTotalPremiosSorteos)}
              </TableCell>
            </TableRow>
            {/* <TableRow>
              <TableCell>PREMIOS PAGADOS SORTEOS PASADOS</TableCell>
              <TableCell align="right">
                {Money(reporte?.premiosPagadosOtros)}
              </TableCell>
            </TableRow> */}
            <TableRow>
              <TableCell>SUBTOTAL</TableCell>
              <TableCell align="right">
                {Money(reporte?.sumaTotalesSorteos)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>POR PAGAR</TableCell>
              <TableCell align="right">
                {Money(
                  reporte?.premiosEncontrados - reporte?.sumaTotalPremiosSorteos
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </>
  );
}
