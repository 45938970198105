// REACT
import React, { useEffect } from "react";
// ROUTER
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// MUI COMPONENTS
import { Button, Card, CardContent, CardHeader } from "@mui/material";
import SorteoCardLoading from "./components/SorteoCardLoading";
// ICONS
import { ArrowBackIcon } from "../../../../icons";
// SERVICES
import { obtenerSorteo } from "../../../../services/bola";
import {
  setCargandoSorteo,
  setSorteoSeleccionado,
} from "../../../../features/sorteos/sorteosSlice";
import LogoSorteo from "./components/LogoSorteo";
import NumerosGanadores from "./components/NumerosGanadores";
import SorteoAcciones from "./components/SorteoAcciones";
import SorteoDescripcion from "./components/SorteoDescripcion";

const Sorteo = () => {
  const { cargandoSorteo, sorteoSeleccionado } = useSelector(
    (state) => state.sorteos
  );
  const { sorteoKey } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    cargarSorteo();
  }, []);

  const cargarSorteo = async () => {
    try {
      dispatch(setCargandoSorteo(true));
      const _sorteo = await obtenerSorteo(sorteoKey);
      dispatch(setSorteoSeleccionado(_sorteo));
      dispatch(setCargandoSorteo(false));
    } catch (error) {
      console.log(error.message);
    }
  };

  if (cargandoSorteo) {
    return <SorteoCardLoading />;
  }

  if (!cargandoSorteo && !sorteoSeleccionado) {
    return <div>sorteo no encontrado</div>;
  }

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Card sx={{ width: "100%", maxWidth: 500 }}>
        <CardHeader
          subheader={
            <Button
              startIcon={<ArrowBackIcon />}
              onClick={() => navigate("../../sorteos")}
            >
              Volver a sorteos
            </Button>
          }
        />
        <CardContent>
          <LogoSorteo codigo={sorteoSeleccionado.codigoSorteo} />
          <NumerosGanadores numeros={sorteoSeleccionado.numerosGanadores} />
          <SorteoDescripcion />
          <SorteoAcciones />
        </CardContent>
      </Card>
    </div>
  );
};

export default Sorteo;
