import { View, Text, StyleSheet } from "@react-pdf/renderer";

export default function DocumentHead({ title, subtitle }) {
  return (
    <View style={styles.documentHeader}>
      <Text style={styles.headerText}>{title}</Text>
      <Text style={styles.headerText}>{subtitle}</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  headerText: {
    fontSize: 12,
  },
});
