import { useEffect, useState } from "react";
import { useCustomParams } from "../../../hooks";
import InformeIndividualLayout from "./InformeIndividualLayout";
import InformeGeneralLayout from "./InformeGeneralLayout";
import TablaGeneralTicketPlus from "./TablaGeneralTicketPlus";
import { obtenerReporteTicketPlus } from "../../../services/reportes";
import TicketPlusPDF from "./TicketPlusPDF";
import { useSelector } from "react-redux";
import ViewPDF from "../../../components/ViewPDF";
const tableHeadData = "USUARIO.REGISTROS.VENTA.PREMIOS.CANCELADOS.TOTAL".split(
  "."
);

export default function TicketPlus() {
  const customParams = useCustomParams();
  const [paramsError, setParamsError] = useState(false);
  const [cargando, setCargando] = useState(false);
  const [periodo, setPeriodo] = useState("");
  const [reporte, setReporte] = useState(null);
  const { showPDFButton } = useSelector((state) => state.reportes);
  const params = {
    range: customParams.get("range"),
  };

  useEffect(() => {
    verififyParams();
  }, [params.range]);

  const verififyParams = async () => {
    try {
      setCargando(true);
      if (!params.range) {
        setParamsError(true);
        setCargando(false);
        return;
      }
      const res = await obtenerReporteTicketPlus(params.range);
      // console.log(res);
      setPeriodo(res.periodo);
      setReporte(res);
      setCargando(false);
    } catch ({ message }) {
      console.error(message);
      setCargando(false);
      setParamsError(true);
    }
  };

  if (paramsError) return <div>Error al cargar los parametros</div>;

  return (
    <>
      {cargando && <div>cargando</div>}
      {!cargando && (
        <>
          <InformeGeneralLayout
            title="Estado semanal"
            subtitle={periodo.toUpperCase()}
            action={
              <>
                {showPDFButton && (
                  <ViewPDF>
                    <TicketPlusPDF reporte={reporte} />
                  </ViewPDF>
                )}
              </>
            }
          >
            <TablaGeneralTicketPlus reporte={reporte} />
          </InformeGeneralLayout>
          {reporte && (
            <>
              {reporte.rows.map((row) => (
                <InformeIndividualLayout
                  title={
                    "INFORME POR USUARIO SORTEO " + row.sorteo + " " + row.fecha
                  }
                  tableHeadData={tableHeadData}
                  tableData={row.usuarios}
                />
              ))}
            </>
          )}
        </>
      )}
    </>
  );
}
