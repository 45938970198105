import { Button } from "@mui/material";
import { useState } from "react";
import { getUserAccountStatus } from "../../services/reports";

export default function TestingScreen() {
  const [cargando, setCargando] = useState(false);
  const [reporte, setReporte] = useState(null);

  const handleClick = async () => {
    try {
      setCargando(true);
      const userAcountStatus = await getUserAccountStatus(
        { start: "2023-08-14", end: "2023-08-20" },
        {
          activo: true,
          agencia: "LOMA B.",
          apellidos: "Reyes",
          id: "b1faf570-4931-11e9-9c47-67a4751a9a6b",
          limiteVenta: 10000,
          nomComercial: "Loma",
          nombre: "José",
          usuario: "37001",
        }
      );
      setReporte(userAcountStatus);
      setCargando(false);
    } catch ({ message }) {
      console.log("Error:", message);
    }
  };

  return (
    <div>
      <Button disabled={cargando} variant="contained" onClick={handleClick}>
        Obtener Reporte Semanal
      </Button>
      {!cargando && reporte !== null && (
        <pre>{JSON.stringify(reporte, null, 2)}</pre>
      )}
    </div>
  );
}
