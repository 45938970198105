import { Navigate } from "react-router-dom";
import { useAuthContext } from "../../context/authContext";
import Login from "../login";

export default function PublicRoutes() {
    const { isAuthenticated } = useAuthContext();
    // console.log("Public", isAuthenticated);
    if (isAuthenticated) {
        return <Navigate to="/panel/sorteos" />;
    }

    return (
        <>
            <Login />
        </>
    );
}
