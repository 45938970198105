// MUI COMPONENTS
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@mui/material";
// PLACEHOLDERS
import { TextLoading, CircleLoading } from "../../../placeholders";

export default function SorteosTableLoader() {
    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>
                        <TextLoading height={20} />
                    </TableCell>
                    <TableCell>
                        <TextLoading height={20} />
                    </TableCell>
                    <TableCell>
                        <TextLoading height={20} />
                    </TableCell>
                    <TableCell>
                        <TextLoading height={20} />
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell align="left">
                        <CircleLoading />
                    </TableCell>
                    <TableCell align="left">
                        <TextLoading width={80} />
                    </TableCell>
                    <TableCell align="left">
                        <TextLoading width={80} />
                    </TableCell>
                    <TableCell align="left">
                        <TextLoading width={80} />
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );
}
