import { DATABASE_TABLES } from "../../constants";
import Database from "../../database";
import { Helpers, Moment, Money } from "../../utils";
import { obtenerUsuarios } from "../bola";
import {
  obtenerEstadosDeCuentaUsuarios,
  obtenerReporteSemanal,
} from "../estado_de_cuenta";
import { getReport } from "../taecel";

export async function obtenerReporteTransacciones(rango) {
  try {
    const [inicial, final] = rango.split("_");
    // console.log(Moment(Moment(final)).diff(Moment(inicial), "days"));
    const transacciones = await Database.getItemsInRange(
      DATABASE_TABLES.TRANSACCIONES,
      "_fecha",
      inicial,
      final,
      (trans) => trans.Status === "Exitosa"
    );
    const usuarios = await obtenerUsuarios();
    // RECARGAS
    const tiempoAire = obtenerTotalTransacciones(transacciones, "1");
    const paquetes = obtenerTotalTransacciones(transacciones, "2");
    const totalVentaRecargas = tiempoAire.total + paquetes.total;
    const totalRegistrosRecargas = tiempoAire.size + paquetes.size;
    const cobroServicioRecargas = tiempoAire.comision + paquetes.comision;
    // SERVICIOS
    const pagoServicios = obtenerTotalTransacciones(transacciones, "3");
    const giftcards = obtenerTotalTransacciones(transacciones, "4");
    const totalPagoServicios = pagoServicios.total + giftcards.total;
    const totalRegistrosServicios = pagoServicios.size + giftcards.size;
    const cobroServicioServicios = pagoServicios.comision;
    // SALDO TOTAL AL CIERRE
    const saldosAlCierre = await obtenerSaldosAlCierre(inicial);
    // DEPOSITOS
    const fechasSemana = obtenerfechasSemana(inicial, final);
    const reporteCompras = await obtenerReportesCompras(fechasSemana);
    // COMISIONES
    const comisionGeneradaRecargas = reporteCompras.recargas.total * 0.065;
    const comisionUsuarioRecargas = totalVentaRecargas * 0.04;
    const comisionUsuarioServicios = pagoServicios.size * 3.5;
    const comisionAdminRecargas = totalVentaRecargas * 0.025;
    const comisionAdminServicios = pagoServicios.size * 3.5;
    // RECUPERACION
    const recuperacionRecargas =
      totalVentaRecargas - comisionUsuarioRecargas - comisionAdminRecargas;
    const recuperacionServicios = totalPagoServicios + cobroServicioServicios;
    // SALDO INICIAL
    const saldoInicalRecargas =
      saldosAlCierre.recargas +
      reporteCompras.recargas.total +
      comisionGeneradaRecargas;
    const saldoInicalServicios =
      saldosAlCierre.servicios + reporteCompras.servicios.total;
    // CANTIDAD FINAL
    const cantidadFinalRecargas = saldoInicalRecargas - totalVentaRecargas;
    const cantidadFinalServicios =
      saldoInicalServicios -
      (totalPagoServicios + pagoServicios.comision) +
      giftcards.abono;
    // VENTA USUARIOS
    const ventaUsuariosRecargas = obtenerVentaUsuarios(
      usuarios,
      transacciones.filter((trans) => ["1", "2"].includes(trans.CategoriaID))
    );
    const ventaUsuariosServicios = obtenerVentaUsuarios(
      usuarios,
      transacciones.filter((trans) => ["3", "4"].includes(trans.CategoriaID))
    );
    const timestamp = await Database.getServerDate();

    return {
      alCierre: saldosAlCierre,
      depositos: {
        recargas: reporteCompras.recargas,
        servicios: reporteCompras.servicios,
      },
      comisionGenerada: {
        recargas: comisionGeneradaRecargas,
      },
      inicial: {
        recargas: saldoInicalRecargas,
        servicios: saldoInicalServicios,
      },
      venta: {
        recargas: {
          total: totalVentaRecargas,
          registros: totalRegistrosRecargas,
        },
        servicios: {
          total: totalPagoServicios,
          registros: totalRegistrosServicios,
        },
      },
      comisionServicio: {
        recargas: cobroServicioRecargas,
        servicios: cobroServicioServicios,
      },
      comision: {
        usuario: {
          recargas: comisionUsuarioRecargas,
          servicios: comisionUsuarioServicios,
        },
        admin: {
          recargas: comisionAdminRecargas,
          servicios: comisionAdminServicios,
        },
      },
      abono: {
        usuario: giftcards.abono / 2,
        admin: giftcards.abono / 2,
      },
      cantidadFinal: {
        recargas: cantidadFinalRecargas,
        servicios: cantidadFinalServicios,
      },
      ventaUsuarios: {
        recargas: ventaUsuariosRecargas,
        servicios: ventaUsuariosServicios,
      },
      rango,
      periodo: Helpers.obtenerPeriodoTexto({ inicial, final }),
      recuperacionRecargas,
      recuperacionServicios,
      timestamp,
    };
  } catch ({ message }) {
    throw new Error(message);
  }
}

export async function obtenerReporteTicketPlus(range) {
  try {
    const [inicial, final] = range.split("_");
    // BOLETOS
    const reporteSemanal = await obtenerReporteSemanal({ inicial, final }, [
      "depositos",
    ]);
    // OBTENER LAS FECHAS DE LOS SORTEOS A PARTIR DE LOS BOLETOS VENDIDOS
    const fechasSorteos = encontrarFechasSorteos(reporteSemanal.boletos);
    // OBTENER PREMIOS PAGADOS QUE NO COINCIDAN CON NINGUN SORTEO
    const premiosPagadosOtros = reporteSemanal.premiosPagados.filter(
      (pp) => !fechasSorteos.includes(pp.fechaSorteo)
    );
    // OBTENER LOS SORTEOS QUE COINCIDAN CON LAS FECHAS ENCONTRADAS
    let sorteos = [];
    let sumaTotalVentasSorteos = 0;
    let sumaTotalPremiosSorteos = 0;
    let sumaTotalesSorteos = 0;
    let premiosEncontrados = 0;
    for (let i = 0; i < fechasSorteos.length; i++) {
      const fechaSorteo = fechasSorteos[i];
      const sorteo = await Database.getItemsByProp(
        DATABASE_TABLES.SORTEOS,
        "fecha",
        fechaSorteo
      );
      if (sorteo) {
        sorteos.push(sorteo[0]);
      }
    }
    let rows = [];
    for (let i = 0; i < sorteos.length; i++) {
      const sorteo = sorteos[i];
      const registros = reporteSemanal.boletos.filter((b) =>
        Moment(b.fechaSorteo).isSame(Moment(sorteo.fecha))
      );
      const venta = registros.reduce(
        (acc, reg) => acc + parseFloat(reg.totalApostado),
        0
      );
      const premiosFiltrados = reporteSemanal.premiosPagados.filter((pp) =>
        Moment(pp.fechaSorteo).isSame(Moment(sorteo.fecha))
      );
      const canceladosFiltrados = reporteSemanal.cancelados.filter((c) =>
        Moment(c.boleto?.fechaSorteo).isSame(Moment(sorteo.fecha))
      );
      const numerosGanadores = await Database.getItem(
        DATABASE_TABLES.NUMEROS_GANADORES,
        "fechaSorteo",
        sorteo.fecha
      );
      let boletosPremiados = [];
      // VEIFICAR SI SE HAN PUBLICADO LOS NUMEROS PARA EL SORTEO
      if (numerosGanadores) {
        boletosPremiados = encontrarBoletosPremiados(
          numerosGanadores.numeros,
          registros
        );
      }
      let users = {};
      // FILTRAR BOLETOS POR USUARI
      registros.forEach((boleto) => {
        if (users[boleto.numeroAgencia] === undefined) {
          users[boleto.numeroAgencia] = { boletos: [boleto] };
        } else {
          users[boleto.numeroAgencia].boletos.push(boleto);
        }
      });
      // USUARIOS
      let _usuarios = [];
      for (let user in users) {
        if (users.hasOwnProperty(user)) {
          const currentUser = users[user];
          const informeUsuario = {
            usuario: user,
            registros: 0,
            venta: 0,
            premios: 0,
            cancelados: 0,
            total: 0,
          };
          const ventaPorUsuario = currentUser.boletos.reduce(
            (acc, b) => acc + parseFloat(b.totalApostado),
            0
          );
          const premiosPorUsuario = premiosFiltrados
            .filter((pp) => pp.pagadoPor === user)
            .reduce((acc, el) => acc + parseFloat(el.premio), 0);
          const canceladosPorUsuario = canceladosFiltrados
            .filter((c) => c.agencia === user)
            .reduce((acc, el) => acc + parseFloat(el.reembolso), 0);
          informeUsuario.registros = currentUser.boletos.length;
          informeUsuario.venta = Money(ventaPorUsuario);
          informeUsuario.premios = Money(premiosPorUsuario);
          informeUsuario.cancelados = Money(canceladosPorUsuario);
          informeUsuario.total = Money(ventaPorUsuario - premiosPorUsuario);
          _usuarios.push(informeUsuario);
        }
      }
      // PREMIOS PAGADOS NO RELACIONADS A NINGUN SORTEO
      const totalPremios = premiosFiltrados.reduce(
        (acc, pf) => acc + parseFloat(pf.premio),
        0
      );
      // SUMAS VENTA, PREMIOS, COMISIONES, TOTALES
      sumaTotalVentasSorteos += venta;
      sumaTotalPremiosSorteos += totalPremios;
      sumaTotalesSorteos += venta - totalPremios;
      const premiosEncontradosSorteo = boletosPremiados.reduce(
        (acc, b) => acc + parseFloat(b.premio),
        0
      );
      premiosEncontrados += premiosEncontradosSorteo;
      rows.push({
        sorteo: sorteo.sorteo,
        fecha: sorteo.fecha,
        registros: registros.length,
        venta: venta,
        premios: totalPremios,
        cancelados: canceladosFiltrados.reduce(
          (acc, c) => acc + parseFloat(c.reembolso),
          0
        ),
        total: venta - totalPremios,
        usuarios: _usuarios,
        // PREMIOS ENCONTRADOS
        encontrados: premiosEncontradosSorteo,
      });
    }

    const totalPremiosPagadosOtros = premiosPagadosOtros.reduce(
      (acc, el) => acc + parseFloat(el.premio),
      0
    );
    // CALCULAR COMISIONES DE USUARIOS POR SEMANA
    const comisionSemanal = calcularComisionSemanal(rows);
    // sumaTotalesSorteos =
    //   sumaTotalesSorteos - (totalPremiosPagadosOtros + comisionSemanal);
    sumaTotalesSorteos = sumaTotalesSorteos - comisionSemanal;
    // TRANSACCIONES
    const tiempoAire = obtenerTotalTransacciones(
      reporteSemanal.transacciones,
      "1"
    );
    const paquetes = obtenerTotalTransacciones(
      reporteSemanal.transacciones,
      "2"
    );
    const totalVentaRecargas = tiempoAire.total + paquetes.total;
    const pagoServicios = obtenerTotalTransacciones(
      reporteSemanal.transacciones,
      "3"
    );
    const giftcards = obtenerTotalTransacciones(
      reporteSemanal.transacciones,
      "4"
    );
    const totalPagoServicios = pagoServicios.total + giftcards.total;
    const cobroServicioServicios = pagoServicios.comision;
    // COMISIONES
    const comisionUsuarioRecargas = totalVentaRecargas * 0.04;
    const comisionAdminServicios = pagoServicios.size * 3.5;
    const abonoAdmin = giftcards.abono / 2;
    // OPERACIONES
    const ventaMenosComisionUsuario =
      totalVentaRecargas - comisionUsuarioRecargas;
    const generalServicios =
      totalPagoServicios +
      cobroServicioServicios +
      comisionAdminServicios +
      abonoAdmin;
    const sumaTotalesGeneral =
      ventaMenosComisionUsuario + generalServicios + sumaTotalesSorteos;
    const estadosDeCuentaUsuarios = await obtenerEstadosDeCuentaUsuarios(
      reporteSemanal,
      { inicial, final }
    );
    const totalImportes = estadosDeCuentaUsuarios.reduce(
      (acc, el) => acc + (parseFloat(el.importe) + parseFloat(el.totalAbonos)),
      0
    );

    return {
      rows,
      periodo: Helpers.obtenerPeriodoTexto({ inicial, final }),
      premiosPagadosOtros: totalPremiosPagadosOtros,
      sumaTotalVentasSorteos,
      sumaTotalPremiosSorteos,
      sumaTotalesSorteos,
      comisionSemanal,
      premiosEncontrados,
      general: {
        recargas: ventaMenosComisionUsuario,
        servicios: generalServicios,
        ticketPlus: sumaTotalesSorteos,
        sumaTotales: sumaTotalesGeneral,
        resto: Math.abs(totalImportes) - Math.abs(sumaTotalesGeneral),
      },
    };
  } catch ({ message }) {
    throw new Error(message);
  }
}

function encontrarFechasSorteos(boletos = []) {
  let fechas = [];
  boletos.forEach((boleto) => {
    if (!fechas.includes(boleto.fechaSorteo)) {
      fechas.push(boleto.fechaSorteo);
    }
  });
  return fechas;
}

function obtenerTotalTransacciones(transacciones, categoriaID) {
  const filtered = [...transacciones].filter(
    (t) => t.CategoriaID == categoriaID
  );
  let total = 0;
  let comision = 0;
  let abono = 0;
  filtered.forEach((item) => {
    total += parseFloat(Money(item.Monto, false));
    if (["1", "2"].includes(categoriaID)) {
      comision += parseFloat(Money(item.Comision, false));
    }
    if (["3", "4"].includes(categoriaID)) {
      abono += parseFloat(Money(item.Abono, false));
      comision += parseFloat(Money(item.Cargo, false));
    }
  });

  return {
    total,
    size: filtered.length,
    comision,
    abono,
  };
}

function obtenerVentaUsuarios(usuarios, transacciones) {
  let rows = {};

  usuarios.forEach((usuario) => {
    const filtradasPorUsuario = transacciones.filter(
      (t) => t._usuario === usuario.usuario
    );
    rows[usuario.usuario] = {
      lunes: 0,
      martes: 0,
      miercoles: 0,
      jueves: 0,
      viernes: 0,
      sabado: 0,
      domingo: 0,
      total: 0,
    };
    const currentUser = rows[usuario.usuario];
    filtradasPorUsuario.forEach((trans) => {
      const transDay = Moment(trans._fecha).format("dddd");
      currentUser[transDay] += parseFloat(Money(trans.Monto, false));
      currentUser.total += parseFloat(Money(trans.Monto, false));
    });
  });

  let ventas = [];
  for (const key in rows) {
    if (rows.hasOwnProperty(key)) {
      ventas.push({ ...rows[key], usuario: key });
    }
  }

  return ventas;
}

async function obtenerSaldosAlCierre(fechaInicial) {
  let recargas = [];
  let servicios = [];
  // BUSCAMOS SALDOS ALMACENADOS
  const saldos = await Database.getItemsByProp(
    DATABASE_TABLES.SALDOS,
    "fecha",
    fechaInicial
  );
  // SI EXISTE SALDO ALMCENADO
  if (saldos !== undefined && saldos.length > 0) {
    return {
      recargas: parseFloat(Money(saldos[0].bolsas[0].Saldo, false)),
      servicios: parseFloat(Money(saldos[0].bolsas[1].Saldo, false)),
    };
  }
  // OBTENER TRASACCIONES
  // const transacciones = await Database.getItemsInRange(
  //   DATABASE_TABLES.TRANSACCIONES,
  //   "_fecha",
  //   fecha_lunes_anterior,
  //   fecha_domingo_anterior,
  //   (trans) => trans.Status === "Exitosa"
  // );
  // const transaccionesOrdenadas = Helpers.ordenarElementos(
  //   transacciones,
  //   (a, b) => Moment(b.Fecha).valueOf() - Moment(a.Fecha).valueOf()
  // );
  // transaccionesOrdenadas.forEach((transaccion) => {
  //   if (transaccion.CategoriaID == "1" || transaccion.CategoriaID == "2") {
  //     recargas.push(transaccion);
  //   }
  //   if (transaccion.CategoriaID == "3" || transaccion.CategoriaID == "4") {
  //     servicios.push(transaccion);
  //   }
  // });
  // SALDO ULTIMA RECARGA REGISTRADA
  const ultimaRecargaRegistrada =
    recargas.length > 0
      ? parseFloat(Money(recargas[0]["Saldo Final"], false))
      : 0;
  // ULTIMO SERVICIO REGISTRADO
  const ultimoServicioRegistrado =
    servicios.length > 0
      ? parseFloat(Money(servicios[0]["Saldo Final"], false))
      : 0;

  return {
    recargas: ultimaRecargaRegistrada,
    servicios: ultimoServicioRegistrado,
  };
}

function obtenerFechaLunesAnterior(fechaReferencia) {
  let counter = 0;
  while (
    Moment(fechaReferencia).subtract(counter, "days").format("dddd") !== "lunes"
  ) {
    counter++;
  }
  return Moment(fechaReferencia).subtract(counter, "days").format("YYYY-MM-DD");
}

function calcularComisionSemanal(sorteos) {
  let VENTAS_USUARIOS = {};
  let comision_semanal = 0;
  sorteos.forEach((sorteo) => {
    sorteo.usuarios.forEach((usuario) => {
      if (VENTAS_USUARIOS[usuario.usuario] === undefined) {
        VENTAS_USUARIOS[usuario.usuario] = { ventas: [usuario.venta] };
      } else {
        VENTAS_USUARIOS[usuario.usuario].ventas.push(usuario.venta);
      }
    });
  });

  for (const key in VENTAS_USUARIOS) {
    if (Object.hasOwnProperty.call(VENTAS_USUARIOS, key)) {
      const venta_semanal_usuario = VENTAS_USUARIOS[key].ventas.reduce(
        (acc, el) => acc + parseFloat(Money(el, false)),
        0
      );
      comision_semanal += calcularComisionVentaTickets(venta_semanal_usuario);
    }
  }

  return comision_semanal;
}

function calcularComisionVentaTickets(totalVenta) {
  if (totalVenta > 10000) return totalVenta * 0.15;
  if (totalVenta > 5001) return totalVenta * 0.12;
  return totalVenta * 0.1;
}

function encontrarBoletosPremiados(numeros, boletos) {
  let boletosPremiados = [];
  boletos.forEach((boleto) => {
    numeros.forEach((numero, ngIndex) => {
      const { jugadas } = boleto;
      jugadas.forEach((jugada) => {
        const ngCifras = {
          tres: numero,
          dos: numero.slice(1),
          una: numero.slice(2),
        };
        let cantidadApostada = parseInt(jugada.lugares[ngIndex]);
        let boletoGuardadoIndex = boletosPremiados.findIndex(
          (el) => el.boletoKey === boleto.key
        );
        // SI YA SE GUARDO EL BOLETO
        if (boletoGuardadoIndex !== -1) {
          // TRES CIFRAS
          if (ngCifras.tres == jugada.numero && cantidadApostada) {
            boletosPremiados[boletoGuardadoIndex].premio +=
              cantidadApostada * 550;
          }
          // DOS CIFRAS
          if (ngCifras.dos == jugada.numero && cantidadApostada) {
            boletosPremiados[boletoGuardadoIndex].premio +=
              cantidadApostada * 70;
          }
          // DOS CIFRAS
          if (ngCifras.una == jugada.numero && cantidadApostada) {
            boletosPremiados[boletoGuardadoIndex].premio +=
              cantidadApostada * 7;
          }
        } else {
          // TRES CIFRAS
          if (
            ngCifras.tres == jugada.numero &&
            cantidadApostada &&
            boletoGuardadoIndex === -1
          ) {
            boletosPremiados.push({
              premio: cantidadApostada * 550,
              fechaSorteo: boleto.fechaSorteo,
              boletoKey: boleto.key,
              numeroBoleto: boleto.numeroBoleto,
              usuario: boleto.numeroAgencia,
            });
          }
          // DOS CIFRAS
          if (ngCifras.dos == jugada.numero && cantidadApostada) {
            boletosPremiados.push({
              premio: cantidadApostada * 70,
              fechaSorteo: boleto.fechaSorteo,
              boletoKey: boleto.key,
              numeroBoleto: boleto.numeroBoleto,
              usuario: boleto.numeroAgencia,
            });
          }
          // UNA CIFRA
          if (ngCifras.una == jugada.numero && cantidadApostada) {
            boletosPremiados.push({
              premio: cantidadApostada * 7,
              fechaSorteo: boleto.fechaSorteo,
              boletoKey: boleto.key,
              numeroBoleto: boleto.numeroBoleto,
              usuario: boleto.numeroAgencia,
            });
          }
        }
      });
    });
  });

  return boletosPremiados;
}

async function obtenerReportesCompras(fechas) {
  try {
    // tipo_bolsa [RECARGAS = 1, SERVICIOS = 2]
    let compras = [];
    let recargas = {
      total: 0,
      registros: 0,
    };
    let servicios = {
      total: 0,
      registros: 0,
    };
    for (let i = 0; i < fechas.length; i++) {
      const res = await getReport(fechas[i]);
      const data = res.data.data;
      if (data.length > 0) {
        data.forEach((item) => compras.push(item));
      }
    }
    // RECORRER COMPRAS
    compras.forEach((compra) => {
      if (compra.tipo_bolsa == "1") {
        recargas.total += parseFloat(compra.Cantidad);
        recargas.registros++;
      }
      if (compra.tipo_bolsa == "2") {
        servicios.total += parseFloat(compra.Cantidad);
        servicios.registros++;
      }
    });

    return { recargas, servicios };
  } catch ({ message }) {
    throw new Error(message);
  }
}

function obtenerfechasSemana(inicial, final) {
  let fechas = [];
  let counter = 0;
  while (
    !Moment(inicial).add(counter, "days").isSame(Moment(final).add(1, "days"))
  ) {
    fechas.push(Moment(inicial).add(counter, "days").format("YYYY-MM-DD"));
    counter++;
  }
  return fechas;
}
