import { Moment, Money } from "../../../utils";
import TableSection from "./TableSection";
import DataRow from "../../../components/DataRow";

export default function AccountStatus({ accountStatus }) {
  const { lastInform } = accountStatus;
  return (
    <>
      <TableSection>
        <DataRow
          data={[
            `SALDO ANTERIOR AL ${Moment(lastInform.end)
              .format("dddd DD MMMM YYYY")
              .toUpperCase()}`,
            Money(lastInform.amount),
          ]}
        />
        <DataRow
          data={[
            "PREMIOS PAGADOS DEL LUNES AL MIERCOLES ACTUAL",
            Money(accountStatus.paidPrizesBeforeWeekPaymentLimitDay.total),
          ]}
        />
        <DataRow data={["SU PAGO", Money(lastInform.totalDeposits)]} />
        <DataRow
          data={["SALDO VENCIDO", Money(accountStatus.dueBalance)]}
          bgColor={accountStatus.dueBalance <= 1 ? "#1EC03A" : "tomato"}
        />
      </TableSection>
      <TableSection title="VENTA DE LA SEMANA">
        <DataRow
          data={[
            "TICKET PLUS",
            accountStatus.tickets.recordsFound,
            Money(accountStatus.tickets.total),
          ]}
        />
        <DataRow
          data={[
            "RECARGAS",
            accountStatus.recharges.recordsFound,
            Money(accountStatus.recharges.total),
          ]}
        />
        <DataRow
          data={[
            "CARGO POR SERVICIO DE RECARGA AL CLIENTE",
            accountStatus.recharges.recordsFound,
            Money(accountStatus.recharges.chargeToClientForService),
          ]}
        />
        <DataRow
          data={[
            "SERVICIOS",
            accountStatus.paidServices.recordsFound,
            Money(accountStatus.paidServices.total),
          ]}
        />
        <DataRow
          data={[
            "GIFT CARDS",
            accountStatus.giftCards.recordsFound,
            Money(accountStatus.giftCards.total),
          ]}
        />
        <DataRow
          data={["TOTAL VENTAS", "", Money(accountStatus.totalSalesSum)]}
          bgColor="orange"
          textColor="#000"
        />
      </TableSection>
      <TableSection title="PREMIOS Y COMISIONES">
        <DataRow
          data={[
            "PREMIOS PAGADOS DEL JUEVES A DOMINGO ACTUAL",
            accountStatus.paidPrizesAfterWeekPaymentLimitDay.recordsFound,
            Money(accountStatus.paidPrizesAfterWeekPaymentLimitDay.total),
          ]}
        />
        <DataRow
          data={[
            "COMISION TICKET PLUS",
            accountStatus.tickets.recordsFound,
            Money(accountStatus.tickets.commission),
          ]}
        />
        <DataRow
          data={[
            "COMISION RECARGAS",
            accountStatus.recharges.recordsFound,
            Money(accountStatus.recharges.commission),
          ]}
        />
        <DataRow
          data={[
            "COMISION PAGO SERVICIOS",
            accountStatus.paidServices.recordsFound,
            Money(accountStatus.paidServices.commission),
          ]}
        />
        <DataRow
          data={[
            "COMISION GIFT CARDS",
            accountStatus.giftCards.recordsFound,
            Money(accountStatus.giftCards.commission),
          ]}
        />
        <DataRow
          data={[
            "COMISION COBRADA AL CLIENTE",
            accountStatus.recharges.recordsFound,
            Money(accountStatus.commissionChargedToClient),
          ]}
        />
        <DataRow
          data={[
            "TOTAL PREMIOS Y COMISIONES",
            "",
            Money(accountStatus.totalCommissionsSum),
          ]}
          bgColor="orange"
          textColor="#000"
        />
      </TableSection>
      <TableSection title="ABONOS, AJUSTES Y REEMBOLSOS">
        <DataRow
          data={[
            "ABONOS",
            accountStatus.payouts.recordsFound,
            Money(accountStatus.payouts.total),
          ]}
        />
        <DataRow
          data={[
            "REEMBOLSO",
            accountStatus.refunds.recordsFound,
            Money(accountStatus.refunds.total),
          ]}
        />
        <DataRow
          data={[
            "AJUSTES",
            accountStatus.adjustments.recordsFound,
            Money(accountStatus.adjustments.total),
          ]}
        />
        <DataRow
          data={[
            "TOTAL PREMIOS Y COMISIONES MENOS ABONOS",
            "",
            Money(accountStatus.totalPrizesAndCommissionsWithoutPayouts),
          ]}
          bgColor="orange"
          textColor="#000"
        />
        <div style={{ marginTop: 50 }}></div>
        <DataRow
          data={[
            "CANCELADOS",
            accountStatus.canceledTickets.recordsFound,
            Money(accountStatus.canceledTickets.total),
          ]}
        />
        <DataRow data={["IMPORTE", "", Money(accountStatus.amount)]} />
        <DataRow
          data={[
            "CREDITO DISPONIBLE",
            "",
            Money(accountStatus.creditoDisponible),
          ]}
        />
      </TableSection>
    </>
  );
}
