import { Backdrop } from "@mui/material";
import { PDFViewer } from "@react-pdf/renderer";
import React from "react";

export default function CustomPDFViewer({ open = false, onClick, children }) {
  return (
    <Backdrop
      open={open}
      onClick={onClick}
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <PDFViewer width="90%" height="90%">
        {children}
      </PDFViewer>
    </Backdrop>
  );
}
