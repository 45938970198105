import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Database from "../../../database";
import { selectPeriodo } from "../../../features/administracion/administracionSlice";
import { Moment, uuid } from "../../../utils";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useParams } from "react-router-dom";

export default function RangoSelector() {
  const { tabName } = useParams();
  const [listaOpcionValue, setListaOpcionValue] = useState("");
  const [cargandoBotonesPeriodo, setCargandoBotonesPeriodo] = useState(true);
  const [periodos, setPeriodos] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    cargarPeriodos();
  }, []);

  const cargarPeriodos = async () => {
    setCargandoBotonesPeriodo(true);
    const timestamp = await Database.getServerDate();
    const semanas = [];
    for (let i = 0; i < 4; i++) {
      const periodo = obtenerPeriodo(timestamp, i);
      const fecha = {
        id: uuid(),
        inicial: periodo[0],
        final: periodo[periodo.length - 1],
        active: i === 0,
      };
      semanas.push(fecha);
    }
    setPeriodos(semanas);
    setCargandoBotonesPeriodo(false);
    setListaOpcionValue(semanas[0].id);
    dispatch(selectPeriodo(semanas[0]));
  };

  const obtenerPeriodo = (timestamp, semanaID) => {
    let ocurrencies = 4;
    let ocurrenciesCounter = 0;
    let counter = 0;
    let dias_lunes = [];
    // OBTENEMOS SOLO LOS DIAS LUNES
    while (ocurrenciesCounter !== ocurrencies) {
      if (
        Moment(timestamp)
          .subtract(counter, "days")
          .format("dddd")
          .toLowerCase() === "lunes"
      ) {
        dias_lunes.push(
          Moment(timestamp).subtract(counter, "days").format("YYYY-MM-DD")
        );
        ocurrenciesCounter++;
      }
      counter++;
    }
    // FECHAS FINALES
    const fechasFinales = {
      0: Moment(timestamp).format("YYYY-MM-DD"),
      1: Moment(dias_lunes[0]).subtract(1, "days").format("YYYY-MM-DD"),
      2: Moment(dias_lunes[1]).subtract(1, "days").format("YYYY-MM-DD"),
      3: Moment(dias_lunes[2]).subtract(1, "days").format("YYYY-MM-DD"),
    };
    // OBTENER DIAS DE LA SEMANA
    let _fechas = [];
    let counter2 = 0;
    while (
      !Moment(dias_lunes[semanaID])
        .add(counter2, "days")
        .isSame(Moment(fechasFinales[semanaID]))
    ) {
      _fechas.push(
        Moment(dias_lunes[semanaID]).add(counter2, "days").format("YYYY-MM-DD")
      );
      counter2++;
    }
    _fechas.push(
      Moment(dias_lunes[semanaID]).add(counter2, "days").format("YYYY-MM-DD")
    );
    return _fechas;
  };

  const handleSelectPeriodo = ({ target }) => {
    const id = target.value;
    if (id !== "") {
      setListaOpcionValue(target.value);
      setPeriodos(
        periodos.map((el) => ({
          ...el,
          active: el.id === id,
        }))
      );
      dispatch(selectPeriodo(periodos.find((item) => item.id === id)));
    }
  };

  return (
    <>
      <FormControl sx={{ minWidth: 200 }} size="small">
        <InputLabel id="periodo-select-label">Periodo</InputLabel>
        <Select
          disabled={
            cargandoBotonesPeriodo ||
            ["registroPagos", "pagosPendientes"].includes(tabName)
          }
          labelId="periodo-select-label"
          value={listaOpcionValue}
          label="Periodo"
          onChange={handleSelectPeriodo}
        >
          {periodos.map((item, index) => (
            <MenuItem key={item.id} value={item.id}>
              {getOptionText(item, index)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
}

const getOptionText = (periodo, index) => {
  return (
    "Del " +
    Moment(periodo.inicial).format("DD MMM") +
    " al " +
    Moment(periodo.final).format("DD MMM")
  );
};
