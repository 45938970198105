import axios from "axios";
import { Helpers, Moment } from "../../utils";
import Database from "../../database";
import { DATABASE_TABLES } from "../../constants";
const qs = require("qs");

export const registroCuenta = async ({
  nombre,
  apellidos,
  correo,
  telefono,
  nomComercial,
  forzarActivacion,
}) => {
  try {
    const url = process.env.REACT_APP_TAECEL_BASE_URL + "/RegistroCuenta";
    const data = qs.stringify({
      key: process.env.REACT_APP_TAECEL_KEY,
      nip: process.env.REACT_APP_TAECEL_NIP,
      nombre,
      apellidos,
      correo,
      telefono,
      nomComercial,
      forzarActivacion,
    });
    const res = await axios.post(url, data);
    // SI SE CREO LA CUENTA RETORNA SU NUEVA INFORMACION
    return res;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getBalance = async () => {
  try {
    const url = process.env.REACT_APP_TAECEL_BASE_URL + "/getBalance";
    const data = qs.stringify({
      key: process.env.REACT_APP_TAECEL_KEY,
      nip: process.env.REACT_APP_TAECEL_NIP,
    });
    // console.time('request');
    const res = await axios.post(url, data);
    // console.log(res.data);
    return res;
    // console.timeEnd('request');
  } catch (error) {
    console.log("[Error]: " + error.message, "[Func]: getBalance");
    throw Error("Error al obtener el balance");
  }
};

export const getReport = async (date) => {
  try {
    const url = process.env.REACT_APP_TAECEL_BASE_URL + "/getReports";
    const data = qs.stringify({
      key: process.env.REACT_APP_TAECEL_KEY,
      nip: process.env.REACT_APP_TAECEL_NIP,
      fecha: date,
    });
    const res = await axios.post(url, data);
    return res;
  } catch ({ message }) {
    throw new Error(message);
  }
};
// OBTENER TRANSACCIONES
const GET_TRANS_OPTIONS = {
  limitarNumTrans: false,
};
export async function obtenerTransacciones(options = { ...GET_TRANS_OPTIONS }) {
  try {
    const config = { ...GET_TRANS_OPTIONS, ...options };
    // SI LIMITAR NUM TRANS ES VERDADERO
    if (config.limitarNumTrans) {
      const timestamp = await Database.getServerDate();
      const fechaFinal = Moment(timestamp).format("YYYY-MM-DD");
      const fechaInicial = Moment(timestamp)
        .subtract(30, "days")
        .format("YYYY-MM-DD");
      const _transacciones = await Database.getItemsInRange(
        DATABASE_TABLES.TRANSACCIONES,
        "_fecha",
        fechaInicial,
        fechaFinal
      );
      return Helpers.ordenarElementos(
        _transacciones,
        (a, b) =>
          Moment(b.Fecha || b._fecha + " " + b._hora).valueOf() -
          Moment(a.Fecha || a._fecha + " " + a._hora).valueOf()
      );
    }
    const _transacciones = await Database.getItems(
      DATABASE_TABLES.TRANSACCIONES
    );
    return Helpers.ordenarElementos(
      _transacciones,
      (a, b) =>
        Moment(b.Fecha || b._fecha + " " + b._hora).valueOf() -
        Moment(a.Fecha || a._fecha + " " + a._hora).valueOf()
    );
  } catch ({ message }) {
    throw new Error(message);
  }
}
// PENDING TRANSACTIONS
export async function getPendingTransactions() {
  try {
    const transactions = await Database.getItems(
      DATABASE_TABLES.TRANSACTIONS,
      (txn) => txn.CategoriaID == undefined
    );
    const sortedTransactions = Helpers.ordenarElementos(
      transactions,
      (a, b) => Moment(b.Fecha).valueOf() - Moment(a.Fecha).valueOf()
    );
    return sortedTransactions;
  } catch ({ message }) {
    throw new Error(message);
  }
}
