import { Table, TableCell, TableHead, TableRow } from "@mui/material";
import { Helpers, Moment, uuid } from "../../../../utils";

export default function InfoBoleto({ boleto, lugares = [] }) {
  return (
    <div style={{ maxWidth: 280, margin: "0 auto" }}>
      <h4 style={{ margin: 0, marginBottom: 5, textAlign: "center" }}>
        Ticket Plus
      </h4>
      <div style={{ textAlign: "center" }}>
        Sorteo {Moment(boleto.fechaSorteo).format("llll")}
      </div>
      <div>
        <p style={{ padding: 0, margin: 0 }}>Agencia {boleto.numeroAgencia}</p>
        <p style={{ padding: 0, margin: 0 }}>
          Impresión {Moment(boleto.fechaExp).format("DD/MM/YYYY")}{" "}
          {boleto.horaImpresion}
        </p>
      </div>
      <div>
        <div style={{ fontWeight: "bolder" }}>{Helpers.generateHashes(29)}</div>
        <Table padding="none">
          <TableHead>
            <TableRow>
              <TableCell sx={{ textAlign: "right" }}>Apuestas</TableCell>
              {lugares.map((lugar) => (
                <TableCell
                  sx={{
                    textAlign: "right",
                    color: lugar === "empty" ? "transparent" : "",
                  }}
                  key={uuid()}
                >
                  {lugar}
                </TableCell>
              ))}
            </TableRow>
            {boleto.jugadas.map((jugada) => (
              <TableRow sx={{ textAlign: "right" }} key={uuid()}>
                <TableCell
                  sx={{
                    padding: 1,
                    border: 0,
                    textAlign: "right",
                  }}
                >
                  {jugada.numero}
                </TableCell>
                {jugada.lugares.map((lugar) => (
                  <TableCell
                    key={uuid()}
                    sx={{
                      textAlign: "right",
                      padding: 1,
                      border: 0,
                    }}
                  >
                    {lugar === "0" ? "X" : lugar}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
        </Table>
        <div style={{ fontWeight: "bolder" }}>{Helpers.generateHashes(29)}</div>
        <div>
          <p style={{ margin: 0 }}>Jugadas {boleto.jugadas.length}</p>
          <p style={{ margin: 0 }}>Total {boleto.totalApostado} Pts</p>
          <p style={{ margin: 0 }}>ID {boleto.numeroBoleto}</p>
        </div>
      </div>
    </div>
  );
}
