import { Colors } from "../constants";

const TextLoading = ({
    marginTop = 0,
    marginBottom = 0,
    marginLeft = 0,
    marginRight = 0,
    background = Colors.darkGray,
    width = 100,
    height = 10,
}) => {
    return (
        <div
            style={{
                marginTop,
                marginBottom,
                marginLeft,
                marginRight,
                background,
                width,
                height,
            }}
        ></div>
    );
};

export default TextLoading;
