import { Grid, Paper, Typography } from "@mui/material";

export default function InformeGeneralLayout({
  title,
  subtitle,
  action,
  children,
}) {
  return (
    <Paper>
      <Grid sx={{ padding: 2 }} container>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography>{title}</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Typography sx={{ textAlign: "right" }}>{subtitle}</Typography>
          {action}
        </Grid>
        <Grid item xs={12} sx={{ paddingTop: 2 }}>
          {children}
        </Grid>
      </Grid>
    </Paper>
  );
}
