// ROUTER
import { useNavigate } from "react-router-dom";
// REDUX
import { useSelector } from "react-redux";
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
// CUSTOM COMPONENTS
import { ScrollView, CustomAvatar } from "../../../components";
import SorteosTableLoader from "./SorteosTableLoader";
// CONSTANTS
import { Colors } from "../../../constants";
// UTILS
import { Helpers, Moment } from "../../../utils";

export default function SorteosTable() {
  const { cargandoSorteos } = useSelector((state) => state.sorteos);
  const navigate = useNavigate();

  const handleRowClick = (item) => {
    navigate(item.key);
  };
  return (
    <Card sx={{ marginTop: 1.8 }}>
      <CardHeader subheader={<Typography>Todos los sorteos</Typography>} />
      <CardContent>
        <ScrollView scrollBoth>
          {cargandoSorteos && <SorteosTableLoader />}
          {!cargandoSorteos && <Tabla handleRowClick={handleRowClick} />}
        </ScrollView>
      </CardContent>
    </Card>
  );
}

const Tabla = ({ handleRowClick }) => {
  const { celebrados } = useSelector((state) => state.sorteos);

  return (
    <Table stickyHeader>
      <TableHead>
        <TableRow>
          <TableCell>Logo</TableCell>
          <TableCell>Nombre</TableCell>
          <TableCell>Fecha celebración</TableCell>
          <TableCell>Código</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {celebrados.map((item) => (
          <TableRow
            key={item.id}
            onClick={() => handleRowClick(item)}
            sx={{
              "&:hover": {
                background: "rgba(0,0,0,0.075)",
                cursor: "pointer",
              },
            }}
          >
            <TableCell align="left">
              <CustomAvatar src={Helpers.setImgSrc(item.codigoSorteo)} />
            </TableCell>
            <TableCell align="left" sx={{ color: Colors.smoke }}>
              {item.sorteo}
            </TableCell>
            <TableCell align="left" sx={{ color: Colors.smoke }}>
              {Moment(item.fecha).format("ddd DD MMM YY")}
            </TableCell>
            <TableCell align="left" sx={{ color: Colors.smoke }}>
              {item.codigoSorteo}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
