import { Grid } from "@mui/material";
import TablaBoletos from "./components/TablaBoletos";
import NumerosJugados from "./components/NumerosJugados";
import SectionTitle from "../../components/SectionTitle";

export default function Boletos() {
  return (
    <>
      <Grid container rowSpacing={2} columnSpacing={2}>
        <Grid item xs={12}>
          <SectionTitle title="Boletos" />
        </Grid>
        <Grid item xs={12}>
          {/* CARD COMPONENT */}
          <TablaBoletos />
        </Grid>
        <Grid item xs={12}>
          {/* CARD COMPONENT */}
          <NumerosJugados />
        </Grid>
      </Grid>
    </>
  );
}
