import { useState, useEffect } from "react";
import { FormControl, MenuItem, Select } from "@mui/material";
import { obtenerUsuarios } from "../../../services/bola";
import {
    setCargandoUsuarios,
    setListaUsuarios,
    setUsuarioKey,
} from "../../../features/usuarios/usuariosSlice";
import { useSelector, useDispatch } from "react-redux";
import { filtrarCanceladosUsuario } from "../../../features/cancelados/canceladosSlice";
import { uuid } from "../../../utils";

const SelectUsuario = () => {
    const usuariosState = useSelector((state) => state.usuarios);
    const [optionKey, setOptionKey] = useState("");
    const dispatch = useDispatch();

    useEffect(() => {
        cargarUsuarios();
    }, []);

    const cargarUsuarios = async () => {
        dispatch(setCargandoUsuarios(true));
        const items = await obtenerUsuarios();
        dispatch(setListaUsuarios(items));
        dispatch(setCargandoUsuarios(false));
    };

    const onSelect = ({ target }) => {
        const usuarioKey = target.value;
        setOptionKey(target.value);
        setTimeout(() => {
            dispatch(setUsuarioKey(usuarioKey));
            const usuario = usuariosState.lista.find(
                (u) => u.key === usuarioKey
            );
            dispatch(
                filtrarCanceladosUsuario(
                    usuario !== undefined ? usuario.usuario : ""
                )
            );
        }, 100);
    };

    return (
        <FormControl fullWidth>
            <Select
                size="small"
                displayEmpty
                value={optionKey}
                onChange={onSelect}
                disabled={usuariosState.cargarUsuarios}
            >
                <MenuItem value="">
                    <span style={{ fontSize: 14 }}>Todos</span>
                </MenuItem>
                {usuariosState.lista.map((u) => (
                    <MenuItem key={uuid()} value={u.key}>
                        <span style={{ fontSize: 14 }}>{u.nomComercial}</span>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default SelectUsuario;
