import { Alert, Snackbar } from "@mui/material";

const Toast = ({
    open,
    handleClose,
    message,
    autoHideDuration = 5000,
    severity = "success",
    position = "bottom-right",
}) => {
    const _position = {
        vertical: position.split("-")[0],
        horizontal: position.split("-")[1],
    };
    return (
        <Snackbar
            anchorOrigin={_position}
            open={open}
            autoHideDuration={autoHideDuration}
            onClose={handleClose}
        >
            <Alert
                onClose={handleClose}
                severity={severity}
                sx={{ width: "100%" }}
            >
                {message}
            </Alert>
        </Snackbar>
    );
};

export default Toast;
