import axios from "axios";
import { DATABASE_TABLES } from "../../constants";
import Database from "../../database";
import { Storage } from "../../utils";
const bcrypt = require("bcryptjs");

export const authenticate = async (usuario = "", password = "") => {
  try {
    const usuarioDB = await Database.getItem(
      DATABASE_TABLES.AGENCIAS,
      "usuario",
      usuario
    );
    // SI NO EXISTE
    if (!usuarioDB) return false;
    // VERIFICAR TIPO
    // if (!"admin.dev".split(".").includes(usuarioDB.tipoUsuario))
    //   throw new Error("Lo sentimos, no tienes los permisos suficientes.");
    // COMPARAR PASSWORDS
    const passwordsMatched = await bcrypt.compare(
      password,
      usuarioDB.bpassword
    );
    // RETORNA TRUE SI COINCIDEN O FALSO SI NO
    return passwordsMatched;
  } catch ({ message }) {
    throw new Error(message);
  }
};

export async function verifyUser(password) {
  try {
    const usuarioStorage = Storage.get("usuario", true);
    const autenticacionValida = await authenticate(
      usuarioStorage.usuario,
      password
    );
    // SI NO TIENE PERMISO
    if (!autenticacionValida)
      throw new Error("Contraseña incorrecta, intenta nuevamente.");
    return true;
  } catch ({ message }) {
    throw new Error(message);
  }
}

export async function auth(user, password) {
  try {
    const URL = process.env.REACT_APP_AUTH_BASE_URL + "/login";
    const data = {
      email: user,
      password,
    };
    const res = await axios.post(URL, data);
    return res.data;
  } catch ({ message }) {
    throw new Error(message);
  }
}

export async function authLogout(user) {
  try {
    const URL = process.env.REACT_APP_AUTH_BASE_URL + "/logout";
    const data = {
      email: user,
    };
    const res = await axios.post(URL, data);
    return res.data;
  } catch ({ message }) {
    throw new Error(message);
  }
}
