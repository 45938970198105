import { useEffect } from "react";
import ListaSorteosPendientes from "./components/ListaSorteosPendientes";
import { Grid } from "@mui/material";
import SorteosTable from "./components/SorteosTable";
import AgregarSorteo from "./components/AgregarSorteo";
import AgregarOpcionLista from "./components/AgregarOpcionLista";
import { Helpers, Moment } from "../../utils";
import { useDispatch } from "react-redux";
import { obtenerSorteos } from "../../services/bola";
import {
  setCargandoSorteos,
  setCargandoSorteosPendientes,
  setSorteos,
  setSorteosCelebrados,
} from "../../features/sorteos/sorteosSlice";
import Ajustes from "./components/Ajustes";
import Database from "../../database";
import { DATABASE_TABLES } from "../../constants";
import SectionTitle from "../../components/SectionTitle";

export default function Sorteos() {
  const dispatch = useDispatch();

  useEffect(() => {
    cargarSorteos();
  }, []);

  const cargarSorteos = async () => {
    try {
      dispatch(setCargandoSorteos(true));
      dispatch(setCargandoSorteosPendientes(true));
      const items = await obtenerSorteos();
      const timestamp = await Database.getServerDate();
      const horaCierre = await Database.getObject(DATABASE_TABLES.HORA_CIERRE);
      const fechaServidor = Moment(timestamp).format("YYYY-MM-DD");
      let celebrados = Helpers.filtrarSorteosCelebrados(
        items,
        fechaServidor,
        horaCierre
      );
      dispatch(setSorteosCelebrados(Helpers.ordenarLista(celebrados, "fecha")));
      dispatch(setSorteos(Helpers.ordenarLista(items, "fecha")));
      dispatch(setCargandoSorteos(false));
    } catch ({ message }) {
      alert(message);
    }
  };

  return (
    <>
      <Grid container rowSpacing={2} columnSpacing={2}>
        <Grid item xs={12}>
          <SectionTitle title="Sorteos" />
        </Grid>
        <Grid item xs={12} md={8}>
          {/* CARD COMPONENT */}
          <Ajustes />
          {/* CARD COMPONENT */}
          <ListaSorteosPendientes />
          {/* SORTEOS TABLE */}
          <SorteosTable />
        </Grid>

        <Grid item xs={12} md={4}>
          {/* AGREGAR SORTEOS */}
          <AgregarSorteo />
          {/* CARD COMPONENT */}
          <AgregarOpcionLista />
        </Grid>
      </Grid>
    </>
  );
}
