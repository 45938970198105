import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AuthContextProvider } from "./context/authContext";
import Login from "./routes/login";
import PublicRoutes from "./routes/publicRoutes";
import ProtectedRoutes from "./routes/protectedRoutes";
import Panel from "./routes/panel";
import Sorteos from "./routes/sorteos";
import Sorteo from "./routes/sorteos/pages/sorteo";
import Boletos from "./routes/boletos";
import Cancelados from "./routes/cancelados";
import Reportes from "./routes/reportes";
import Usuarios from "./routes/usuarios";
import Boleto from "./routes/boletos/pages/boleto";
import UsuarioInfoCard from "./routes/usuarios/components/UsuarioInfoCard";
import LiberarEspacio from "./routes/liberarEspacio";
import Administracion from "./routes/administracion";
import BoletoCancelado from "./routes/cancelados/pages/BoletoCancelado";
import PagoPendiente from "./routes/administracion/pages/PagoPendiente";
import TestingScreen from "./routes/testing-screen";

function App() {
  return (
    <AuthContextProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<PublicRoutes />}>
            <Route index path="login" element={<Login />} />
          </Route>
          <Route path="/panel" element={<ProtectedRoutes />}>
            <Route path="/panel" element={<Panel />}>
              <Route path="sorteos" element={<Sorteos />} />
              <Route path="sorteos/:sorteoKey" element={<Sorteo />} />
              <Route path="boletos" element={<Boletos />} />
              <Route path="boletos/:boletoKey" element={<Boleto />} />
              <Route path="cancelados" element={<Cancelados />} />
              <Route
                path="cancelados/:boletoKey"
                element={<BoletoCancelado />}
              />
              <Route path="reportes" element={<Reportes />} />
              <Route path="usuarios" element={<Usuarios />}>
                <Route path=":usuarioKey" element={<UsuarioInfoCard />} />
              </Route>
              <Route path="liberar-espacio" element={<LiberarEspacio />} />
              <Route
                path="administracion/:tabName"
                element={<Administracion />}
              />
              <Route
                path="administracion/pagosPendientes/:boletoKey"
                element={<PagoPendiente />}
              />
              <Route path="testing-screen" element={<TestingScreen />} />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </AuthContextProvider>
  );
}

export default App;
