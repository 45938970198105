import { createSlice } from "@reduxjs/toolkit";
import { Helpers } from "../../utils";

const initialState = {
  cargandoUsuarios: true,
  lista: [],
  filtrados: [],
  usuarioKey: "",
  codigoUsuario: "",
  totalUsuarios: 0,
  selectedUsuario: null,
  toolbar: {
    informacion: true,
    editar: false,
    abonar: false,
    cambiarEstado: false,
    estado: false,
  },
  cargandoAbonos: false,
  listaAbonos: [],
  dispositivos: [],
};

export const usuariosSlice = createSlice({
  name: "usuarios",
  initialState,
  reducers: {
    setCargandoUsuarios: (state, { payload }) => {
      state.cargandoUsuarios = payload;
    },
    setListaUsuarios: (state, { payload }) => {
      state.lista = Helpers.sortList([...payload], "usuario");
      state.filtrados = Helpers.sortList([...payload], "usuario");
      state.totalUsuarios = [...payload].length;
    },
    setUsuarioKey: (state, { payload }) => {
      state.usuarioKey = payload;
    },
    setCodigoUsuario: (state, { payload }) => {
      state.codigoUsuario = payload;
    },
    buscarUsuario: (state, { payload }) => {
      let copiaLista = [...state.lista];
      let busqueda = payload.toLowerCase();
      // FILTRAR POR BUSQUEDA
      const resultadoBusqueda = copiaLista.filter(
        (u) =>
          u.nomComercial.toLowerCase().match(busqueda) ||
          u.usuario.match(busqueda)
      );
      state.filtrados = resultadoBusqueda;
      state.totalUsuarios = resultadoBusqueda.length;
    },
    actualizarUsuarioEnLista: (state, { payload }) => {
      let copiaLista = [...state.lista];
      let usuarioIndex = copiaLista.findIndex((u) => u.key === payload.key);
      copiaLista[usuarioIndex] = payload;
      state.lista = copiaLista;
      state.filtrados = copiaLista;
    },
    setSelectedUsuario: (state, { payload }) => {
      // console.log(payload);
      state.selectedUsuario = { ...payload };
    },
    setToolbarOptionsState: (state, { payload }) => {
      const copyToolbar = { ...state.toolbar };
      for (const key in copyToolbar) {
        if (Object.hasOwnProperty.call(copyToolbar, key)) {
          copyToolbar[key] = key === payload;
        }
      }
      state.toolbar = copyToolbar;
    },
    setCargandoAbonos: (state, { payload }) => {
      state.cargandoAbonos = payload;
    },
    setListaAbonos: (state, { payload }) => {
      state.listaAbonos = [...payload];
    },
    agregarAbonoStore: (state, { payload }) => {
      const newLista = [...state.listaAbonos, payload];
      state.listaAbonos = Helpers.ordenarLista(newLista, "fechaAbono");
    },
    cambiarEstadoUsuarioStore: (state, { payload }) => {
      const copyLista = [...state.lista];
      const usuarioIndex = copyLista.findIndex((u) => u.id === payload.id);
      copyLista[usuarioIndex].activo = !payload.activo;
      state.lista = copyLista;
    },
    eliminarAbonoStore: (state, { payload }) => {
      const newLista = state.listaAbonos.filter((a) => a.key !== payload.key);
      state.listaAbonos = newLista;
    },
    agregarUsuarioStore: (state, { payload }) => {
      const newLista = [...state.lista, payload];
      state.lista = Helpers.sortList(newLista, "usuario");
      state.filtrados = Helpers.sortList(newLista, "usuario");
      state.totalUsuarios = newLista.length;
    },
    eliminarUsuarioStore: (state, { payload }) => {
      const newLista = state.lista.filter((u) => u.key !== payload.key);
      state.lista = Helpers.sortList(newLista, "usuario");
      state.filtrados = Helpers.sortList(newLista, "usuario");
      state.totalUsuarios = newLista.length;
    },
    setDispositivos: (state, { payload }) => {
      state.dispositivos = [...payload];
    },
  },
});

export const {
  setListaUsuarios,
  setUsuarioKey,
  setCodigoUsuario,
  setCargandoUsuarios,
  buscarUsuario,
  actualizarUsuarioEnLista,
  setSelectedUsuario,
  setToolbarOptionsState,
  setCargandoAbonos,
  setListaAbonos,
  agregarAbonoStore,
  eliminarAbonoStore,
  cambiarEstadoUsuarioStore,
  agregarUsuarioStore,
  eliminarUsuarioStore,
  setDispositivos,
} = usuariosSlice.actions;
export default usuariosSlice.reducer;
