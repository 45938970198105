import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    cargandoDatos: false,
    antiguedad: "",
    fechaAntiguedad: null,
    sorteos: 0,
    boletos: 0,
    cancelados: 0,
    premiosPagados: 0,
    numerosGanadores: 0,
    abonos: 0,
    deletedCounter: 0,
};

export const liberarEspacioSlice = createSlice({
    name: "liberarEspacio",
    initialState,
    reducers: {
        setCargandoDatos: (state, { payload }) => {
            state.cargandoDatos = payload;
        },
        setDatos: (state, { payload }) => {
            state.antiguedad = payload.antiguedad;
            state.sorteos = payload.sorteos;
            state.boletos = payload.boletos;
            state.cancelados = payload.cancelados;
            state.premiosPagados = payload.premiosPagados;
            state.abonos = payload.abonos;
            state.numerosGanadores = payload.numerosGanadores;
            state.fechaAntiguedad = payload.fechaAntiguedad;
        },
        setDatosIndividual: (state, { payload }) => {
            // payload: nombreRegistro
            const registros = {
                "premios-pagados": "premiosPagados",
                "numeros-ganadores": "numerosGanadores",
            };
            const prop = registros[payload] ? registros[payload] : payload;
            state[prop] = 0;
        },
        resetDatos: (state, { payload }) => {
            state.sorteos = 0;
            state.boletos = 0;
            state.cancelados = 0;
            state.premiosPagados = 0;
            state.abonos = 0;
            state.numerosGanadores = 0;
        },
        setDeletedCounter: (state, { payload }) => {
            state.deletedCounter = payload;
        },
    },
});

export const {
    setCargandoDatos,
    setDatos,
    setDatosIndividual,
    resetDatos,
    setDeletedCounter,
} = liberarEspacioSlice.actions;
export default liberarEspacioSlice.reducer;
