import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardContent, CardHeader, Box, Typography } from "@mui/material";
import ScrollView from "../../../components/ScrollView";
import { Helpers, Money, uuid } from "../../../utils";
import emptyImg from "../../../assets/no-results.png";
import { setNumerosJugados } from "../../../features/boletos/boletosSlice";
import StatusCircle from "../../../components/StatusCircle";
const CIFRAS_ENUM = {
  LETRAS: {
    tres: "3 cifras",
    dos: "2 cifras",
    una: "1 cifra",
  },
  NUMEROS: {
    tres: 3,
    dos: 2,
    una: 1,
  },
};

const TablaNumeros = () => {
  return (
    <Card>
      <CardHeader title={<TablaTitle />} subheader={<TablaSubheader />} />
      <CardContent>
        <Numeros />
      </CardContent>
    </Card>
  );
};

const TablaTitle = () => {
  const [title, setTitle] = useState("Números Jugados");
  const { usuarioKey, lista } = useSelector((state) => state.usuarios);

  useEffect(() => {
    const usuario = lista.find((u) => u.key === usuarioKey);
    setTitle(
      usuario === undefined
        ? "Números Jugados"
        : "Números Jugados " + usuario.nomComercial
    );
  }, [usuarioKey]);

  return <span>{title}</span>;
};

const TablaSubheader = () => {
  const { totalNumerosJugados, numerosJugados } = useSelector(
    (state) => state.boletos
  );

  return (
    <>
      <StatusCircle
        text={` Total(${numerosJugados.length}): ${Money(
          totalNumerosJugados
        )} MXN`}
      />
    </>
  );
};

const Numeros = () => {
  const { filtrados, filtros, numerosJugados } = useSelector(
    (state) => state.boletos
  );
  const dispatch = useDispatch();

  useEffect(() => {
    cargarNumerosJugados();
  }, [filtrados, filtros]);

  const cargarNumerosJugados = () => {
    let _filtrados = [...filtrados];
    let _jugadas = [];
    // MEZCLAR TODAS LAS JUGADAS DE CADA BOLETO
    _filtrados.forEach((b) => {
      b.jugadas.forEach((j) =>
        _jugadas.push({ ...j, usuario: b.numeroAgencia })
      );
    });
    // FILTRAR JUGADAS POR CIFRA
    let _jugadasPorCifra = _jugadas.filter(
      (j) => j.numero.length === CIFRAS_ENUM.NUMEROS[filtros.cifras]
    );
    let NUMEROS_ENUM = {};
    _jugadasPorCifra.forEach((j) => {
      // VERIFICAR SI EL NUMERO NO SE HA ALMACENADO
      if (NUMEROS_ENUM[j.numero] === undefined) {
        NUMEROS_ENUM[j.numero] = {
          lugares: j.lugares,
          usuario: j.usuario,
        };
      } else {
        // SI YA SE ALMACENO ENTONCES SUMAR SUS LUGARES CON LA ITERACION ACTUAL
        let _lugares = [];
        NUMEROS_ENUM[j.numero].lugares.forEach((l, index) => {
          _lugares.push(parseInt(l) + parseInt(j.lugares[index]));
        });
        // REEMPLAZAR LOS LUGARES
        NUMEROS_ENUM[j.numero].lugares = _lugares;
      }
    });
    // VOLVER A CREAR LAS JUGADAS APARTIR DE LOS NUMEROS_ENUM CREADOS
    let _numerosJugados = [];
    for (const key in NUMEROS_ENUM) {
      if (Object.hasOwnProperty.call(NUMEROS_ENUM, key)) {
        let numero_enum = NUMEROS_ENUM[key];
        _numerosJugados.push({
          numero: key,
          lugares: numero_enum.lugares,
          usuario: numero_enum.usuario,
          totalApostado: numero_enum.lugares.reduce(
            (acc, monto) => parseInt(acc) + parseInt(monto)
          ),
        });
      }
    }
    // FILTRAR POR VENTA
    const numerosJugadosPorVenta = filtrarNumerosPorVenta(
      _numerosJugados,
      filtros.venta
    );
    // ORDENAR LISTA
    numerosJugadosPorVenta.sort((a, b) => {
      if (filtros.orden === "asc") {
        return a.numero - b.numero;
      } else {
        return b.numero - a.numero;
      }
    });
    dispatch(setNumerosJugados(numerosJugadosPorVenta));
  };

  const filtrarNumerosPorVenta = (lista, tipoVenta) => {
    // [todos, menor-50, mayor-50-menor-100, mayor-100-menor-500, sin-vender]
    switch (tipoVenta) {
      case "todos":
        return lista;
      case "menor-50":
        return lista.filter((j) => j.totalApostado < 50);
      case "mayor-50-menor-100":
        return lista.filter(
          (j) => j.totalApostado >= 50 && j.totalApostado < 100
        );
      case "mayor-100-menor-500":
        return lista.filter(
          (j) => j.totalApostado >= 100 && j.totalApostado < 500
        );
      case "sin-vender":
        return numerosSinVender(lista);
      default:
        return lista;
    }
  };

  const numerosSinVender = (lista) => {
    const limite = {
      tres: 1000,
      dos: 100,
      una: 10,
    };
    const listaNumeros = lista.map((item) => item.numero);
    let noVendidos = [];
    for (let i = 0; i < limite[filtros.cifras]; i++) {
      const numero = Helpers.padWithZeros(i, limite[filtros.cifras]);
      if (!listaNumeros.includes(numero)) {
        noVendidos.push({
          numero: numero,
          lugares: [0],
          usuario: null,
          totalApostado: 0,
          sinVender: true,
        });
      }
    }
    return noVendidos;
  };

  return (
    <>
      {numerosJugados.length === 0 && (
        <div style={{ textAlign: "center" }}>
          <img src={emptyImg} style={{ width: 250 }} alt="" />
        </div>
      )}
      <ScrollView vertical maxHeight={483}>
        <ul style={{ padding: 0, margin: 0 }}>
          {numerosJugados.map((n) => (
            <NumeroCard key={uuid()} numero={n} />
          ))}
        </ul>
      </ScrollView>
    </>
  );
};

const NumeroCard = ({ numero }) => {
  const headerTitle =
    "$" + numero.lugares.reduce((acc, monto) => acc + parseInt(monto), 0);
  return (
    <li
      style={{
        display: "inline-block",
        margin: 5,
        position: "relative",
      }}
    >
      <Box
        sx={{
          width: { xs: 120, sm: 150, md: 150 },
          height: 70,
          backgroundColor: "#3C3C3C",
          display: "flex",
          color: "white",
        }}
      >
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: numero.sinVender ? "" : "#00b8d4",
          }}
        >
          {numero.numero}
        </div>
        <div
          style={{
            flex: 2,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            <span
              style={{
                display: "inline-block",
                width: "100%",
                textAlign: "center",
              }}
            >
              {headerTitle}
            </span>
            <div
              style={{
                width: "100%",
                display: "flex",
              }}
            >
              {numero.lugares.map((monto, index) => (
                <LugarCircle key={uuid()} monto={monto} posicion={index + 1} />
              ))}
            </div>
          </div>
        </div>
      </Box>
    </li>
  );
};

const LugarCircle = ({ monto }) => {
  return (
    <div
      style={{
        width: 30,
        height: 30,
        borderRadius: "50%",
        backgroundColor: "white",
        color: "black",
        fontSize: 10,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: "bold",
      }}
    >
      <span>${monto}</span>
    </div>
  );
};

export default TablaNumeros;
