export default function StatusCircle({ color = "blue", text = "" }) {
  return (
    <div style={{ display: "flex", flexDirection: "row", margin: "10px 0" }}>
      <div
        style={{
          width: 20,
          height: 20,
          backgroundColor: color,
          borderRadius: "50%",
          marginRight: 10,
        }}
      ></div>
      <span>{text}</span>
    </div>
  );
}
