const COMISIONES = {
  TICKET_ESTANDAR: 0.1,
  TICKET_MEDIA: 0.12,
  TICKET_ALTA: 0.15,
  SOBRE_RECARGA: 0.04,
  TRANSACCIONES_SERVICIOS: 0.5,
  TRANSACCIONES_ABONO: 0.5,
};

export default COMISIONES;
