import React from "react";
import { Grid, Paper } from "@mui/material";
import TablaVentaPorSorteo from "./components/TablaVentaPorSorteo";
import TablaPagosRealizados from "./components/TablaPagosRealizados";
import RangoSelector from "../components/RangoSelector";
import UserSelector from "../components/UserSelector";

export default function Tickets() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {/* VENTAS POR SORTEO */}
        <TablaVentaPorSorteo />
      </Grid>
      <Grid item xs={12}>
        {/* PREMIOS REALIZADOS */}
        <TablaPagosRealizados />
      </Grid>
    </Grid>
  );
}
