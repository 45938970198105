import { CircleLoading, TextLoading } from "../../../placeholders";

export default function ListaSorteosPendientesLoader() {
    return (
        <div
            style={{
                width: "100%",
                display: "flex",
            }}
        >
            <Sorteo />
            <Sorteo />
        </div>
    );
}

const Sorteo = () => {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginRight: 20,
            }}
        >
            <CircleLoading size={90} />
            <TextLoading width={70} marginTop={10} />
        </div>
    );
};
