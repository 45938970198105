import { useState, useEffect } from "react";
import { Paper, Button, Grid, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { DATABASE_TABLES } from "../../../../constants";
import Database from "../../../../database";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CancelIcon from "@mui/icons-material/Cancel";
import { cancelarBoleto, sorteoCancelable } from "../../../../services/bola";
import CustomModal from "../../../../components/CustomModal";
import AdminPasswordField from "../../../../components/AdminPasswordField";
import * as Ticket from "../../../../components/Ticket";

const Boleto = () => {
  const [cargando, setCargando] = useState(true);
  const [boleto, setBoleto] = useState(null);
  const [sorteoOpcion, setSorteoOpcion] = useState(null);
  const { boletoKey } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    cargarBoleto();
  }, []);

  const cargarBoleto = async () => {
    try {
      // console.log(boletoKey);
      setCargando(true);
      const _boleto = await Database.getItemByKey(
        DATABASE_TABLES.BOLETOS,
        boletoKey
      );
      if (_boleto) {
        const _sorteoOpcion = await Database.getItemsByProp(
          DATABASE_TABLES.LISTA_SORTEOS,
          "codigo",
          _boleto.codigoSorteo
        );
        setBoleto(_boleto);
        setSorteoOpcion(_sorteoOpcion[0]);
      }
      setCargando(false);
    } catch ({ message }) {
      console.log(message);
    }
  };

  if (cargando) return <Ticket.Placeholder />;

  if (!cargando && !boleto) {
    return <div>Boleto no encontrado</div>;
  }

  return (
    <>
      {!cargando && boleto && sorteoOpcion && (
        <Grid container rowSpacing={2} columnSpacing={2}>
          <Grid item xs={12} md={3}>
            <Paper>
              <Button
                fullWidth
                startIcon={<ArrowBackIcon />}
                onClick={() => navigate("../boletos")}
              >
                Volver a boletos
              </Button>
              <CancelarBoleto boleto={boleto} />
            </Paper>
          </Grid>
          <Grid item xs={12} md={9}>
            <Paper sx={{ padding: 2, maxWidth: 300 }}>
              <Ticket.Header boleto={boleto} />
              <Ticket.Body boleto={boleto} sorteoOpcion={sorteoOpcion} />
            </Paper>
          </Grid>
        </Grid>
      )}
    </>
  );
};

const CancelarBoleto = ({ boleto }) => {
  const [puedeCancelar, setPuedeCancelar] = useState(false);
  const [password, setPassword] = useState("");
  const [customModalConfig, setCustomModalConfig] = useState({
    open: false,
    type: "alert",
    alertTitle: "¿Cancelar boleto?",
    progressTitle: "cancelando",
    confirmBtnText: "aceptar",
    showCancelBtn: true,
    contentType: "cancelar-boleto",
    error: null,
  });
  const navigate = useNavigate();

  useEffect(() => {
    verificarFecha();
  }, []);

  const verificarFecha = async () => {
    try {
      const sePuedeCancelar = await sorteoCancelable(boleto.fechaSorteo);
      setPuedeCancelar(sePuedeCancelar);
    } catch ({ message }) {
      console.log(message);
    }
  };

  const handleClick = () => {
    setCustomModalConfig((prevState) => ({
      ...prevState,
      open: true,
      type: "alert",
      alertTitle: "¿Cancelar boleto?",
      contentType: "cancelar-boleto",
      showCancelBtn: true,
    }));
  };

  const handleCancel = () => {
    setCustomModalConfig((prevState) => ({
      ...prevState,
      open: false,
    }));
  };

  const handleAccept = () => {
    const actions = {
      "cancelar-boleto": () => _cancelarBoleto(),
      "boleto-cancelado": () => _cargarPantallaBoletos(),
      error: () => handleCancel(),
    };
    actions[customModalConfig.contentType]();
  };

  const _cancelarBoleto = async () => {
    try {
      setCustomModalConfig((prevState) => ({
        ...prevState,
        type: "progress",
        progressTitle: "Cancelando",
        error: null,
      }));
      const cancelado = await cancelarBoleto(password, boleto);
      if (cancelado) {
        setCustomModalConfig((prevState) => ({
          ...prevState,
          open: true,
          type: "alert",
          alertTitle: "Mensaje",
          showCancelBtn: false,
          contentType: "boleto-cancelado",
        }));
        setPassword("");
        return;
      }
    } catch ({ message }) {
      setCustomModalConfig((prevState) => ({
        ...prevState,
        type: "alert",
        alertTitle: "Mensaje",
        contentType: "error",
        showCancelBtn: false,
        error: <span>{message}</span>,
      }));
      setPassword("");
    }
  };

  const _cargarPantallaBoletos = () => {
    setCustomModalConfig((prevState) => ({
      ...prevState,
      open: false,
    }));
    navigate(-1);
  };

  const disableConfirmBtn = () => {
    if (
      customModalConfig.contentType === "cancelar-boleto" &&
      password.trim().length === 0
    ) {
      return true;
    }
    return false;
  };

  const _disableConfirmBtn = disableConfirmBtn();

  return (
    <>
      <Button
        disabled={!puedeCancelar}
        fullWidth
        startIcon={<CancelIcon sx={{ color: puedeCancelar ? "red" : "" }} />}
        onClick={handleClick}
      >
        Cancelar boleto
      </Button>
      <CustomModal
        open={customModalConfig.open}
        type={customModalConfig.type}
        alertTitle={customModalConfig.alertTitle}
        progressTitle={customModalConfig.progressTitle}
        showCancelBtn={customModalConfig.showCancelBtn}
        confirmBtnText={customModalConfig.confirmBtnText}
        onCancel={handleCancel}
        onAccept={handleAccept}
        disableConfirmBtn={_disableConfirmBtn}
      >
        {customModalConfig.contentType === "cancelar-boleto" && (
          <>
            <Typography sx={{ textAlign: "justify" }}>
              Al cancelar este boleto ya no podrá participar en el sorteo.
            </Typography>
            <AdminPasswordField
              divider
              autoFocus
              password={password}
              message="Por seguridad, te pedimos ingresar tu contraseña para poder
                        continuar con el proceso de cancelación."
              onChange={({ target }) => setPassword(target.value.trim())}
            />
          </>
        )}
        {customModalConfig.contentType === "boleto-cancelado" && (
          <Typography>El boleto se canceló correctamente</Typography>
        )}
        {customModalConfig.contentType === "error" && customModalConfig.error}
      </CustomModal>
    </>
  );
};

export default Boleto;
