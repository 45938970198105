import { Grid, Paper, Typography } from "@mui/material";

export default function AccountStatusLayout({ title, right, children }) {
  return (
    <Paper>
      <Grid sx={{ padding: 2 }} container>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Typography>{title}</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {right}
        </Grid>
        <Grid item xs={12}>
          {children}
        </Grid>
      </Grid>
    </Paper>
  );
}
