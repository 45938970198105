import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Drawer,
  TableCell,
  TableRow,
} from "@mui/material";
import Tabla from "../../../../components/Tabla";
import { Money, uuid } from "../../../../utils";
import { useState } from "react";
import { InputField } from "../../../../components";
import { useFormik } from "formik";
import * as YUP from "yup";
import { saveDeposit } from "../../../../services/reports";
import { useDispatch, useSelector } from "react-redux";
import { setAccountStatuses } from "../../../../features/administracion/administracionSlice";
import { DeleteIcon } from "../../../../icons";
import PasswordProtectedAction from "../../../../components/PasswordProtectedAction";
import { deleteDeposit } from "../../../../services/common";

export default function AccountStatusesTable() {
  const { accountStatuses } = useSelector((state) => state.administracion);

  return (
    <Tabla
      headData={["USUARIO", "A PAGAR", "SU PAGO", "DEPOSITOS"]}
      data={accountStatuses}
      renderItem={(item) => (
        <AccountStatusRow accountStatus={item} key={uuid()} />
      )}
    />
  );
}

function AccountStatusRow({ accountStatus }) {
  const { accountStatuses } = useSelector((state) => state.administracion);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [guardandoDeposito, setGuardandoDeposito] = useState(false);
  const suPago = accountStatus.deposits.list.reduce(
    (acc, item) => acc + parseFloat(item.pago),
    0
  );
  const dispatch = useDispatch();
  const [authError, setAuthError] = useState({ error: false, message: "" });
  const formik = useFormik({
    initialValues: {
      cantidad: "",
      password: "",
    },
    validationSchema: YUP.object().shape({
      cantidad: YUP.number()
        .typeError("Ingresa una cantidad valida")
        .required("Este campo es requerido"),
      password: YUP.string().required("Este campo es requerido"),
    }),
    onSubmit: (formData) => {
      guardarDeposito(formData);
    },
  });

  const guardarDeposito = async ({ cantidad, password }) => {
    try {
      setGuardandoDeposito(true);
      setAuthError({ error: false, message: "" });
      const newDeposit = await saveDeposit({
        accountStatus,
        depositAmount: cantidad,
        password,
        period: accountStatus.period,
        user: accountStatus.user,
      });
      if (newDeposit) {
        let accountStatusesCopy = [...accountStatuses];
        const currentAccountStatusIndex = accountStatusesCopy.findIndex(
          (item) => item.user.usuario === accountStatus.user.usuario
        );
        const currentAccountStatus = {
          ...accountStatusesCopy[currentAccountStatusIndex],
        };
        currentAccountStatus.deposits = {
          ...currentAccountStatus.deposits,
          total: currentAccountStatus.deposits.total + newDeposit.pago,
          recordsFound: currentAccountStatus.deposits.recordsFound + 1,
          list: [...currentAccountStatus.deposits.list, newDeposit],
        };
        accountStatusesCopy[currentAccountStatusIndex] = {
          ...currentAccountStatus,
        };
        dispatch(setAccountStatuses([...accountStatusesCopy]));
      }
    } catch ({ message }) {
      setGuardandoDeposito(false);
      setAuthError({ error: true, message });
    }
  };

  const handleRowClick = () => {
    setOpenDrawer(true);
  };

  const handleCloseDrawer = () => {
    if (!guardandoDeposito) {
      setOpenDrawer(false);
    }
  };

  const handleGuardarDeposito = () => {
    formik.handleSubmit();
  };

  const disableGuardarBtn = () => {
    return (
      guardandoDeposito ||
      formik.errors.hasOwnProperty("cantidad") ||
      formik.errors.hasOwnProperty("password") ||
      formik.values.cantidad.length === 0 ||
      formik.values.password.length === 0
    );
  };

  return (
    <>
      <TableRow
        hover
        onClick={handleRowClick}
        sx={{
          cursor: "pointer",
        }}
      >
        <TableCell>
          {accountStatus.user.nomComercial} - {accountStatus.user.usuario}
        </TableCell>
        <TableCell>{Money(accountStatus.amount)}</TableCell>
        <TableCell>{Money(suPago)}</TableCell>
        <TableCell>{accountStatus.deposits.recordsFound}</TableCell>
      </TableRow>
      <Drawer anchor="right" open={openDrawer} onClose={handleCloseDrawer}>
        <div style={{ minWidth: 300, marginLeft: 15, marginRight: 15 }}>
          <DrawerSectionTitle title={accountStatus.user.nomComercial} />
          <InputField
            label="Cantidad"
            name="cantidad"
            value={formik.values.cantidad}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            marginTop={0}
            error={formik.errors.cantidad}
            touched={formik.touched.cantidad}
            disableErrorMessage
            disabled={guardandoDeposito}
          />
          <p>
            <InputField
              type="password"
              label="Contraseña"
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              marginTop={0}
              error={formik.errors.password}
              touched={formik.touched.password}
              disableErrorMessage
              disabled={guardandoDeposito}
            />
          </p>
          {authError.error && (
            <Alert severity="error">{authError.message}</Alert>
          )}
          <p>
            <Button
              disabled={disableGuardarBtn()}
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleGuardarDeposito}
              startIcon={guardandoDeposito && <CircularProgress size={20} />}
            >
              {guardandoDeposito ? "Guardando" : "Guardar"}
            </Button>
          </p>
          <div>
            <DrawerSectionTitle
              title={
                accountStatus.deposits.recordsFound === 0
                  ? "No hay depositos"
                  : "Depositos"
              }
            />
            {accountStatus.deposits.list.map((item) => (
              <DepositoRow
                disableHover={guardandoDeposito}
                deposito={item}
                key={item}
              />
            ))}
          </div>
        </div>
      </Drawer>
    </>
  );
}

function DrawerSectionTitle({ title }) {
  return <p style={{ fontSize: 20 }}>{title}</p>;
}

function DepositoRow({ deposito, disableHover }) {
  const { accountStatuses } = useSelector((state) => state.administracion);
  const [isHovering, setIsHovering] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const dispatch = useDispatch();

  const handleMouseOver = () => {
    if (!disableHover) {
      setIsHovering(true);
    }
  };
  const handleMouseOut = () => {
    setIsHovering(false);
  };
  const handleDeleteClick = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleEliminarDeposito = async () => {
    try {
      const depositoEliminado = await deleteDeposit(deposito);
      if (depositoEliminado) {
        let accountStatusesCopy = [...accountStatuses];
        const currentAccountStatusIndex = accountStatusesCopy.findIndex(
          (item) => item.user.usuario === deposito.usuario
        );
        const currentAccountStatus = {
          ...accountStatusesCopy[currentAccountStatusIndex],
        };
        currentAccountStatus.deposits = {
          ...currentAccountStatus.deposits,
          total: currentAccountStatus.deposits.total - deposito.pago,
          recordsFound: currentAccountStatus.deposits.recordsFound - 1,
          list: [
            ...currentAccountStatus.deposits.list.filter(
              (d) => d.key !== deposito.key
            ),
          ],
        };
        accountStatusesCopy[currentAccountStatusIndex] = {
          ...currentAccountStatus,
        };
        dispatch(setAccountStatuses([...accountStatusesCopy]));
        setOpenDialog(false);
      }
    } catch ({ message }) {
      alert(message);
    }
  };

  return (
    <>
      <Box
        sx={styles.deposito}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
      >
        <span>
          {deposito.fecha} {deposito.hora}
        </span>{" "}
        <span style={styles.depositoPago}>{Money(deposito.pago)}</span>
        {isHovering && (
          <div style={styles.deleteDeposit}>
            <DeleteIcon onClick={handleDeleteClick} />
          </div>
        )}
      </Box>
      <PasswordProtectedAction
        open={openDialog}
        onClose={handleCloseDialog}
        progressDialogMessage="Eliminando deposito"
        dialogActionMessage={"eliminará un deposito de " + Money(deposito.pago)}
        onValidPassword={handleEliminarDeposito}
      />
    </>
  );
}

const styles = {
  deposito: {
    display: "flex",
    justifyContent: "space-between",
    cursor: "pointer",
    padding: "8px 6px",
    position: "relative",
  },
  depositoPago: {
    color: "green",
    fontWeight: "bold",
  },
  deleteDeposit: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    backgroundColor: "rgba(0,0,0,0.5)",
    zIndex: 10,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    paddingRight: 10,
  },
};
