import { Money } from ".";
import { CATEGORIAS_TRANSACCIONES, COMISIONES, LOGOS_URL } from "../constants";
import { ReceiptIcon, WhatsappIcon } from "../icons";
import Moment from "./Moment";

const _Helpers = () => {
  //FOR IN WITH INDEX INDICATOR
  function forIn(obj, callback) {
    let counter = 0;
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        callback(key, counter);
        counter++;
      }
    }
  }
  //COMMA SEPARETED ITEMS
  function commaSeparatedNumbers(item, index) {
    return (index ? ", " : "") + item;
  }
  // ORDENAR LISTA
  function ordenarLista(list = [], orderBy, orderWay = "desc") {
    if (orderWay === "desc") {
      return list.sort(
        (a, b) => Moment(b[orderBy]).valueOf() - Moment(a[orderBy]).valueOf()
      );
    }
    if (orderWay === "asc") {
      return list.sort(
        (a, b) => Moment(a[orderBy]).valueOf() - Moment(b[orderBy]).valueOf()
      );
    }

    return list;
  }
  // ORDENAR ELEMENTOS
  function ordenarElementos(list, func) {
    return list.sort(func);
  }
  // SORT LIST
  function sortList(list = [], orderBy, orderWay = "desc") {
    if (orderWay === "desc") {
      return list.sort((a, b) => parseInt(b[orderBy]) - parseInt(a[orderBy]));
    }
    if (orderWay === "asc") {
      return list.sort((a, b) => parseInt(a[orderBy]) - parseInt(b[orderBy]));
    }

    return list;
  }
  // DETECTA SI LA CADENA CONTIENE CARACTERES ESPECIALES
  function hasSpecialChars(str) {
    const format = /[ `´¨!¡@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?¿~]/;
    if (!str || str === "") return false;
    return format.test(str);
  }
  // DETECTA SI CONTIENE NUMEROS
  function hasNumbers(text) {
    if (!text || text === "") return false;
    const numbers = "0.1.2.3.4.5.6.7.8.9".split(".");
    const text_arr = text.split("");
    for (let i = 0; i < text_arr.length; i++) {
      if (numbers.includes(text_arr[i])) {
        return true;
      }
    }
    return false;
  }
  // DETECTA SI SOLO CONTIENE NUMEROS
  function onlyNumbers(text) {
    if (text === "") return true;
    const numbers = "0.1.2.3.4.5.6.7.8.9".split(".");
    const text_arr = text.split("");
    for (let i = 0; i < text_arr.length; i++) {
      if (!numbers.includes(text_arr[i])) {
        return false;
      }
    }
    return true;
  }
  // DETECTA SI EL FORMATO ES DEL TIPO BOLETO
  function formatoNumeroBoleto(text = "") {
    let _text = text.split("-").join("");
    let numbers = _text.replace(/\D+/g, "");
    if (numbers.length > 0) {
      numbers = numbers.match(new RegExp(".{1,4}", "g")).join("-");
    }
    return numbers;
  }
  // RESUELVE EL PROBLEMA DE IMAGEN EN CACHE
  function setImgSrc(imgName) {
    // SI EL SRC VIENE CON EXTENSION
    if (imgName.indexOf(".") !== -1) {
      const imgSrc = imgName.split(".")[0];
      const src = imgSrc === "GN" ? "SGOR" : imgSrc;
      // return LOGOS_URL + src + ".png?" + Moment().milliseconds();
      return LOGOS_URL + src + ".png";
    }
    const src = imgName === "GN" ? "SGOR" : imgName;
    // return LOGOS_URL + src + ".png?" + Moment().milliseconds();
    return LOGOS_URL + src + ".png";
  }
  // PADD WITH ZEROS
  function padWithZeros(number, numOfCells) {
    let str = number.toString();

    if (numOfCells === 100 && number < 10) {
      str = "0" + str;
    }

    if (numOfCells === 1000 && number < 10) {
      str = "00" + str;
    }

    if (numOfCells === 1000 && number >= 10 && number < 100) {
      str = "0" + str;
    }
    return str;
  }
  // OBTENER COMISION VENTA
  function obtenerComision(totalVenta) {
    if (totalVenta > 10000) return totalVenta * 0.15;
    if (totalVenta > 5001) return totalVenta * 0.12;
    return totalVenta * 0.1;
  }
  // OBTENER COMISIONES POR TRANSACCIONES
  function obtenerComisionTransacciones(transacciones = []) {
    let comision = {
      recargas: 0,
      servicios: 0,
      total: 0,
    };

    for (let i = 0; i < transacciones.length; i++) {
      const transaccion = transacciones[i];
      const categoriaID = transaccion.CategoriaID;
      // SI ES RECARGA | PAQUETE | GIFTCARD
      if (["1", "2"].includes(categoriaID)) {
        const comisionSobreRecarga =
          parseFloat(Money(transaccion.Monto, false)) * 0.0375587;
        const comisionRecarga =
          parseFloat(Money(transaccion.Cargo, false)) +
          parseFloat(Money(transaccion.Comision, false));
        const sumaComisiones =
          parseFloat(comisionSobreRecarga) + parseFloat(comisionRecarga);
        comision.recargas += parseFloat(sumaComisiones);
      }
      // SI ES SERVICIO
      if (["3", "4"].includes(categoriaID)) {
        // SI HAY ABONO
        const abono =
          transaccion.Abono !== "$0.00"
            ? parseFloat(Money(transaccion.Abono, false)) * 0.5
            : 0;
        const comisionServicio =
          parseFloat(Money(transaccion.Comision, false)) * 0.5;
        comision.servicios += comisionServicio + abono;
      }
    }

    return {
      recargas: comision.recargas,
      servicios: comision.servicios,
      total: comision.recargas + comision.servicios,
    };
  }
  function calcularComisionTransacciones(transacciones = [], categoriaID) {
    const transaccionesFiltradas = transacciones.filter(
      (t) => t.CategoriaID === categoriaID
    );
    let totalComision = 0;
    for (let i = 0; i < transaccionesFiltradas.length; i++) {
      const transaccion = transaccionesFiltradas[i];
      // RECARGA | PAQUETE
      if (["1", "2"].includes(categoriaID)) {
        const comisionSobreRecarga =
          parseFloat(Money(transaccion.Monto, false, true)) *
          COMISIONES.SOBRE_RECARGA;
        // const comisionRecarga =
        //   parseFloat(Money(transaccion.Cargo, false, true)) +
        //   parseFloat(Money(transaccion.Comision, false, true));
        // const sumaComisiones =
        //   parseFloat(Money(comisionSobreRecarga, false, true)) +
        //   parseFloat(Money(comisionRecarga, false, true));
        totalComision += comisionSobreRecarga;
      }
      // SERVICIO
      if (categoriaID === "3") {
        const comisionServicio =
          parseFloat(Money(transaccion.Comision, false, true)) * 0.5;
        totalComision += comisionServicio;
      }
      // GIFTCARD
      if (categoriaID === "4") {
        const abono = parseFloat(Money(transaccion.Abono, false, true)) * 0.5;
        const comisionServicio =
          parseFloat(Money(transaccion.Comision, false, true)) * 0.5;
        totalComision += comisionServicio + abono;
      }
    }
    return totalComision;
  }
  // SORTEOS CELEBRADOS
  function filtrarSorteosCelebrados(sorteos, fechaServidor, horaCierre) {
    const horaServidor = Moment(fechaServidor, "HH:mm");
    const horaCierreDB = Moment(horaCierre.hora, "HH:mm");
    let celebrados = [];
    sorteos.forEach((item, index) => {
      if (Moment(item.fecha).isBefore(fechaServidor)) {
        celebrados.push({ ...item });
      }
      if (
        Moment(item.fecha).isSame(fechaServidor) &&
        Moment(horaServidor).isAfter(horaCierreDB)
      ) {
        celebrados.push({ ...item });
      }
    });
    return celebrados;
  }

  function extraerInfoRef(referencia) {
    // SI NO SE PASA LA REFERENCIA
    if (!referencia) return { inicial: "", final: "", usuario: "" };
    // SI HAY REFERENCIA
    const refSplit = referencia.split("#");
    return { inicial: refSplit[0], final: refSplit[1], usuario: refSplit[2] };
  }
  // REPRESENTAR PERIODO DE FECHAS EN TEXTO
  function obtenerPeriodoTexto(periodo) {
    // SI LAS FECHAS ESTAN VACIAS
    if (periodo.inicial == "" || periodo.final == "") {
      return "";
    }
    const { inicial, final } = periodo;
    const inicialMoment = Moment(inicial);
    const finalMoment = Moment(final);
    if (inicialMoment.format("MMM") === finalMoment.format("MMM")) {
      return `Del ${inicialMoment.format("dddd DD")} al ${finalMoment.format(
        "dddd DD MMMM YYYY"
      )}`.toUpperCase();
    } else {
      return `Del ${inicialMoment.format(
        "dddd DD MMMM YYYY"
      )} al ${finalMoment.format("dddd DD MMMM YYYY")}`.toUpperCase();
    }
  }
  // VERIFICAR DISPONIBLIDAD DE SORTEO
  function disponiblidadDeSorteo(timestamp, fechaSorteo, horaCierre) {
    const fecha = {
      servidor: timestamp,
      actual: Moment(timestamp).format("YYYY-MM-DD"),
      sorteo: fechaSorteo,
    };
    const hora = {
      cierre: horaCierre,
      actual: Moment(timestamp).format("HH:mm"),
    };
    // SI EL SORTEO SE CELEBRA HOY Y YA CERRO
    if (
      Moment(fecha.sorteo).isSame(fecha.actual) &&
      Moment(hora.actual, "HH:mm").isSameOrAfter(Moment(hora.cierre, "HH:mm"))
    ) {
      return {
        error: true,
        message: "Lo sentimos, el sorteo ha cerrado",
      };
    }
    // SI EL SORTEO YA SE CELEBRO
    if (Moment(fecha.actual).isAfter(fecha.sorteo)) {
      return {
        error: true,
        message: "Lo sentimos, el sorteo seleccionado ya fue celebrado",
      };
    }
    // SI NO HAY ERRORES
    return {
      error: false,
      message: "",
    };
  }
  // CALCULAR COMISION SOBRE VENTA DE TICKETS
  function calcularComisionVentaTickets(venta) {
    if (venta > 10000) return venta * COMISIONES.TICKET_ALTA;
    if (venta > 5001) return venta * COMISIONES.TICKET_MEDIA;
    return venta * COMISIONES.TICKET_ESTANDAR;
  }
  // VENTA RECARGAS
  function obtenerVentaRecargas(transacciones = []) {
    let total = 0;
    transacciones.forEach((transaccion) => {
      if (
        [
          CATEGORIAS_TRANSACCIONES.TIEMPO_AIRE,
          CATEGORIAS_TRANSACCIONES.PAQUETES,
        ].includes(transaccion.CategoriaID)
      ) {
        total +=
          parseFloat(Money(transaccion.Monto, false)) +
          parseFloat(Money(transaccion.Comision, false));
      }
    });
    return total;
  }
  // COMISION RECARGAS
  function obtenerComisionRecargas(transacciones = []) {
    let comision = 0;
    transacciones.forEach((transaccion) => {
      if (
        [
          CATEGORIAS_TRANSACCIONES.TIEMPO_AIRE,
          CATEGORIAS_TRANSACCIONES.PAQUETES,
        ].includes(transaccion.CategoriaID)
      ) {
        const comisionSobreRecarga =
          parseFloat(Money(transaccion.Monto, false)) *
          COMISIONES.SOBRE_RECARGA;
        const comisionRecarga =
          parseFloat(Money(transaccion.Cargo, false)) +
          parseFloat(Money(transaccion.Comision, false));
        const sumaComisiones =
          parseFloat(Money(comisionSobreRecarga, false)) +
          parseFloat(Money(comisionRecarga, false));
        comision += sumaComisiones;
      }
    });
    return comision;
  }
  // TOTAL PAGO SERVICIOS
  function obtenerTotalPagoServicios(transacciones = []) {
    let total = 0;
    transacciones.forEach((transaccion) => {
      if (
        [
          CATEGORIAS_TRANSACCIONES.SERVICIOS,
          CATEGORIAS_TRANSACCIONES.GIFTCARDS,
        ].includes(transaccion.CategoriaID)
      ) {
        const cargoMasComision =
          parseFloat(Money(transaccion.Cargo, false)) +
          parseFloat(Money(transaccion.Comision, false));
        total += parseFloat(Money(transaccion.Monto, false)) + cargoMasComision;
      }
    });
    return total;
  }
  // COMISION PAGO SERVICIOS
  function obtenerComisionPagoServicios(transacciones = []) {
    let comision = 0;
    transacciones.forEach((transaccion) => {
      if (
        [
          CATEGORIAS_TRANSACCIONES.SERVICIOS,
          CATEGORIAS_TRANSACCIONES.GIFTCARDS,
        ].includes(transaccion.CategoriaID)
      ) {
        const comisionServicio =
          parseFloat(Money(transaccion.Comision, false)) *
          COMISIONES.TRANSACCIONES_SERVICIOS;
        const abonos =
          parseFloat(Money(transaccion.Abono, false)) *
          COMISIONES.TRANSACCIONES_ABONO;
        comision += comisionServicio + abonos;
      }
    });
    return comision;
  }
  function obtenerPeriodoPrevio(periodo) {
    const domingoAnterior = Moment(periodo.inicial)
      .subtract(1, "days")
      .format("YYYY-MM-DD");
    let counter = 0;
    while (
      Moment(domingoAnterior)
        .subtract(counter, "days")
        .format("dddd")
        .toLowerCase() !== "lunes"
    ) {
      counter++;
    }
    const lunesAnterior = Moment(domingoAnterior)
      .subtract(counter, "days")
      .format("YYYY-MM-DD");
    return { inicial: lunesAnterior, final: domingoAnterior };
  }
  // EXTRAER DECIMALES
  function extraerDecimales(number) {
    const str = number.toString();
    // SI EXISTE UN PUNTO
    if (str.indexOf(".") !== -1) {
      return Number("0." + str.split(".")[1]);
    }
    return 0;
  }
  // GENERAR HASHES
  function generateHashes(number) {
    let hashes = "";
    for (let i = 0; i < number; i++) {
      hashes += "#";
    }
    return hashes;
  }
  // GET VIA ICON
  function getViaIcon(via) {
    // boleto.via ? boleto.via : "impresion";
    if (via === undefined) return <ReceiptIcon />;
    const ICONS = {
      impresion: <ReceiptIcon />,
      whatsapp: <WhatsappIcon />,
    };
    return ICONS[via];
  }
  // COMISION VENTA DE TICKET
  function totalMenosComisionTicket(totalApostado) {
    const comision = totalApostado * 0.15;
    // console.log(totalApostado, totalApostado * 0.15);
    return totalApostado - comision;
    // return totalApostado;
  }

  // EXPOSE API
  return {
    forIn,
    commaSeparatedNumbers,
    ordenarLista,
    sortList,
    hasSpecialChars,
    hasNumbers,
    onlyNumbers,
    formatoNumeroBoleto,
    setImgSrc,
    padWithZeros,
    ordenarElementos,
    obtenerComision,
    obtenerComisionTransacciones,
    calcularComisionTransacciones,
    filtrarSorteosCelebrados,
    extraerInfoRef,
    obtenerPeriodoTexto,
    disponiblidadDeSorteo,
    calcularComisionVentaTickets,
    obtenerVentaRecargas,
    obtenerComisionRecargas,
    obtenerTotalPagoServicios,
    obtenerComisionPagoServicios,
    obtenerPeriodoPrevio,
    extraerDecimales,
    generateHashes,
    getViaIcon,
    totalMenosComisionTicket,
  };
};

export default _Helpers;
