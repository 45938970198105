const _Storage = () => {
    // GET ANY ITEM
    function get(key, parseJSON = false) {
        const item = localStorage.getItem(key);
        if (!item) return null;
        if (parseJSON) return JSON.parse(item);
        return item;
    }
    // SAVE AN ELEMENT
    function set(key, value, stringfy = false) {
        if (stringfy) {
            localStorage.setItem(key, JSON.stringify(value));
            return;
        }
        localStorage.setItem(key, value);
    }
    // REMOVE AN ITEM
    function remove(key) {
        // VERIFY IF ITEM EXISTS
        if (localStorage.getItem(key)) {
            localStorage.removeItem(key);
        }
    }
    // EXPOSE API
    return {
        get,
        set,
        remove,
    };
};

export default _Storage;
