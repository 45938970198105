import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Moment, Money, uuid } from "../utils";
import noResults from "../assets/no-results.png";
import ScrollView from "./ScrollView";
import CustomAvatar from "./CustomAvatar";
import { useState } from "react";

const CustomTable = ({
  headerData = [],
  tableData = [],
  dataProps = [],
  scrollVertical = false,
  scrollHorizontal = false,
  scrollBoth = false,
  maxWidth = "100%",
  maxHeight = 200,
  onRowClick = undefined,
  stickyHeader = false,
}) => {
  const [dataLimit, setDataLimit] = useState(50);

  const renderHeadCell = (item) => {
    return (
      <TableCell size="small" key={uuid()}>
        {item}
      </TableCell>
    );
  };

  const renderCells = (data, index) => {
    const cellMaxWidth = 200;
    let cells = [];
    if (index < dataLimit) {
      for (let i = 0; i < dataProps.length; i++) {
        const item = dataProps[i];
        if (item === "#") {
          cells.push(
            <TableCell
              sx={{ maxWidth: cellMaxWidth }}
              size="small"
              key={uuid()}
            >
              <Typography sx={{ overflow: "hidden" }}>{index + 1}</Typography>
            </TableCell>
          );
        }
        if (item.indexOf("$:") !== -1) {
          const money = data[dataProps[i].split(":")[1]];
          cells.push(
            <TableCell
              sx={{ maxWidth: cellMaxWidth }}
              size="small"
              key={uuid()}
            >
              <Typography sx={{ overflow: "hidden" }} title={Money(money)}>
                {Money(money)}
              </Typography>
            </TableCell>
          );
        }
        if (item.indexOf("D:") !== -1) {
          const propsArray = dataProps[i].split(":");
          const date = data[propsArray[1]];
          const format = propsArray[2];
          const formattedDate = Moment(date).format(format);
          cells.push(
            <TableCell
              sx={{ maxWidth: cellMaxWidth }}
              size="small"
              key={uuid()}
            >
              <Typography sx={{ overflow: "hidden" }} title={formattedDate}>
                {formattedDate}
              </Typography>
            </TableCell>
          );
        }
        if (item.indexOf("IF:") > -1) {
          const propsArray = dataProps[i].split(":");
          const conditions = propsArray[1];
          const propName = conditions.split(",")[0].split("|")[0];
          const conditionTrue = conditions.split(",")[0].split("|")[1];
          const conditionFalse = conditions.split(",")[1];
          cells.push(
            <TableCell
              size="small"
              key={uuid()}
              sx={{ color: conditionTrue === data[propName] ? "green" : "red" }}
            >
              <Typography sx={{ overflow: "hidden" }} title={data[propName]}>
                {data[propName]}
              </Typography>
            </TableCell>
          );
        }
        if (item.indexOf("Img:") > -1) {
          const propName = dataProps[i].split(":")[1];
          cells.push(
            <TableCell
              sx={{ maxWidth: cellMaxWidth }}
              size="small"
              key={uuid()}
            >
              <CustomAvatar src={data[propName]} size={40} background="#fff" />
            </TableCell>
          );
        }

        if (
          item !== "#" &&
          item.indexOf("$:") === -1 &&
          item.indexOf("D:") === -1 &&
          item.indexOf("IF:") === -1 &&
          item.indexOf("Img:") === -1
        ) {
          cells.push(
            <TableCell
              sx={{ maxWidth: cellMaxWidth }}
              size="small"
              key={uuid()}
            >
              <Typography
                sx={{ overflow: "hidden" }}
                title={data[dataProps[i]]}
              >
                {data[dataProps[i]]}
              </Typography>
            </TableCell>
          );
        }
      }
    }
    return cells;
  };

  const renderRow = (item, index) => {
    if (onRowClick) {
      return (
        <TableRow
          onClick={() => onRowClick(item)}
          key={uuid()}
          sx={{
            cursor: "pointer",
            "&:hover": { background: "rgba(255,255,255,0.075)" },
          }}
        >
          {renderCells(item, index)}
        </TableRow>
      );
    }

    return <TableRow key={uuid()}>{renderCells(item, index)}</TableRow>;
  };

  const handleScrollView = ({ target }) => {
    // FIND THE SCROLL VIEW HEIGHT
    if (target.scrollHeight - target.scrollTop === target.clientHeight) {
      setDataLimit((currentValue) => currentValue + 50);
    }
  };

  if (tableData.length === 0) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src={noResults} alt="Sin resultados" style={{ width: 250 }} />
      </div>
    );
  }

  if (scrollVertical) {
    return (
      <ScrollView
        onScroll={handleScrollView}
        vertical
        maxHeight={maxHeight}
        maxWidth={maxWidth}
      >
        <Table stickyHeader={stickyHeader}>
          <TableHead>
            <TableRow>
              {headerData.map((h) => (
                <TableCell key={uuid()}>{h}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>{tableData.map(renderRow)}</TableBody>
        </Table>
      </ScrollView>
    );
  }

  if (scrollHorizontal) {
    return (
      <ScrollView onScroll={handleScrollView} horizontal maxWidth={maxWidth}>
        <Table stickyHeader={stickyHeader}>
          <TableHead>
            <TableRow>
              {headerData.map((h) => (
                <TableCell key={uuid()}>{h}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>{tableData.map(renderRow)}</TableBody>
        </Table>
      </ScrollView>
    );
  }

  if (scrollBoth) {
    return (
      <ScrollView
        onScroll={handleScrollView}
        scrollBoth
        maxHeight={maxHeight}
        maxWidth={maxWidth}
      >
        <Table stickyHeader={stickyHeader}>
          <TableHead>
            <TableRow>{headerData.map(renderHeadCell)}</TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((item, index) => renderRow(item, index))}
          </TableBody>
        </Table>
      </ScrollView>
    );
  }

  return (
    <Table stickyHeader={stickyHeader}>
      <TableHead>
        <TableRow>
          {headerData.map((h) => (
            <TableCell key={uuid()}>{h}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {tableData.map((item, index) => renderRow(item, index))}
      </TableBody>
    </Table>
  );
};

export default CustomTable;
