import { configureStore } from "@reduxjs/toolkit";
import boletosReducer from "../features/boletos/boletosSlice";
import sorteosReducer from "../features/sorteos/sorteosSlice";
import usuariosReducer from "../features/usuarios/usuariosSlice";
import canceladosReducer from "../features/cancelados/canceladosSlice";
import reportesReducer from "../features/reportes/reportesSlice";
import liberarEspacioReducer from "../features/liberarEspacio/liberarEspacioSlice";
import administracionReducer from "../features/administracion/administracionSlice";
import estadoDeCuentaReducer from "../features/estadoDeCuenta/estadoDeCuentaSlice";

export const store = configureStore({
  reducer: {
    boletos: boletosReducer,
    sorteos: sorteosReducer,
    usuarios: usuariosReducer,
    cancelados: canceladosReducer,
    reportes: reportesReducer,
    liberarEspacio: liberarEspacioReducer,
    administracion: administracionReducer,
    estadoDeCuenta: estadoDeCuentaReducer,
  },
});
