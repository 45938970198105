import { createTheme } from "@mui/material/styles";

const theme = createTheme({
    status: {
        danger: "#e53e3e",
    },
    palette: {
        mode: "dark",
        primary: {
            main: "#1ED760",
            contrastText: "#fff",
        },
        secondary: {
            main: "#e30613",
            contrastText: "white",
        },
        blue: {
            main: "blue",
            contrastText: "#fff",
        },
    },
});

export default theme;
