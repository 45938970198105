import { useEffect, useState } from "react";
import { useCustomParams } from "../../../hooks";
import AccountStatusLayout from "./AccountStatusLayout";
import AccountStatus from "./AccountStatus";
import { getUserAccountStatus } from "../../../services/reports";
import { Utils } from "../../../utils";
import { CustomSelect } from "../../../components";
import { getUsers } from "../../../services/common";

export default function AccountStatusTab() {
  const customParams = useCustomParams();
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [userID, setUserID] = useState("");
  const [inform, setInform] = useState(null);

  useEffect(() => {
    getInform();
  }, []);

  useEffect(() => {
    getInform();
  }, [customParams.get("range")]);

  const getInform = async () => {
    try {
      setLoading(true);
      const _users = await getUsers();
      const range = customParams.get("range").split("_");
      const period = { start: range[0], end: range[1] };
      const userAccountStatus = await getUserAccountStatus(period, _users[0]);
      setUsers(_users);
      setUserID(_users[0].id);
      setInform(userAccountStatus);
      setLoading(false);
    } catch ({ message }) {
      console.log(message);
    }
  };

  const handleChange = async (val) => {
    try {
      setUserID(val);
      setLoading(true);
      const _user = users.find((u) => u.id === val);
      const range = customParams.get("range").split("_");
      const period = { start: range[0], end: range[1] };
      const userAccountStatus = await getUserAccountStatus(period, _user);
      setInform(userAccountStatus);
      setLoading(false);
    } catch ({ message }) {
      alert(message);
    }
  };

  return (
    <AccountStatusLayout
      title={
        !loading && inform
          ? `REPORTE ${Utils.periodToLongText(inform.period)}`
          : "..."
      }
      right={
        <CustomSelect
          disabled={loading}
          label="Usuario"
          options={users}
          optionLabel="nomComercial"
          optionValue="id"
          onChange={handleChange}
          value={userID}
          width={200}
        />
      }
    >
      {!loading && inform && <AccountStatus accountStatus={inform} />}
    </AccountStatusLayout>
  );
}
