import React, { useCallback } from "react";
import { uuid } from "../utils";

const CustomList = ({ list, renderItem }) => {
    const memoizedList = useCallback(() => {
        return list.map((item) => (
            <React.Fragment key={uuid()}>{renderItem(item)}</React.Fragment>
        ));
    }, [list]);
    return <>{memoizedList()}</>;
};

export default CustomList;
