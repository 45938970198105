import { Document, Page, StyleSheet, View } from "@react-pdf/renderer";
import DocumentHead from "../../../../components/DocumentHead";
import DocumentTableRow from "../../../../components/DocumentTableRow";
import { Helpers, Money } from "../../../../utils";
const TABLE_HEAD =
  "NOMBRE.SALDO VENCIDO.IMPORTE.A PAGAR.SU PAGO.RESTO.METODO PAGO".split(".");

export default function RegistroPagosPDF({ estadosDeCuenta }) {
  const periodoTexto =
    estadosDeCuenta.length > 0
      ? Helpers.obtenerPeriodoTexto(estadosDeCuenta[0].periodo)
      : "";
  return (
    <Document>
      <Page>
        <View style={styles.page}>
          <DocumentHead title={"ESTADOS DE CUENTA " + periodoTexto} />
          <DocumentTable estadosDeCuenta={estadosDeCuenta} />
        </View>
      </Page>
    </Document>
  );
}

function DocumentTable({ estadosDeCuenta }) {
  return (
    <View style={styles.table}>
      <DocumentTableRow header cellWidth="15%" data={TABLE_HEAD} />
      {estadosDeCuenta.map((item, index) => {
        const { usuario, deposito, saldoVencido } = item;
        return (
          <DocumentTableRow
            key={index}
            cellWidth="15%"
            data={[
              usuario.nomComercial,
              Money(saldoVencido.saldo),
              Money(item.importe),
              getAmount(deposito, "totalApagar"),
              getAmount(deposito, "pago"),
              getTipoDeposito(deposito),
              getMetodoPago(deposito),
            ]}
          />
        );
      })}
    </View>
  );
}

function getAmount(deposito, propName) {
  if (!deposito) return 0;
  return Money(deposito[propName]);
}

function getTipoDeposito(deposito) {
  const TIPOS = {
    "pago-premios": "p-p",
    "pago-completo": "p-c",
    "saldo-vencido": "s-v",
    reposicion: "r",
  };
  if (!deposito || deposito.tipoResto === undefined) return "sin definir";
  return Money(deposito.resto) + " (" + TIPOS[deposito.tipoResto] + ")";
}

function getMetodoPago(deposito) {
  if (!deposito || deposito.metodoPago === undefined) return "sin definir";
  return deposito.metodoPago;
}

const styles = StyleSheet.create({
  page: {
    margin: 20,
  },
  table: {
    marginVertical: 5,
  },
});
