import { TableCell, TableRow, Typography } from "@mui/material";
import CustomAvatar from "../../../../components/CustomAvatar";
import CustomModal from "../../../../components/CustomModal";
import { useModal } from "../../../../hooks";
import DetalleTransaccion from "./DetalleTransaccion";

export default function FilaTransaccion({ transaccion, index }) {
  const statusTextColor =
    transaccion.Status == "Exitosa"
      ? "green"
      : transaccion.Status == "PROCESSING"
      ? "#000"
      : "red";
  const modal = useModal();

  const handleModalCancel = () => {
    modal.setConfig({ open: false });
  };

  const handleModalAccept = () => {
    modal.setConfig({ open: false });
  };

  const handleClick = () => {
    modal.setConfig({
      open: true,
      type: "alert",
      alertTitle: "Detalle transacción",
      contentType: "content",
      showCancelBtn: false,
      confirmBtnText: "Cerrar",
    });
  };

  return (
    <>
      <TableRow
        onClick={handleClick}
        hover
        sx={{
          cursor: "pointer",
          backgroundColor:
            transaccion.Status == "PROCESSING" ? "orange" : undefined,
        }}
      >
        <TableCell>{index + 1}</TableCell>
        <TableCell>{transaccion.Bolsa}</TableCell>
        <TableCell>
          <CustomAvatar src={transaccion.logo} size={40} background="#fff" />
        </TableCell>
        <TableCell>{transaccion.Carrier}</TableCell>
        <TableCell>{transaccion.Monto}</TableCell>
        <TableCell>{transaccion.Telefono}</TableCell>
        <TableCell>
          {transaccion._fecha} {transaccion._hora}
        </TableCell>
        <TableCell>
          <Typography sx={{ color: statusTextColor }}>
            {transaccion.Status}
          </Typography>
        </TableCell>
        <TableCell>{transaccion._usuario}</TableCell>
      </TableRow>
      <CustomModal
        open={modal.config.open}
        type={modal.config.type}
        alertTitle={modal.config.alertTitle}
        progressTitle={modal.config.progressTitle}
        showCancelBtn={modal.config.showCancelBtn}
        confirmBtnText={modal.config.confirmBtnText}
        onCancel={handleModalCancel}
        onAccept={handleModalAccept}
        fullWidth
        maxWidth="xs"
      >
        {modal.config.contentType === "content" && (
          <DetalleTransaccion transaccion={transaccion} />
        )}
        {modal.config.contentType === "error" && modal.config.error}
      </CustomModal>
    </>
  );
}
