import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cargandoTransacciones: true,
  transacciones: [],
  transaccionesFiltradas: [],
  cargandoUsuarios: true,
  usuarios: [],
  usuarioID: "",
  cargandoSorteosSemana: true,
  sorteosSemana: [],
  semanaSeleccionada: null,
  periodoSeleccionado: null,
  cargandoPagosRealizados: true,
  pagosRealizados: [],
  cargandoPagosPendientes: true,
  pagosPendientes: [],
  usuarioSelected: null,
  premiosPagados: [],
  buscarPremioPagadoActive: false,
  buscandoPremioPagado: false,
  resultadosBusquedaPremioPagado: [],
  fetchingAccountStatuses: false,
  accountStatuses: [],
};

const administracionSlice = createSlice({
  name: "administracion",
  initialState,
  reducers: {
    setCargandoTransacciones: (state, { payload }) => {
      state.cargandoTransacciones = payload;
    },
    setTransacciones: (state, { payload }) => {
      state.transacciones = [...payload];
    },
    setTransaccionesFiltradas: (state, { payload }) => {
      state.transaccionesFiltradas = [...payload];
    },
    setSorteosSemana: (state, { payload }) => {
      state.sorteosSemana = [...payload];
    },
    setCargandoSorteosSemana: (state, { payload }) => {
      state.cargandoSorteosSemana = payload;
    },
    setSemanaSeleccionada: (state, { payload }) => {
      state.semanaSeleccionada = payload;
    },
    selectPeriodo: (state, { payload }) => {
      state.periodoSeleccionado = { ...payload };
    },
    setCargandoPagosRealizados: (state, { payload }) => {
      state.cargandoPagosRealizados = payload;
    },
    setPagosRealizados: (state, { payload }) => {
      state.pagosRealizados = [...payload];
    },
    setCargandoPagosPendientes: (state, { payload }) => {
      state.cargandoPagosPendientes = payload;
    },
    setPagosPendientes: (state, { payload }) => {
      state.pagosPendientes = [...payload];
    },
    setUsuarioSelected: (state, { payload }) => {
      state.usuarioSelected = payload;
    },
    setCargandoUsuarios: (state, { payload }) => {
      state.cargandoUsuarios = payload;
    },
    setUsuarios: (state, { payload }) => {
      state.usuarios = [...payload];
    },
    setUsuarioID: (state, { payload }) => {
      state.usuarioID = payload;
    },
    setPremiosPagados: (state, { payload }) => {
      state.premiosPagados = [...payload];
    },
    setBuscarPremioPagadoActive: (state, { payload }) => {
      state.buscarPremioPagadoActive = payload;
    },
    setBuscandoPremioPagado: (state, { payload }) => {
      state.buscandoPremioPagado = payload;
    },
    setResultadosBusquedaPremioPagado: (state, { payload }) => {
      state.resultadosBusquedaPremioPagado = payload;
    },
    setFetchingAccountStatuses: (state, { payload }) => {
      state.fetchingAccountStatuses = payload;
    },
    setAccountStatuses: (state, { payload }) => {
      state.accountStatuses = [...payload];
    },
  },
});

export const {
  setCargandoTransacciones,
  setTransacciones,
  setTransaccionesFiltradas,
  setSorteosSemana,
  setCargandoSorteosSemana,
  setSemanaSeleccionada,
  selectPeriodo,
  setCargandoPagosRealizados,
  setPagosRealizados,
  setCargandoPagosPendientes,
  setPagosPendientes,
  setUsuarioSelected,
  setCargandoUsuarios,
  setUsuarios,
  setUsuarioID,
  setPremiosPagados,
  setBuscarPremioPagadoActive,
  setBuscandoPremioPagado,
  setResultadosBusquedaPremioPagado,
  setFetchingAccountStatuses,
  setAccountStatuses,
} = administracionSlice.actions;

export default administracionSlice.reducer;
