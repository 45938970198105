import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useRef } from "react";
import { uuid } from "../utils";

export default function CustomSelect({
  label,
  disabled = false,
  options = [],
  optionLabel,
  optionValue,
  width = "100%",
  marginY = 0,
  marginX = 0,
  onChange,
  value,
}) {
  const selectRef = useRef(null);

  const handleChange = ({ target }) => {
    selectRef?.current.blur();
    onChange(target.value);
  };

  return (
    <FormControl
      sx={{ width: width, marginY: marginY, marginX: marginX }}
      size="small"
    >
      <InputLabel>{label}</InputLabel>
      <Select
        ref={selectRef}
        disabled={disabled}
        value={value}
        onChange={handleChange}
      >
        {options.map((option) => (
          <MenuItem key={uuid()} value={option[optionValue]}>
            {option[optionLabel]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
