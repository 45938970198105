import { useEffect } from "react";
import { Grid } from "@mui/material";
import ListaRegistros from "./components/ListaRegistros";
import TotalRegistros from "./components/TotalRegistros";

import { useLiberarEspacio } from "../../hooks";
import SectionTitle from "../../components/SectionTitle";

export default function LiberarEspacio() {
  const { cargarDatosLiberarEspacio } = useLiberarEspacio();

  useEffect(() => {
    cargarDatosLiberarEspacio();
  }, []);

  return (
    <Grid container rowSpacing={2} columnSpacing={2}>
      <Grid item xs={12}>
        <SectionTitle title="Liberar espacio" />
      </Grid>
      <Grid item xs={12} md={3}>
        <ListaRegistros />
      </Grid>
      <Grid item xs={12} md={9}>
        <TotalRegistros />
      </Grid>
    </Grid>
  );
}
