import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormControl, MenuItem, Select } from "@mui/material";
import {
  setSorteoKey,
  setSorteos,
} from "../../../features/sorteos/sorteosSlice";
import { obtenerSorteos } from "../../../services/bola";
import { Helpers, Moment, uuid } from "../../../utils";
import Database from "../../../database";
import { setNumerosJugados } from "../../../features/boletos/boletosSlice";
import { setUsuarioKey } from "../../../features/usuarios/usuariosSlice";

const SelectSorteo = () => {
  const [cargando, setCargando] = useState(true);
  const [optionKey, setOptionKey] = useState("");
  const sorteosState = useSelector((state) => state.sorteos);
  const dispatch = useDispatch();

  useEffect(() => {
    cargarSorteos();
  }, []);

  const cargarSorteos = async () => {
    setCargando(true);
    const items = await obtenerSorteos();
    const timestamp = await Database.getServerDate();
    const sorteosOrdenados = Helpers.ordenarLista(items, "fecha");
    const ultimoSorteoKey = obtenerUltimoSorteoJugado(
      sorteosOrdenados,
      timestamp
    );
    dispatch(setSorteos(sorteosOrdenados));
    setOptionKey(ultimoSorteoKey);
    dispatch(setSorteoKey(ultimoSorteoKey));
    setCargando(false);
  };

  const onSelect = ({ target }) => {
    setOptionKey(target.value);
    dispatch(setUsuarioKey(""));
    dispatch(setNumerosJugados([]));
    setTimeout(() => {
      dispatch(setSorteoKey(target.value));
    }, 100);
  };

  const obtenerUltimoSorteoJugado = (sorteos, serverTimestamp) => {
    const sorteosJugados = sorteos.filter((s) =>
      Moment(s.fecha).isSameOrBefore(serverTimestamp)
    );
    return Helpers.ordenarLista(sorteosJugados, "fecha")[0].key;
  };

  return (
    <FormControl fullWidth size="small">
      <Select
        disabled={cargando}
        onChange={onSelect}
        value={optionKey}
        displayEmpty
      >
        <MenuItem value="">
          <span style={{ fontSize: 14 }}>--selecciona un sorteo--</span>
        </MenuItem>
        {sorteosState.todos.map((item) => (
          <MenuItem
            key={uuid()}
            value={item.key}
            sx={{ justifyContent: "space-between" }}
          >
            <span style={{ fontSize: 14 }}>{item.sorteo}</span>{" "}
            <span style={{ fontSize: 14 }}>
              {Moment(item.fecha).format("ddd DD MMM")}
            </span>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectSorteo;
