import { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { Moment } from "../../../utils";
import Database from "../../../database";
import { DATABASE_TABLES } from "../../../constants";
import { useModal, useToast } from "../../../hooks";
import { CustomModal, InputField, Toast } from "../../../components";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  guardarHoraCierre,
  guardarLimiteApuestas,
} from "../../../services/bola";

export default function Ajustes() {
  return (
    <Card sx={{ marginBottom: 1.5 }}>
      <CardHeader subheader={<Typography>Ajustes</Typography>} />
      <CardContent>
        <Grid container rowSpacing={1} columnSpacing={1}>
          <Grid item xs={12} md={6}>
            <HoraCierre />
          </Grid>
          <Grid item xs={12} md={6}>
            <LimiteApuesta />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

const HoraCierre = () => {
  const [cargandoHoraCierre, setCargandoHoraCierre] = useState(true);
  const [hora, setHora] = useState(Moment("00:00", "HH:mm"));
  const [timeError, setTimeError] = useState(false);
  const formik = useFormik({
    initialValues: {
      passwordAdmin: "",
    },
    validationSchema: Yup.object().shape({
      passwordAdmin: Yup.string().required("Este campo es requerido"),
    }),
    onSubmit: (formData) => {
      if (!modal.config.confirmBtnClicked) {
        guardarHora(formData);
      }
    },
  });
  const modal = useModal();
  const toast = useToast();

  useEffect(() => {
    cargarHoraCierre();
  }, []);

  const cargarHoraCierre = async () => {
    setCargandoHoraCierre(true);
    const horaCierre = await Database.getObject(DATABASE_TABLES.HORA_CIERRE);
    setHora(Moment(horaCierre.hora, "HH:mm"));
    setCargandoHoraCierre(false);
  };

  const handleChange = (newTime) => {
    setHora(Moment(newTime));
  };

  const handleError = (arg) => {
    setTimeError(arg !== null);
  };

  const handleGuardarHora = () => {
    modal.setConfig({
      open: true,
      type: "alert",
      alertTitle: "Guardar hora cierre",
      contentType: "input-password",
      confirmBtnText: "Aceptar",
      action: "guardar-hora",
      showCancelBtn: true,
      content: (
        <Typography>
          La hora de cierre de sorteo será a las{" "}
          {Moment(hora, "HH:mm").format("HH:mm")}, deseas continuar?
        </Typography>
      ),
    });
  };

  const handleCancel = () => {
    modal.setConfig({ open: false });
    formik.handleReset();
  };

  const handleModalAccept = () => {
    if (modal.config.action === "guardar-hora") {
      formik.handleSubmit();
    }
    if (modal.config.action === "error") {
      handleCancel();
    }
  };

  const guardarHora = async ({ passwordAdmin }) => {
    try {
      modal.setConfig({
        type: "progress",
        progressTitle: "Guardando",
        confirmBtnClicked: true,
      });
      const horaGuardada = await guardarHoraCierre(passwordAdmin, hora);
      if (horaGuardada) {
        modal.setConfig({ open: false, confirmBtnClicked: false });
        formik.handleReset();
        setTimeout(() => {
          toast.setConfig({
            open: true,
            message: "Hora de cierre establecida correctamente",
          });
        }, 500);
      }
    } catch ({ message }) {
      modal.setConfig({
        type: "alert",
        alertTitle: "Mensaje",
        showCancelBtn: false,
        confirmBtnClicked: false,
        contentType: "error",
        error: <span>{message}</span>,
        action: "error",
      });
    }
  };

  return (
    <>
      <Typography sx={{ marginBottom: 2 }}>Hora cierre de sorteo</Typography>
      <TimePicker
        disabled={cargandoHoraCierre}
        label="Hora"
        value={hora}
        onChange={handleChange}
        onError={handleError}
        renderInput={(params) => <TextField {...params} />}
      />
      <div style={{ marginTop: 15 }}>
        <Button
          disabled={cargandoHoraCierre || timeError}
          variant="contained"
          size="small"
          onClick={handleGuardarHora}
        >
          Guardar hora
        </Button>
      </div>
      <CustomModal
        open={modal.config.open}
        type={modal.config.type}
        alertTitle={modal.config.alertTitle}
        progressTitle={modal.config.progressTitle}
        showCancelBtn={modal.config.showCancelBtn}
        confirmBtnText={modal.config.confirmBtnText}
        onCancel={handleCancel}
        onAccept={handleModalAccept}
        // disableConfirmBtn={disableConfirmBtn()}
      >
        {modal.config.contentType === "input-password" && (
          <>
            {modal.config.content}
            <Divider sx={{ marginY: 2 }} />
            <Typography>
              Por seguridad, te pedimos ingresar tu contraseña para poder
              continuar con el proceso.
            </Typography>
            <InputField
              type="password"
              label="Contraseña administrador"
              name="passwordAdmin"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.passwordAdmin}
              error={formik.errors.passwordAdmin}
              touched={formik.touched.passwordAdmin}
            />
          </>
        )}
        {modal.config.contentType === "error" && modal.config.error}
      </CustomModal>
      <Toast
        open={toast.config.open}
        handleClose={() => toast.setConfig({ open: false })}
        message={toast.config.message}
      />
    </>
  );
};

const LimiteApuesta = () => {
  const [cargandoLimite, setCargandoLimite] = useState(true);
  const formik = useFormik({
    initialValues: {
      tres: 0,
      dos: 0,
      una: 0,
    },
    validationSchema: Yup.object().shape({
      tres: Yup.number()
        .typeError("Ingresa un número valido")
        .integer("Ingresa un número entero")
        .positive("Ingresa un número mayor a 0")
        .required("Este campo es requerido"),
      dos: Yup.number()
        .typeError("Ingresa un número valido")
        .integer("Ingresa un número entero")
        .positive("Ingresa un número mayor a 0")
        .required("Este campo es requerido"),
      una: Yup.number()
        .typeError("Ingresa un número valido")
        .integer("Ingresa un número entero")
        .positive("Ingresa un número mayor a 0")
        .required("Este campo es requerido"),
    }),
    onSubmit: (validatedData) => {
      modal.setConfig({
        open: true,
        type: "alert",
        alertTitle: "Guardar limite",
        contentType: "input-password",
        confirmBtnText: "Aceptar",
        action: "guardar-limite",
        showCancelBtn: true,
        content: (
          <Typography>
            Estas cantidades definirán los limites por apuesta, deseas
            continuar?
          </Typography>
        ),
      });
    },
  });
  const formikPassword = useFormik({
    initialValues: {
      passwordAdmin: "",
    },
    validationSchema: Yup.object().shape({
      passwordAdmin: Yup.string().required("Este campo es requerido"),
    }),
    onSubmit: (validatedData) => {
      if (!modal.config.confirmBtnClicked) {
        guardarLimite(validatedData);
      }
    },
  });
  const modal = useModal();
  const toast = useToast();

  useEffect(() => {
    cargarLimiteApuestas();
  }, []);

  const cargarLimiteApuestas = async () => {
    setCargandoLimite(true);
    const limite = await Database.getObject(DATABASE_TABLES.LIMITE_APUESTAS);
    formik.setFieldValue("tres", limite.tresCifras);
    formik.setFieldValue("dos", limite.dosCifras);
    formik.setFieldValue("una", limite.unaCifra);
    setCargandoLimite(false);
  };

  const handleGuardarLimite = () => {
    formik.handleSubmit();
  };

  const handleCancel = () => {
    modal.setConfig({ open: false });
    formikPassword.handleReset();
  };

  const handleModalAccept = () => {
    if (modal.config.action === "guardar-limite") {
      formikPassword.handleSubmit();
    }
    if (modal.config.action === "error") {
      handleCancel();
    }
  };

  const guardarLimite = async ({ passwordAdmin }) => {
    try {
      modal.setConfig({
        type: "progress",
        progressTitle: "Guardando",
        confirmBtnClicked: true,
      });
      const limiteGuardado = await guardarLimiteApuestas(
        passwordAdmin,
        formik.values
      );
      if (limiteGuardado) {
        modal.setConfig({ open: false, confirmBtnClicked: false });
        formikPassword.handleReset();
        setTimeout(() => {
          toast.setConfig({
            open: true,
            message: "Limite establecido correctamente",
          });
        }, 500);
      }
    } catch ({ message }) {
      modal.setConfig({
        type: "alert",
        alertTitle: "Mensaje",
        showCancelBtn: false,
        confirmBtnClicked: false,
        contentType: "error",
        error: <span>{message}</span>,
        action: "error",
      });
    }
  };

  return (
    <>
      <Typography sx={{ marginBottom: 2 }}>Limite apuesta</Typography>
      <Grid container rowSpacing={1} columnSpacing={1}>
        <Grid item xs={4}>
          <InputField
            label="Tres cifras"
            name="tres"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.tres}
            error={formik.errors.tres}
            touched={formik.touched.tres}
            disabled={cargandoLimite}
          />
        </Grid>
        <Grid item xs={4}>
          <InputField
            label="Dos cifras"
            name="dos"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.dos}
            error={formik.errors.dos}
            touched={formik.touched.dos}
            disabled={cargandoLimite}
          />
        </Grid>
        <Grid item xs={4}>
          <InputField
            label="Una cifra"
            name="una"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.una}
            error={formik.errors.una}
            touched={formik.touched.una}
            disabled={cargandoLimite}
          />
        </Grid>
      </Grid>
      <div style={{ marginTop: 23 }}>
        <Button
          disabled={cargandoLimite}
          variant="contained"
          size="small"
          onClick={handleGuardarLimite}
        >
          Guardar limite
        </Button>
      </div>
      <CustomModal
        open={modal.config.open}
        type={modal.config.type}
        alertTitle={modal.config.alertTitle}
        progressTitle={modal.config.progressTitle}
        showCancelBtn={modal.config.showCancelBtn}
        confirmBtnText={modal.config.confirmBtnText}
        onCancel={handleCancel}
        onAccept={handleModalAccept}
      >
        {modal.config.contentType === "input-password" && (
          <>
            {modal.config.content}
            <Divider sx={{ marginY: 2 }} />
            <Typography>
              Por seguridad, te pedimos ingresar tu contraseña para poder
              continuar con el proceso.
            </Typography>
            <InputField
              type="password"
              label="Contraseña administrador"
              name="passwordAdmin"
              onChange={formikPassword.handleChange}
              onBlur={formikPassword.handleBlur}
              value={formikPassword.values.passwordAdmin}
              error={formikPassword.errors.passwordAdmin}
              touched={formikPassword.touched.passwordAdmin}
            />
          </>
        )}
        {modal.config.contentType === "error" && modal.config.error}
      </CustomModal>
      <Toast
        open={toast.config.open}
        handleClose={() => toast.setConfig({ open: false })}
        message={toast.config.message}
      />
    </>
  );
};
