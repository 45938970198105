import { useSearchParams } from "react-router-dom";

export function useCustomParams() {
  const [searchParams, setSearchParams] = useSearchParams();

  const update = (_key, _val) => {
    let currentParams = {};
    searchParams.forEach((val, key) => {
      currentParams[key] = val;
    });
    // IF IT OBJECT
    if (typeof _key === "object") {
      setSearchParams(Object.assign(currentParams, _key));
      return;
    }
    setSearchParams({
      ...currentParams,
      [_key]: _val,
    });
  };

  const get = (key) => {
    return searchParams.get(key);
  };

  const set = (params) => {
    setSearchParams(params);
  };

  return {
    get,
    set,
    update,
    size: searchParams.size,
  };
}
