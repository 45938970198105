import { Table, TableBody, Typography } from "@mui/material";

export default function TableSection({ children, title = null }) {
  return (
    <>
      {title !== null && (
        <Typography sx={{ textAlign: "center", marginY: 3 }}>
          {title}
        </Typography>
      )}
      <Table size="small" sx={{ marginTop: 2 }}>
        <TableBody>{children}</TableBody>
      </Table>
    </>
  );
}
