import { Button, Typography } from "@mui/material";
import { Colors } from "../../../constants";

function ButtonInputFile({
    text = "Seleccionar imagen",
    onChange,
    error,
    value,
    touched,
}) {
    return (
        <>
            <Button variant="contained" component="label" size="small">
                {text}
                <input
                    type="file"
                    accept="image/png"
                    name="file"
                    id="file"
                    hidden
                    onChange={onChange}
                />
            </Button>
            {error && touched && (
                <Typography
                    sx={{
                        marginTop: 1.5,
                        fontSize: 14,
                        color: "tomato",
                        padding: 1,
                        border: "solid thin tomato",
                    }}
                >
                    {error}
                </Typography>
            )}
            <Typography sx={{ color: Colors.green }}>{value}</Typography>
        </>
    );
}

export default ButtonInputFile;
