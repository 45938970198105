import { DATABASE_TABLES } from "../../constants";
import Database from "../../database";
import { Helpers, Moment, Money } from "../../utils";

export async function obtenerEstadoCuenta(periodo, usuario) {
  try {
    const reporteSemanal = await obtenerReporteSemanal(periodo);
    const reporteSemanalUsuario = await calcularReporteSemanalUsuario(
      reporteSemanal,
      usuario
    );
    const periodoPrevio = obtenerPeriodoPrevio(periodo);
    const reporteSemanalAnterior = await obtenerReporteSemanal(periodoPrevio);
    const reporteSemanalAnteriorUsuario = await calcularReporteSemanalUsuario(
      reporteSemanalAnterior,
      usuario
    );
    const referenciaSemanaAnterior =
      periodoPrevio.inicial + "#" + periodoPrevio.final + "#" + usuario.usuario;
    const depositosSemanaAnterior = await Database.getItemsByProp(
      DATABASE_TABLES.DEPOSITOS,
      "referencia",
      referenciaSemanaAnterior
    );
    const saldoAnterior = reporteSemanalAnteriorUsuario.importe;
    const premiosPagadosInicioSemAnterior =
      reporteSemanalAnteriorUsuario.totalPremiosPagadosInicioSem;
    const abonosSemanaAnterior = await Database.getItemsByProp(
      DATABASE_TABLES.ABONOS,
      "fechaAbono",
      periodoPrevio.inicial,
      (item) => item.agenciaId === usuario.id
    );
    const totalAbonosLunesSemanaAnterior = abonosSemanaAnterior.reduce(
      (acc, el) => acc + parseFloat(el.cantidad),
      0
    );
    const totalPagosSemanaAnterior = depositosSemanaAnterior.reduce(
      (acc, el) => acc + parseFloat(el.pago),
      0
    );
    // ESTADO PENDIENTE DE RESOLUCION EN PAGOS A TIEMPO (LUNES)
    // const saldoVencido =
    //   saldoAnterior -
    //   totalPagosSemanaAnterior +
    //   totalAbonosLunesSemanaAnterior -
    //   premiosPagadosInicioSemAnterior;

    const saldoVencido = saldoAnterior - totalPagosSemanaAnterior;
    return {
      periodo: Helpers.obtenerPeriodoTexto(periodo),
      saldoAnterior,
      premiosPagadosInicioSemAnterior,
      totalAbonosLunesSemanaAnterior,
      totalPagosSemanaAnterior,
      saldoVencido,
      ...reporteSemanalUsuario,
      importe: reporteSemanalUsuario.importe + saldoVencido,
    };
  } catch ({ message }) {
    throw new Error(message);
  }
}

export async function obtenerEstadosDeCuenta() {
  try {
    const timestamp = await Database.getServerDate();
    const periodoSemAnt = { inicial: "", final: "" };
    periodoSemAnt.final = obtenerDiaSemAnt(timestamp, "domingo");
    periodoSemAnt.inicial = obtenerDiaSemAnt(periodoSemAnt.final, "lunes");
    // periodoSemAnt.final = "2023-07-16";
    // periodoSemAnt.inicial = "2023-07-10";
    // REPORTE SEMANAL
    const reporteSemanal = await obtenerReporteSemanal(periodoSemAnt, [
      "depositos",
    ]);
    const estadosDeCuentaUsuarios = await obtenerEstadosDeCuentaUsuarios(
      reporteSemanal,
      periodoSemAnt
    );
    return estadosDeCuentaUsuarios;
  } catch ({ message }) {
    throw new Error(message);
  }
}

export async function obtenerEstadosDeCuentaUsuarios(reporteSemanal, periodo) {
  try {
    let estadosDeCuenta = [];
    // FILTRAR INFORMACION POR CADA USUARIO
    for (let i = 0; i < reporteSemanal.usuarios.length; i++) {
      const usuario = reporteSemanal.usuarios[i];
      const referencia =
        periodo.inicial + "#" + periodo.final + "#" + usuario.usuario;
      const depositos = await Database.getItemsByProp(
        DATABASE_TABLES.DEPOSITOS,
        "referencia",
        referencia
      );
      const reporte = obtenerReporteSemanalUsuario(reporteSemanal, usuario);
      const ventaTotalTickets = reporte.boletos.total;
      const comisionVentaTickets =
        calcularComisionVentaTickets(ventaTotalTickets);
      const premiosPagadosInicioSem = reporte.premiosPagados.lista.filter(
        (p) =>
          Moment(p.fechaPago).format("dddd").toLocaleLowerCase() === "lunes"
      );
      const totalPremiosPagadosInicioSem = premiosPagadosInicioSem.reduce(
        (acc, item) => acc + parseFloat(item.premio),
        0
      );
      const premiosPagadosRestoSem = reporte.premiosPagados.lista.filter(
        (p) =>
          Moment(p.fechaPago).format("dddd").toLocaleLowerCase() !== "lunes"
      );
      const totalPremiosPagadosRestoSem = premiosPagadosRestoSem.reduce(
        (acc, item) => acc + parseFloat(item.premio),
        0
      );
      const tiempoAire = obtenerVentaTransacciones(
        reporte.transacciones.lista,
        "1"
      );
      const paquetes = obtenerVentaTransacciones(
        reporte.transacciones.lista,
        "2"
      );
      const totalVentaRecargas = tiempoAire.total + paquetes.total;
      const pagoServicios = obtenerVentaTransacciones(
        reporte.transacciones.lista,
        "3"
      );
      const giftcards = obtenerVentaTransacciones(
        reporte.transacciones.lista,
        "4"
      );
      // SUMA TOTAL VENTAS
      const sumaTotalVentas =
        ventaTotalTickets +
        totalVentaRecargas +
        pagoServicios.total +
        giftcards.total;
      // COMISIONES
      const comisionTiempoAire = obtenerComisionTransacciones(
        reporte.transacciones.lista,
        "1"
      );
      const comisionPaquetes = obtenerComisionTransacciones(
        reporte.transacciones.lista,
        "2"
      );
      const comisionPagoServicios = obtenerComisionTransacciones(
        reporte.transacciones.lista,
        "3"
      );
      const comisionGiftCards = obtenerComisionTransacciones(
        reporte.transacciones.lista,
        "4"
      );
      // SUMA TOTAL COMISIONES
      const sumaTotalComisiones =
        reporte.premiosPagados.total +
        comisionVentaTickets +
        comisionTiempoAire +
        comisionPaquetes +
        comisionPagoServicios +
        comisionGiftCards;
      const _importe =
        sumaTotalVentas -
        sumaTotalComisiones -
        (reporte.reembolsos.total + reporte.ajustes.total);
      const estadoDeCuentaUsuario = {
        referencia,
        usuario,
        numRegistrosTicketPlus: reporte.boletos.lista.length,
        ventaTotalTicketPlus: reporte.boletos.total,
        comisionTicketPlus: comisionVentaTickets,
        numRegistrosRecargas: tiempoAire.registros + paquetes.registros,
        ventaTotalRecargas: totalVentaRecargas,
        comisionRecargas: comisionTiempoAire + comisionPaquetes,
        numRegistrosPagoServicios: pagoServicios.registros,
        ventaTotalPagoServicios: pagoServicios.total,
        comisionPagoServicios,
        numRegistrosGiftCards: giftcards.registros,
        ventaTotalGiftCards: giftcards.total,
        comisionGiftCards,
        sumaTotalVentas,
        sumaTotalComisiones,
        numPremiosPagadosInicioSem: premiosPagadosInicioSem.length,
        totalPremiosPagadosInicioSem,
        numPremiosPagadosRestoSem: premiosPagadosRestoSem.length,
        totalPremiosPagadosRestoSem,
        numRegistrosCancelados: reporte.cancelados.lista.length,
        totalCancelados: reporte.cancelados.total,
        totalAbonos: reporte.abonos.total,
        depositos: depositos.reduce((acc, el) => acc + parseFloat(el.pago), 0),
        importe: _importe,
        importeMasAbonos: _importe + reporte.abonos.total,
      };
      estadosDeCuenta.push(estadoDeCuentaUsuario);
    }
    return estadosDeCuenta;
  } catch ({ message }) {
    throw new Error(message);
  }
}

export async function obtenerReporteSemanal(periodo, exceptions = []) {
  try {
    const boletos = exceptions.includes("boletos")
      ? []
      : await Database.getItemsInRange(
          DATABASE_TABLES.BOLETOS,
          "fechaExp",
          periodo.inicial,
          periodo.final
        );
    const cancelados = exceptions.includes("cancelados")
      ? []
      : await Database.getItemsInRange(
          DATABASE_TABLES.BOLETOS_CANCELADOS,
          "fechaCancelacion",
          periodo.inicial,
          periodo.final
        );
    const premiosPagados = exceptions.includes("premiosPagados")
      ? []
      : await Database.getItemsInRange(
          DATABASE_TABLES.PREMIOS_PAGADOS,
          "fechaPago",
          periodo.inicial,
          periodo.final
        );
    const abonos = exceptions.includes("abonos")
      ? []
      : await Database.getItemsInRange(
          DATABASE_TABLES.ABONOS,
          "fechaAbono",
          periodo.inicial,
          periodo.final
        );
    // OBTENER TRANSACCIONES
    const transacciones = exceptions.includes("transacciones")
      ? []
      : await Database.getItemsInRange(
          DATABASE_TABLES.TRANSACCIONES,
          "_fecha",
          periodo.inicial,
          periodo.final,
          (item) => item.Status == "Exitosa"
        );
    const depositos = exceptions.includes("depositos")
      ? []
      : await Database.getItemsInRange(
          DATABASE_TABLES.DEPOSITOS,
          "fecha",
          periodo.inicial,
          periodo.final
        );
    const usuarios = exceptions.includes("usuarios")
      ? []
      : await Database.getItems(
          DATABASE_TABLES.AGENCIAS,
          (u) => !["37000", "57000", "58000", "00000"].includes(u.usuario)
        );
    return {
      boletos,
      cancelados,
      premiosPagados,
      abonos,
      transacciones,
      depositos,
      usuarios,
    };
  } catch ({ message }) {
    throw new Error(message);
  }
}

function obtenerReporteSemanalUsuario(reporteSemanal, usuario) {
  const filtrados = {
    boletos: reporteSemanal.boletos.filter(
      (item) => item.numeroAgencia === usuario.usuario
    ),
    cancelados: reporteSemanal.cancelados.filter(
      (item) => item.agencia === usuario.usuario
    ),
    premiosPagados: reporteSemanal.premiosPagados.filter(
      (item) => item.pagadoPor === usuario.usuario
    ),
    abonos: reporteSemanal.abonos.filter(
      (item) => item.agenciaId === usuario.id
    ),
    transacciones: reporteSemanal.transacciones.filter(
      (item) => item._usuario === usuario.usuario
    ),
  };
  // TIPOS DE ABONOS
  const tipoAbono = filtrados.abonos.filter((el) => el.tipo === "abono");
  const totalTipoAbono = tipoAbono.reduce(
    (acc, el) => acc + parseFloat(el.cantidad),
    0
  );
  const tipoReembolso = filtrados.abonos.filter(
    (el) => el.tipo === "reembolso"
  );
  const totalTipoReembolso = tipoReembolso.reduce(
    (acc, el) => acc + parseFloat(el.cantidad),
    0
  );
  const tipoAjuste = filtrados.abonos.filter((el) => el.tipo === "ajuste");
  const totalTipoAjuste = tipoAjuste.reduce(
    (acc, el) => acc + parseFloat(el.cantidad),
    0
  );

  return {
    boletos: {
      lista: filtrados.boletos,
      total: filtrados.boletos.reduce(
        (acc, el) => acc + parseInt(el.totalApostado),
        0
      ),
    },
    cancelados: {
      lista: filtrados.cancelados,
      total: filtrados.cancelados.reduce(
        (acc, el) => acc + parseInt(el.reembolso),
        0
      ),
    },
    premiosPagados: {
      lista: filtrados.premiosPagados,
      total: filtrados.premiosPagados.reduce(
        (acc, el) => acc + parseInt(el.premio),
        0
      ),
    },
    abonos: {
      lista: tipoAbono,
      total: totalTipoAbono,
    },
    reembolsos: {
      lista: tipoReembolso,
      total: totalTipoReembolso,
    },
    ajustes: {
      lista: tipoAjuste,
      total: totalTipoAjuste,
    },
    transacciones: {
      lista: filtrados.transacciones,
      total: filtrados.transacciones.reduce(
        (acc, el) => acc + parseFloat(Money(el.Monto, false)),
        0
      ),
    },
  };
}

async function calcularReporteSemanalUsuario(reporteSemanal, usuario) {
  try {
    const {
      abonos,
      reembolsos,
      ajustes,
      boletos,
      premiosPagados,
      transacciones,
      cancelados,
    } = obtenerReporteSemanalUsuario(reporteSemanal, usuario);
    // VENTAS
    const ventaTotalTicketPlus = boletos.lista.reduce(
      (acc, el) => acc + parseInt(el.totalApostado),
      0
    );
    const premiosPagadosInicioSem = premiosPagados.lista.filter(
      (p) => Moment(p.fechaPago).format("dddd").toLocaleLowerCase() === "lunes"
    );
    const totalPremiosPagadosInicioSem = premiosPagadosInicioSem.reduce(
      (acc, item) => acc + parseFloat(item.premio),
      0
    );
    const premiosPagadosRestoSem = premiosPagados.lista.filter(
      (p) => Moment(p.fechaPago).format("dddd").toLocaleLowerCase() !== "lunes"
    );
    const totalPremiosPagadosRestoSem = premiosPagadosRestoSem.reduce(
      (acc, item) => acc + parseFloat(item.premio),
      0
    );
    const tiempoAire = obtenerVentaTransacciones(transacciones.lista, "1");
    const paquetes = obtenerVentaTransacciones(transacciones.lista, "2");
    const pagoServicios = obtenerVentaTransacciones(transacciones.lista, "3");
    const giftcards = obtenerVentaTransacciones(transacciones.lista, "4");
    const ventaTotalRecargas = tiempoAire.total + paquetes.total;
    const totalRegistrosRecargas = tiempoAire.registros + paquetes.registros;
    const sumaTotalVentas =
      ventaTotalTicketPlus +
      ventaTotalRecargas +
      pagoServicios.total +
      giftcards.total;
    // MENOS
    const comisionTicketPlus =
      calcularComisionVentaTickets(ventaTotalTicketPlus);
    const comisionTiempoAire = obtenerComisionTransacciones(
      transacciones.lista,
      "1"
    );
    const comisionPaquetes = obtenerComisionTransacciones(
      transacciones.lista,
      "2"
    );
    const comisionPagoServicios = obtenerComisionTransacciones(
      transacciones.lista,
      "3"
    );
    const comisionGiftCards = obtenerComisionTransacciones(
      transacciones.lista,
      "4"
    );
    const sumaTotalComisiones =
      premiosPagados.total +
      comisionTicketPlus +
      comisionTiempoAire +
      comisionPaquetes +
      comisionPagoServicios +
      comisionGiftCards;

    return {
      // VENTAS
      numRegistrosTicketPlus: boletos.lista.length,
      ventaTotalTicketPlus,
      numRegistrosRecargas: totalRegistrosRecargas,
      ventaTotalRecargas,
      numRegistrosPagoServicios: pagoServicios.registros,
      ventaTotalPagoServicios: pagoServicios.total,
      numRegistrosGiftCards: giftcards.registros,
      ventaTotalGiftCards: giftcards.total,
      sumaTotalVentas,
      // MENOS
      numPremiosPagadosRestoSem: premiosPagadosRestoSem.length,
      totalPremiosPagadosRestoSem,
      numPremiosPagadosInicioSem: premiosPagadosInicioSem.length,
      totalPremiosPagadosInicioSem,
      comisionTicketPlus,
      comisionRecargas: comisionTiempoAire + comisionPaquetes,
      comisionPagoServicios,
      comisionGiftCards,
      sumaTotalComisiones,
      numRegistrosAbonos: abonos.lista.length,
      totalAbonos: abonos.total,
      numRegistrosReembolsos: reembolsos.lista.length,
      totalReembolsos: reembolsos.total,
      numRegistrosAjustes: ajustes.lista.length,
      totalAjustes: ajustes.total,
      numRegistrosCancelados: cancelados.lista.length,
      totalCancelados: cancelados.total,
      importe:
        abonos.total +
        (sumaTotalVentas - sumaTotalComisiones) -
        (reembolsos.total + ajustes.total),
    };
  } catch ({ message }) {
    throw new Error(message);
  }
}

function obtenerPeriodoPrevio(periodo) {
  const domingoAnterior = Moment(periodo.inicial)
    .subtract(1, "days")
    .format("YYYY-MM-DD");
  let counter = 0;
  while (
    Moment(domingoAnterior)
      .subtract(counter, "days")
      .format("dddd")
      .toLowerCase() !== "lunes"
  ) {
    counter++;
  }
  const lunesAnterior = Moment(domingoAnterior)
    .subtract(counter, "days")
    .format("YYYY-MM-DD");
  return { inicial: lunesAnterior, final: domingoAnterior };
}

async function obtenerEstadoDiario(dia, usuario) {
  try {
    const timestamp = await Database.getServerDate();
    const periodo = obtenerPeriodoDiario(timestamp, dia);
    const referencia =
      periodo.inicial + "#" + periodo.final + "#" + usuario.usuario;
    // BOLETOS
    const boletosObj = await Database.getFullObjectInRange(
      DATABASE_TABLES.BOLETOS,
      "fechaExp",
      periodo.inicial,
      periodo.final,
      (item) => item.numeroAgencia === usuario.usuario,
      "totalApostado"
    );
    const ventaTotalTickets = boletosObj.total;
    const comisionVentaTickets =
      calcularComisionVentaTickets(ventaTotalTickets);
    // OBTENER PREMIOS PAGADOS
    const premiosPagadosObj = await Database.getFullObjectInRange(
      DATABASE_TABLES.PREMIOS_PAGADOS,
      "fechaPago",
      periodo.inicial,
      periodo.final,
      (item) => item.pagadoPor === usuario.usuario,
      "premio"
    );
    const premiosPagadosInicioSem = premiosPagadosObj.list.filter(
      (p) => Moment(p.fechaPago).format("dddd").toLocaleLowerCase() === "lunes"
    );
    const totalPremiosPagadosInicioSem = premiosPagadosInicioSem.reduce(
      (acc, item) => acc + parseFloat(item.premio),
      0
    );
    const premiosPagadosRestoSem = premiosPagadosObj.list.filter(
      (p) => Moment(p.fechaPago).format("dddd").toLocaleLowerCase() !== "lunes"
    );
    const totalPremiosPagadosRestoSem = premiosPagadosRestoSem.reduce(
      (acc, item) => acc + parseFloat(item.premio),
      0
    );
    // OBTENER CANCELADOS
    const canceladosObj = await Database.getFullObjectInRange(
      DATABASE_TABLES.BOLETOS_CANCELADOS,
      "fechaCancelacion",
      periodo.inicial,
      periodo.final,
      (item) => item.agencia === usuario.usuario,
      "reembolso"
    );
    // OBTENER TRANSACCIONES
    const trans = await Database.getItemsInRange(
      DATABASE_TABLES.TRANSACCIONES,
      "_fecha",
      periodo.inicial,
      periodo.final,
      (item) => item._usuario === usuario.usuario && item.Status == "Exitosa"
    );
    const tiempoAire = obtenerVentaTransacciones(trans, "1");
    const paquetes = obtenerVentaTransacciones(trans, "2");
    const totalVentaRecargas = tiempoAire.total + paquetes.total;
    const pagoServicios = obtenerVentaTransacciones(trans, "3");
    const giftcards = obtenerVentaTransacciones(trans, "4");
    // SUMA TOTAL VENTAS
    const sumaTotalVentas =
      ventaTotalTickets +
      totalVentaRecargas +
      pagoServicios.total +
      giftcards.total;
    // COMISIONES
    const comisionTiempoAire = obtenerComisionTransacciones(trans, "1");
    const comisionPaquetes = obtenerComisionTransacciones(trans, "2");
    const comisionPagoServicios = obtenerComisionTransacciones(trans, "3");
    const comisionGiftCards = obtenerComisionTransacciones(trans, "4");
    // SUMA TOTAL COMISIONES
    const sumaTotalComisiones =
      premiosPagadosObj.total +
      comisionVentaTickets +
      comisionTiempoAire +
      comisionPaquetes +
      comisionPagoServicios +
      comisionGiftCards;
    return {
      referencia,
      numRegistrosTicketPlus: boletosObj.list.length,
      ventaTotalTicketPlus: ventaTotalTickets,
      comisionTicketPlus: comisionVentaTickets,
      numRegistrosRecargas: tiempoAire.registros + paquetes.registros,
      ventaTotalRecargas: totalVentaRecargas,
      comisionRecargas: comisionTiempoAire + comisionPaquetes,
      numRegistrosPagoServicios: pagoServicios.registros,
      ventaTotalPagoServicios: pagoServicios.total,
      comisionPagoServicios,
      numRegistrosGiftCards: giftcards.registros,
      ventaTotalGiftCards: giftcards.total,
      comisionGiftCards,
      sumaTotalVentas,
      sumaTotalComisiones,
      numRegistrosCancelados: canceladosObj.list.length,
      totalCancelados: canceladosObj.total,
      numPremiosPagadosInicioSem: premiosPagadosInicioSem.length,
      totalPremiosPagadosInicioSem,
      numPremiosPagadosRestoSem: premiosPagadosRestoSem.length,
      totalPremiosPagadosRestoSem,
      numPremiosPagados: premiosPagadosObj.list.length,
      totalPremiosPagados: premiosPagadosObj.total,
    };
  } catch ({ message }) {
    throw new Error(message);
  }
}

async function obtenerEstadoSemanal(_periodo, usuario) {
  try {
    const periodo = await obtenerPeriodo(_periodo);
    const referencia =
      periodo.inicial + "#" + periodo.final + "#" + usuario.usuario;
    let estadoDeCuenta = await generarEstadoDeCuenta(
      referencia,
      periodo,
      usuario
    );
    // OBTENER ABONOS
    const abonosObj = await Database.getFullObjectInRange(
      DATABASE_TABLES.ABONOS,
      "fechaAbono",
      periodo.inicial,
      periodo.final,
      (item) => item.agenciaId === usuario.id,
      "cantidad"
    );
    // BUSCAR SI YA SE HIZO EL DEPOSITO CORRESPONDIENTE AL ESTADO DE CUENTA ACTUAL
    const existeDeposito = await Database.getItem(
      DATABASE_TABLES.DEPOSITOS,
      "referencia",
      estadoDeCuenta.referencia
    );
    if (existeDeposito) estadoDeCuenta.existeDeposito = true;
    // BUSCAR ESTADO DE CUENTA REGISTRADO
    const estadoDeCuentaPrevio = await obtenerEstadoDeCuentaPrevio(referencia);
    estadoDeCuenta.previo = estadoDeCuentaPrevio;
    estadoDeCuenta.numRegistrosAbonos = abonosObj.list.length;
    estadoDeCuenta.totalAbonos = abonosObj.total;
    const importe = await obtenerImporteEstadoDeCuenta(estadoDeCuenta);
    estadoDeCuenta.importe = importe + abonosObj.total;
    return estadoDeCuenta;
  } catch ({ message }) {
    throw new Error(message);
  }
}

export function obtenerPeriodoDiario(timestamp, periodo) {
  const dias = "domingo.lunes.martes.miercoles.jueves.viernes.sabado".split(
    "."
  );
  if (periodo === "hoy") {
    return {
      inicial: Moment(timestamp).format("YYYY-MM-DD"),
      final: Moment(timestamp).format("YYYY-MM-DD"),
    };
  }
  // DIAS DE LA SEMANA
  if (dias.includes(periodo)) {
    let counter =
      Moment(timestamp).subtract(0, "days").format("dddd") === periodo ? 1 : 0;
    while (
      Moment(timestamp).subtract(counter, "days").format("dddd") !== periodo
    ) {
      counter++;
    }
    return {
      inicial: Moment(timestamp).subtract(counter, "days").format("YYYY-MM-DD"),
      final: Moment(timestamp).subtract(counter, "days").format("YYYY-MM-DD"),
    };
  }
}

export async function guardarDeposito({
  referencia,
  importe,
  usuario,
  monto,
  metodoPago,
  tipoResto,
  resto,
}) {
  try {
    const timestamp = await Database.getServerDate();
    const _pago = importe < 0 ? -Math.abs(monto) : monto;
    console.log({
      referencia,
      importe,
      usuario,
      monto,
      metodoPago,
      tipoResto,
      resto,
    });
    return true;
    // VERIFICAR SI AUN NO EXISTE UN DEPOSITO CON LA MISMA REFERENCIA
    const existeDeposito = await Database.getItem(
      DATABASE_TABLES.DEPOSITOS,
      "referencia",
      referencia
    );
    if (existeDeposito) {
      throw new Error("Ya se realizo el deposito anteriormente");
    }
    return await Database.save(DATABASE_TABLES.DEPOSITOS, {
      referencia,
      fecha: Moment(timestamp).format("YYYY-MM-DD"),
      hora: Moment(timestamp).format("HH:mm:ss"),
      importe,
      pago: _pago,
      saldoVencido: importe - _pago,
      usuario,
    });
  } catch ({ message }) {
    throw new Error(message);
  }
}

async function obtenerPeriodo(periodo) {
  try {
    if (periodo.nombre && periodo.nombre === "semanal") {
      return await obtenerPeriodoSemAct();
    }
    return periodo;
  } catch ({ message }) {
    throw new Error(message);
  }
}

async function obtenerPeriodoSemAct() {
  try {
    const timestamp = await Database.getServerDate();
    const fechaFinal = Moment(timestamp).format("YYYY-MM-DD");
    let counter = 0;
    while (
      Moment(timestamp)
        .subtract(counter, "days")
        .format("dddd")
        .toLowerCase() !== "lunes"
    ) {
      counter++;
    }
    const fechaInicial = Moment(timestamp)
      .subtract(counter, "days")
      .format("YYYY-MM-DD");
    return { inicial: fechaInicial, final: fechaFinal };
  } catch ({ message }) {
    throw new Error(message);
  }
}

export function obtenerVentaTransacciones(transacciones = [], categoriaID) {
  const transaccionesFiltradas = transacciones.filter(
    (t) => t.CategoriaID == categoriaID
  );
  let totalVenta = 0;
  // ITERAR TRANSACCIONES FILTRADAS
  for (let i = 0; i < transaccionesFiltradas.length; i++) {
    const transaccion = transaccionesFiltradas[i];
    // SI ES RECARGA | PAQUETE
    if (["1", "2"].includes(categoriaID)) {
      const ventaMasComision =
        parseFloat(Money(transaccion.Monto, false, true)) +
        parseFloat(Money(transaccion.Comision, false, true));
      totalVenta += ventaMasComision;
    }
    // SI ES SERVICIO | GIFTCARD
    if (["3", "4"].includes(categoriaID)) {
      const comisionAdmin =
        parseFloat(Money(transaccion.Comision, false, true)) * 0.5;
      const comisionServicio =
        parseFloat(Money(transaccion.Comision, false, true)) * 0.5;
      const cargoMasComision =
        parseFloat(Money(transaccion.Cargo, false, true)) +
        parseFloat(Money(transaccion.Comision, false, true));
      const resultado =
        parseFloat(Money(transaccion.Monto, false, true)) +
        (parseFloat(cargoMasComision) - parseFloat(comisionServicio));
      totalVenta += resultado + comisionAdmin;
    }
  }
  return {
    registros: parseInt(transaccionesFiltradas.length),
    total: totalVenta,
  };
}

export function obtenerComisionTransacciones(transacciones = [], categoriaID) {
  const transaccionesFiltradas = transacciones.filter(
    (t) => t.CategoriaID === categoriaID
  );
  let totalComision = 0;
  for (let i = 0; i < transaccionesFiltradas.length; i++) {
    const transaccion = transaccionesFiltradas[i];
    // RECARGA | PAQUETE
    if (["1", "2"].includes(categoriaID)) {
      const comisionSobreRecarga =
        parseFloat(Money(transaccion.Monto, false, true)) * 0.0375587;
      const comisionRecarga =
        parseFloat(Money(transaccion.Cargo, false, true)) +
        parseFloat(Money(transaccion.Comision, false, true));
      const sumaComisiones =
        parseFloat(Money(comisionSobreRecarga, false, true)) +
        parseFloat(Money(comisionRecarga, false, true));
      totalComision += sumaComisiones;
    }
    // SERVICIO
    if (categoriaID === "3") {
      const comisionServicio =
        parseFloat(Money(transaccion.Comision, false, true)) * 0.5;
      totalComision += comisionServicio;
    }
    // GIFTCARD
    if (categoriaID === "4") {
      const abono = parseFloat(Money(transaccion.Abono, false, true)) * 0.5;
      const comisionServicio =
        parseFloat(Money(transaccion.Comision, false, true)) * 0.5;
      totalComision += comisionServicio + abono;
    }
  }
  return totalComision;
}

function crearReferenciasAnteriores(referencia, ocurrencies = 5) {
  const { inicial, usuario } = Helpers.extraerInfoRef(referencia);
  let referencias = [];
  let ocurrenciesCounter = 0;
  let counter = 0;
  let dias_lunes = [];
  // OBTENEMOS SOLO LOS DIAS LUNES
  while (ocurrenciesCounter !== ocurrencies) {
    if (
      Moment(inicial).subtract(counter, "days").format("dddd").toLowerCase() ===
      "lunes"
    ) {
      dias_lunes.push(
        Moment(inicial).subtract(counter, "days").format("YYYY-MM-DD")
      );
      ocurrenciesCounter++;
    }
    counter++;
  }
  //   OBTENER DIAS DOMINGOS
  dias_lunes.forEach((lunes) => {
    let counter2 = 0;
    while (
      Moment(lunes).add(counter2, "days").format("dddd").toLowerCase() !==
      "domingo"
    ) {
      counter2++;
    }
    referencias.push(
      lunes +
        "#" +
        Moment(lunes).add(counter2, "days").format("YYYY-MM-DD") +
        "#" +
        usuario
    );
  });
  referencias.shift();
  return referencias;
}

function calcularComisionVentaTickets(totalVenta) {
  if (totalVenta > 10000) return totalVenta * 0.15;
  if (totalVenta > 5001) return totalVenta * 0.12;
  return totalVenta * 0.1;
}

async function generarEstadoDeCuenta(referencia, periodo, usuario) {
  try {
    // BOLETOS
    const boletosObj = await Database.getFullObjectInRange(
      DATABASE_TABLES.BOLETOS,
      "fechaExp",
      periodo.inicial,
      periodo.final,
      (item) => item.numeroAgencia === usuario.usuario,
      "totalApostado"
    );
    const ventaTotalTickets = boletosObj.total;
    const comisionVentaTickets =
      calcularComisionVentaTickets(ventaTotalTickets);
    // OBTENER PREMIOS PAGADOS
    const premiosPagadosObj = await Database.getFullObjectInRange(
      DATABASE_TABLES.PREMIOS_PAGADOS,
      "fechaPago",
      periodo.inicial,
      periodo.final,
      (item) => item.pagadoPor === usuario.usuario,
      "premio"
    );
    const premiosPagadosInicioSem = premiosPagadosObj.list.filter(
      (p) => Moment(p.fechaPago).format("dddd").toLocaleLowerCase() === "lunes"
    );
    const totalPremiosPagadosInicioSem = premiosPagadosInicioSem.reduce(
      (acc, item) => acc + parseFloat(item.premio),
      0
    );
    const premiosPagadosRestoSem = premiosPagadosObj.list.filter(
      (p) => Moment(p.fechaPago).format("dddd").toLocaleLowerCase() !== "lunes"
    );
    const totalPremiosPagadosRestoSem = premiosPagadosRestoSem.reduce(
      (acc, item) => acc + parseFloat(item.premio),
      0
    );
    // OBTENER CANCELADOS
    const canceladosObj = await Database.getFullObjectInRange(
      DATABASE_TABLES.BOLETOS_CANCELADOS,
      "fechaCancelacion",
      periodo.inicial,
      periodo.final,
      (item) => item.agencia === usuario.usuario,
      "reembolso"
    );
    // OBTENER TRANSACCIONES
    const trans = await Database.getItemsInRange(
      DATABASE_TABLES.TRANSACCIONES,
      "_fecha",
      periodo.inicial,
      periodo.final,
      (item) => item._usuario === usuario.usuario && item.Status == "Exitosa"
    );
    const tiempoAire = obtenerVentaTransacciones(trans, "1");
    const paquetes = obtenerVentaTransacciones(trans, "2");
    const totalVentaRecargas = tiempoAire.total + paquetes.total;
    const pagoServicios = obtenerVentaTransacciones(trans, "3");
    const giftcards = obtenerVentaTransacciones(trans, "4");
    // SUMA TOTAL VENTAS
    const sumaTotalVentas =
      ventaTotalTickets +
      totalVentaRecargas +
      pagoServicios.total +
      giftcards.total;
    // COMISIONES
    const comisionTiempoAire = obtenerComisionTransacciones(trans, "1");
    const comisionPaquetes = obtenerComisionTransacciones(trans, "2");
    const comisionPagoServicios = obtenerComisionTransacciones(trans, "3");
    const comisionGiftCards = obtenerComisionTransacciones(trans, "4");
    // SUMA TOTAL COMISIONES
    const sumaTotalComisiones =
      premiosPagadosObj.total +
      comisionVentaTickets +
      comisionTiempoAire +
      comisionPaquetes +
      comisionPagoServicios +
      comisionGiftCards;
    return {
      referencia,
      numRegistrosTicketPlus: boletosObj.list.length,
      ventaTotalTicketPlus: ventaTotalTickets,
      comisionTicketPlus: comisionVentaTickets,
      numRegistrosRecargas: tiempoAire.registros + paquetes.registros,
      ventaTotalRecargas: totalVentaRecargas,
      comisionRecargas: comisionTiempoAire + comisionPaquetes,
      numRegistrosPagoServicios: pagoServicios.registros,
      ventaTotalPagoServicios: pagoServicios.total,
      comisionPagoServicios,
      numRegistrosGiftCards: giftcards.registros,
      ventaTotalGiftCards: giftcards.total,
      comisionGiftCards,
      sumaTotalVentas,
      sumaTotalComisiones,
      numPremiosPagadosInicioSem: premiosPagadosInicioSem.length,
      totalPremiosPagadosInicioSem,
      numPremiosPagadosRestoSem: premiosPagadosRestoSem.length,
      totalPremiosPagadosRestoSem,
      numRegistrosCancelados: canceladosObj.list.length,
      totalCancelados: canceladosObj.total,
    };
  } catch ({ message }) {
    throw new Error(message);
  }
}

function obtenerDiaSemAnt(timestamp, dia) {
  let counter = 1;
  while (
    Moment(timestamp).subtract(counter, "days").format("dddd").toLowerCase() !==
    dia
  ) {
    counter++;
  }

  return Moment(timestamp).subtract(counter, "days").format("YYYY-MM-DD");
}

async function obtenerImporteEstadoDeCuenta(estadoDeCuenta) {
  try {
    // SALDO VENCIDO PAGO ANTERIOR + VENTA TOTAL - SUMA TOTAL COMISIONES
    const { referencia, sumaTotalVentas, sumaTotalComisiones } = estadoDeCuenta;
    // BUSCAR PAGO ANTERIOR
    const ultimoDepositoRegistrado = await obtenerUltimoDepositoRegistrado(
      referencia
    );
    return (
      ultimoDepositoRegistrado.saldoVencido +
      sumaTotalVentas -
      sumaTotalComisiones
    );
  } catch ({ message }) {
    throw new Error(message);
  }
}

async function obtenerUltimoDepositoRegistrado(referencia) {
  try {
    const referenciasAnteriores = crearReferenciasAnteriores(referencia, 4);
    let ultimoDepositoRegistrado = { saldoVencido: 0 };
    for (let i = 0; i < referenciasAnteriores.length; i++) {
      const referenciaActual = referenciasAnteriores[i];
      const depositoRegistrado = await Database.getItem(
        DATABASE_TABLES.DEPOSITOS,
        "referencia",
        referenciaActual
      );
      if (depositoRegistrado) {
        ultimoDepositoRegistrado = depositoRegistrado;
        break;
      }
    }
    return ultimoDepositoRegistrado;
  } catch ({ message }) {
    throw new Error(message);
  }
}

async function obtenerEstadoDeCuentaPrevio(referencia) {
  try {
    let estadoDeCuentaPrevio = {
      totalPremiosPagadosInicioSem: 0,
      totalAbonos: 0,
      depositoRegistrado: { pago: 0, saldoVencido: 0 },
      importe: 0,
    };
    const ultimoDepositoRegistrado = await obtenerUltimoDepositoRegistrado(
      referencia
    );
    // SI SE ENCONTRO UN DEPOSITO
    if (ultimoDepositoRegistrado.referencia) {
      const periodo = Helpers.extraerInfoRef(
        ultimoDepositoRegistrado.referencia
      );
      estadoDeCuentaPrevio = await generarEstadoDeCuenta(
        ultimoDepositoRegistrado.referencia,
        periodo,
        { usuario: periodo.usuario }
      );
      const importePrevio = await obtenerImporteEstadoDeCuenta(
        estadoDeCuentaPrevio
      );
      estadoDeCuentaPrevio.depositoRegistrado = ultimoDepositoRegistrado;
      estadoDeCuentaPrevio.importe =
        importePrevio - estadoDeCuentaPrevio.totalPremiosPagadosInicioSem;
    }

    return estadoDeCuentaPrevio;
  } catch ({ message }) {
    throw new Error(message);
  }
}

export async function obtenerUsuariosActivos() {
  try {
    return await Database.getItems(
      DATABASE_TABLES.AGENCIAS,
      (u) => !["37000", "57000", "58000", "00000"].includes(u.usuario)
    );
  } catch ({ message }) {
    throw new Error(message);
  }
}
