import React, { useEffect } from "react";
import { Card, CardContent, CardHeader } from "@mui/material";
import CustomTable from "../../../../components/CustomTable";
import { useDispatch, useSelector } from "react-redux";
import { Helpers, Moment, Money } from "../../../../utils";
import {
  setCargandoPagosRealizados,
  setPagosRealizados,
} from "../../../../features/administracion/administracionSlice";
import Database from "../../../../database";
import { DATABASE_TABLES } from "../../../../constants";
import { useNavigate } from "react-router-dom";
import StatusCircle from "../../components/StatusCircle";

export default function TablaPagosRealizados() {
  const { cargandoPagosRealizados, pagosRealizados, periodoSeleccionado } =
    useSelector((state) => state.administracion);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (periodoSeleccionado) {
      cargarPagosRealizados(periodoSeleccionado);
    }
  }, [periodoSeleccionado]);

  const cargarPagosRealizados = async () => {
    try {
      dispatch(setCargandoPagosRealizados(true));
      const _pagosRealizados = await Database.getItemsInRange(
        DATABASE_TABLES.PREMIOS_PAGADOS,
        "fechaPago",
        periodoSeleccionado.inicial,
        periodoSeleccionado.final
      );
      // OBTENER BOLETOS CORRESPONDIENTES
      for (let i = 0; i < _pagosRealizados.length; i++) {
        const item = _pagosRealizados[i];
        // SI NO EXISTE PROP boleto ENTONCES CREARLA
        if (!item.boleto) {
          const _boleto = await Database.getItem(
            DATABASE_TABLES.BOLETOS,
            "numeroBoleto",
            item.numeroBoleto
          );
          if (_boleto) {
            _pagosRealizados[i].boleto = _boleto;
          }
        }
      }
      dispatch(
        setPagosRealizados(
          Helpers.ordenarElementos(
            _pagosRealizados,
            (a, b) =>
              Moment(b.fechaPago + " " + b.horaPago).valueOf() -
              Moment(a.fechaPago + " " + a.horaPago).valueOf()
          )
        )
      );
      dispatch(setCargandoPagosRealizados(false));
    } catch ({ message }) {
      console.log(message);
    }
  };

  const handleRowClick = (item) => {
    navigate("../../../panel/boletos/" + item.boleto.key);
  };

  return (
    <Card>
      <CardHeader
        title={<Header />}
        subheader={
          <StatusCircle
            text={`Total(${pagosRealizados.length}): ${Money(
              pagosRealizados.reduce((acc, el) => acc + parseInt(el.premio), 0)
            )}`}
          />
        }
      />
      <CardContent>
        {cargandoPagosRealizados && <div>cargando tabla</div>}
        {!cargandoPagosRealizados && (
          <CustomTable
            scrollBoth
            stickyHeader
            maxHeight={400}
            headerData={["#", "Fecha", "Hora", "Cantidad", "Pagado Por", "ID"]}
            tableData={pagosRealizados}
            dataProps={[
              "#",
              "fechaPago",
              "horaPago",
              "$:premio",
              "pagadoPor",
              "numeroBoleto",
            ]}
            onRowClick={handleRowClick}
          />
        )}
      </CardContent>
    </Card>
  );
}

function Header() {
  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div>Pagos realizados</div>
    </div>
  );
}
