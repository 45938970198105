import firebase from "../firebase";

const Database = (() => {
  const _database = firebase.db;
  // GET ITEMS FROM DATABASE
  const getItems = async (refName, filterFnc) => {
    const dataSnapshot = await _database.ref(refName).once("value");
    let items = [];
    // SI NO EXISTE
    if (!dataSnapshot.val()) return [];
    // SI EXISTE
    dataSnapshot.forEach((i) => {
      items.push({ ...i.val(), key: i.key });
    });
    // RETORNAR LISTA DE ITEMS
    return filterFnc ? items.filter(filterFnc) : items;
  };
  // GET ITEMS FROM DATABASE
  const getLastLimitedItems = async (refName, limit = 10, filterFnc) => {
    const dataSnapshot = await _database
      .ref(refName)
      .limitToLast(limit)
      .once("value");
    let items = [];
    // SI NO EXISTE
    if (!dataSnapshot.val()) return [];
    // SI EXISTE
    dataSnapshot.forEach((i) => {
      items.push({ ...i.val(), key: i.key });
    });
    // RETORNAR LISTA DE ITEMS
    return filterFnc ? items.filter(filterFnc) : items;
  };
  const getItemsEndingAt = async (refName, propName, propVal) => {
    const dataSnapshot = await _database
      .ref(refName)
      .orderByChild(propName)
      .endAt(propVal)
      .once("value");
    let items = [];
    // SI NO EXISTE
    if (!dataSnapshot.val()) return [];
    // SI EXISTE
    dataSnapshot.forEach((i) => {
      items.push({ ...i.val(), key: i.key });
    });
    // RETORNAR LISTA DE ITEMS
    return items; // [{...}, {...}] || []
  };
  // GET VALUES BETWEEN
  const getItemsInRange = async (
    refName,
    propName,
    startDate,
    endDate,
    filterFnc = undefined
  ) => {
    const dataSnapshot = await _database
      .ref(refName)
      .orderByChild(propName)
      .startAt(startDate)
      .endAt(endDate)
      .once("value");
    let items = [];
    // SI NO EXISTE
    if (!dataSnapshot.val()) return [];
    // SI EXISTE
    dataSnapshot.forEach((i) => {
      items.push({ ...i.val(), key: i.key });
    });
    // RETORNAR LISTA DE ITEMS
    return filterFnc ? items.filter(filterFnc) : items; // [{...}, {...}] || []
  };
  // GET ONE
  const getItem = async (refName, propName, propVal) => {
    const dataSnapshot = await _database
      .ref(refName)
      .orderByChild(propName)
      .equalTo(propVal)
      .once("value");
    let item = null;
    // SI NO EXISTE
    if (!dataSnapshot.val()) return null;
    // SI EXISTE
    dataSnapshot.forEach((i) => {
      item = { ...i.val(), key: i.key };
    });
    // RETURN OBJECT
    return item;
  };
  // GET ITEM BY PROP
  const getItemsByProp = async (
    refName,
    propName,
    propVal,
    filterFnc = undefined
  ) => {
    try {
      const databaseSnapshot = await _database
        .ref(refName)
        .orderByChild(propName)
        .equalTo(propVal)
        .once("value");
      const items = [];
      // IF FOUND
      if (databaseSnapshot.val()) {
        databaseSnapshot.forEach((item) => {
          items.push({ key: item.key, ...item.val() });
        });
      }
      return filterFnc ? items.filter(filterFnc) : items;
    } catch (error) {
      console.log(error);
    }
  };
  // GET OBJECT
  const getObject = async (refName) => {
    const _objectSnp = await _database.ref(refName).once("value");
    // RETORNAR OBJETO
    return _objectSnp.val(); // {...} || null
  };
  // GET REF
  const getRef = (refName) => {
    // RETORNAR OBJETO
    return _database.ref(refName); //
  };
  // GET ITEM BY CHILD
  const getItemByKey = async (refName, childKey) => {
    const item = await _database.ref(refName).child(childKey).once("value");
    return item.val() ? { ...item.val(), key: item.key } : null;
  };
  // DELETE ITEM
  const deleteItem = async (refName, childKey) => {
    await _database.ref(refName).child(childKey).set(null);
    return true;
  };
  // SERVER DATE
  const getServerDate = async () => {
    const _serverDateRef = _database.ref("fechaServidor");

    await _serverDateRef.update({
      date: firebase.database.ServerValue.TIMESTAMP,
    });
    let serverDate = await _serverDateRef.once("value");
    // RETORNA TIMESTAMP
    return serverDate.val().date; // timestamp
  };
  // SAVE VALUE
  const save = async (refName, data) => {
    await _database.ref(refName).push().set(data);
    return true;
  };
  // UPDATE VALUE
  const update = async (refName = "", childKey = "", data = {}) => {
    await _database.ref(refName).child(childKey).update(data);
    return true;
  };
  // GET ITEMS OBJECT
  const getFullObjectInRange = async (
    refName,
    orderBy,
    startDate,
    endDate,
    filterFunction,
    amountProp = undefined
  ) => {
    const dataSnapshot = await _database
      .ref(refName)
      .orderByChild(orderBy)
      .startAt(startDate)
      .endAt(endDate)
      .once("value");
    let items = [];
    // SI NO EXISTE
    if (!dataSnapshot.val()) return { list: [], total: 0 };
    // SI EXISTE
    dataSnapshot.forEach((i) => {
      items.push({ ...i.val(), key: i.key });
    });
    const list = items.filter(filterFunction);
    const total = amountProp
      ? list.reduce((acc, el) => acc + parseFloat(el[amountProp]), 0)
      : 0;
    // RETORNAR LISTA DE ITEMS
    return { list, total }; // [{...}, {...}] || []
  };
  // EXPOSE API
  return {
    getItems,
    getLastLimitedItems,
    getItem,
    getItemByKey,
    getItemsByProp,
    getItemsEndingAt,
    getItemsInRange,
    getObject,
    getServerDate,
    getRef,
    getFullObjectInRange,
    save,
    update,
    deleteItem,
  };
})();

export default Database;
