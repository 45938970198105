import { useDispatch, useSelector } from "react-redux";
import { useModal } from "../../../../../hooks";
import { useFormik } from "formik";
import * as Yup from "yup";
import { setSorteoSeleccionado } from "../../../../../features/sorteos/sorteosSlice";
import { eliminarNumerosPublicados } from "../../../../../services/bola";
import { Button, Divider, Typography } from "@mui/material";
import { CustomModal, InputField } from "../../../../../components";
import { Colors } from "../../../../../constants";

const NumerosGanadores = () => {
  const { sorteoSeleccionado } = useSelector((state) => state.sorteos);
  const modal = useModal();
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      passwordAdmin: "",
    },
    validationSchema: Yup.object().shape({
      passwordAdmin: Yup.string().required("Este campo es requerido"),
    }),
    onSubmit: (validatedData) => {
      if (!modal.config.confirmBtnClicked) {
        eliminar(validatedData);
      }
    },
  });

  const handleEliminar = () => {
    modal.setConfig({
      open: true,
      type: "alert",
      alertTitle: "Eliminar números",
      contentType: "eliminar",
      showCancelBtn: true,
      confirmBtnText: "aceptar",
    });
  };

  const handleModalCancel = () => {
    modal.setConfig({ open: false });
    formik.handleReset();
  };

  const handleModalAccept = () => {
    if (modal.config.contentType === "eliminar") {
      formik.handleSubmit();
    }
    if (modal.config.contentType === "error") {
      modal.setConfig({ open: false });
      setTimeout(() => {
        formik.handleReset();
      }, 300);
    }
  };

  const eliminar = async ({ passwordAdmin }) => {
    try {
      modal.setConfig({
        type: "progress",
        progressTitle: "Eliminando",
        confirmBtnClicked: true,
      });
      const numerosEliminados = await eliminarNumerosPublicados(
        passwordAdmin,
        sorteoSeleccionado.fecha
      );
      // SI SE ELIMINARON LOS NUMEROS
      if (numerosEliminados) {
        const newSorteo = { ...sorteoSeleccionado };
        newSorteo.numerosGanadores = [];
        dispatch(setSorteoSeleccionado(newSorteo));
        formik.handleReset();
        modal.setConfig({ open: false, confirmBtnClicked: false });
      }
    } catch ({ message }) {
      modal.setConfig({
        type: "alert",
        alertTitle: "Mensaje",
        contentType: "error",
        error: <span>{message}</span>,
        confirmBtnText: "Entendido",
        showCancelBtn: false,
        confirmBtnClicked: false,
      });
    }
  };
  // SI NO HAY NUMEROS GANADORES
  if (sorteoSeleccionado.numerosGanadores.length === 0) return null;

  return (
    <>
      <h3 style={styles.numerosGanadoresTitle}>Números Ganadores</h3>
      <div style={styles.numerosGanadoresBox}>
        {sorteoSeleccionado.numerosGanadores.map((numero, i) => (
          <NumeroGanadorCircle key={i} numero={numero} lugar={i + 1} />
        ))}
      </div>
      <div style={{ width: "100%", textAlign: "center" }}>
        <Button size="small" color="error" onClick={handleEliminar}>
          Eliminar números
        </Button>
      </div>
      <CustomModal
        open={modal.config.open}
        type={modal.config.type}
        alertTitle={modal.config.alertTitle}
        progressTitle={modal.config.progressTitle}
        showCancelBtn={modal.config.showCancelBtn}
        confirmBtnText={modal.config.confirmBtnText}
        onCancel={handleModalCancel}
        onAccept={handleModalAccept}
      >
        {modal.config.contentType === "eliminar" && (
          <>
            <Typography>¿Deseas eliminar los números publicados?</Typography>
            <Divider sx={{ marginY: 1.5 }} />
            <Typography>
              Por seguridad te pedimos ingreses tu contraseña para poder
              continuar con el proceso de eliminación.
            </Typography>
            <InputField
              type="password"
              label="Contraseña administrador"
              name="passwordAdmin"
              value={formik.values.passwordAdmin}
              error={formik.errors.passwordAdmin}
              touched={formik.touched.passwordAdmin}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </>
        )}
        {modal.config.contentType === "error" && modal.config.error}
      </CustomModal>
    </>
  );
};

const NumeroGanadorCircle = ({ numero, lugar, color = Colors.green }) => {
  return (
    <div style={{ ...styles.circle, backgroundColor: color }}>
      {numero} <div style={{ ...styles.numberPosition, color }}>{lugar}°</div>
    </div>
  );
};

const styles = {
  numerosGanadoresTitle: {
    margin: 0,
    fontSize: 22,
    marginBottom: 10,
    textAlign: "center",
  },
  numerosGanadoresBox: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    margin: "10px 0",
  },
  circle: {
    width: 65,
    height: 65,
    borderRadius: "50%",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: "#fff",
    fontWeight: "bold",
    margin: 5,
  },
  numberPosition: {
    position: "absolute",
    bottom: 3,
    left: 8,
    width: 20,
    height: 20,
    borderRadius: "50%",
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
};

export default NumerosGanadores;
