import { StyleSheet, Text, View } from "@react-pdf/renderer";

export default function DocumentTableRow({
  data = [],
  cellWidth = "25%",
  header = false,
}) {
  const renderText = (text, index) => {
    return (
      <View
        style={[
          styles.col,
          {
            width: typeof cellWidth === "string" ? cellWidth : cellWidth[index],
          },
        ]}
      >
        <Text style={[styles.text, { fontWeight: header ? "bold" : "normal" }]}>
          {text}
        </Text>
      </View>
    );
  };

  return <View style={styles.row}>{data.map(renderText)}</View>;
}

const styles = StyleSheet.create({
  row: {
    display: "flex",
    flexDirection: "row",
    borderBottomWidth: 1,
    padding: 3,
  },
  col: {},
  text: {
    fontSize: 8,
  },
});
