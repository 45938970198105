import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import { Money, uuid } from "../utils";
import { useEffect, useState } from "react";

export default function ReportePDF({ sorteoSeleccionado }) {
  const [mounted, setMounted] = useState(false);
  const [paginasPDF, setPaginasPDF] = useState([]);

  useEffect(() => {
    if (sorteoSeleccionado.numerosJugados && !mounted) {
      setMounted(true);
      fillCols();
    }
  }, []);

  const fillCols = () => {
    let paginas = [];
    const registrosPorPagina = 360;
    const colsPorPagina = 6;
    const registrosPorColumna = 60;
    const numerosJugados = sorteoSeleccionado.numerosJugados.length;
    const numPaginas = Math.ceil(numerosJugados / registrosPorPagina);
    let numerosJugadosIndex = 0;
    for (let i = 0; i < numPaginas; i++) {
      let pagina = {
        columnas: [],
      };
      for (let j = 0; j < colsPorPagina; j++) {
        let columna = [];
        for (let k = numerosJugadosIndex; k < numerosJugados; k++) {
          let numero = sorteoSeleccionado.numerosJugados[k];
          columna.push(numero);
          numerosJugadosIndex++;
          if (
            columna.length === registrosPorColumna ||
            (columna.length < registrosPorColumna &&
              numerosJugadosIndex === numerosJugados)
          ) {
            pagina.columnas.push(columna);
            break;
          }
        }
      }
      paginas.push(pagina);
    }
    setPaginasPDF(paginas);
  };

  return (
    <Document>
      {paginasPDF.map((pagina, index) => (
        <Page key={uuid()}>
          <View style={styles.document}>
            {index === 0 && (
              <>
                <DocumentHeader sorteoSeleccionado={sorteoSeleccionado} />
              </>
            )}
            <DocumentBody pagina={pagina} />
          </View>
          <Text
            style={styles.pageNumber}
            render={({ pageNumber, totalPages }) =>
              `page ${pageNumber} / ${totalPages} from report ${sorteoSeleccionado.codigoSorteo}-${sorteoSeleccionado.fecha}`
            }
            fixed
          />
        </Page>
      ))}
    </Document>
  );
}

function DocumentHeader({ sorteoSeleccionado }) {
  return (
    <>
      <View style={styles.header}>
        <Text>
          Report {sorteoSeleccionado.codigoSorteo} {sorteoSeleccionado.fecha}
        </Text>
        {/* <Image style={styles.logo} src={logo} /> */}
      </View>
      <View style={styles.details}>
        <View style={styles.detailsLeft}>
          <View style={styles.row}>
            <Text style={styles.detailsLeftText}>Code</Text>
            <Text style={styles.detailsLeftText}>
              {sorteoSeleccionado.codigoSorteo}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.detailsLeftText}>Number of places</Text>
            <Text style={styles.detailsLeftText}>
              {sorteoSeleccionado.numLugares}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.detailsLeftText}>Records</Text>
            <Text style={styles.detailsLeftText}>
              {sorteoSeleccionado.numBoletos}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.detailsLeftText}>Selected numbers</Text>
            <Text style={styles.detailsLeftText}>
              {sorteoSeleccionado.numerosGanadores.map((numero) => (
                <Text key={uuid()} style={styles.detailsLeftText}>
                  {numero}{" "}
                </Text>
              ))}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.detailsLeftText}>Total numbers</Text>
            <Text style={styles.detailsLeftText}>
              {sorteoSeleccionado.numerosJugados.length}
            </Text>
          </View>
        </View>
        <View style={styles.detailsRight}>
          <Text style={styles.detailsRightText}>Total</Text>
          <Text style={styles.detailsRightText}>
            {Money(sorteoSeleccionado.ventaTotal)}
          </Text>
          <Text style={styles.detailsRightText}>Subtract</Text>
          <Text style={styles.detailsRightText}>
            {Money(sorteoSeleccionado.pagosPendientes)}
          </Text>
        </View>
      </View>
    </>
  );
}

function DocumentBody({ pagina }) {
  return (
    <View style={styles.numbers}>
      {pagina.columnas.map((columna) => (
        <View key={uuid()} style={styles.col}>
          <>
            {columna.map((item) => (
              <View style={styles.number}>
                <View>
                  <Text style={[styles.numberText, { fontWeight: "bold" }]}>
                    {item.numero}
                  </Text>
                </View>
                {item.lugares.map((cantidad) => (
                  <View>
                    <Text style={styles.numberText}>{cantidad}</Text>
                  </View>
                ))}
              </View>
            ))}
          </>
        </View>
      ))}
    </View>
  );
}

// Create styles
const styles = StyleSheet.create({
  document: {
    margin: 20,
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: 10,
    borderBottomWidth: 1,
    borderBottomColor: "#000",
  },
  logo: {
    width: 50,
    height: 50,
  },
  details: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 5,
  },
  detailsLeft: {
    flex: "0 1 50%",
    padding: 5,
    borderBottomWidth: 1,
    borderLeftWidth: 1,
  },
  detailsLeftText: {
    fontSize: 10,
  },
  detailsRight: {
    flex: "0 1 50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    borderLeftWidth: 1,
    borderBottomWidth: 1,
    borderRightWidth: 1,
  },
  detailsRightText: {
    fontSize: 10,
    fontWeight: "bold",
  },
  row: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  numbers: {
    display: "flex",
    flexDirection: "row",
    gap: 3,
    width: "100%",
  },
  col: {
    width: "16.66%",
  },
  number: {
    borderWidth: 1,
    paddingHorizontal: 2,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  cantidades: {
    display: "flex",
    flexDirection: "row",
    gap: 3,
  },
  numberText: {
    fontSize: 8,
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 10,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});
