import { CustomSelect } from "../../../components";

export default function SemanalSelect({
  options,
  value,
  onChange,
  width = 200,
}) {
  const handleChange = (val) => {
    onChange(val);
  };

  return (
    <CustomSelect
      label="Periodo"
      options={options}
      optionLabel="label"
      optionValue="id"
      onChange={handleChange}
      value={value}
      width={width}
    />
  );
}
