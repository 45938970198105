import { Document, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { Money } from "../../../utils";
const HEADER_TABLE_USUARIOS =
  "USUARIO.LUNES.MARTES.MIERCOLES.JUEVES.VIERNES.SABADO.DOMINGO.TOTAL".split(
    "."
  );

export default function RecargasServiciosPDF({ reporte }) {
  if (!reporte) {
    return (
      <Document>
        <Page>
          <View style={styles.page}>
            <DocumentHeader title="REPORTE NO LISTO" subtitle="" />
          </View>
        </Page>
      </Document>
    );
  }
  return (
    <Document>
      <Page>
        <View style={styles.page}>
          <DocumentHeader
            title="ESTADO SEMANAL"
            subtitle={reporte.periodo.toUpperCase()}
          />
          <DocumentMainTable reporte={reporte} />
        </View>
      </Page>
    </Document>
  );
}

function DocumentHeader({ title, subtitle }) {
  return (
    <View style={styles.documentHeader}>
      <Text style={styles.headerText}>{title}</Text>
      <Text style={styles.headerText}>{subtitle}</Text>
    </View>
  );
}

function DocumentMainTable({ reporte }) {
  const renderUserInfo = (user, index) => {
    let extractedData = [];
    HEADER_TABLE_USUARIOS.forEach((item) => {
      extractedData.push(
        item.toLocaleLowerCase() === "usuario"
          ? user[item.toLocaleLowerCase()]
          : Money(user[item.toLocaleLowerCase()])
      );
    });

    return <CustomRow cellWidth="11.11%" data={extractedData} />;
  };

  return (
    <>
      <View style={styles.table}>
        <CustomRow
          header
          data={[
            "",
            "TRANSACCIONES",
            "TIEMPO AIRE",
            "TRANSACCIONES",
            "SERVICIOS Y GIFT CARDS",
          ]}
        />
        <CustomRow
          data={[
            "AL CIERRE",
            "",
            Money(reporte?.alCierre.recargas),
            "",
            Money(reporte?.alCierre.servicios),
          ]}
        />
        <CustomRow
          data={[
            "DEPOSITO",
            reporte?.depositos.recargas.registros,
            Money(reporte?.depositos.recargas.total),
            reporte?.depositos.servicios.registros,
            Money(reporte?.depositos.servicios.total),
          ]}
        />
        <CustomRow
          data={[
            "COMISION GENERADA",
            "",
            Money(reporte?.comisionGenerada.recargas),
            "",
            "$0.00",
          ]}
        />
        <CustomRow
          data={[
            "INICIAL",
            "",
            Money(reporte?.inicial.recargas),
            "",
            Money(reporte?.inicial.servicios),
          ]}
        />
        <CustomRow
          data={[
            "VENTA",
            reporte?.venta.recargas.registros,
            Money(reporte?.venta.recargas.total),
            reporte?.venta.servicios.registros,
            Money(reporte?.venta.servicios.total),
          ]}
        />
        <CustomRow
          data={[
            "CARGO DE SERVICIO",
            "",
            // Money(reporte?.comisionServicio.recargas),
            "$0.00",
            "",
            Money(reporte?.comisionServicio.servicios),
          ]}
        />
        <CustomRow
          data={[
            "COMISIÓN USUARIO",
            "",
            Money(
              reporte?.comision.usuario.recargas +
                reporte?.comisionServicio.recargas
            ),
            "",
            Money(reporte?.comision.usuario.servicios),
          ]}
        />
        <CustomRow
          data={[
            "COMISIÓN ADMIN",
            "",
            Money(reporte?.comision.admin.recargas),
            "",
            Money(reporte?.comision.admin.servicios),
          ]}
        />
        <CustomRow
          data={[
            "ABONO USUARIO",
            "",
            "$0.00",
            "",
            Money(reporte?.abono.usuario),
          ]}
        />
        <CustomRow
          data={["ABONO ADMIN", "", "$0.00", "", Money(reporte?.abono.admin)]}
        />
        <CustomRow
          data={[
            "FINAL",
            "",
            Money(reporte?.cantidadFinal.recargas),
            "",
            Money(reporte?.cantidadFinal.servicios),
          ]}
        />
        <CustomRow
          data={[
            "RECUPERACIÓN",
            "",
            Money(reporte?.recuperacionRecargas),
            "",
            Money(reporte?.recuperacionServicios),
          ]}
        />
      </View>
      <View style={styles.table}>
        <TableTitle title="RECARGAS Y PAQUETES" />
        <CustomRow cellWidth="11.11%" data={HEADER_TABLE_USUARIOS} />
        {reporte.ventaUsuarios.recargas.map(renderUserInfo)}
      </View>
      <View style={styles.table}>
        <TableTitle title="SERVICIOS Y GIFTCARDS" />
        <CustomRow cellWidth="11.11%" data={HEADER_TABLE_USUARIOS} />
        {reporte.ventaUsuarios.servicios.map(renderUserInfo)}
      </View>
    </>
  );
}

function CustomRow({ data = [], cellWidth = "25%", header = false }) {
  const renderText = (text) => {
    return (
      <View style={[styles.col, { width: cellWidth }]}>
        <Text style={[styles.text, { fontWeight: header ? "bold" : "normal" }]}>
          {text}
        </Text>
      </View>
    );
  };

  return <View style={styles.row}>{data.map(renderText)}</View>;
}

function TableTitle({ title }) {
  return (
    <View style={styles.tableTitle}>
      <Text style={styles.tableTitleText}>{title}</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  page: {
    margin: 20,
  },
  documentHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  headerText: {
    fontSize: 12,
  },
  table: {
    marginVertical: 5,
  },
  tableTitle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginVertical: 5,
  },
  tableTitleText: {
    fontSize: 12,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    borderBottomWidth: 1,
    padding: 3,
  },
  col: {},
  text: {
    fontSize: 8,
  },
});
