import currency from "currency.js";
//console.log(money(5000).format(true));
const money = (value, format = true) => {
  // CON FORMATO
  if (format) return currency(value, { precision: 2 }).format();
  // SIN FORMATO
  return currency(value, { precision: 2 });
};

export default money;
