import Database from "../../database";
import { Moment, Utils, uuid } from "../../utils";
import { DATABASE_TABLES } from "../constants";
// RETURNS ONLY VALID USERS
export async function getUsers() {
  try {
    const users = await Database.getItems(
      DATABASE_TABLES.USERS,
      // (u) => !["37000", "57000", "58000", "00000"].includes(u.usuario)
      (u) => !["37000", "57000"].includes(u.usuario)
    );
    return users;
  } catch ({ message }) {
    throw new Error(message);
  }
}
// RETURNS ALL DRAWS
export async function getDraws() {
  try {
    return await Database.getItems(DATABASE_TABLES.SORTEOS);
  } catch ({ message }) {
    throw new Error(message);
  }
}
// RETURNS A DATE ORDERED LIST OF THE CELEBTATED DRAWS
export async function getCelebratedDraws() {
  try {
    const draws = await getDraws();
    const timestamp = await getServerTimestamp();
    const drawClosingTime = await Database.getObject(
      DATABASE_TABLES.HORA_CIERRE
    );
    const serverDate = Moment(timestamp).format("YYYY-MM-DD");
    let celebratedDraws = Utils.getCelebratedDraws(
      draws,
      serverDate,
      drawClosingTime
    );
    return Utils.orderObjectsList(celebratedDraws, "fecha");
  } catch ({ message }) {
    throw new Error(message);
  }
}
// RETURNS THE SERVER TIMESTAMP
export async function getServerTimestamp() {
  try {
    return await Database.getServerDate();
  } catch ({ message }) {
    throw new Error(message);
  }
}
// RETURNS A PERIOD OF TIME FROM MONDAY TO NOW
// @param timestamp
export function getPeriodFromMonday(serverTimestamp) {
  const today = Moment(serverTimestamp);
  // IF TODAY IS MONDAY THEN RETURN INMEDIATELY
  if (today.format("dddd").toLowerCase() === "lunes") {
    return {
      start: today.format("YYYY-MM-DD"),
      end: today.format("YYYY-MM-DD"),
    };
  }
  let daysCounter = 0;
  while (
    Moment(serverTimestamp)
      .subtract(daysCounter, "days")
      .format("dddd")
      .toLowerCase() !== "lunes"
  ) {
    daysCounter++;
  }
  return {
    start: Moment(serverTimestamp)
      .subtract(daysCounter, "days")
      .format("YYYY-MM-DD"),
    end: today.format("YYYY-MM-DD"),
  };
}
// GET USER CURRENT CREDIT
export async function getUserCredit(user) {
  try {
    const credit = await Database.getItem(
      DATABASE_TABLES.CREDITS,
      "usuario",
      user
    );
    return credit;
  } catch ({ message }) {
    throw new Error(message);
  }
}
export async function getDatabaseItem(tableName, propName, propValue) {
  try {
    return await Database.getItem(
      DATABASE_TABLES[tableName],
      propName,
      propValue
    );
  } catch (message) {
    throw new Error(message);
  }
}
// ADD ONE DEPOSIT TO DATABASE.DEPOSITS
export async function addDeposit(deposit) {
  try {
    await Database.save(DATABASE_TABLES.DEPOSITS, deposit);
  } catch ({ message }) {
    throw new Error(message);
  }
}
// DELETE USER DEPOSIT
export async function deleteDeposit(deposit) {
  try {
    await Database.deleteItem(DATABASE_TABLES.DEPOSITS, deposit.key);
    // console.log("deposito eliminado", deposit);
    const credito = await Database.getItem(
      DATABASE_TABLES.CREDITS,
      "usuario",
      deposit.usuario
    );
    const newBalance = parseFloat(credito.saldo) - parseFloat(deposit.pago);
    await Database.update(DATABASE_TABLES.CREDITS, credito.key, {
      saldo: newBalance,
    });
    // console.log("credito actualizado", credito);
    // UPDATE DEPOSIT
    const informExists = await getUserWeekInform(
      deposit.periodo + ":" + deposit.usuario
    );
    const timestamp = await getServerTimestamp();
    if (informExists) {
      const newTotalDeposits =
        parseFloat(informExists.totalDeposits) - parseFloat(deposit.pago);
      const period = {
        start: deposit.periodo.split(":")[0],
        end: deposit.periodo.split(":")[1],
      };
      // GET NEXT WEEK PAID PRIZES ON MONDAY
      const nextMonday = Moment(period.end).add(1, "days").format("YYYY-MM-DD");
      const nextMondayPaidPrizes = await getPaidPrizesByDate(nextMonday);
      const userNextMondayPaidPrizes = nextMondayPaidPrizes.filter(
        (item) => item.pagadoPor == deposit.usuario
      );
      const totalUserNextMondayPaidPrizes = userNextMondayPaidPrizes.reduce(
        (acc, item) => acc + parseFloat(item.premio),
        0
      );
      // VERIFY IF LIMIT DAY HAS PASSED AND USER HAS NOT PAID
      // THEN DEACTIVATE ACCOUNT
      const totalAfterDeposits =
        informExists.amount - newTotalDeposits - totalUserNextMondayPaidPrizes;
      const newNumDeposits = informExists.numDeposits - 1;
      const newDueBalance = Utils.roundDecimals(
        informExists.amount - newTotalDeposits
      );
      const paymentCompleted = totalAfterDeposits < 1;
      await Database.update(DATABASE_TABLES.WEEK_INFORMS, informExists.key, {
        totalDeposits: Utils.roundDecimals(newTotalDeposits),
        numDeposits: newNumDeposits,
        dueBalance: newDueBalance,
        modified: Moment(timestamp).format("YYYY-MM-DD HH:mm:ss"),
        paymentCompleted,
      });
      if (
        ["jueves", "viernes", "sabado", "domingo"].includes(
          Moment(timestamp).format("dddd").toLowerCase()
        ) &&
        totalAfterDeposits >= 1
      ) {
        await suspendUserAccount(deposit.usuario);
      }
    }
    // const newInform = await getUserWeekInform(
    //   deposit.periodo + ":" + deposit.usuario
    // );
    // console.log(newInform);
    return true;
  } catch ({ message }) {
    throw new Error(message);
  }
}
// UPDATE USER CREDIT
export async function updateUserCredit(userCreditKey, newBalance) {
  try {
    await Database.update(DATABASE_TABLES.CREDITS, userCreditKey, {
      saldo: parseFloat(newBalance),
    });
  } catch ({ message }) {
    throw new Error(message);
  }
}
// SAVE USER WEEK INFORM
export async function saveUserWeekInform(weekInform) {
  try {
    const weekInformExists = await getUserWeekInform(weekInform.reference);
    // IF WEEK INFORM DOES NOT EXISTS THEN SAVE IT
    if (!weekInformExists) {
      await Database.save(DATABASE_TABLES.WEEK_INFORMS, weekInform);
    }
  } catch ({ message }) {
    throw new Error(message);
  }
}
// GET USER WEEK INFORM
export async function getUserWeekInform(reference = "") {
  try {
    // console.log(reference);
    const userWeekInform = await Database.getItem(
      DATABASE_TABLES.WEEK_INFORMS,
      "reference",
      reference
    );
    // console.log(userWeekInform);
    // if (userWeekInform == null) {
    //   const reference_split = reference.split(":");
    //   const periodo = reference_split[0] + ":" + reference_split[1];
    //   const user_number = reference_split[reference_split.length - 1];
    //   return {
    //     amount: 0,
    //     created: Moment().format("YYYY-MM-DD HH:m:s"),
    //     dueBalance: 0,
    //     id: uuid(),
    //     modified: Moment().format("YYYY-MM-DD HH:m:s"),
    //     numDeposits: 0,
    //     paymentCompleted: false,
    //     period: periodo,
    //     reference: reference,
    //     totalDeposits: 0,
    //     user: user_number,
    //   };
    // }
    return userWeekInform;
  } catch ({ message }) {
    throw new Error(message + " getUserWeekInform");
  }
}
// UPDATE USER WEEK INFORM
export async function updateUserWeekInform(reference, data) {
  try {
    const inform = await getUserWeekInform(reference);
    if (inform) {
      // console.log(inform, data);
      await Database.update(DATABASE_TABLES.WEEK_INFORMS, inform.key, data);
    }
  } catch ({ message }) {
    throw new Error(message);
  }
}
// UPDATE USER WEEK INFORM WITH EACH NEW DEPOSIT
export async function updateWeekInform(
  reference,
  depositAmount,
  paymentCompleted
) {
  try {
    const informExists = await getUserWeekInform(reference);
    // console.log(informExists);
    const timestamp = await getServerTimestamp();
    if (informExists) {
      const newTotalDeposits =
        parseFloat(informExists.totalDeposits) + parseFloat(depositAmount);
      const newNumDeposits = informExists.numDeposits + 1;
      const newDueBalance = Utils.roundDecimals(
        informExists.amount - newTotalDeposits
      );
      await Database.update(DATABASE_TABLES.WEEK_INFORMS, informExists.key, {
        totalDeposits: newTotalDeposits,
        numDeposits: newNumDeposits,
        dueBalance: newDueBalance,
        modified: Moment(timestamp).format("YYYY-MM-DD HH:mm:ss"),
        paymentCompleted,
      });
    }
  } catch ({ message }) {
    throw new Error(message + " updateWeekInform");
  }
}
// GET PAID PRIZES FROM A GIVEN DATE
export async function getPaidPrizesByDate(formattedDate) {
  try {
    const paidPrizes = await Database.getItemsByProp(
      DATABASE_TABLES.PAID_PRIZES,
      "fechaPago",
      formattedDate
    );
    return paidPrizes;
  } catch ({ message }) {
    throw new Error(message);
  }
}
// GET PAID PRIZES FROM A GIVEN RANGE
export async function getPaidPrizesByRange(start, end) {
  try {
    const paidPrizes = await Database.getItemsInRange(
      DATABASE_TABLES.PAID_PRIZES,
      "fechaPago",
      start,
      end
    );
    return paidPrizes;
  } catch ({ message }) {
    throw new Error(message);
  }
}
// SUSPEND USER ACCOUNT
export async function suspendUserAccount(userNumber) {
  try {
    const user = await Database.getItem(
      DATABASE_TABLES.USERS,
      "usuario",
      userNumber
    );
    if (user && user.activo) {
      // console.log(user.usuario, "supendido por falta de pago");
      await Database.update(DATABASE_TABLES.USERS, user.key, {
        activo: false,
        disableAccountReason:
          "Su cuenta ha sido desactivada debido a que no ha cubierto en su totalidad el pago de liquidación semanal.",
      });
    }
  } catch ({ message }) {
    throw new Error(message);
  }
}
// UPDATE USER
export async function updateUser(userKey, data) {
  try {
    await Database.update(DATABASE_TABLES.USERS, userKey, data);
  } catch ({ message }) {
    throw new Error(message);
  }
}
