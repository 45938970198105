import { Moment, uuid } from "../utils";
import LOGO from "../assets/logo.png";
import {
  Button,
  Grid,
  Paper,
  Table,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import Circle116 from "../placeholders/Circle116";
import RectLargeText from "../placeholders/RectLargeText";
import { ArrowBackIcon } from "../icons";
import { Colors } from "../constants";

export const Header = ({ boleto, cancelado = null }) => {
  const horaImp = Moment(boleto.horaImpresion, "HH:mm:ss");
  return (
    <>
      {cancelado && (
        <div
          style={{
            width: "100%",
            background: Colors.red,
            color: "#fff",
            textAlign: "center",
            padding: "5px",
            marginBottom: 10,
            fontWeight: "bold",
          }}
        >
          <span>
            Cancelado: {cancelado.fechaCancelacion} {cancelado.horaCancelacion}
          </span>
        </div>
      )}
      {!cancelado && (
        <div style={{ textAlign: "center" }}>
          <img src={LOGO} alt="logo recargas y más" style={{ width: 80 }} />
        </div>
      )}
      <h4 style={{ margin: 0, marginBottom: 5, textAlign: "center" }}>
        Ticket Plus
      </h4>
      <div style={{ textAlign: "center" }}>
        Sorteo {Moment(boleto.fechaSorteo).format("llll")}
      </div>
      <div>
        <p style={{ padding: 0, margin: 0 }}>Agencia {boleto.numeroAgencia}</p>
        <p style={{ padding: 0, margin: 0 }}>
          Impresión {Moment(boleto.fechaExp).format("DD/MM/YYYY")}{" "}
          {Moment(horaImp).format("HH:mm:ss")}
        </p>
      </div>
    </>
  );
};

export const Body = ({ boleto, sorteoOpcion }) => {
  const posLetras = {
    1: ["1er", "empty", "empty"],
    2: ["1er", "2do", "empty"],
    3: ["1er", "2do", "3er"],
  };
  const lugares = posLetras[sorteoOpcion.numLugares];

  return (
    <div>
      <div style={{ fontWeight: "bolder" }}>{generateHashes(27)}</div>
      <Table padding="none">
        <TableHead>
          <TableRow>
            <TableCell sx={{ textAlign: "right" }}>Apuestas</TableCell>
            {lugares.map((lugar) => (
              <TableCell
                sx={{
                  textAlign: "right",
                  color: lugar === "empty" ? "transparent" : "",
                }}
                key={uuid()}
              >
                {lugar}
              </TableCell>
            ))}
          </TableRow>
          {boleto.jugadas.map((jugada) => (
            <TableRow sx={{ textAlign: "right" }} key={uuid()}>
              <TableCell
                sx={{
                  padding: 1,
                  border: 0,
                  textAlign: "right",
                }}
              >
                {jugada.numero}
              </TableCell>
              {jugada.lugares.map((lugar) => (
                <TableCell
                  key={uuid()}
                  sx={{
                    textAlign: "right",
                    padding: 1,
                    border: 0,
                  }}
                >
                  {lugar === "0" ? "X" : lugar}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
      </Table>
      <div style={{ fontWeight: "bolder" }}>{generateHashes(27)}</div>
      <div>
        <p style={{ margin: 0 }}>Jugadas {boleto.jugadas.length}</p>
        <p style={{ margin: 0 }}>Total {boleto.totalApostado} Pts</p>
        <p style={{ margin: 0 }}>ID {boleto.numeroBoleto}</p>
      </div>
    </div>
  );
};

const generateHashes = (number) => {
  let hashes = "";
  for (let i = 0; i < number; i++) {
    hashes += "#";
  }
  return hashes;
};

export const Placeholder = () => {
  return (
    <Grid container rowSpacing={2} columnSpacing={2}>
      <Grid item xs={12} md={3}>
        <Paper>
          <Button fullWidth startIcon={<ArrowBackIcon />} disabled>
            Volver a boletos
          </Button>
        </Paper>
      </Grid>
      <Grid item xs={12} md={9}>
        <Paper
          sx={{
            padding: 2,
            maxWidth: 300,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Circle116 />
          <h4 style={{ margin: 0, marginBottom: 5 }}>
            <RectLargeText />
          </h4>
          <h4 style={{ margin: 0, marginBottom: 5, fontSize: 24 }}>
            <RectLargeText />
          </h4>
        </Paper>
      </Grid>
    </Grid>
  );
};
