import React, { useState } from "react";
import CustomModal from "./CustomModal";
import AdminPasswordField from "./AdminPasswordField";

export default function ProtectedActionModal({ modal, onAccept }) {
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);

  const handleModalCancel = () => {
    modal.setConfig({ open: false });
    setTimeout(() => {
      setPassword("");
      setPasswordError(false);
    }, 500);
  };

  const handleModalAccept = () => {
    if (modal.config.contentType === "content") {
      if (password.trim() === "") {
        setPasswordError(true);
        return;
      }
      onAccept(password);
      setPassword("");
    }
    if (modal.config.contentType === "error") {
      modal.setConfig({ open: false });
      setPassword("");
    }
  };

  const handleChange = ({ target }) => {
    setPassword(target.value);
    setPasswordError(target.value.trim() === "");
  };

  const handleBlur = () => {
    setPasswordError(password.trim() === "");
  };

  return (
    <CustomModal
      open={modal.config.open}
      type={modal.config.type}
      alertTitle={modal.config.alertTitle}
      progressTitle={modal.config.progressTitle}
      showCancelBtn={modal.config.showCancelBtn}
      confirmBtnText={modal.config.confirmBtnText}
      onCancel={handleModalCancel}
      onAccept={handleModalAccept}
      fullWidth
      maxWidth="xs"
    >
      {modal.config.contentType === "content" && (
        <>
          <AdminPasswordField
            password={password}
            message="Por seguridad, ingresa tu contraseña para poder continuar con el proceso."
            onChange={handleChange}
            error={passwordError}
          />
        </>
      )}
      {modal.config.contentType === "error" && modal.config.error}
    </CustomModal>
  );
}
