import { useEffect, useState } from "react";
import { Button, Grid } from "@mui/material";
import { Moment, uuid } from "../../utils";
import { useCustomParams } from "../../hooks";
import Database from "../../database";
import SectionTitle from "../../components/SectionTitle";
import SemanalSelect from "./components/SemanalSelect";
import TabsComponent from "./components/TabsComponent";
import RecargasServicios from "./components/RecargasServicios";
import TicketPlus from "./components/TicketPlus";
import bugImg from "../../assets/bug.png";
import { useNavigate } from "react-router-dom";
import EstadoDeCuenta from "./components/EstadoDeCuenta";
import { useDispatch } from "react-redux";
import { setShowPDFButton } from "../../features/reportes/reportesSlice";
import AccountStatusTab from "./components/AccountStatusTab";
import { DATABASE_TABLES } from "../../constants";

export default function Reportes() {
  const customParams = useCustomParams();
  const [cargando, setCargando] = useState(true);
  const [paramsError, setParamsError] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [rangos, setRangos] = useState([]);
  const [rangoID, setRangoID] = useState("");
  const [fechaActual, setFechaActual] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    cargarRangos();
  }, []);

  const cargarRangos = async () => {
    try {
      setCargando(true);
      setParamsError(false);
      const timestamp = await Database.getServerDate();
      const _rangos = obtenerFechas(timestamp);
      const params = obtenerParams(_rangos);
      const currentRange = _rangos.find((el) =>
        Moment(el.inicial).isSame(Moment(params.range.split("_")[0]))
      );
      setRangoID(currentRange.id);
      setRangos(_rangos);
      setTabIndex(params.tabIndex);
      customParams.update({
        tabIndex: params.tabIndex,
        range: currentRange.inicial + "_" + currentRange.final,
      });
      dispatch(
        setShowPDFButton(
          !Moment(Moment(timestamp).format("YYYY-MM-DD")).isSame(
            Moment(params.range.split("_")[1])
          )
        )
      );
      setFechaActual(Moment(timestamp).format("YYYY-MM-DD"));
      setCargando(false);
    } catch ({ message }) {
      console.error(message);
      setParamsError(true);
      setCargando(false);
    }
  };

  const handleRangoChange = (rID) => {
    const _range = rangos.find((el) => el.id === rID);
    let periods = [];
    rangos.forEach((item) => {
      if (item.label !== "En curso") {
        periods.push({
          start: item.inicial,
          end: item.final,
        });
      }
    });
    periods.reverse();
    // console.log(periods);
    setRangoID(rID);
    customParams.update({ range: _range.inicial + "_" + _range.final });
    dispatch(
      setShowPDFButton(
        !Moment(Moment(fechaActual).format("YYYY-MM-DD")).isSame(
          Moment(_range.final)
        )
      )
    );
  };

  const obtenerParams = (r) => {
    const range = customParams.get("range")
      ? customParams.get("range")
      : r[0].inicial + "_" + r[0].final;
    const tabIndex = customParams.get("tabIndex")
      ? parseInt(customParams.get("tabIndex"))
      : 0;
    return {
      range,
      tabIndex,
    };
  };

  const obtenerFechas = (timestamp) => {
    const _semanas = [];
    for (let i = 0; i < 9; i++) {
      const periodo = obtenerPeriodo(timestamp, i);
      const _inicial = periodo[0];
      const _final = periodo[periodo.length - 1];
      const _label = Moment(Moment(timestamp).format("YYYY-MM-DD")).isSame(
        Moment(_final)
      )
        ? "En curso"
        : Moment(_final).format("llll");
      const fecha = {
        id: uuid(),
        inicial: _inicial,
        final: _final,
        label: _label,
      };
      _semanas.push(fecha);
    }
    // _semanas.shift(); REMUEVE LA SEMANA EN CURSO

    return _semanas;
  };

  const obtenerPeriodo = (timestamp, semanaID) => {
    let ocurrencies = 9;
    let ocurrenciesCounter = 0;
    let counter = 0;
    let dias_lunes = [];
    // OBTENEMOS SOLO LOS DIAS LUNES
    while (ocurrenciesCounter !== ocurrencies) {
      if (
        Moment(timestamp)
          .subtract(counter, "days")
          .format("dddd")
          .toLowerCase() === "lunes"
      ) {
        dias_lunes.push(
          Moment(timestamp).subtract(counter, "days").format("YYYY-MM-DD")
        );
        ocurrenciesCounter++;
      }
      counter++;
    }
    // FECHAS FINALES
    const fechasFinales = {
      0: Moment(timestamp).format("YYYY-MM-DD"),
      1: Moment(dias_lunes[0]).subtract(1, "days").format("YYYY-MM-DD"),
      2: Moment(dias_lunes[1]).subtract(1, "days").format("YYYY-MM-DD"),
      3: Moment(dias_lunes[2]).subtract(1, "days").format("YYYY-MM-DD"),
      4: Moment(dias_lunes[3]).subtract(1, "days").format("YYYY-MM-DD"),
      5: Moment(dias_lunes[4]).subtract(1, "days").format("YYYY-MM-DD"),
      6: Moment(dias_lunes[5]).subtract(1, "days").format("YYYY-MM-DD"),
      7: Moment(dias_lunes[6]).subtract(1, "days").format("YYYY-MM-DD"),
      8: Moment(dias_lunes[7]).subtract(1, "days").format("YYYY-MM-DD"),
    };
    // OBTENER DIAS DE LA SEMANA
    let _fechas = [];
    let counter2 = 0;
    while (
      !Moment(dias_lunes[semanaID])
        .add(counter2, "days")
        .isSame(Moment(fechasFinales[semanaID]))
    ) {
      _fechas.push(
        Moment(dias_lunes[semanaID]).add(counter2, "days").format("YYYY-MM-DD")
      );
      counter2++;
    }
    _fechas.push(
      Moment(dias_lunes[semanaID]).add(counter2, "days").format("YYYY-MM-DD")
    );

    return _fechas;
  };

  const handleRetry = () => {
    navigate("/panel/reportes");
    cargarRangos();
  };

  if (paramsError)
    return (
      <div style={{ textAlign: "center" }}>
        <img width={400} src={bugImg} alt="bug found" />
        <p>Error al cargar los parametros</p>
        <Button variant="outlined" onClick={handleRetry}>
          Reintentar
        </Button>
      </div>
    );

  return (
    <>
      <Grid container rowSpacing={2} columnSpacing={2}>
        <Grid item xs={12}>
          <SectionTitle title="Reportes" />
        </Grid>
        <Grid item xs={12}>
          {!cargando && (
            <TabsComponent
              tabNames={[
                "Recargas y Servicios",
                "Ticket Plus",
                "Liquidaciones",
              ]}
              // tabs={[<RecargasServicios />, <TicketPlus />, <EstadoDeCuenta />]}
              tabs={[
                <RecargasServicios />,
                <TicketPlus />,
                <AccountStatusTab />,
              ]}
              defaultTabIndex={tabIndex}
              right={
                <div>
                  <SemanalSelect
                    options={rangos}
                    value={rangoID}
                    onChange={handleRangoChange}
                  />
                </div>
              }
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}
