import { useState } from "react";

export const useToast = () => {
    const [config, setToastConfig] = useState({
        open: false,
        message: "",
        autoHideDuration: 5000,
        severity: "success",
    });

    const handleClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setToastConfig((prevState) => ({
            ...prevState,
            open: false,
        }));
    };

    const setConfig = (newConfig) => {
        setToastConfig((prevState) => ({
            ...prevState,
            ...newConfig,
        }));
    };

    return { config, setConfig, handleClose };
};
