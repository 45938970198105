import app from "firebase/app";
import "firebase/database";

import firebaseConfig from "./config";

class Firebase {
    constructor() {
        if (!app.apps.length) {
            app.initializeApp(firebaseConfig);
        }
        this.db = app.database();
        this.database = app.database;
    }
}

const firebase = new Firebase();

export default firebase;
