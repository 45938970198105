import { Divider, FormControl, TextField, Typography } from "@mui/material";

const AdminPasswordField = ({
  password,
  message,
  onChange,
  onBlur,
  divider = false,
  autoFocus = false,
  error = false,
  errormessage = "",
}) => {
  return (
    <>
      {divider && <Divider sx={{ marginY: 1 }} />}
      <Typography sx={{ textAlign: "justify", fontSize: 14 }}>
        {message}
      </Typography>
      <FormControl fullWidth style={{ marginTop: 10 }}>
        <TextField
          autoFocus={autoFocus}
          type="password"
          label="Contraseña administrador"
          value={password}
          onChange={onChange}
          onBlur={onBlur}
          size="small"
          name="password"
          error={error}
        />
      </FormControl>
      {error && (
        <Typography
          style={{ fontStyle: "italic", fontSize: 14, color: "tomato" }}
        >
          {errormessage}
        </Typography>
      )}
    </>
  );
};

export default AdminPasswordField;
