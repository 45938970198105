import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  guardandoDeposito: false,
};

const estadoDeCuentaSlice = createSlice({
  name: "estadoDeCuenta",
  initialState,
  reducers: {
    setGuardandoDeposito: (state, { payload }) => {
      state.guardandoDeposito = payload;
    },
  },
});

export const { setGuardandoDeposito } = estadoDeCuentaSlice.actions;

export default estadoDeCuentaSlice.reducer;
