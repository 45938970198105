import { useSelector } from "react-redux";
import { Button, Divider, List, ListSubheader } from "@mui/material";
import { FileDownloadIcon } from "../../../../../icons";
import { PDFDownloadLink } from "@react-pdf/renderer";
import ReportePDF from "../../../../../components/ReportePDF";
import EliminarSorteoButton from "./EliminarSorteoButton";
import PublicarNumerosGanadoresButton from "./PublicarNumerosGanadoresButton";
import CambiarEstadoSorteoButton from "./CambiarEstadoSorteoButton";

const SorteoAcciones = () => {
  const { sorteoSeleccionado } = useSelector((state) => state.sorteos);

  return (
    <>
      <Divider sx={{ marginY: 1.8 }} />
      <List subheader={<ListSubheader>Acciones</ListSubheader>}>
        <EliminarSorteoButton />
        <PublicarNumerosGanadoresButton />
        <CambiarEstadoSorteoButton />
      </List>
      <PDFDownloadLink
        document={<ReportePDF sorteoSeleccionado={sorteoSeleccionado} />}
        fileName={sorteoSeleccionado.codigoSorteo + sorteoSeleccionado.fecha}
      >
        <Button fullWidth startIcon={<FileDownloadIcon />}>
          Descargar PDF
        </Button>
      </PDFDownloadLink>
    </>
  );
};

export default SorteoAcciones;
