import { useEffect, useState } from "react";
import { Button, Grid, Paper } from "@mui/material";
import { ArrowBackIcon } from "../../../icons";
import * as Ticket from "../../../components/Ticket";
import Database from "../../../database";
import { DATABASE_TABLES } from "../../../constants";
import { useNavigate, useParams } from "react-router-dom";

export default function BoletoCancelado() {
  const [cargando, setCargando] = useState(true);
  const [cancelado, setCancelado] = useState(null);
  const [sorteoOpcion, setSorteoOpcion] = useState(null);
  const { boletoKey } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    cargarBoleto();
  }, []);

  const cargarBoleto = async () => {
    try {
      setCargando(true);
      const _cancelado = await Database.getItemByKey(
        DATABASE_TABLES.BOLETOS_CANCELADOS,
        boletoKey
      );
      if (_cancelado) {
        const _sorteoOpcion = await Database.getItemsByProp(
          DATABASE_TABLES.LISTA_SORTEOS,
          "codigo",
          _cancelado.boleto.codigoSorteo
        );
        setCancelado(_cancelado);
        setSorteoOpcion(_sorteoOpcion[0]);
      }
      setCargando(false);
    } catch ({ message }) {
      console.log(message);
    }
  };

  if (cargando) return <Ticket.Placeholder />;

  if (!cargando && !cancelado) {
    return <div>Boleto no encontrado</div>;
  }

  return (
    <>
      <Grid container rowSpacing={2} columnSpacing={2}>
        <Grid item xs={12} md={3}>
          <Paper>
            <Button
              fullWidth
              startIcon={<ArrowBackIcon />}
              onClick={() => navigate(-1)}
            >
              Volver
            </Button>
          </Paper>
        </Grid>
        <Grid item xs={12} md={9}>
          <Paper sx={{ padding: 2, maxWidth: 300 }}>
            <Ticket.Header boleto={cancelado.boleto} cancelado={cancelado} />
            <Ticket.Body
              boleto={cancelado.boleto}
              sorteoOpcion={sorteoOpcion}
            />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
