// REACT
import React, { useEffect } from "react";
// REDUX
import { useDispatch, useSelector } from "react-redux";
// MUI COMPONENTS
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Divider,
  Grid,
} from "@mui/material";
// YUP
import * as Yup from "yup";
// ICONS
import { AddCircleIcon, DeleteIcon } from "../../../icons";
// CUSTOM COMPONENTS
import {
  CustomAvatar,
  CustomModal,
  InputField,
  ScrollView,
} from "../../../components";
// SERVICES
import {
  actualizarLogoSorteo,
  actualizarOpcionInfoLista,
  eliminarElementoLista,
  guardarOpcionListaSorteos,
  obtenerListaOpciones,
} from "../../../services/bola";
// CONSTANTS
import { Colors } from "../../../constants";
// UTILS
import { Helpers, uuid } from "../../../utils";
// STORE ACTIONS
import {
  actualizarImagenStore,
  actualizarOpcionInfoListaStore,
  eliminarElementoListaStore,
  guardarOpcionListaStore,
  setCargandoListaOpciones,
  setListaOpciones,
  setListaOpcionKey,
} from "../../../features/sorteos/sorteosSlice";
// CUSTOM HOOKS
import { useModal } from "../../../hooks";
import { useFormik } from "formik";
import Schemas from "../../../schemas";
// LOCAL COMPONENTS
import ButtonInputFile from "./ButtonInputFile";

export default function AgregarOpcionLista() {
  return (
    <>
      <Card style={{ marginTop: 20 }}>
        <CardHeader
          subheader="Opciones en lista"
          action={<AgregarOpcionBtn />}
        />
        <CardContent>
          <ListaOpciones />
        </CardContent>
      </Card>
    </>
  );
}

const AgregarOpcionBtn = () => {
  const modal = useModal();
  const formik = useFormik({
    initialValues: {
      nombreSorteo: "",
      codigoSorteo: "",
      numLugares: 0,
      passwordAdmin: "",
      file: "",
    },
    validationSchema: Yup.object().shape({
      nombreSorteo: Yup.string().required("Este campo es requerido"),
      codigoSorteo: Yup.string()
        .required("Este campo es requerido")
        .length(4, "Debe contener 4 letras")
        .matches(
          "^[A-Z]*$",
          "No debe incluir caracteres especiales ni números"
        ),
      numLugares: Yup.number()
        .typeError("Introduce un número válido")
        .integer("Introduce un número entero")
        .positive("Introduce un número mayor a 0")
        .required("Este campo es obligatorio")
        .max(3, "Elige un número entre el 1 y el 3"),
      passwordAdmin: Yup.string().required("Este campo es requerido"),
      file: Yup.mixed().required("Debes elegir una imagen"),
    }),
    onSubmit: (data) => {
      if (!modal.config.confirmBtnClicked) {
        guardarOpcion(data);
      }
    },
  });
  const dispatch = useDispatch();

  const handleOpenModal = () => {
    modal.setConfig({
      open: true,
      type: "alert",
      alertTitle: "Agregar opción",
      showCancelBtn: true,
      confirmBtnText: "Aceptar",
      contentType: "agregar-opcion",
      action: "agregar-opcion",
    });
  };

  const modalOnAccept = () => {
    if (modal.config.action === "agregar-opcion") {
      formik.handleSubmit();
    }
    if (modal.config.action === "error") {
      modalOnCancel();
    }
  };

  const modalOnCancel = () => {
    modal.setConfig({ open: false });
    formik.handleReset();
  };

  const handleCodigoSorteoChange = (e) => {
    const value = e.target.value || "";
    if (value.trim().length < 5) {
      formik.setFieldValue("codigoSorteo", value.trim().toUpperCase());
    }
  };

  const guardarOpcion = async ({
    codigoSorteo,
    nombreSorteo,
    numLugares,
    passwordAdmin,
  }) => {
    try {
      const fileInput = document.getElementById("file").files[0];
      modal.setConfig({
        type: "progress",
        progressTitle: "Guardando",
        confirmBtnClicked: true,
      });
      const opcionGuardada = await guardarOpcionListaSorteos({
        nombre: nombreSorteo,
        codigo: codigoSorteo,
        imagen: fileInput,
        numLugares,
        password: passwordAdmin,
      });
      // SI SE GUARDO CORRECTAMENTE
      if (opcionGuardada) {
        dispatch(guardarOpcionListaStore(opcionGuardada));
        modal.setConfig({ open: false, confirmBtnClicked: false });
        formik.handleReset();
      }
    } catch ({ message }) {
      modal.setConfig({
        type: "alert",
        alertTitle: "Mensaje",
        showCancelBtn: false,
        confirmBtnClicked: false,
        contentType: "error",
        error: <span>{message}</span>,
        action: "error",
      });
    }
  };

  return (
    <>
      <IconButton edge="end" onClick={handleOpenModal}>
        <AddCircleIcon
          sx={{
            width: 35,
            height: 35,
            color: Colors.green,
          }}
        />
      </IconButton>
      <CustomModal
        open={modal.config.open}
        type={modal.config.type}
        alertTitle={modal.config.alertTitle}
        progressTitle={modal.config.progressTitle}
        showCancelBtn={modal.config.showCancelBtn}
        confirmBtnText={modal.config.confirmBtnText}
        onCancel={modalOnCancel}
        onAccept={modalOnAccept}
      >
        {modal.config.contentType === "agregar-opcion" && (
          <>
            <Box sx={styles.fileBox}>
              <ButtonInputFile
                onChange={formik.handleChange}
                error={formik.errors.file}
                value={formik.values.file}
                touched={formik.touched.file}
              />
            </Box>
            <Grid container rowSpacing={1} columnSpacing={1}>
              <Grid item xs={12} md={6}>
                <InputField
                  label="Nombre del sorteo"
                  name="nombreSorteo"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.nombreSorteo}
                  error={formik.errors.nombreSorteo}
                  touched={formik.touched.nombreSorteo}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <InputField
                  type="number"
                  label="Número de lugares"
                  name="numLugares"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.numLugares}
                  error={formik.errors.numLugares}
                  touched={formik.touched.numLugares}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <InputField
                  label="Código de sorteo"
                  name="codigoSorteo"
                  onChange={handleCodigoSorteoChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.codigoSorteo}
                  error={formik.errors.codigoSorteo}
                  touched={formik.touched.codigoSorteo}
                />
              </Grid>
              <Grid item xs={12}>
                <Divider sx={{ marginY: 2 }} />
                <Typography>
                  Por seguridad, te pedimos ingresar tu contraseña para poder
                  continuar con el proceso.
                </Typography>
                <InputField
                  type="password"
                  label="Contraseña administrador"
                  name="passwordAdmin"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.passwordAdmin}
                  error={formik.errors.passwordAdmin}
                  touched={formik.touched.passwordAdmin}
                />
              </Grid>
            </Grid>
          </>
        )}
        {modal.config.contentType === "error" && modal.config.error}
      </CustomModal>
    </>
  );
};

const ListaOpciones = () => {
  const { cargandoListaOpciones, listaOpciones } = useSelector(
    (state) => state.sorteos
  );
  const dispatch = useDispatch();

  useEffect(() => {
    cargarLista();
  }, []);

  const cargarLista = async () => {
    dispatch(setCargandoListaOpciones(true));
    const items = await obtenerListaOpciones();
    dispatch(setListaOpciones(items));
    dispatch(setCargandoListaOpciones(false));
  };

  return (
    <List>
      {!cargandoListaOpciones && (
        <ScrollView vertical maxHeight={300}>
          {listaOpciones.map((item) => (
            <ListaOpcion key={uuid()} opcion={item} />
          ))}
        </ScrollView>
      )}
    </List>
  );
};

const ListaOpcion = ({ opcion }) => {
  const modal = useModal();
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      nombreSorteo: opcion.nombre,
      codigoSorteo: opcion.codigo,
      numLugares: opcion.numLugares,
      passwordAdmin: "",
    },
    validationSchema: Schemas.listaOpcionSorteo.editar.validation,
    onSubmit: (formData) => {
      // EVITAMOS QUE EL USUARIO PRESIONE EL BOTON EN VARIAS OCASIONES
      if (!modal.config.confirmBtnClicked && modal.config.action === "editar") {
        actualizarInfo(formData);
      }
    },
  });
  const formikEliminar = useFormik({
    initialValues: {
      passwordAdmin: "",
    },
    validationSchema: Yup.object().shape({
      passwordAdmin: Yup.string().required("Este campo es requerido"),
    }),
    onSubmit: (formData) => {
      // EVITAMOS QUE EL USUARIO PRESIONE EL BOTON EN VARIAS OCASIONES
      if (
        !modal.config.confirmBtnClicked &&
        modal.config.action === "eliminar"
      ) {
        eliminarOpcion(formData.passwordAdmin);
      }
    },
  });
  const formikImagen = useFormik({
    initialValues: {
      passwordAdmin: "",
      file: undefined,
    },
    validationSchema: Yup.object().shape({
      passwordAdmin: Yup.string().required("Este campo es requerido"),
      file: Yup.mixed().required("Debes elegir una imagen"),
    }),
    onSubmit: (formData) => {
      // EVITAMOS QUE EL USUARIO PRESIONE EL BOTON EN VARIAS OCASIONES
      if (!modal.config.confirmBtnClicked) {
        actualizarImagen(formData, document.getElementById("file").files[0]);
      }
    },
  });

  const handleOpenModal = (action) => {
    const actions = {
      eliminar: () => {
        modal.setConfig({
          open: true,
          type: "alert",
          alertTitle: "¿Eliminar sorteo " + opcion.nombre + "?",
          contentType: "eliminar",
          showCancelBtn: true,
          action,
        });
      },
      editar: () => {
        modal.setConfig({
          open: true,
          type: "alert",
          alertTitle: "Editar sorteo",
          contentType: "editar",
          showCancelBtn: true,
          action,
        });
      },
      "cambiar-imagen": () => {
        modal.setConfig({
          open: true,
          type: "alert",
          alertTitle: "Cambiar imagen",
          contentType: "cambiar-imagen",
          showCancelBtn: true,
          action,
        });
      },
    };
    const handler = actions[action];
    handler();
  };

  const modalOnCancel = () => {
    modal.setConfig({ open: false });
    formik.handleReset();
    formikEliminar.handleReset();
    formikImagen.handleReset();
  };

  const modalOnAccept = () => {
    if (modal.config.action === "editar") {
      formik.handleSubmit();
    }
    if (modal.config.action === "eliminar") {
      formikEliminar.handleSubmit();
    }
    if (modal.config.action === "cambiar-imagen") {
      formikImagen.handleSubmit();
    }
    if (modal.config.action === "error") {
      modalOnCancel();
    }
  };

  const actualizarInfo = async (formData) => {
    try {
      modal.setConfig({
        confirmBtnClicked: true,
        type: "progress",
        progressTitle: "Actualizando",
      });
      const infoActualizada = await actualizarOpcionInfoLista(
        formData.passwordAdmin,
        opcion.key,
        formData.nombreSorteo,
        formData.numLugares
      );
      // SI LA INFO SE ACTUALIZO CORRECTAMENTE ACTUALIZAMOS EL STORE
      if (infoActualizada) {
        dispatch(
          actualizarOpcionInfoListaStore({
            opcionKey: opcion.key,
            codigoSorteo: formData.codigoSorteo,
            nombreSorteo: formData.nombreSorteo,
            numLugares: formData.numLugares,
          })
        );
        modal.setConfig({ open: false });
        formik.handleReset();
      }
    } catch ({ message }) {
      modal.setConfig({
        type: "alert",
        alertTitle: "Mensaje",
        showCancelBtn: false,
        confirmBtnClicked: false,
        contentType: "error",
        error: <span>{message}</span>,
        action: "error",
      });
    }
  };

  const actualizarImagen = async (formData, file) => {
    try {
      modal.setConfig({
        confirmBtnClicked: true,
        type: "progress",
        progressTitle: "Actualizando",
      });
      const imagenActualizada = await actualizarLogoSorteo(
        formData.passwordAdmin,
        opcion,
        file
      );
      // SI LA IMAGEN SE ACTUALIZO
      if (imagenActualizada) {
        dispatch(actualizarImagenStore());
        formikImagen.handleReset();
        modal.setConfig({ open: false });
      }
    } catch ({ message }) {
      modal.setConfig({
        type: "alert",
        alertTitle: "Mensaje",
        showCancelBtn: false,
        confirmBtnClicked: false,
        contentType: "error",
        error: <span>{message}</span>,
        action: "error",
      });
    }
  };

  const eliminarOpcion = async (passwordAdmin) => {
    try {
      modal.setConfig({
        confirmBtnClicked: true,
        type: "progress",
        progressTitle: "Eliminando",
      });
      const elementoEliminado = await eliminarElementoLista({
        elemento: opcion,
        password: passwordAdmin,
      });
      // SI SE ELIMINO
      if (elementoEliminado) {
        dispatch(eliminarElementoListaStore(opcion.key));
        dispatch(setListaOpcionKey(""));
        modal.setConfig({
          open: false,
          confirmBtnClicked: false,
        });
        formikEliminar.handleReset();
      }
    } catch ({ message }) {
      modal.setConfig({
        type: "alert",
        alertTitle: "Mensaje",
        showCancelBtn: false,
        confirmBtnClicked: false,
        contentType: "error",
        error: <span>{message}</span>,
        action: "error",
      });
    }
  };

  return (
    <>
      <ListItem
        secondaryAction={
          <IconButton size="small" onClick={() => handleOpenModal("eliminar")}>
            <DeleteIcon />
          </IconButton>
        }
      >
        <ListItemAvatar
          sx={{ "&:hover": { cursor: "pointer" } }}
          onClick={() => handleOpenModal("cambiar-imagen")}
        >
          <CustomAvatar size={40} src={Helpers.setImgSrc(opcion.logo)} />
        </ListItemAvatar>
        <ListItemText
          primary={opcion.nombre}
          secondary={
            <>
              <Button size="small" onClick={() => handleOpenModal("editar")}>
                Editar
              </Button>
            </>
          }
        />
      </ListItem>
      <CustomModal
        open={modal.config.open}
        type={modal.config.type}
        alertTitle={modal.config.alertTitle}
        progressTitle={modal.config.progressTitle}
        showCancelBtn={modal.config.showCancelBtn}
        confirmBtnText={modal.config.confirmBtnText}
        onCancel={modalOnCancel}
        onAccept={modalOnAccept}
      >
        {modal.config.contentType === "eliminar" && (
          <>
            <Typography sx={{ textAlign: "justify" }}>
              Al eliminar este elemento ya no podrás recuperarlo.
            </Typography>
            <Divider sx={{ marginY: 2 }} />
            <Typography>
              Por seguridad, te pedimos ingresar tu contraseña para poder
              continuar con el proceso.
            </Typography>
            <InputField
              type="password"
              label="Contraseña administrador"
              name="passwordAdmin"
              onChange={formikEliminar.handleChange}
              onBlur={formikEliminar.handleBlur}
              value={formikEliminar.values.passwordAdmin}
              error={formikEliminar.errors.passwordAdmin}
              touched={formikEliminar.touched.passwordAdmin}
            />
          </>
        )}
        {modal.config.contentType === "editar" && (
          <Grid container rowSpacing={1} columnSpacing={1}>
            <Grid item xs={12} md={6}>
              <InputField
                label="Nombre del sorteo"
                name="nombreSorteo"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.nombreSorteo}
                error={formik.errors.nombreSorteo}
                touched={formik.touched.nombreSorteo}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputField
                type="number"
                label="Número de lugares"
                name="numLugares"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.numLugares}
                error={formik.errors.numLugares}
                touched={formik.touched.numLugares}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputField
                label="Código de sorteo"
                name="codigoSorteo"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.codigoSorteo}
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{ marginY: 2 }} />
              <Typography>
                Por seguridad, te pedimos ingresar tu contraseña para poder
                continuar con el proceso.
              </Typography>
              <InputField
                type="password"
                label="Contraseña administrador"
                name="passwordAdmin"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.passwordAdmin}
                error={formik.errors.passwordAdmin}
                touched={formik.touched.passwordAdmin}
              />
            </Grid>
          </Grid>
        )}
        {modal.config.contentType === "cambiar-imagen" && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <CustomAvatar src={Helpers.setImgSrc(opcion.logo)} size={150} />
            <div
              style={{
                marginTop: "10px",
                marginBottom: 30,
                color: "gray",
                fontSize: 14,
              }}
            >
              Imagen actual
            </div>
            <ButtonInputFile
              onChange={formikImagen.handleChange}
              error={formikImagen.errors.file}
              value={formikImagen.values.file}
            />
            <div>
              <Divider sx={{ marginY: 2 }} />
              <Typography>
                Por seguridad, te pedimos ingresar tu contraseña para poder
                continuar con el proceso.
              </Typography>
              <InputField
                type="password"
                label="Contraseña administrador"
                name="passwordAdmin"
                onChange={formikImagen.handleChange}
                onBlur={formikImagen.handleBlur}
                value={formikImagen.values.passwordAdmin}
                error={formikImagen.errors.passwordAdmin}
                touched={formikImagen.touched.passwordAdmin}
              />
            </div>
          </Box>
        )}
        {modal.config.contentType === "error" && modal.config.error}
      </CustomModal>
    </>
  );
};

const styles = {
  logos: {
    marginTop: 20,
  },
  subtitle: {
    margin: 0,
    marginBottom: 20,
    fontWeight: 400,
    fontSize: "1rem",
    lineHeight: 1.5,
    letterSpacing: "0.00938em",
    color: "rgba(0,0,0,0.6)",
    display: "block",
  },
  listaLogos: {
    listStyle: "none",
    margin: 0,
    padding: 0,
    display: "flex",
    flexWrap: "wrap",
  },
  listaItem: {
    margin: 3,
    background: "gray",
    borderRadius: "50%",
    padding: 2,
  },
  fileBox: {
    width: "100%",
    height: 100,
    border: "dashed thin " + Colors.gray,
    borderRadius: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  formMessage: {
    fontSize: 12,
    marginTop: 1,
    color: "gray",
    fontStyle: "italic",
  },
  modalCambiarImagen: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  modalCambiarImagenName: {
    marginTop: "10px",
    marginBottom: 30,
    color: "gray",
    fontSize: 14,
  },
};
