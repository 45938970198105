import { CircularProgress } from "@mui/material";

export default function CustomCircularProgress({
  align = "center",
  icon = false,
}) {
  if (icon) return <CircularProgress size={20} />;

  return (
    <div
      style={{
        textAlign: align,
        margin: "10px 0",
      }}
    >
      <CircularProgress />
    </div>
  );
}
