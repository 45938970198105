const CustomAvatar = ({
  src,
  alt = "image",
  size = 50,
  background = "transparent",
  border = true,
  borderRadius = true,
}) => {
  return (
    <div
      style={{
        width: size,
        height: size,
        background: background,
        borderRadius: borderRadius ? "50%" : undefined,
        border: border ? "solid thin #eee" : undefined,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
      }}
    >
      <img src={src} alt={alt} style={{ maxWidth: "100%" }} />
    </div>
  );
};

export default CustomAvatar;
