import { FormControl, TextField } from "@mui/material";

const InputField = ({
  label,
  name,
  value = "",
  type = "text",
  onChange,
  onBlur,
  error,
  disableErrorMessage = false,
  touched,
  disabled = false,
  marginTop = 10,
  marginBottom = undefined,
  maxWidth = undefined,
}) => {
  return (
    <>
      <FormControl
        fullWidth
        style={{
          marginTop: marginTop,
          maxWidth: maxWidth,
          marginBottom: marginBottom,
        }}
      >
        <TextField
          type={type}
          label={label}
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          error={error && touched}
          size="small"
          disabled={disabled}
          autoComplete={type === "password" ? "new-password" : "off"}
          InputProps={{
            autoComplete: type === "password" ? "new-password" : "off",
          }}
          inputProps={{
            autoComplete: type === "password" ? "new-password" : "off",
          }}
        />
      </FormControl>
      {error && touched && !disableErrorMessage && (
        <span style={{ fontSize: 12, color: "tomato" }}>{error}</span>
      )}
    </>
  );
};

export default InputField;
