import { Moment, Money } from ".";

const Utils = {
  // ORDENAR LISTA
  orderObjectsList: function (objectsList = [], orderBy, orderWay = "desc") {
    if (orderWay === "desc") {
      return objectsList.sort(
        (a, b) => Moment(b[orderBy]).valueOf() - Moment(a[orderBy]).valueOf()
      );
    }
    if (orderWay === "asc") {
      return objectsList.sort(
        (a, b) => Moment(a[orderBy]).valueOf() - Moment(b[orderBy]).valueOf()
      );
    }
    return objectsList;
  },
  // GET CELEBRATED DRAWS
  getCelebratedDraws: function (draws, serverDate, closingTime) {
    const serverTime = Moment(serverDate, "HH:mm");
    const closingTimeDB = Moment(closingTime.hora, "HH:mm");
    let celebratedDraws = [];
    draws.forEach((item) => {
      if (Moment(item.fecha).isBefore(serverDate)) {
        celebratedDraws.push({ ...item });
      }
      if (
        Moment(item.fecha).isSame(serverDate) &&
        Moment(serverTime).isAfter(closingTimeDB)
      ) {
        celebratedDraws.push({ ...item });
      }
    });
    return celebratedDraws;
  },
  // CONVERT DATE TO DAY LOWER CASE
  dateToDayLowerCase: function (date) {
    return Moment(date).format("dddd").toLowerCase();
  },
  // CALCULATE TICKETS TOTAL SALE COMISION
  ticketsSaleComision: function (totalSale) {
    if (totalSale > 10000) return totalSale * 0.15;
    if (totalSale > 5001) return totalSale * 0.12;
    return totalSale * 0.1;
  },
  // CALCULATE TRANSACTIONS SALE
  calculateTransactionsSale: function (transactions, categoryId) {
    const filtered = transactions.filter(
      (txn) => txn.CategoriaID == categoryId
    );
    let totalSale = 0;
    // ITERAR TRANSACCIONES FILTRADAS
    for (let i = 0; i < filtered.length; i++) {
      const txn = filtered[i];
      // SI ES RECARGA | PAQUETE
      if (["1", "2"].includes(categoryId)) {
        // const ventaMasComision =
        //   parseFloat(Money(txn.Monto, false, true)) +
        //   parseFloat(Money(txn.Comision, false, true));
        const sale = parseFloat(Money(txn.Monto, false, true));
        totalSale += sale;
      }
      // SI ES SERVICIO | GIFTCARD
      if (["3", "4"].includes(categoryId)) {
        const comisionAdmin =
          parseFloat(Money(txn.Comision, false, true)) * 0.5;
        const serviceCommission =
          parseFloat(Money(txn.Comision, false, true)) * 0.5;
        const cargoMasComision =
          parseFloat(Money(txn.Cargo, false, true)) +
          parseFloat(Money(txn.Comision, false, true));
        const result =
          parseFloat(Money(txn.Monto, false, true)) +
          (parseFloat(cargoMasComision) - parseFloat(serviceCommission));
        totalSale += result + comisionAdmin;
      }
    }
    return {
      total: totalSale,
      recordsFound: parseInt(filtered.length),
    };
  },
  // CALCULATE AIRTIME COMISION
  calculateTransactionsCommission(transactions = [], categoryId) {
    const filtered = transactions.filter((t) => t.CategoriaID === categoryId);
    let totalCommission = 0;
    for (let i = 0; i < filtered.length; i++) {
      const txn = filtered[i];
      // RECARGA | PAQUETE
      if (["1", "2"].includes(categoryId)) {
        const commissionOnRecharge =
          parseFloat(Money(txn.Monto, false, true)) * 0.04;
        // const rechargeCommission =
        //   parseFloat(Money(txn.Cargo, false, true)) +
        //   parseFloat(Money(txn.Comision, false, true));
        // const sumaComisiones =
        //   parseFloat(Money(commissionOnRecharge, false, true)) +
        //   parseFloat(Money(rechargeCommission, false, true));
        totalCommission += commissionOnRecharge;
      }
      // SERVICIO
      if (categoryId === "3") {
        const serviceCommission =
          parseFloat(Money(txn.Comision, false, true)) * 0.5;
        totalCommission += serviceCommission;
      }
      // GIFTCARD
      if (categoryId === "4") {
        const giftCardPayout = parseFloat(Money(txn.Abono, false, true)) * 0.5;
        const serviceCommission =
          parseFloat(Money(txn.Comision, false, true)) * 0.5;
        totalCommission += serviceCommission + giftCardPayout;
      }
    }
    return totalCommission;
  },
  // CALCULATE COMMISSION CHARGED TO CLIENT
  calculateCommissionChargedToClient: function (transactions = []) {
    const filtered = transactions.filter((txn) =>
      ["1", "2"].includes(txn.CategoriaID)
    );
    let total = 0;
    filtered.forEach((txn) => {
      const commission =
        txn._comisionRecargas !== undefined ? txn._comisionRecargas : Money(2);
      total += Utils.dollarToFloatNumber(commission);
    });
    return total;
  },
  // REMOVE DOLLAR SIGN AND CONVER VALUE TO FLOAT NUMBER
  dollarToFloatNumber: function (valueWithDollarSign = "") {
    // IF DOLLAR SIGN IS NOT FOUND
    if (valueWithDollarSign.indexOf("$") === -1) return valueWithDollarSign;
    // IF DOLLAR SIGN IS FOUND
    return parseFloat(valueWithDollarSign.replace("$", ""));
  },
  // FILTER PAYOUTS BY TYPE
  filterPayoutsByType: function (payouts = [], type = "abono") {
    return payouts.filter((item) => item.tipo === type);
  },
  // REDUCE OBJECT LIST
  sumObjListByProp: function (list, propName) {
    return list.reduce((acc, item) => acc + parseFloat(item[propName]), 0);
  },
  // GET PREVIOUS WEEK PERIOD FROM A GIVEN CURRENT PERIOD
  getPreviousWeekPeriod: function (currentPeriod) {
    const previousWeekPeriod = {
      start: "",
      end: Moment(currentPeriod.start).subtract(1, "days").format("YYYY-MM-DD"),
    };
    let counter = 0;
    while (
      Moment(previousWeekPeriod.end)
        .subtract(counter, "days")
        .format("dddd")
        .toLowerCase() !== "lunes"
    ) {
      counter++;
    }
    previousWeekPeriod.start = Moment(previousWeekPeriod.end)
      .subtract(counter, "days")
      .format("YYYY-MM-DD");
    return previousWeekPeriod;
  },
  // CONVERT PERIOD OBJECT TO LONG TEXT
  periodToLongText: function (period) {
    // DATES ARE EQUALS
    if (Moment(period.start).isSame(Moment(period.end))) {
      return `${Moment(period.start).format(
        "dddd DD MMMM YYYY"
      )}`.toUpperCase();
    }
    return `DEL ${Moment(period.start).format("dddd DD")} AL ${Moment(
      period.end
    ).format("dddd DD MMMM YYYY")}`.toUpperCase();
  },
  // GET PAST DAY DATE BY DAY NAME
  getLastWeekDateByDayName: function (timestamp, dayName) {
    let counter = 1;
    while (
      Moment(timestamp)
        .subtract(counter, "days")
        .format("dddd")
        .toLowerCase() !== dayName
    ) {
      counter++;
    }

    return Moment(timestamp).subtract(counter, "days").format("YYYY-MM-DD");
  },
  // GET PAST DAY BY NAME
  getPastDateByDayName: function (timestamp, dayName) {
    let counter =
      Moment(timestamp).subtract(0, "days").format("dddd").toLowerCase() ===
      dayName
        ? 1
        : 0;
    while (
      Moment(timestamp)
        .subtract(counter, "days")
        .format("dddd")
        .toLowerCase() !== dayName
    ) {
      counter++;
    }
    return Moment(timestamp).subtract(counter, "days").format("YYYY-MM-DD");
  },
  // GET PERIOD OR A SINGE DATE
  getPeriodOrDate: function (period) {
    // IF START DATE AND END DATE ARE EQUAL
    if (Moment(period.start).isSame(Moment(period.end))) {
      return Moment(period.start).format("YYYY-MM-DD");
    }
    return `${Moment(period.start).format("YYYY-MM-DD")} - ${Moment(
      period.end
    ).format("YYYY-MM-DD")}`;
  },
  // ROUND UP DECIMALS
  roundDecimals: function (floatNumber) {
    return Math.round(floatNumber * 100) / 100;
  },
  // MONEY TO NUMBER
  moneyToNumber: function (moneyFormatString) {
    // Eliminar todos los caracteres que no sean números, puntos o signos negativos
    const num = moneyFormatString.replace(/[^0-9.-]+/g, "");

    // Convertir el string limpio a un número flotante
    return parseFloat(num);
  },
};

export default Utils;
