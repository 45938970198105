import * as Yup from "yup";

const editar = {
    values: {
        nombreSorteo: "",
        codigoSorteo: "",
        imagen: null,
        numLugares: 0,
        passwordAdmin: "",
    },
    validation: Yup.object().shape({
        nombreSorteo: Yup.string().required("Este campo es obligatorio"),
        codigoSorteo: Yup.string()
            .required("Este campo es obligatorio")
            .length(4, "Este campo debe tener 4 caracteres"),
        numLugares: Yup.number()
            .typeError("Introduce un número válido")
            .integer("Introduce un número entero")
            .positive("Introduce un número mayor a 0")
            .required("Este campo es obligatorio")
            .max(3, "Elige un número entre el 1 y el 3"),
        passwordAdmin: Yup.string().required("Este campo es obligatorio"),
    }),
};

export default { editar };
