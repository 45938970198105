import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import PanelAppbar from "./components/PanelAppbar";
import PanelNavigation from "./components/PanelNavigation";
import PanelContent from "./components/PanelContent";
import { WINDOW_EVENTS } from "../../constants";
import { useAuthContext } from "../../context/authContext";
const TIMEOUT_TIME = 5 * 60 * 1000;
// const TIMEOUT_TIME = 30000;

export default function Panel() {
  const [mobileOpen, setMobileOpen] = useState(false);
  const { logout } = useAuthContext();
  let timer;
  //   HANDLES TIMER THAT LOGS OUT USER
  const handleLogoutTimer = () => {
    timer = setTimeout(() => {
      // CLEAR ANY PENDING TIMER
      resetTimer();
      // LISTENER CLEAN UP. REMOVES THE EXISTING EVENT LISTENER FROM THE WINMDOW
      Object.values(WINDOW_EVENTS).forEach((eventName) => {
        window.removeEventListener(eventName, eventListener);
      });
      // LOGOUT ACTION
      logout();
    }, TIMEOUT_TIME);
  };
  //   RESET TIME IF EXISTS
  const resetTimer = () => {
    if (timer) clearTimeout(timer);
  };

  useEffect(() => {
    // Object.values(WINDOW_EVENTS).forEach((eventName) => {
    //   window.addEventListener(eventName, eventListener);
    // });
    // return () => {
    //   // LISTENER CLEAN UP. REMOVES THE EXISTING EVENT LISTENER FROM THE WINDOW
    //   Object.values(WINDOW_EVENTS).forEach((eventName) => {
    //     window.removeEventListener(eventName, eventListener);
    //   });
    // };
  }, []);

  const eventListener = () => {
    resetTimer();
    handleLogoutTimer();
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <PanelAppbar onMenuIconClick={() => setMobileOpen(!mobileOpen)} />
      <PanelNavigation
        onCloseDrower={() => setMobileOpen(false)}
        mobileOpen={mobileOpen}
      />
      <PanelContent />
    </Box>
  );
}
