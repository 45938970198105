const DatabaseTables = {
  BOLETOS: "boletos",
  AGENCIAS: "usuarios",
  SORTEOS: "ajustes/sorteos",
  FECHA_SERVIDOR: "fechaServidor",
  LIMITE_APUESTAS: "ajustes/limiteApuestas",
  HORA_CIERRE: "ajustes/horaCierre",
  ABONOS: "ajustes/abonos",
  PRONOSTICOS: "futbol/pronosticos",
  NUMEROS_GANADORES: "ajustes/numerosGanadores",
  PREMIOS_PAGADOS: "premiosPagados",
  BOLETOS_CANCELADOS: "boletosCancelados",
  VERSIONES: "ajustes/versiones",
  MANTENIMIENTO: "ajustes/mantenimiento",
  LISTA_SORTEOS: "ajustes/listaSorteos",
  LIBERAR_ESPACIO: "ajustes/liberarEspacio",
  TRANSACCIONES: "transacciones",
  DEPOSITOS: "depositos",
  ESTADOS_DE_CUENTA: "estadosDeCuenta",
  SALDOS: "ajustes/saldos",
  SALDOS_VENCIDOS: "saldosVencidos",
  DISPOSITIVOS_REGISTRADOS: "dispositivosRegistrados",
  REPORTES_SEMANALES: "reportesSemanales",
  CREDITOS: "creditos",
  // ENGLISH VERSION
  CANCELED_TICKETS: "boletosCancelados",
  DEPOSITS: "depositos",
  PAID_PRIZES: "premiosPagados",
  PAYOUTS: "ajustes/abonos",
  PAST_DUE_BALANCES: "saldosVencidos",
  TICKETS: "boletos",
  TRANSACTIONS: "transacciones",
};

export default DatabaseTables;
