import { Button, Typography } from "@mui/material";

const IconButtonWithLabel = ({ icon, label, onClick }) => {
    return (
        <Button
            title={label}
            sx={{ flexDirection: "column" }}
            onClick={onClick}
            size="small"
        >
            {icon}
            <Typography sx={{ fontSize: 14, color: "white" }}>
                {label}
            </Typography>
        </Button>
    );
};

export default IconButtonWithLabel;
