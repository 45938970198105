import React from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import ScrollView from "./ScrollView";
import emptyImg from "../assets/no-results.png";

export default function Tabla({ headData = [], data = [], renderItem }) {
  if (data.length === 0)
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src={emptyImg} style={{ width: 200 }} />
        <Typography>sin resultados</Typography>
      </Box>
    );
  return (
    <ScrollView scrollBoth maxHeight={600}>
      <Table stickyHeader size="small">
        <TableHead>
          <TableRow>
            {headData.map((colName) => (
              <TableCell key={colName}>{colName}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>{data.map(renderItem)}</TableBody>
      </Table>
    </ScrollView>
  );
}
