import { Document, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { Money } from "../../../utils";
const MAIN_HEADER =
  "SORTEO.FECHA.REGISTROS.VENTA.PREMIOS.ENCONTRADOS.CANCELADOS.TOTAL".split(
    "."
  );
const USER_HEADER = "USUARIO.REGISTROS.VENTA.PREMIOS.CANCELADOS.TOTAL".split(
  "."
);

export default function TicketPlusPDF({ reporte }) {
  if (!reporte) {
    return (
      <Document>
        <Page>
          <View style={styles.page}>
            <DocumentHeader title="REPORTE NO LISTO" subtitle="" />
          </View>
        </Page>
      </Document>
    );
  }

  return (
    <Document>
      <Page>
        <View style={styles.page}>
          <DocumentHeader
            title="Estado semanal"
            subtitle={reporte.periodo.toUpperCase()}
          />
          <DocumentMainTable reporte={reporte} />
        </View>
      </Page>
    </Document>
  );
}

function DocumentHeader({ title, subtitle }) {
  return (
    <View style={styles.documentHeader}>
      <Text style={styles.headerText}>{title}</Text>
      <Text style={styles.headerText}>{subtitle}</Text>
    </View>
  );
}

function DocumentMainTable({ reporte }) {
  const renderHeaderData = (sorteo) => {
    const AMOUNTS = "VENTA.PREMIOS.ENCONTRADOS.CANCELADOS.TOTAL".split(".");
    return MAIN_HEADER.map((text) =>
      AMOUNTS.includes(text)
        ? Money(sorteo[text.toLocaleLowerCase()])
        : sorteo[text.toLocaleLowerCase()]
    );
  };

  const renderUserData = (user) => {
    return USER_HEADER.map((text) =>
      text === "USUARIO"
        ? user[text.toLocaleLowerCase()]
        : Money(user[text.toLocaleLowerCase()])
    );
  };

  return (
    <View style={styles.table}>
      <CustomRow header cellWidth="15%" data={MAIN_HEADER} />
      {reporte.rows.map((sorteo) => (
        <CustomRow header cellWidth="15%" data={renderHeaderData(sorteo)} />
      ))}
      <View style={styles.tableResultsBox}>
        <View style={[styles.tableResults, { maxWidth: "40%" }]}>
          <CustomRow
            cellWidth="50%"
            data={["RECARGAS", Money(reporte.general.recargas)]}
          />
          <CustomRow
            cellWidth="50%"
            data={["SERVICIOS", Money(reporte.general.servicios)]}
          />
          <CustomRow
            cellWidth="50%"
            data={["TICKET PLUS", Money(reporte.general.ticketPlus)]}
          />
          <CustomRow
            cellWidth="50%"
            data={["SUMA TOTALES", Money(reporte.general.sumaTotales)]}
          />
          <CustomRow
            cellWidth="50%"
            data={[
              reporte?.general.resto >= 0 ? "EXCEDENTE" : "FALTANTE",
              Money(reporte.general.resto),
            ]}
          />
        </View>
        <View style={styles.tableResults}>
          <CustomRow
            cellWidth={["70%", "30%"]}
            data={["VENTAS", Money(reporte.sumaTotalVentasSorteos)]}
          />
          <CustomRow
            cellWidth={["70%", "30%"]}
            data={["PREMIOS PAGADOS", Money(reporte.sumaTotalPremiosSorteos)]}
          />
          <CustomRow
            cellWidth={["70%", "30%"]}
            data={[
              "PREMIOS PAGADOS SORTEOS PASADOS",
              Money(reporte.premiosPagadosOtros),
            ]}
          />
          <CustomRow
            cellWidth={["70%", "30%"]}
            data={["COMISIONES", Money(reporte.comisionSemanal)]}
          />
          <CustomRow
            cellWidth={["70%", "30%"]}
            data={["RESTO", Money(reporte.sumaTotalesSorteos)]}
          />
        </View>
      </View>
      {reporte.rows.map((sorteo) => (
        <View style={styles.table}>
          <TableTitle
            title={
              "INFORME POR USUARIO SORTEO " + sorteo.sorteo + " " + sorteo.fecha
            }
          />
          <CustomRow header cellWidth="15%" data={USER_HEADER} />
          {sorteo.usuarios.map((usuario) => (
            <CustomRow header cellWidth="15%" data={renderUserData(usuario)} />
          ))}
        </View>
      ))}
    </View>
  );
}

function CustomRow({ data = [], cellWidth = "25%", header = false }) {
  const renderText = (text, index) => {
    return (
      <View
        style={[
          styles.col,
          {
            width: typeof cellWidth === "string" ? cellWidth : cellWidth[index],
          },
        ]}
      >
        <Text style={[styles.text, { fontWeight: header ? "bold" : "normal" }]}>
          {text}
        </Text>
      </View>
    );
  };

  return <View style={styles.row}>{data.map(renderText)}</View>;
}

function TableTitle({ title }) {
  return (
    <View style={styles.tableTitle}>
      <Text style={styles.tableTitleText}>{title}</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  page: {
    margin: 20,
  },
  documentHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  headerText: {
    fontSize: 12,
  },
  table: {
    marginVertical: 5,
  },
  tableTitle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginVertical: 5,
  },
  tableTitleText: {
    fontSize: 12,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    borderBottomWidth: 1,
    padding: 3,
  },
  col: {},
  text: {
    fontSize: 8,
  },
  tableResultsBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 10,
  },
  tableResults: {
    maxWidth: "50%",
  },
});
