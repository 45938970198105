import { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { Colors, MAIN_MENU_ITEMS } from "../../../constants";
import logo from "../../../assets/logo.png";

export default function PanelDrawer({ onClick }) {
  const [menuItems, setMenuItems] = useState([...MAIN_MENU_ITEMS]);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    highlightMenuItem();
  }, [location]);

  const highlightMenuItem = () => {
    const { pathname } = location;
    const route = pathname.split("/")[2];
    let newMenuItems = [...menuItems];
    // SI ROUTE ESTA VACIO ACTIVAR LINK PANEL
    if (route === undefined) {
      menuItems.forEach((el) => (el.active = false));
      newMenuItems[0].active = true;
    } else {
      newMenuItems = menuItems.map((el) => ({
        ...el,
        active: el.route.includes(route),
      }));
    }
    setMenuItems(newMenuItems);
  };

  const handleClick = (item) => {
    onClick();
    // SE EVITA BORRAR LOS PARAMETROS DE LA URL
    if (location.pathname.indexOf(item.route) === -1) {
      navigate(item.route);
    }
  };

  return (
    <>
      <Box>
        <Toolbar>
          <Avatar alt="Logo Recargas y más" src={logo} />
          <Typography
            style={{
              marginLeft: 10,
              fontWeight: "bold",
              color: "white",
            }}
          >
            Recargas y Más
          </Typography>
        </Toolbar>
      </Box>
      <Divider />
      <List>
        {menuItems.map((item) => (
          <MenuItem
            item={item}
            key={item.id}
            onClick={() => handleClick(item)}
          />
        ))}
      </List>
    </>
  );
}

const MenuItem = ({ item, onClick }) => {
  if (item.active) {
    return (
      <ListItem disablePadding>
        <ListItemButton onClick={() => onClick()}>
          <ListItemIcon style={{ color: Colors.red }}>{item.icon}</ListItemIcon>
          <ListItemText primary={item.text} />
        </ListItemButton>
      </ListItem>
    );
  }
  return (
    <ListItem disablePadding>
      <ListItemButton onClick={() => onClick()}>
        <ListItemIcon>{item.icon}</ListItemIcon>
        <ListItemText sx={{ color: Colors.smoke }} primary={item.text} />
      </ListItemButton>
    </ListItem>
  );
};
