import { Grid } from "@mui/material";
import RadioGroupFiltros from "./RadioGroupFiltros";
import TablaNumeros from "./TablaNumeros";

const NumerosJugados = () => {
  return (
    <Grid container rowSpacing={2} columnSpacing={2}>
      <Grid item xs={12} md={3}>
        <RadioGroupFiltros />
      </Grid>
      <Grid item xs={12} md={9}>
        <TablaNumeros />
      </Grid>
    </Grid>
  );
};

export default NumerosJugados;
