import { useDispatch, useSelector } from "react-redux";
import {
    setCargandoAbonos,
    setCargandoUsuarios,
    setListaAbonos,
    setListaUsuarios,
    setSelectedUsuario,
    setUsuarioKey,
} from "../features/usuarios/usuariosSlice";
import { obtenerUsuarios, obtenerAbonos } from "../services/bola";

export const useUsuarios = () => {
    const usuariosState = useSelector((state) => state.usuarios);
    const dispatch = useDispatch();

    const cargarUsuarios = async () => {
        dispatch(setCargandoUsuarios(true));
        const items = await obtenerUsuarios();
        dispatch(setListaUsuarios(items));
        dispatch(setCargandoUsuarios(false));
    };

    const cargarListaUsuarios = async () => {
        dispatch(setCargandoUsuarios(true));
        const items = await obtenerUsuarios();
        dispatch(setUsuarioKey(items.length > 0 ? items[0].key : ""));
        dispatch(setSelectedUsuario(items.length > 0 ? items[0] : null));
        dispatch(setListaUsuarios(items));
        dispatch(setCargandoUsuarios(false));
    };

    const cargarAbonos = async (usuarioKey) => {
        dispatch(setCargandoAbonos(true));
        const items = await obtenerAbonos(usuarioKey);
        dispatch(setListaAbonos(items));
        dispatch(setCargandoAbonos(false));
    };

    return {
        cargarUsuarios,
        cargarListaUsuarios,
        usuariosState,
        cargarAbonos,
    };
};
