import React, { useEffect, useState } from "react";
import { Card, CardContent, CardHeader, Grid } from "@mui/material";
import { AttachMoneyIcon } from "../../../icons";
import { useTransacciones } from "../../../hooks/useTransacciones";
import { Money } from "../../../utils";
import StatusCircle from "../components/StatusCircle";
import { getBalance } from "../../../services/taecel";
import TablaTransacciones from "./components/TablaTransacciones";

export default function Transacciones() {
  const [cargandoBolsas, setCargandoBolsas] = useState(true);
  const [bolsas, setBolsas] = useState([
    { Bolsa: "", Saldo: 0 },
    { Bolsa: "", Saldo: 0 },
  ]);

  useEffect(() => {
    obtenerBolsas();
  }, []);

  const obtenerBolsas = async () => {
    try {
      setCargandoBolsas(true);
      const res = await getBalance();
      //   SI FUE EXITOSA LA COSULTA
      if (res.data.success) {
        setBolsas(res.data.data);
      }
      setCargandoBolsas(false);
    } catch ({ message }) {
      throw new Error(message);
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <SaldoCard
            title="Saldo Recargas"
            amount={cargandoBolsas ? "cargando" : bolsas[0].Saldo}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SaldoCard
            title="Saldo Servicios"
            amount={cargandoBolsas ? "cargando" : bolsas[1].Saldo}
          />
        </Grid>
        <Grid item xs={12}>
          <TablaTransacciones />
        </Grid>
      </Grid>
    </>
  );
}

function SubHeader() {
  const { cargandoTransacciones, transacciones, exitosas, fracasadas } =
    useTransacciones();
  return (
    <div>
      {!cargandoTransacciones && (
        <div style={{ display: "flex", justifyContent: "flex-start", gap: 8 }}>
          <StatusCircle text={`Total: ${transacciones.length}`} />
          <StatusCircle text={`Fracasadas: ${fracasadas.length}`} color="red" />
          <StatusCircle
            text={`Exitosas(${exitosas.length}) 
            ${Money(
              exitosas.reduce(
                (acc, el) => acc + parseInt(el.Monto.split("$")[1]),
                0
              )
            )}`}
            color="green"
          />
        </div>
      )}
    </div>
  );
}

function SaldoCard({ title, amount }) {
  return (
    <Card>
      <CardHeader title={title} />
      <CardContent>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <AttachMoneyIcon style={{ fontSize: 48 }} />
          <span style={{ fontSize: 36 }}>{amount}</span>
        </div>
      </CardContent>
    </Card>
  );
}
