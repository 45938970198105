import { Card, CardContent, CardHeader, Divider } from "@mui/material";
import { CircleLoading, TextLoading } from "../../../../../placeholders";

export default function SorteoCardLoading() {
    return (
        <div style={{ display: "flex", justifyContent: "center" }}>
            <Card sx={{ width: "100%", maxWidth: 500 }}>
                <CardHeader subheader={<TextLoading height={20} />} />
                <CardContent>
                    <div style={styles.cardLogoBox}>
                        <CircleLoading size={130} />
                    </div>
                    <Divider sx={{ marginY: 1.8 }} />
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: 10,
                        }}
                    >
                        <TextLoading height={15} />
                        <TextLoading />
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: 10,
                        }}
                    >
                        <TextLoading height={15} />
                        <TextLoading />
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <TextLoading height={15} />
                        <TextLoading />
                    </div>
                </CardContent>
            </Card>
        </div>
    );
}

const styles = {
    cardLogoBox: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginBottom: 10,
        backgroundColor: "#3C3C3C",
        padding: 10,
    },
};
