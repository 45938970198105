import {
  Alert,
  Button,
  FormControl,
  Icon,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import { Money } from "../../../../utils";
import { useModal } from "../../../../hooks";
import { CustomModal, CustomSelect } from "../../../../components";
import { useEffect, useState } from "react";
import CustomCircularProgress from "../../../../components/CustomCircularProgress";
import Database from "../../../../database";
import { Colors, DATABASE_TABLES } from "../../../../constants";
import InfoBoleto from "./InfoBoleto";
import { obtenerUsuarios, registrarPago } from "../../../../services/bola";
import { SaveIcon } from "../../../../icons";
import { useDispatch, useSelector } from "react-redux";
import { setPagosPendientes } from "../../../../features/administracion/administracionSlice";

export default function PagoPendienteFila({ pagoPendiente, counter }) {
  const modal = useModal();

  const handleModalCancel = () => {
    modal.setConfig({ open: false });
  };

  const handleModalAccept = () => {
    modal.setConfig({ open: false });
  };

  const handleClick = () => {
    modal.setConfig({
      open: true,
      type: "alert",
      alertTitle: "Información Boleto",
      contentType: "content",
      showCancelBtn: false,
      confirmBtnText: "cerrar",
    });
  };

  return (
    <>
      <TableRow onClick={handleClick} hover sx={{ cursor: "pointer" }}>
        <TableCell>{counter}</TableCell>
        <TableCell>{pagoPendiente.numeroBoleto}</TableCell>
        <TableCell>{pagoPendiente.fechaSorteo}</TableCell>
        <TableCell>{Money(pagoPendiente.premio)}</TableCell>
        <TableCell>{pagoPendiente.usuario}</TableCell>
      </TableRow>
      <CustomModal
        open={modal.config.open}
        type={modal.config.type}
        alertTitle={modal.config.alertTitle}
        progressTitle={modal.config.progressTitle}
        showCancelBtn={modal.config.showCancelBtn}
        confirmBtnText={modal.config.confirmBtnText}
        onCancel={handleModalCancel}
        onAccept={handleModalAccept}
        fullWidth
        maxWidth="xs"
      >
        {modal.config.contentType === "content" && (
          <DetalleBoleto
            pagoPendiente={pagoPendiente}
            closeModal={() => modal.setConfig({ open: false })}
          />
        )}
        {modal.config.contentType === "error" && modal.config.error}
      </CustomModal>
    </>
  );
}

function DetalleBoleto({ pagoPendiente, closeModal }) {
  const { numeroBoleto, premio } = pagoPendiente;
  const [cargando, setCargando] = useState(true);
  const [boleto, setBoleto] = useState(null);
  const [lugares, setLugares] = useState([]);
  const [usuarios, setUsuarios] = useState([]);

  useEffect(() => {
    cargarBoleto();
  }, []);

  const cargarBoleto = async () => {
    try {
      setCargando(true);
      const _boleto = await Database.getItem(
        DATABASE_TABLES.BOLETOS,
        "numeroBoleto",
        numeroBoleto
      );
      const _sorteo = await Database.getItem(
        DATABASE_TABLES.LISTA_SORTEOS,
        "codigo",
        _boleto.codigoSorteo
      );
      const _usuarios = await obtenerUsuarios();
      const posLetras = {
        1: ["1er", "empty", "empty"],
        2: ["1er", "2do", "empty"],
        3: ["1er", "2do", "3er"],
      };
      setBoleto(_boleto);
      setLugares(posLetras[_sorteo.numLugares]);
      setUsuarios(_usuarios);
      setCargando(false);
    } catch ({ message }) {
      console.log(message);
    }
  };

  if (cargando) return <CustomCircularProgress />;

  return (
    <>
      <InfoBoleto boleto={boleto} lugares={lugares} />
      <PagarPremio
        boleto={boleto}
        premio={premio}
        usuarios={usuarios}
        closeModal={closeModal}
      />
    </>
  );
}

function PagarPremio({ boleto, premio, usuarios, closeModal }) {
  const { pagosPendientes } = useSelector((state) => state.administracion);
  const [numeroUsuario, setNumeroUsuario] = useState("");
  const [password, setPassword] = useState("");
  const [guardandoPago, setGuardandoPago] = useState(false);
  const [errorExists, setErrorExists] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();

  const handleChange = (val) => {
    setNumeroUsuario(val);
  };

  const handleClick = () => {
    if (
      numeroUsuario !== "" &&
      boleto !== null &&
      password !== "" &&
      premio !== ""
    ) {
      efectuarPago();
    }
  };

  const onPasswordChange = ({ target }) => {
    setPassword(target.value);
  };

  const efectuarPago = async () => {
    try {
      setGuardandoPago(true);
      setErrorExists(false);
      setErrorMessage("");
      const pagoEfectuado = await registrarPago(
        numeroUsuario,
        password,
        boleto,
        premio
      );
      if (pagoEfectuado) {
        const _newPagosPendientes = [...pagosPendientes].filter(
          (item) => item.numeroBoleto !== boleto.numeroBoleto
        );
        closeModal();
        setTimeout(() => {
          dispatch(setPagosPendientes(_newPagosPendientes));
          setPassword("");
          setNumeroUsuario("");
        }, 300);
      }
      setGuardandoPago(false);
    } catch ({ message }) {
      setErrorMessage(message);
      setErrorExists(true);
      setGuardandoPago(false);
    }
  };

  return (
    <div style={styles.pagarPremioBox}>
      <span style={styles.premioLabel}>Premio</span>
      <span style={styles.premio}>{Money(premio)}</span>
      <div style={{ width: "100%", marginTop: 20 }}>
        <CustomSelect
          label="¿Quién lo paga?"
          options={usuarios}
          optionLabel="nomComercial"
          optionValue="usuario"
          width="100%"
          onChange={handleChange}
          value={numeroUsuario}
          disabled={guardandoPago}
        />
        {errorExists && (
          <Alert style={{ marginTop: 10 }} severity="error">
            {errorMessage}
          </Alert>
        )}
        <FormControl fullWidth style={{ marginTop: 10 }}>
          <TextField
            type="password"
            label="Contraseña administrador"
            value={password}
            onChange={onPasswordChange}
            size="small"
            name="password"
          />
        </FormControl>
        <Button
          variant="contained"
          color="success"
          style={{ color: "#fff", marginTop: 10, marginBottom: 20 }}
          disabled={guardandoPago || numeroUsuario === "" || password === ""}
          fullWidth
          startIcon={
            <>
              {guardandoPago && <CustomCircularProgress icon />}
              {!guardandoPago && <SaveIcon style={{ color: "#fff" }} />}
            </>
          }
          onClick={handleClick}
        >
          {guardandoPago ? "Guardando pago" : "Efectuar pago"}
        </Button>
      </div>
    </div>
  );
}

const styles = {
  pagarPremioBox: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 20,
  },
  premioLabel: {
    fontSize: 24,
  },
  premio: {
    fontSize: 26,
    color: Colors.green,
  },
};
