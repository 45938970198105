import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";
import InputField from "./InputField";
import { useFormik } from "formik";
import * as YUP from "yup";
import { Alert, Button, CircularProgress } from "@mui/material";
import { verifyUser } from "../services/auth";

export default function PasswordProtectedAction({
  open,
  onClose,
  onValidPassword,
  dialogActionMessage = "",
  progressDialogMessage = "",
}) {
  const [validationError, setValidationError] = useState(null);
  const [validating, setValidating] = useState(false);
  const [progressDialog, setProgressDialog] = useState(false);
  const formik = useFormik({
    initialValues: {
      password: "",
    },
    validationSchema: YUP.object().shape({
      password: YUP.string().required("Este camp es obligatorio"),
    }),
    onSubmit: function (validData) {
      validatePassword(validData.password);
    },
  });

  const handleClick = () => {
    formik.handleSubmit();
  };

  const handleClose = () => {
    formik.resetForm();
    setValidationError(null);
    onClose();
  };

  const validatePassword = async (_password) => {
    try {
      setValidationError(null);
      setValidating(true);
      await verifyUser(_password);
      onValidPassword();
      setProgressDialog(true);
      setValidating(false);
    } catch ({ message }) {
      setValidationError(message);
      setValidating(false);
    }
  };

  return (
    <Dialog disableEscapeKeyDown open={open}>
      {/* ALERT DIALOG */}
      {!progressDialog && (
        <>
          <DialogTitle>Introduce tu contraseña</DialogTitle>
          <DialogContent>
            <InputField
              label=""
              name="password"
              type="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.errors.password}
              touched={formik.touched.password}
              value={formik.values.password}
              disabled={validating}
              disableErrorMessage
              autofocus
            />
            <p style={{ color: "rgba(255,255,255,0.8)", fontStyle: "italic" }}>
              {dialogActionMessage}
            </p>
            {validationError && (
              <Alert severity="error">{validationError}</Alert>
            )}
          </DialogContent>
          <DialogActions>
            {!validating && (
              <>
                <Button onClick={handleClose}>cancelar</Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleClick}
                  disabled={
                    formik.errors.password ||
                    formik.values.password.trim() == ""
                  }
                >
                  aceptar
                </Button>
              </>
            )}
            {validating && (
              <>
                <Button disabled startIcon={<CircularProgress size={20} />}>
                  validando contraseña
                </Button>
              </>
            )}
          </DialogActions>
        </>
      )}
      {progressDialog && (
        <DialogContent>
          <div style={{ display: "flex", alignItems: "center" }}>
            <CircularProgress size={40} />
            <span style={{ marginLeft: 10 }}>{progressDialogMessage}</span>
          </div>
        </DialogContent>
      )}
    </Dialog>
  );
}
