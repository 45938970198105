import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  filtrosCargados: false,
  usuariosCargados: false,
  usuarioSelected: null,
  cargandoLiquidacion: null,
  liquidacion: {
    periodo: { inicial: "", final: "" },
    venta: {
      ticketPlus: 0,
      transacciones: 0,
    },
    comisiones: {
      ticketPlus: 0,
      transacciones: 0,
    },
    pagos: {
      realizados: 0,
      abonos: 0,
    },
    importe: 0,
  },
  tipoPeriodo: "diario", // diario | semanal | liquidacion,
  deposito: 0,
  // ESTADO DE CUENTA STARTS
  tipoEstadoDeCuenta: "", //diario | semanal | liquidacion
  periodoSeleccionado: null, // string | obj
  cargandoEstadoDeCuenta: true,
  semanaEnCurso: false,
  estadoDeCuenta: {
    referencia: "",
    numRegistrosTicketPlus: 0,
    ventaTotalTicketPlus: 0,
    comisionTicketPlus: 0,
    numRegistrosRecargas: 0,
    ventaTotalRecargas: 0,
    comisionRecargas: 0,
    numRegistrosPagoServicios: 0,
    ventaTotalPagoServicios: 0,
    comisionPagoServicios: 0,
    numRegistrosGiftCards: 0,
    ventaTotalGiftCards: 0,
    comisionGiftCards: 0,
    sumaTotalVentas: 0,
    sumaTotalComisiones: 0,
    numPremiosPagadosInicioSem: 0,
    totalPremiosPagadosInicioSem: 0,
    numPremiosPagadosRestoSem: 0,
    totalPremiosPagadosRestoSem: 0,
    numRegistrosCancelados: 0,
    totalCancelados: 0,
  },
  // ESTADO DE CUENTA ENDS
  showPDFButton: false,
};

export const reportesSlice = createSlice({
  name: "reportes",
  initialState,
  reducers: {
    setFiltrosCargados: (state, { payload }) => {
      state.filtrosCargados = payload;
    },
    setUsuariosCargados: (state, { payload }) => {
      state.usuariosCargados = payload;
    },
    setUsuarioSelected: (state, { payload }) => {
      state.usuarioSelected = { ...payload };
    },
    setCargandoLiquidacion: (state, { payload }) => {
      state.cargandoLiquidacion = payload;
    },
    setLiquidacion: (state, { payload }) => {
      state.liquidacion = { ...payload };
    },
    setPeriodo: (state, { payload }) => {
      state.periodo = { ...payload };
    },
    setTipoPeriodo: (state, { payload }) => {
      state.tipoPeriodo = payload;
    },
    setDeposito: (state, { payload }) => {
      state.deposito = payload;
    },
    // ESTADO DE CUENTA STARTS
    setCargandoEstadoDeCuenta: (state, { payload }) => {
      state.cargandoEstadoDeCuenta = payload;
    },
    setEstadoDeCuenta: (state, { payload }) => {
      state.estadoDeCuenta = { ...payload };
    },
    setTipoEstadoDeCuenta: (state, { payload }) => {
      state.tipoEstadoDeCuenta = payload;
    },
    setPeriodoSeleccionado: (state, { payload }) => {
      state.periodoSeleccionado = { ...payload };
    },
    setSemanaEnCurso: (state, { payload }) => {
      state.semanaEnCurso = payload;
    },
    // ESTADO DE CUENTA ENDS
    setShowPDFButton: (state, { payload }) => {
      state.showPDFButton = payload;
    },
  },
});

export const {
  setFiltrosCargados,
  setUsuariosCargados,
  setUsuarioSelected,
  setCargandoLiquidacion,
  setLiquidacion,
  setPeriodo,
  setTipoPeriodo,
  setDeposito,
  setCargandoEstadoDeCuenta,
  setEstadoDeCuenta,
  setTipoEstadoDeCuenta,
  setPeriodoSeleccionado,
  setSemanaEnCurso,
  setShowPDFButton,
} = reportesSlice.actions;
export default reportesSlice.reducer;
