import { useFormik } from "formik";
import { CustomModal, InputField } from "../../../../../components";
import CustomListItemIconButton from "../../../../../components/CustomListItemIconButton";
import { useModal } from "../../../../../hooks";
import { DeleteIcon } from "../../../../../icons";
import { eliminarSorteo } from "../../../../../services/bola";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import * as Yup from "yup";
import { useSelector } from "react-redux";

export default function EliminarSorteoButton() {
  const { sorteoSeleccionado } = useSelector((state) => state.sorteos);
  const formik = useFormik({
    initialValues: {
      passwordAdmin: "",
    },
    validationSchema: Yup.object().shape({
      passwordAdmin: Yup.string().required("Este campo es requerido"),
    }),
    onSubmit: (validatedData) => {
      if (!modal.config.confirmBtnClicked) {
        eliminar(validatedData);
      }
    },
  });
  const modal = useModal();
  const navigate = useNavigate();

  const handleOpenModal = () => {
    modal.setConfig({
      open: true,
      type: "alert",
      alertTitle: "Eliminar",
      contentType: "content",
      showCancelBtn: true,
      confirmBtnText: "aceptar",
    });
  };

  const handleModalCancel = () => {
    modal.setConfig({ open: false });
    formik.handleReset();
  };

  const handleModalAccept = () => {
    if (modal.config.contentType === "content") {
      formik.handleSubmit();
    }
    if (modal.config.contentType === "error") {
      modal.setConfig({ open: false });
      setTimeout(() => {
        formik.handleReset();
      }, 300);
    }
  };

  // ELIMINAR SORTEO
  const eliminar = async ({ passwordAdmin }) => {
    try {
      modal.setConfig({
        type: "progress",
        progressTitle: "Eliminando",
        confirmBtnClicked: true,
      });
      const sorteoEliminado = await eliminarSorteo(
        sorteoSeleccionado,
        passwordAdmin
      );
      // SI SE ELIMINO CORRECTAMENTE
      if (sorteoEliminado) {
        formik.handleReset();
        modal.setConfig({ open: false, confirmBtnClicked: false });
        setTimeout(() => {
          navigate("../../sorteos");
        }, 300);
      }
    } catch ({ message }) {
      modal.setConfig({
        type: "alert",
        alertTitle: "Mensaje",
        contentType: "error",
        error: <span>{message}</span>,
        confirmBtnText: "Entendido",
        showCancelBtn: false,
        confirmBtnClicked: false,
      });
    }
  };

  return (
    <>
      <CustomListItemIconButton
        label="Eliminar"
        icon={<DeleteIcon />}
        onClick={() => handleOpenModal()}
      />
      <CustomModal
        open={modal.config.open}
        type={modal.config.type}
        alertTitle={modal.config.alertTitle}
        progressTitle={modal.config.progressTitle}
        showCancelBtn={modal.config.showCancelBtn}
        confirmBtnText={modal.config.confirmBtnText}
        onCancel={handleModalCancel}
        onAccept={handleModalAccept}
      >
        {modal.config.contentType === "content" && (
          <>
            <Typography>
              Por seguridad te pedimos ingreses tu contraseña para poder
              continuar con el proceso de eliminación.
            </Typography>
            <InputField
              type="password"
              label="Contraseña administrador"
              name="passwordAdmin"
              value={formik.values.passwordAdmin}
              error={formik.errors.passwordAdmin}
              touched={formik.touched.passwordAdmin}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </>
        )}
        {modal.config.contentType === "error" && modal.config.error}
      </CustomModal>
    </>
  );
}
