import { TableCell, TableRow } from "@mui/material";

export default function DataRow({ data = [], bgColor, textColor }) {
  return (
    <TableRow>
      {data.map((item, index) => (
        <TableCell
          align={index === 0 ? "left" : "right"}
          key={index}
          sx={{ backgroundColor: bgColor, color: textColor }}
        >
          {item}
        </TableCell>
      ))}
    </TableRow>
  );
}
