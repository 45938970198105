import { TableCell, TableRow } from "@mui/material";
import { Money } from "../../../../utils";

export default function PremioPagadoFila({ premioPagado }) {
  return (
    <TableRow>
      <TableCell>{premioPagado.numeroBoleto}</TableCell>
      <TableCell>{premioPagado.fechaSorteo}</TableCell>
      <TableCell>{Money(premioPagado.premio)}</TableCell>
      <TableCell>{premioPagado.vendidoPor}</TableCell>
      <TableCell>{premioPagado.pagadoPor}</TableCell>
      <TableCell>
        {premioPagado.fechaPago} {premioPagado.horaPago}
      </TableCell>
    </TableRow>
  );
}
