import * as Yup from "yup";

const Usuario = {
    agregar: {
        values: {
            nombre: "",
            apellidos: "",
            correo: "",
            telefono: "",
            domicilio: "",
            nomComercial: "",
            usuario: "",
            limiteVenta: 0,
            usuarioPassword: "",
            confirmarUsuarioPassword: "",
            passwordAdmin: "",
        },
        validation: Yup.object().shape({
            nombre: Yup.string().required("Este campo es obligatorio"),
            apellidos: Yup.string().required("Este campo es obligatorio"),
            correo: Yup.string()
                .email("Correo no valido")
                .required("Este campo es obligatorio"),
            telefono: Yup.string()
                .matches(
                    /^[0-9]{10}$/g,
                    "Introduce un teléfono valido a 10 dígitos"
                )
                .required("Este campo es obligatorio"),
            domicilio: Yup.string().required("Este campo es obligatorio"),
            nomComercial: Yup.string().required("Este campo es obligatorio"),
            usuario: Yup.number()
                .typeError("Nombre de usuario incorrecto")
                .min(10000, "Introduce un número de 6 cifras")
                .integer("Nombre de usuario incorrecto")
                .required("Este campo es obligatorio"),
            limiteVenta: Yup.number()
                .typeError("Número incorrecto")
                .min(0, "Introduce un número igual o mayor que cero")
                .integer("Introduce un número entero")
                .required("Este campo es obligatorio"),
            usuarioPassword: Yup.string()
                .required("Este campo es obligatorio")
                .oneOf(
                    [Yup.ref("confirmarUsuarioPassword")],
                    "Las contraseñas no coinciden"
                ),
            confirmarUsuarioPassword: Yup.string()
                .required("Este campo es obligatorio")
                .oneOf(
                    [Yup.ref("usuarioPassword")],
                    "Las contraseñas no coinciden"
                ),
            passwordAdmin: Yup.string().required("Este campo es obligatorio"),
        }),
    },
};

export default Usuario;
