import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import ScrollView from "../../components/ScrollView";
import TablaLoading from "../../placeholders/Tabla";
import { obtenerCancelados } from "../../services/bola";
import { Helpers, Moment, Money, uuid } from "../../utils";
import {
  setCanceladosTodos,
  setCargandoCancelados,
} from "../../features/cancelados/canceladosSlice";
import SelectUsuario from "./components/SelectUsuario";
import Buscador from "./components/Buscador";
import NO_DATA_IMG from "../../assets/no-results.png";
import StatusCircle from "../../components/StatusCircle";
import SectionTitle from "../../components/SectionTitle";
import { CustomModal } from "../../components";
import { useModal } from "../../hooks";
import { Colors, DATABASE_TABLES } from "../../constants";
import CustomCircularProgress from "../../components/CustomCircularProgress";
import Database from "../../database";

export default function Cancelados() {
  return (
    <div>
      <SectionTitle title="Cancelados" />
      <Card>
        <CardHeader title={<Header />} subheader={<Subheader />} />
        <CardContent>
          <Tabla />
        </CardContent>
      </Card>
    </div>
  );
}

const Subheader = () => {
  const { registrados, total } = useSelector((state) => state.cancelados);
  return (
    <>
      <StatusCircle text={`Total(${registrados}): ${Money(total)} MXN`} />
    </>
  );
};

function Header() {
  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div>Tabla de registros</div>
      <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
        <Buscador />
        <SelectUsuario />
      </div>
    </div>
  );
}

const Tabla = () => {
  const canceladosState = useSelector((state) => state.cancelados);
  const dispatch = useDispatch();

  useEffect(() => {
    cargarCancelados();
  }, []);

  const cargarCancelados = async () => {
    dispatch(setCargandoCancelados(true));
    const items = await obtenerCancelados();
    dispatch(setCanceladosTodos(items));
    dispatch(setCargandoCancelados(false));
  };

  if (canceladosState.cargando) {
    return <TablaLoading />;
  }

  if (!canceladosState.cargando && canceladosState.filtrados.length === 0) {
    return <NoData />;
  }

  return (
    <ScrollView scrollBoth maxHeight={600}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Tipo</TableCell>
            <TableCell>Fecha Exp</TableCell>
            <TableCell>Fecha Cancelación</TableCell>
            <TableCell>Motivo</TableCell>
            <TableCell>Usuario</TableCell>
            <TableCell>Reembolso</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {canceladosState.filtrados.map((b) => (
            <Fila key={b.key} boleto={b} />
          ))}
        </TableBody>
      </Table>
    </ScrollView>
  );
};

function Fila({ boleto }) {
  const modal = useModal();

  const handleModalCancel = () => {
    modal.setConfig({ open: false });
  };

  const handleModalAccept = () => {
    modal.setConfig({ open: false });
  };

  const handleClick = () => {
    modal.setConfig({
      open: true,
      type: "alert",
      alertTitle: "Información Boleto",
      contentType: "content",
      showCancelBtn: false,
      confirmBtnText: "cerrar",
    });
  };

  return (
    <>
      <TableRow hover sx={{ cursor: "pointer" }} onClick={handleClick}>
        <TableCell sx={{ width: 150 }}>{boleto.numeroBoleto}</TableCell>
        <TableCell>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <span style={{ marginRight: 5 }}>
              {Helpers.getViaIcon(boleto.boleto?.via)}
            </span>
            {boleto.boleto?.tipo ? boleto.boleto?.tipo : "ticket plus"}
          </div>
        </TableCell>
        <TableCell>
          {boleto.boleto?.fechaExp} {boleto.boleto?.horaImpresion}
        </TableCell>
        <TableCell>
          {boleto.fechaCancelacion} {boleto.horaCancelacion}
        </TableCell>
        <TableCell>{boleto.motivo ? boleto.motivo : "QR"}</TableCell>
        <TableCell>{boleto.agencia}</TableCell>
        <TableCell>${boleto.reembolso}</TableCell>
      </TableRow>
      <CustomModal
        open={modal.config.open}
        type={modal.config.type}
        alertTitle={modal.config.alertTitle}
        progressTitle={modal.config.progressTitle}
        showCancelBtn={modal.config.showCancelBtn}
        confirmBtnText={modal.config.confirmBtnText}
        onCancel={handleModalCancel}
        onAccept={handleModalAccept}
        fullWidth
        maxWidth="xs"
      >
        {modal.config.contentType === "content" && (
          <DetalleBoleto boleto={boleto} />
        )}
        {modal.config.contentType === "error" && modal.config.error}
      </CustomModal>
    </>
  );
}

function DetalleBoleto({ boleto }) {
  const [cargando, setCargando] = useState(true);
  const [lugares, setLugares] = useState([]);

  useEffect(() => {
    obtenerSorteo();
  }, []);

  const obtenerSorteo = async () => {
    try {
      const _sorteo = await Database.getItem(
        DATABASE_TABLES.LISTA_SORTEOS,
        "codigo",
        boleto.boleto.codigoSorteo
      );
      const posLetras = {
        1: ["1er", "empty", "empty"],
        2: ["1er", "2do", "empty"],
        3: ["1er", "2do", "3er"],
      };
      setLugares(posLetras[_sorteo.numLugares]);
      setCargando(false);
    } catch ({ message }) {
      alert(message);
    }
  };

  if (cargando) return <CustomCircularProgress />;

  return (
    <div style={{ maxWidth: 280, margin: "0 auto" }}>
      <div style={styles.ribbon}>
        <span>
          Cancelado: {boleto.fechaCancelacion} {boleto.horaCancelacion}
        </span>
      </div>
      <h4 style={{ margin: 0, marginBottom: 5, textAlign: "center" }}>
        {boleto.boleto.tipo ? boleto.boleto.tipo.toUpperCase() : "TICKET PLUS"}
      </h4>
      <div style={{ textAlign: "center" }}>
        Sorteo {Moment(boleto.boleto.fechaSorteo).format("llll")}
      </div>
      <div>
        <p style={{ padding: 0, margin: 0 }}>Agencia {boleto.agencia}</p>
        <p style={{ padding: 0, margin: 0 }}>
          Impresión {Moment(boleto.fechaExp).format("DD/MM/YYYY")}{" "}
          {boleto.boleto.horaImpresion}
        </p>
      </div>
      <div>
        <div style={{ fontWeight: "bolder" }}>{generateHashes(29)}</div>
        <Table padding="none">
          <TableHead>
            <TableRow>
              <TableCell sx={{ textAlign: "right" }}>Apuestas</TableCell>
              {lugares.map((lugar) => (
                <TableCell
                  sx={{
                    textAlign: "right",
                    color: lugar === "empty" ? "transparent" : "",
                  }}
                  key={uuid()}
                >
                  {lugar}
                </TableCell>
              ))}
            </TableRow>
            {boleto.boleto.jugadas.map((jugada) => (
              <TableRow sx={{ textAlign: "right" }} key={uuid()}>
                <TableCell
                  sx={{
                    padding: 1,
                    border: 0,
                    textAlign: "right",
                  }}
                >
                  {jugada.numero}
                </TableCell>
                {jugada.lugares.map((lugar) => (
                  <TableCell
                    key={uuid()}
                    sx={{
                      textAlign: "right",
                      padding: 1,
                      border: 0,
                    }}
                  >
                    {lugar === "0" ? "X" : lugar}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
        </Table>
        <div style={{ fontWeight: "bolder" }}>{generateHashes(29)}</div>
        <div>
          <p style={{ margin: 0 }}>Jugadas {boleto.boleto.jugadas.length}</p>
          <p style={{ margin: 0 }}>Total {boleto.boleto.totalApostado} Pts</p>
          <p style={{ margin: 0 }}>ID {boleto.boleto.numeroBoleto}</p>
          {boleto.boleto.via && boleto.boleto.via == "whatsapp" && (
            <>
              <p style={{ margin: 0 }}>Via {boleto.boleto.via}</p>
              <p style={{ margin: 0 }}>Enviado a {boleto.boleto.telefono}</p>
            </>
          )}
          <p style={{ margin: 0 }}>
            Motivo {boleto.motivo ? boleto.motivo : "QR"}
          </p>
          <p style={{ margin: 0 }}>
            Descripcion motivo:{" "}
            {boleto.motivoDescripcion
              ? boleto.motivoDescripcion
              : "sin especificar"}
          </p>
        </div>
      </div>
    </div>
  );
}

const NoData = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <img src={NO_DATA_IMG} alt="sin registros" style={{ width: "50%" }} />
      <Typography>Sin resultados</Typography>
    </div>
  );
};

const generateHashes = (number) => {
  let hashes = "";
  for (let i = 0; i < number; i++) {
    hashes += "#";
  }
  return hashes;
};

const styles = {
  ribbon: {
    width: "100%",
    background: Colors.red,
    color: "#fff",
    textAlign: "center",
    padding: "5px",
    marginBottom: 10,
    fontWeight: "bold",
  },
};
