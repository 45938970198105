import { Box, Typography } from "@mui/material";
import { CustomAvatar } from "../../../../components";

export default function DetalleTransaccion({ transaccion }) {
  if (!transaccion) return null;
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 1,
        }}
      >
        <CustomAvatar
          src={transaccion.logo}
          size={100}
          border={false}
          background="#fff"
        />
        <Typography>{transaccion.Monto}</Typography>
        <Typography>{transaccion.Telefono}</Typography>
      </Box>
      <Box sx={{ marginY: 1 }}>
        <DetalleFila label="Abono" value={transaccion.Abono} />
        <DetalleFila label="Bolsa" value={transaccion.Bolsa} />
        <DetalleFila label="Cargo" value={transaccion.Cargo} />
        <DetalleFila label="Compañia" value={transaccion.Carrier} />
        <DetalleFila label="Fecha" value={transaccion._fecha} />
        <DetalleFila label="Hora" value={transaccion._hora} />
        <DetalleFila label="Folio" value={transaccion.Folio} />
        <DetalleFila label="IP" value={transaccion.IP} />
        <DetalleFila label="Nota" value={transaccion.Nota} />
        <DetalleFila label="Región" value={transaccion["Región"]} />
        <DetalleFila label="Saldo Final" value={transaccion["Saldo Final"]} />
        <DetalleFila label="Status" value={transaccion.Status} />
        <DetalleFila label="TransID" value={transaccion.TransID} />
        <DetalleFila label="Pin" value={transaccion.pin} />
        <DetalleFila label="Usuario" value={transaccion._usuario} />
      </Box>
    </>
  );
}

function DetalleFila({ label, value }) {
  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <Typography>{label}</Typography>
      <Typography>{value}</Typography>
    </Box>
  );
}
