import { useEffect, useState } from "react";
import AccountStatusesLayout from "./components/AccountStatusesLayout";
import AccountStatusesTable from "./components/AccountStatusesTable";
import { getAccountStatuses } from "../../../services/reports";
import { Money, Utils } from "../../../utils";
import { useDispatch, useSelector } from "react-redux";
import {
  setAccountStatuses,
  setFetchingAccountStatuses,
} from "../../../features/administracion/administracionSlice";
import { obtenerReporteTransacciones } from "../../../services/reportes";
import {
  getPeriodFromMonday,
  getServerTimestamp,
} from "../../../services/common";

export default function AccountStatusesTab() {
  const { fetchingAccountStatuses } = useSelector(
    (state) => state.administracion
  );
  const [accountStatusesPeriod, setAccountStatusesPeriod] = useState({
    start: "",
    end: "",
  });
  const [totales, setTotales] = useState({
    aPagar: 0,
    pagado: 0,
    diferencia: 0,
  });
  const [digital, setDigital] = useState(0);
  const [noDigital, setNoDigital] = useState(62859.527);
  const [sumaTotales, setSumaTotales] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchAccountStatuses();
  }, []);

  const fetchAccountStatuses = async () => {
    try {
      dispatch(setFetchingAccountStatuses(true));
      const _accountStatuses = await getAccountStatuses();
      // TOTAL A PAGAR
      let totalApagar = 0;
      let totalPagado = 0;
      _accountStatuses.forEach((item) => {
        totalApagar += item.amount;
        totalPagado += item.deposits.list.reduce(
          (acc, el) => acc + parseFloat(el.pago),
          0
        );
      });
      setTotales({
        aPagar: totalApagar,
        pagado: totalPagado,
        diferencia: totalApagar - totalPagado,
      });
      dispatch(setAccountStatuses(_accountStatuses));
      setAccountStatusesPeriod(
        _accountStatuses.length > 0 ? _accountStatuses[0].period : ""
      );
      dispatch(setFetchingAccountStatuses(false));
    } catch ({ message }) {
      console.log(message);
    }
  };

  useEffect(() => {
    if (accountStatusesPeriod.start.length > 0) {
      const fecthTransactions = async () => {
        const timestamp = await getServerTimestamp();
        // const periodo = accountStatusesPeriod.start + "_" + accountStatusesPeriod.end;
        const periodo = getPeriodFromMonday(timestamp);
        const rango = periodo.start + "_" + periodo.end;
        const reporteTransacciones = await obtenerReporteTransacciones(rango);
        const recargas = reporteTransacciones.inicial.recargas * 0.96;
        const servicios =
          reporteTransacciones.inicial.servicios +
          reporteTransacciones.venta.servicios.registros * 3.5;
        const sumaTotales = recargas + servicios;
        setDigital(sumaTotales);
        setSumaTotales(sumaTotales + noDigital);
      };
      fecthTransactions();
    }
  }, [accountStatusesPeriod]);

  return (
    <AccountStatusesLayout
      sectionTitle={
        fetchingAccountStatuses
          ? "cargando estados de cuenta"
          : "Estados de cuenta"
      }
      sectionSubTitle={
        fetchingAccountStatuses ? null : (
          <div>
            <p>{Utils.periodToLongText(accountStatusesPeriod)}</p>
            <p>Total a pagar: {Money(totales.aPagar)}</p>
            <p>Total pagado: {Money(totales.pagado)}</p>
            <p>
              {totales.diferencia > 0 ? "Faltante:" : "Sobrante:"}{" "}
              {Money(Math.abs(totales.diferencia))}
            </p>
            {/* <p>No Digital: {noDigital}</p>
            <p>Digital: {digital}</p> */}
            {/* SUM DIGITAL Y NO DIGITAL */}
            {/* <p>Total: {sumaTotales}</p> */}
          </div>
        )
      }
    >
      {!fetchingAccountStatuses && <AccountStatusesTable />}
    </AccountStatusesLayout>
  );
}
