import {
  // DashboardIcon,
  CancelIcon,
  CleaningServicesIcon,
  DashboardIcon,
  EventIcon,
  GroupIcon,
  ReceiptLongIcon,
  SummarizeIcon,
} from "../icons";
import { uuid } from "../utils";

const items = [
  // {
  //     id: uuid(),
  //     text: "Panel",
  //     icon: <DashboardIcon />,
  //     active: false,
  //     route: "/panel",
  // },
  {
    id: uuid(),
    text: "Sorteos",
    icon: <EventIcon />,
    active: false,
    route: "sorteos",
  },
  {
    id: uuid(),
    text: "Boletos",
    icon: <ReceiptLongIcon />,
    active: false,
    route: "boletos",
  },
  {
    id: uuid(),
    text: "Cancelados",
    icon: <CancelIcon />,
    active: false,
    route: "cancelados",
  },
  // {
  //     id: uuid(),
  //     text: "Números Jugados",
  //     icon: <NumbersIcon />,
  //     active: false,
  //     route: "numeros-jugados",
  // },
  // {
  //     id: uuid(),
  //     text: "Números Ganadores",
  //     icon: <SportsScoreIcon />,
  //     active: false,
  //     route: "numeros-ganadores",
  // },
  {
    id: uuid(),
    text: "Reportes",
    icon: <SummarizeIcon />,
    active: false,
    route: "reportes",
  },
  {
    id: uuid(),
    text: "Usuarios",
    icon: <GroupIcon />,
    active: false,
    route: "usuarios",
  },
  {
    id: uuid(),
    text: "Liberar espacio",
    icon: <CleaningServicesIcon />,
    active: false,
    route: "liberar-espacio",
  },
  {
    id: uuid(),
    text: "Administracion",
    icon: <DashboardIcon />,
    active: false,
    route: "administracion/registroPagos",
  },
];

export default items;
