import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardHeader,
  CardContent,
  FormControl,
  TextField,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Button,
  Typography,
} from "@mui/material";
import ScrollView from "../../../components/ScrollView";
import TablaLoading from "../../../placeholders/Tabla";
import { Helpers, Moment, Money, uuid } from "../../../utils";
import SelectSorteo from "./SelectSorteo";
import SelectUsuario from "./SelectUsuario";
import {
  setCargandoBoletos,
  setBuscarBoleto,
  filtrarBoletosUsuario,
  setBoletosTodos,
} from "../../../features/boletos/boletosSlice";
import {
  obtenerBoletosSorteo,
  verificarYcancelarBoleto,
} from "../../../services/bola/";
import emptyImg from "../../../assets/no-results.png";
import StatusCircle from "../../../components/StatusCircle";
import Database from "../../../database";
import { DATABASE_TABLES } from "../../../constants";
import CustomCircularProgress from "../../../components/CustomCircularProgress";
import { AdminPasswordField, CustomModal } from "../../../components";
import { useModal } from "../../../hooks";
import { useNavigate } from "react-router-dom";

export default function TablaBoletos() {
  return (
    <Card>
      <CardHeader title={<Header />} subheader={<TablaSubheader />} />
      <CardContent>
        <ScrollView scrollBoth maxHeight={365}>
          <Tabla />
        </ScrollView>
      </CardContent>
    </Card>
  );
}

const TablaSubheader = () => {
  const { filtrados, registrados, cargando } = useSelector(
    (state) => state.boletos
  );
  const totalVendido = filtrados.reduce(
    (accum, el) => accum + parseInt(el.totalApostado),
    0
  );
  return (
    <Box sx={{ display: "flex" }}>
      {cargando ? (
        "cargando..."
      ) : (
        <>
          <StatusCircle
            text={` Total(${registrados}): ${Money(totalVendido)} MXN`}
          />
        </>
      )}
    </Box>
  );
};

const Buscador = () => {
  const boletosState = useSelector((state) => state.boletos);
  const usuariosState = useSelector((state) => state.usuarios);
  const dispatch = useDispatch();
  const [value, setValue] = useState("");

  useEffect(() => {
    setValue("");
  }, [usuariosState.usuarioKey]);

  const inputChange = (text) => {
    setValue(text);
    if (text === "") {
      const usuario = obtenerUsuario();
      dispatch(
        filtrarBoletosUsuario(usuario !== undefined ? usuario.usuario : "")
      );
    }
  };

  const onKeyDown = ({ keyCode }) => {
    if (keyCode === 13) {
      const usuario = obtenerUsuario();
      dispatch(
        setBuscarBoleto({
          busqueda: value,
          numeroUsuario: usuario !== undefined ? usuario.usuario : "",
        })
      );
    }
  };

  const obtenerUsuario = () => {
    return usuariosState.lista.find((u) => u.key === usuariosState.usuarioKey);
  };

  return (
    <FormControl fullWidth size="small">
      <TextField
        type="search"
        disabled={boletosState.cargando}
        placeholder="Buscar boleto..."
        size="small"
        value={value}
        onChange={({ target }) => inputChange(target.value)}
        inputProps={{
          onKeyDown: (e) => {
            onKeyDown(e);
          },
        }}
      />
    </FormControl>
  );
};

const Tabla = () => {
  const boletosState = useSelector((state) => state.boletos);
  const { sorteoKey } = useSelector((state) => state.sorteos);
  const dispatch = useDispatch();

  useEffect(() => {
    if (sorteoKey !== "") {
      cargarBoletos();
    }
  }, [sorteoKey]);

  const cargarBoletos = async () => {
    dispatch(setCargandoBoletos(true));
    const items = await obtenerBoletosSorteo(sorteoKey);
    dispatch(setBoletosTodos(items));
    dispatch(setCargandoBoletos(false));
  };

  if (boletosState.cargando) {
    return <TablaLoading />;
  }

  if (!boletosState.cargando && boletosState.filtrados.length === 0) {
    return <NoResults />;
  }

  return (
    <div id="tabla">
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Número</TableCell>
            <TableCell>Tipo</TableCell>
            <TableCell>Fecha Impresión</TableCell>
            <TableCell>Hora Impresión</TableCell>
            <TableCell>Usuario</TableCell>
            <TableCell>Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {boletosState.filtrados.map((b) => (
            <Fila key={b.key} boleto={b} />
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

function Fila({ boleto }) {
  const modal = useModal();

  const handleModalCancel = () => {
    modal.setConfig({ open: false });
  };

  const handleModalAccept = () => {
    modal.setConfig({ open: false });
  };

  const handleClick = () => {
    modal.setConfig({
      open: true,
      type: "alert",
      alertTitle: "Información Boleto",
      contentType: "content",
      showCancelBtn: false,
      confirmBtnText: "cerrar",
    });
  };
  return (
    <>
      <TableRow
        hover
        onClick={handleClick}
        sx={{
          "&:hover": {
            cursor: "pointer",
          },
        }}
      >
        <TableCell sx={{ width: 150 }}>{boleto.numeroBoleto}</TableCell>
        <TableCell>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <span style={{ marginRight: 5 }}>
              {Helpers.getViaIcon(boleto.via)}
            </span>
            {boleto.tipo === undefined ? "ticket plus" : boleto.tipo}
          </div>
        </TableCell>
        <TableCell>{Moment(boleto.fechaExp).format("llll")}</TableCell>
        <TableCell>{boleto.horaImpresion}</TableCell>
        <TableCell>{boleto.numeroAgencia}</TableCell>
        <TableCell>{Money(boleto.totalApostado)}</TableCell>
      </TableRow>
      <CustomModal
        open={modal.config.open}
        type={modal.config.type}
        alertTitle={modal.config.alertTitle}
        progressTitle={modal.config.progressTitle}
        showCancelBtn={modal.config.showCancelBtn}
        confirmBtnText={modal.config.confirmBtnText}
        onCancel={handleModalCancel}
        onAccept={handleModalAccept}
        fullWidth
        maxWidth="xs"
      >
        {modal.config.contentType === "content" && (
          <DetalleBoleto
            boleto={boleto}
            closeModal={() => modal.setConfig({ open: false })}
          />
        )}
        {modal.config.contentType === "error" && modal.config.error}
      </CustomModal>
    </>
  );
}

function DetalleBoleto({ boleto, closeModal }) {
  const [cargando, setCargando] = useState(true);
  const [lugares, setLugares] = useState([]);
  const [sePuedeCancelar, setSePuedeCancelar] = useState(false);

  useEffect(() => {
    obtenerSorteo();
  }, []);

  const obtenerSorteo = async () => {
    try {
      const _sorteo = await Database.getItem(
        DATABASE_TABLES.LISTA_SORTEOS,
        "codigo",
        boleto.codigoSorteo
      );
      const posLetras = {
        1: ["1er", "empty", "empty"],
        2: ["1er", "2do", "empty"],
        3: ["1er", "2do", "3er"],
      };
      const timestamp = await Database.getServerDate();
      const horaCierre = await Database.getObject(DATABASE_TABLES.HORA_CIERRE);
      const disponiblidadDeSorteo = Helpers.disponiblidadDeSorteo(
        timestamp,
        boleto.fechaSorteo,
        horaCierre.hora
      );
      setSePuedeCancelar(!disponiblidadDeSorteo.error);
      setLugares(posLetras[_sorteo.numLugares]);
      setCargando(false);
    } catch ({ message }) {
      alert(message);
    }
  };

  if (cargando) return <CustomCircularProgress />;

  return (
    <div style={{ maxWidth: 280, margin: "0 auto" }}>
      <h4 style={{ margin: 0, marginBottom: 5, textAlign: "center" }}>
        Ticket Plus
      </h4>
      <div style={{ textAlign: "center" }}>
        Sorteo {Moment(boleto.fechaSorteo).format("llll")}
      </div>
      <div>
        <p style={{ padding: 0, margin: 0 }}>Agencia {boleto.numeroAgencia}</p>
        <p style={{ padding: 0, margin: 0 }}>
          Impresión {Moment(boleto.fechaExp).format("DD/MM/YYYY")}{" "}
          {boleto.horaImpresion}
        </p>
      </div>
      <div>
        <InfoBoleto boleto={boleto} lugares={lugares} />
        {sePuedeCancelar && (
          <CancelarButton boleto={boleto} closeModal={closeModal} />
        )}
      </div>
    </div>
  );
}

function InfoBoleto({ boleto, lugares = [] }) {
  return (
    <>
      <div style={{ fontWeight: "bolder" }}>{generateHashes(29)}</div>
      <Table padding="none">
        <TableHead>
          <TableRow>
            <TableCell sx={{ textAlign: "right" }}>Apuestas</TableCell>
            {lugares.map((lugar) => (
              <TableCell
                sx={{
                  textAlign: "right",
                  color: lugar === "empty" ? "transparent" : "",
                }}
                key={uuid()}
              >
                {lugar}
              </TableCell>
            ))}
          </TableRow>
          {boleto.jugadas.map((jugada) => (
            <TableRow sx={{ textAlign: "right" }} key={uuid()}>
              <TableCell
                sx={{
                  padding: 1,
                  border: 0,
                  textAlign: "right",
                }}
              >
                {jugada.numero}
              </TableCell>
              {jugada.lugares.map((lugar) => (
                <TableCell
                  key={uuid()}
                  sx={{
                    textAlign: "right",
                    padding: 1,
                    border: 0,
                  }}
                >
                  {lugar === "0" ? "X" : lugar}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
      </Table>
      <div style={{ fontWeight: "bolder" }}>{generateHashes(29)}</div>
      <div>
        <p style={{ margin: 0 }}>Jugadas {boleto.jugadas.length}</p>
        <p style={{ margin: 0 }}>Total {boleto.totalApostado} Pts</p>
        <p style={{ margin: 0 }}>ID {boleto.numeroBoleto}</p>
        {boleto.via && boleto.via == "whatsapp" && (
          <>
            <p style={{ margin: 0 }}>Via {boleto.via}</p>
            <p style={{ margin: 0 }}>Enviado a {boleto.telefono}</p>
          </>
        )}
      </div>
    </>
  );
}

function CancelarButton({ boleto, closeModal }) {
  const [buttonText, setButtonText] = useState("cancelar boleto");
  const [password, setPassword] = useState("");
  const [cancelando, setCancelando] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleClick = () => {
    if (password.trim().length === 0) {
      setError("Error: ingresa tu contraseña");
      return;
    }
    _verificarYcancelarBoleto();
  };

  const _verificarYcancelarBoleto = async () => {
    try {
      setError(null);
      setCancelando(true);
      setButtonText("cancelando...");
      await verificarYcancelarBoleto(password, boleto);
      setCancelando(false);
      setError(null);
      closeModal();
      navigate(0);
    } catch ({ message }) {
      setCancelando(false);
      setError(message);
      setButtonText("cancelar boleto");
    }
  };

  return (
    <div style={{ marginTop: 20, marginBottom: 40 }}>
      <AdminPasswordField
        divider
        password={password}
        message="Para cancelar el boleto ingresa tu contraseña y oprime el botón cancelar boleto."
        onChange={({ target }) => setPassword(target.value.trim())}
      />
      {!cancelando && error && (
        <Typography sx={{ color: "tomato", fontStyle: "italic" }}>
          {error}
        </Typography>
      )}
      <Button
        disabled={cancelando}
        style={{ marginTop: 20 }}
        variant="contained"
        color="error"
        fullWidth
        onClick={handleClick}
      >
        {buttonText}
      </Button>
    </div>
  );
}

function generateHashes(number) {
  let hashes = "";
  for (let i = 0; i < number; i++) {
    hashes += "#";
  }
  return hashes;
}

const NoResults = () => {
  return (
    <div style={{ textAlign: "center" }}>
      <img src={emptyImg} style={{ width: 350 }} alt="" />
      <div>sin resultados</div>
    </div>
  );
};

function Header() {
  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div>Tabla de registros</div>
      <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
        <Buscador />
        <SelectSorteo />
        <SelectUsuario />
      </div>
    </div>
  );
}
