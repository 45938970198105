import { useFormik } from "formik";
import CustomListItemIconButton from "../../../../../components/CustomListItemIconButton";
import { CampaignIcon } from "../../../../../icons";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { publicarNumerosGanadores } from "../../../../../services/bola";
import { setSorteoSeleccionado } from "../../../../../features/sorteos/sorteosSlice";
import { useModal } from "../../../../../hooks";
import { CustomModal, InputField } from "../../../../../components";
import { Divider, Grid, Typography } from "@mui/material";
import { useEffect } from "react";

export default function PublicarNumerosGanadoresButton() {
  const { sorteoSeleccionado } = useSelector((state) => state.sorteos);
  const formik = useFormik({
    initialValues: MODAL_PUBLICAR_INIT_VALUES[sorteoSeleccionado.numLugares],
    validationSchema: Yup.object().shape(
      MODAL_PUBLICAR_SCHEMA[sorteoSeleccionado.numLugares]
    ),
    onSubmit: (validatedData) => {
      if (!modal.config.confirmBtnClicked) {
        publicar(validatedData);
      }
    },
  });
  const modal = useModal();
  const dispatch = useDispatch();

  const handleOpenModal = () => {
    modal.setConfig({
      open: true,
      type: "alert",
      alertTitle: "Publicar numeros",
      contentType: "content",
      showCancelBtn: true,
      confirmBtnText: "aceptar",
    });
  };

  const handleModalCancel = () => {
    modal.setConfig({ open: false });
    formik.handleReset();
  };

  const handleModalAccept = () => {
    if (modal.config.contentType === "content") {
      formik.handleSubmit();
    }
    if (modal.config.contentType === "error") {
      modal.setConfig({ open: false });
      setTimeout(() => {
        formik.handleReset();
      }, 300);
    }
  };

  // PUBLICAR NUMEROS GANADORES
  const publicar = async (data) => {
    try {
      const numeros = {
        1: [data.primero],
        2: [data.primero, data.segundo],
        3: [data.primero, data.segundo, data.tercero],
      };
      modal.setConfig({
        type: "progress",
        progressTitle: "Publicando números",
        confirmBtnClicked: true,
      });
      const updatedSorteo = await publicarNumerosGanadores(
        data.passwordAdmin,
        sorteoSeleccionado,
        numeros[sorteoSeleccionado.numLugares]
      );
      // SI SE ELIMINO CORRECTAMENTE
      if (updatedSorteo) {
        const newSorteo = { ...sorteoSeleccionado };
        newSorteo.numerosGanadores = numeros[sorteoSeleccionado.numLugares];
        dispatch(setSorteoSeleccionado(newSorteo));
        formik.handleReset();
        modal.setConfig({ open: false, confirmBtnClicked: false });
      }
    } catch ({ message }) {
      modal.setConfig({
        type: "alert",
        alertTitle: "Mensaje",
        contentType: "error",
        error: <span>{message}</span>,
        confirmBtnText: "Entendido",
        showCancelBtn: false,
        confirmBtnClicked: false,
      });
    }
  };

  return (
    <>
      <CustomListItemIconButton
        label="Publicar números ganadores"
        icon={<CampaignIcon />}
        onClick={() => handleOpenModal()}
      />
      <CustomModal
        open={modal.config.open}
        type={modal.config.type}
        alertTitle={modal.config.alertTitle}
        progressTitle={modal.config.progressTitle}
        showCancelBtn={modal.config.showCancelBtn}
        confirmBtnText={modal.config.confirmBtnText}
        onCancel={handleModalCancel}
        onAccept={handleModalAccept}
      >
        {modal.config.contentType === "content" && (
          <ModalPublicarContent
            formik={formik}
            sorteoSeleccionado={sorteoSeleccionado}
          />
        )}
        {modal.config.contentType === "error" && modal.config.error}
      </CustomModal>
    </>
  );
}

const ModalPublicarContent = ({ formik, sorteoSeleccionado }) => {
  useEffect(() => {
    const { numLugares, numerosGanadores } = sorteoSeleccionado;
    if (numLugares === 1 && numerosGanadores.length > 0) {
      formik.setFieldValue("primero", numerosGanadores[0]);
    }
    if (numLugares === 2 && numerosGanadores.length > 0) {
      formik.setFieldValue("primero", numerosGanadores[0]);
      formik.setFieldValue("segundo", numerosGanadores[1]);
    }
    if (numLugares === 3 && numerosGanadores.length > 0) {
      formik.setFieldValue("primero", numerosGanadores[0]);
      formik.setFieldValue("segundo", numerosGanadores[1]);
      formik.setFieldValue("tercero", numerosGanadores[2]);
    }
  }, []);

  return (
    <>
      {sorteoSeleccionado.numLugares === 1 && (
        <>
          <Grid container>
            <Grid item>
              <InputField
                label="1°"
                name="primero"
                value={formik.values.primero}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.errors.primero}
                touched={formik.touched.primero}
              />
            </Grid>
          </Grid>
          <Grid container sx={{ marginTop: 1 }}>
            <Grid item>
              <Divider />
              <Typography>
                Por seguridad, te pedimos ingresar tu contraseña para poder
                continuar con la acción.
              </Typography>
              <InputField
                type="password"
                label="Contraseña administrador"
                name="passwordAdmin"
                value={formik.values.passwordAdmin}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.errors.passwordAdmin}
                touched={formik.touched.passwordAdmin}
              />
            </Grid>
          </Grid>
        </>
      )}
      {sorteoSeleccionado.numLugares === 2 && (
        <>
          <Grid container rowSpacing={2} columnSpacing={2}>
            <Grid item xs={6}>
              <InputField
                label="1°"
                name="primero"
                value={formik.values.primero}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.errors.primero}
                touched={formik.touched.primero}
              />
            </Grid>
            <Grid item xs={6}>
              <InputField
                label="2°"
                name="segundo"
                value={formik.values.segundo}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.errors.segundo}
                touched={formik.touched.segundo}
              />
            </Grid>
          </Grid>
          <Grid container sx={{ marginTop: 1 }}>
            <Grid item xs={12}>
              <Divider />
              <Typography>
                Por seguridad, te pedimos ingresar tu contraseña para poder
                continuar con la acción.
              </Typography>
              <InputField
                type="password"
                label="Contraseña administrador"
                name="passwordAdmin"
                value={formik.values.passwordAdmin}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.errors.passwordAdmin}
                touched={formik.touched.passwordAdmin}
              />
            </Grid>
          </Grid>
        </>
      )}
      {sorteoSeleccionado.numLugares === 3 && (
        <>
          <Grid container rowSpacing={2} columnSpacing={2}>
            <Grid item xs={4}>
              <InputField
                label="1°"
                name="primero"
                value={formik.values.primero}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.errors.primero}
                touched={formik.touched.primero}
              />
            </Grid>
            <Grid item xs={4}>
              <InputField
                label="2°"
                name="segundo"
                value={formik.values.segundo}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.errors.segundo}
                touched={formik.touched.segundo}
              />
            </Grid>
            <Grid item xs={4}>
              <InputField
                label="3°"
                name="tercero"
                value={formik.values.tercero}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.errors.tercero}
                touched={formik.touched.tercero}
              />
            </Grid>
          </Grid>
          <Grid
            container
            rowSpacing={2}
            columnSpacing={2}
            sx={{ marginTop: 1 }}
          >
            <Grid item>
              <Divider />
              <Typography>
                Por seguridad, te pedimos ingresar tu contraseña para poder
                continuar con la acción.
              </Typography>
              <InputField
                type="password"
                label="Contraseña administrador"
                name="passwordAdmin"
                value={formik.values.passwordAdmin}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.errors.passwordAdmin}
                touched={formik.touched.passwordAdmin}
              />
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

const MODAL_PUBLICAR_INIT_VALUES = {
  1: {
    primero: "",
    passwordAdmin: "",
  },
  2: {
    primero: "",
    segundo: "",
    passwordAdmin: "",
  },
  3: {
    primero: "",
    segundo: "",
    tercero: "",
    passwordAdmin: "",
  },
};

const MODAL_PUBLICAR_SCHEMA = {
  1: {
    primero: Yup.string()
      .matches("^[0-9]+$", "Ingresa un número valido")
      .required("Este campo es requerido")
      .min(3, "Ingresa un número de 3 cifras")
      .max(3, "Ingresa un número de 3 cifras"),
    passwordAdmin: Yup.string().required("Este campo es requerido"),
  },
  2: {
    primero: Yup.string()
      .matches("^[0-9]+$", "Ingresa un número valido")
      .required("Este campo es requerido")
      .min(3, "Ingresa un número de 3 cifras")
      .max(3, "Ingresa un número de 3 cifras"),
    segundo: Yup.string()
      .matches("^[0-9]+$", "Ingresa un número valido")
      .required("Este campo es requerido")
      .min(3, "Ingresa un número de 3 cifras")
      .max(3, "Ingresa un número de 3 cifras"),
    passwordAdmin: Yup.string().required("Este campo es requerido"),
  },
  3: {
    primero: Yup.string()
      .matches("^[0-9]+$", "Ingresa un número valido")
      .required("Este campo es requerido")
      .min(3, "Ingresa un número de 3 cifras")
      .max(3, "Ingresa un número de 3 cifras"),
    segundo: Yup.string()
      .matches("^[0-9]+$", "Ingresa un número valido")
      .required("Este campo es requerido")
      .min(3, "Ingresa un número de 3 cifras")
      .max(3, "Ingresa un número de 3 cifras"),
    tercero: Yup.string()
      .matches("^[0-9]+$", "Ingresa un número valido")
      .required("Este campo es requerido")
      .min(3, "Ingresa un número de 3 cifras")
      .max(3, "Ingresa un número de 3 cifras"),
    passwordAdmin: Yup.string().required("Este campo es requerido"),
  },
};
