import { Colors } from "../constants";

const CircleLoading = ({ size = 50, background = Colors.darkGray }) => {
    return (
        <div
            style={{
                width: size,
                height: size,
                borderRadius: "50%",
                background,
            }}
        ></div>
    );
};

export default CircleLoading;
