import React from "react";
import ContentLoader from "react-content-loader";

const RectLargeText = (props) => (
    <ContentLoader
        speed={1}
        width={143}
        height={25}
        viewBox="0 0 143 25"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
    >
        <rect x="555" y="235" rx="3" ry="3" width="88" height="6" />
        <rect x="544" y="232" rx="3" ry="3" width="52" height="6" />
        <rect x="391" y="231" rx="3" ry="3" width="410" height="6" />
        <rect x="421" y="232" rx="3" ry="3" width="380" height="6" />
        <rect x="452" y="230" rx="3" ry="3" width="178" height="6" />
        <circle cx="581" cy="222" r="20" />
        <rect x="544" y="157" rx="0" ry="0" width="420" height="201" />
        <circle cx="572" cy="138" r="24" />
        <rect x="551" y="174" rx="8" ry="8" width="60" height="9" />
        <rect x="478" y="84" rx="8" ry="8" width="32" height="8" />
        <circle cx="596" cy="132" r="33" />
        <rect x="9" y="7" rx="0" ry="0" width="134" height="18" />
    </ContentLoader>
);

export default RectLargeText;
