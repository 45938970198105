import { CustomAvatar } from "../../../../../components";
import { Helpers } from "../../../../../utils";

const LogoSorteo = ({ codigo }) => {
  return (
    <div style={styles.cardLogoBox}>
      <CustomAvatar
        background="#fff"
        src={Helpers.setImgSrc(codigo)}
        size={120}
      />
    </div>
  );
};

const styles = {
  cardLogoBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: 10,
    backgroundColor: "#3C3C3C",
    padding: 10,
  },
};

export default LogoSorteo;
