// REACT
import { useEffect, useState } from "react";
// ROUTER
import { useNavigate, useParams } from "react-router-dom";
// REDUX
import { useDispatch } from "react-redux";
// FORMIK
import { useFormik } from "formik";
// MUI COMPONENTS
import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  FormControl,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
// CUSTOM COMPONENTS
import {
  ScrollView,
  CustomList,
  CustomModal,
  ListItemLoader,
} from "../../../components";
// ICONS
import { AccountBoxIcon, PersonAddIcon } from "../../../icons";
// CUSTOM HOOKS
import { useModal, useUsuarios } from "../../../hooks";
// SCHEMAS
import Schemas from "../../../schemas";
// STORE ACTIONS
import {
  agregarUsuarioStore,
  buscarUsuario,
} from "../../../features/usuarios/usuariosSlice";
// SERVICES
import { crearUsuario } from "../../../services/bola";

const ListaUsuarios = () => {
  const { usuariosState, cargarUsuarios } = useUsuarios();

  useEffect(() => {
    cargarUsuarios();
  }, []);

  return (
    <>
      <Buscador />
      <AgregarUsuario cargandoUsuarios={usuariosState.cargandoUsuarios} />
      <ScrollView vertical maxHeight={515}>
        {usuariosState.cargandoUsuarios && <ListItemLoader />}
        {!usuariosState.cargandoUsuarios && (
          <List sx={{ width: "100%" }}>
            <CustomList
              list={usuariosState.filtrados}
              renderItem={(u) => <UsuarioItem usuario={u} />}
            />
          </List>
        )}
      </ScrollView>
    </>
  );
};

const AgregarUsuario = ({ cargandoUsuarios }) => {
  const { modalConfig, setModalConfig } = useModal();

  const handleOpenModalForm = () => {
    setModalConfig((prevState) => ({
      ...prevState,
      open: true,
      type: "alert",
      alertTitle: "Agregar usuario",
      action: "agregar-usuario",
      contentType: "agregar-usuario",
      showActions: false,
    }));
  };
  const handleModalAccept = () => {
    if (modalConfig.action === "cerrar") {
      handleModalCancel();
    }
  };

  const handleModalCancel = () => {
    setModalConfig((prevState) => ({
      ...prevState,
      open: false,
    }));
  };

  return (
    <>
      <Box sx={{ padding: "0 10px" }}>
        <Button
          disabled={cargandoUsuarios}
          color="primary"
          fullWidth
          variant="contained"
          startIcon={<PersonAddIcon />}
          onClick={handleOpenModalForm}
        >
          Agregar usuario
        </Button>
      </Box>
      {/* MODAL */}
      <CustomModal
        open={modalConfig.open}
        type={modalConfig.type}
        alertTitle={modalConfig.alertTitle}
        progressTitle={modalConfig.progressTitle}
        showCancelBtn={modalConfig.showCancelBtn}
        showConfirmBtn={modalConfig.showConfirmBtn}
        cancelBtnText={modalConfig.cancelBtnText}
        confirmBtnText={modalConfig.confirmBtnText}
        disableConfirmBtn={false}
        onCancel={handleModalCancel}
        onAccept={handleModalAccept}
        showActions={modalConfig.showActions}
      >
        <>
          {modalConfig.contentType === "agregar-usuario" && (
            <AgregarUsuarioForm setModalConfig={setModalConfig} />
          )}
          {modalConfig.contentType === "error" && modalConfig.content}
        </>
      </CustomModal>
    </>
  );
};

const AgregarUsuarioForm = ({ setModalConfig }) => {
  const formik = useFormik({
    initialValues: Schemas.usuario.agregar.values,
    validationSchema: Schemas.usuario.agregar.validation,
    onSubmit: (formData) => {
      agregar(formData);
    },
  });
  const dispatch = useDispatch();

  const cerrarFormulario = () => {
    setModalConfig((prevState) => ({
      ...prevState,
      open: false,
    }));
    formik.handleReset();
  };

  const agregar = async (data) => {
    try {
      setModalConfig((prevState) => ({
        ...prevState,
        type: "progress",
        progressTitle: "Guardando",
      }));
      const nuevoUsuario = await crearUsuario(data);
      // SI SE REGISTRO CORRECTAMENTE
      if (nuevoUsuario) {
        dispatch(agregarUsuarioStore(nuevoUsuario));
        cerrarFormulario();
      }
    } catch ({ message }) {
      setModalConfig((prevState) => ({
        ...prevState,
        type: "alert",
        alertTitle: "Mensaje",
        contentType: "error",
        content: <span>{message}</span>,
        action: "cerrar",
        showCancelBtn: false,
        confirmBtnText: "Entendido",
        showActions: true,
      }));
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container rowSpacing={2} columnSpacing={2}>
        <Grid item xs={12} md={6}>
          <FormInput
            type="text"
            label="Nombre"
            name="nombre"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.errors.nombre}
            touched={formik.touched.nombre}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormInput
            type="text"
            label="Apellidos"
            name="apellidos"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.errors.apellidos}
            touched={formik.touched.apellidos}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormInput
            type="text"
            label="Correo"
            name="correo"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.errors.correo}
            touched={formik.touched.correo}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormInput
            type="text"
            label="Teléfono"
            name="telefono"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.errors.telefono}
            touched={formik.touched.telefono}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormInput
            type="text"
            label="Domicilio"
            name="domicilio"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.errors.domicilio}
            touched={formik.touched.domicilio}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormInput
            type="text"
            label="Nombre Comercial"
            name="nomComercial"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.errors.nomComercial}
            touched={formik.touched.nomComercial}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormInput
            type="text"
            label="Usuario"
            name="usuario"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.errors.usuario}
            touched={formik.touched.usuario}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormInput
            type="number"
            label="Limite venta"
            name="limiteVenta"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.errors.limiteVenta}
            touched={formik.touched.limiteVenta}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormInput
            type="password"
            label="Contraseña"
            name="usuarioPassword"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.errors.usuarioPassword}
            touched={formik.touched.usuarioPassword}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormInput
            type="password"
            label="Confirmar contraseña"
            name="confirmarUsuarioPassword"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.errors.confirmarUsuarioPassword}
            touched={formik.touched.confirmarUsuarioPassword}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider />
          <Typography>
            Por seguridad, te pedimos ingresar tu contraseña para poder
            continuar con el proceso.
          </Typography>
          <FormInput
            type="password"
            label="Contraseña administrador"
            name="passwordAdmin"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.errors.passwordAdmin}
            touched={formik.touched.passwordAdmin}
          />
        </Grid>
        <Grid item xs={12}>
          <Button type="button" onClick={cerrarFormulario}>
            Cerrar
          </Button>
          <Button type="submit" variant="contained" color="primary">
            Enviar
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

const FormInput = ({ label, type, name, onChange, onBlur, error, touched }) => {
  return (
    <>
      <FormControl fullWidth>
        <TextField
          type={type}
          onChange={onChange}
          label={label}
          onBlur={onBlur}
          size="small"
          name={name}
          error={error && touched}
        />
      </FormControl>
      {error && touched && (
        <span style={{ fontSize: 12, color: "red" }}>{error}</span>
      )}
    </>
  );
};

const UsuarioItem = ({ usuario }) => {
  const { usuarioKey } = useParams();
  const navigate = useNavigate();
  const selectedUser = usuarioKey && usuarioKey === usuario.key;

  const handleClick = (item) => {
    navigate(item.key);
  };

  return (
    <ListItem
      sx={{
        background: selectedUser ? "rgba(0,255,0, 0.050)" : "",
        "&:hover": {
          background: selectedUser
            ? "rgba(0,255,0, 0.050)"
            : "rgba(0,0,0,0.075)",
          cursor: "pointer",
        },
      }}
      onClick={() => handleClick(usuario)}
    >
      <ListItemAvatar>
        <Avatar sx={{ background: selectedUser ? "#1ED760" : "" }}>
          <AccountBoxIcon sx={{ color: selectedUser ? "white" : "" }} />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={usuario.nomComercial}
        secondary={usuario.usuario}
      />
      <div
        style={{
          width: 20,
          height: 20,
          borderRadius: 50,
          background: usuario.activo ? "green" : "orange",
        }}
      ></div>
    </ListItem>
  );
};

const Buscador = () => {
  const { usuariosState } = useUsuarios();
  const [searchValue, setSearchValue] = useState("");
  const dispatch = useDispatch();

  const inputChange = (text) => {
    setSearchValue(text);
    dispatch(buscarUsuario(text));
  };

  return (
    <div style={{ padding: 15 }}>
      <FormControl fullWidth>
        <TextField
          type="search"
          disabled={usuariosState.cargandoUsuarios}
          placeholder="Buscar usuario..."
          size="small"
          value={searchValue}
          onChange={({ target }) => inputChange(target.value)}
          // inputProps={{
          //     onKeyDown: (e) => {
          //         onKeyDown(e);
          //     },
          // }}
        />
      </FormControl>
      <div style={{ fontSize: 14, marginTop: 10, color: "gray" }}>
        Usuarios: {usuariosState.totalUsuarios}
      </div>
    </div>
  );
};

export default ListaUsuarios;
