import {
  createContext,
  useCallback,
  useMemo,
  useState,
  useContext,
} from "react";
import Database from "../database";
import { DATABASE_TABLES } from "../constants";
import { Storage } from "../utils";
import { auth, authLogout } from "../services/auth";

export const AuthContext = createContext();

export function AuthContextProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(
    Storage.get("usuario", true) ?? false
  );
  const [verifyingAuthentication, setVerifyingAuthentication] = useState(false);

  const login = useCallback(async function (user, password) {
    try {
      setVerifyingAuthentication(true);
      const { data } = await auth(user, password);
      if (data.error) {
        setIsAuthenticated(false);
        setVerifyingAuthentication(false);
        return false;
      }
      const usuarioDB = await Database.getItem(
        DATABASE_TABLES.AGENCIAS,
        "usuario",
        data.user.username
      );
      // SI NO EXISTE
      if (!usuarioDB) {
        setIsAuthenticated(false);
        setVerifyingAuthentication(false);
        return false;
      }
      // SI SE AUTENTICO CORRECTAMENTE
      Storage.set("usuario", { ...usuarioDB, email: user }, true);
      setIsAuthenticated(true);
      setVerifyingAuthentication(false);
      return true;
    } catch (error) {
      console.log(error);
    }
  }, []);

  const logout = useCallback(async function () {
    try {
      const user = Storage.get("usuario", true);
      await authLogout(user.email);
      // console.log(data);
      Storage.remove("usuario");
      setIsAuthenticated(false);
    } catch ({ message }) {
      throw new Error(message);
    }
  });

  const value = useMemo(
    () => ({
      login,
      logout,
      isAuthenticated,
      verifyingAuthentication,
    }),
    [login, logout, isAuthenticated, verifyingAuthentication]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuthContext() {
  return useContext(AuthContext);
}
