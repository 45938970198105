import {
    Button,
    Divider,
    Grid,
    IconButton,
    ListItem,
    ListItemText,
    Paper,
    Typography,
} from "@mui/material";
import { CustomModal, InputField, Toast } from "../../../components";
import { DeleteIcon } from "../../../icons";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useModal, useToast } from "../../../hooks";
import {
    eliminarRegistros,
    eliminarRegistrosTodos,
    guardarAntiguedad,
} from "../../../services/bola";
import { useDispatch, useSelector } from "react-redux";
import { useLiberarEspacio } from "../../../hooks";
import { useEffect, useState } from "react";
import {
    resetDatos,
    setDatosIndividual,
} from "../../../features/liberarEspacio/liberarEspacioSlice";
import { Moment } from "../../../utils";

export default function ListaRegistros() {
    return (
        <>
            <AntiguedadInput />
            <Lista />
            <EliminarTodoBtn />
        </>
    );
}

const AntiguedadInput = () => {
    const registros = useSelector((state) => state.liberarEspacio);
    const { cargandoDatos, fechaAntiguedad } = registros;
    const formik = useFormik({
        initialValues: {
            antiguedad: "",
        },
        validationSchema: Yup.object().shape({
            antiguedad: Yup.number()
                .typeError("Ingresa un número valido mayor a 0")
                .positive("Ingresa un número positivo")
                .required("Este campo es requerido")
                .min(60, "Escribe un número igual o mayor a 60"),
        }),
        onSubmit: (validatedData) => {
            modal.setConfig({
                open: true,
                type: "alert",
                alertTitle: "Guardar antiguedad",
                confirmBtnText: "Aceptar",
                contentType: "guardar",
            });
        },
    });
    const formik2 = useFormik({
        initialValues: {
            passwordAdmin: "",
        },
        validationSchema: Yup.object().shape({
            passwordAdmin: Yup.string().required("Este campo es requerido"),
        }),
        onSubmit: (validatedData) => {
            if (!modal.config.confirmBtnClicked) {
                guardar();
            }
        },
    });
    const modal = useModal();
    const toast = useToast();
    const { cargarDatosLiberarEspacio } = useLiberarEspacio();

    useEffect(() => {
        if (!cargandoDatos) {
            formik.setFieldValue("antiguedad", registros.antiguedad);
        }
    }, [cargandoDatos]);

    const handleGuardar = () => {
        formik.handleSubmit();
    };

    const handleModalCancel = () => {
        modal.setConfig({ open: false });
        formik2.handleReset();
    };

    const handleModalAccept = () => {
        if (modal.config.contentType === "guardar") {
            formik2.handleSubmit();
        }
    };

    const guardar = async () => {
        try {
            modal.setConfig({
                type: "progress",
                progressTitle: "Guardando",
                confirmBtnClicked: true,
            });
            const antiguedadGuardada = await guardarAntiguedad(
                formik2.values.passwordAdmin,
                formik.values.antiguedad
            );
            // SI SE GUARDO
            if (antiguedadGuardada) {
                formik2.handleReset();
                modal.setConfig({ open: false, confirmBtnClicked: false });
                toast.setConfig({
                    open: true,
                    message: "Antiguedad establecida correctamente",
                });
                cargarDatosLiberarEspacio();
            }
        } catch ({ message }) {
            modal.setConfig({
                type: "alert",
                alertTitle: "Mensaje",
                contentType: "error",
                error: <span>{message}</span>,
                confirmBtnText: "Entendido",
                showCancelBtn: false,
                confirmBtnClicked: false,
            });
        }
    };

    return (
        <>
            <Paper sx={{ padding: 1.5 }}>
                <Grid container rowSpacing={1} columnSpacing={1}>
                    <Grid item xs={12}>
                        <Typography sx={{ marginBottom: 1.5 }}>
                            Elige la antiguedad de los registros con la cúal
                            serán eliminados.
                        </Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <div style={styles.antiguedadCol}>
                            <InputField
                                marginTop={0}
                                label="Antiguedad"
                                name="antiguedad"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.antiguedad}
                                error={formik.errors.antiguedad}
                                touched={formik.touched.antiguedad}
                                disabled={cargandoDatos}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={7}>
                        <div style={styles.antiguedadCol}>
                            <Typography>dias</Typography>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        {fechaAntiguedad
                            ? Moment(fechaAntiguedad)
                                  .subtract(formik.values.antiguedad)
                                  .format("llll")
                            : ""}
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            fullWidth
                            variant="contained"
                            sx={{ marginTop: 1.5 }}
                            onClick={handleGuardar}
                            disabled={cargandoDatos}
                        >
                            Guardar
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
            <CustomModal
                open={modal.config.open}
                type={modal.config.type}
                alertTitle={modal.config.alertTitle}
                progressTitle={modal.config.progressTitle}
                showCancelBtn={modal.config.showCancelBtn}
                confirmBtnText={modal.config.confirmBtnText}
                onCancel={handleModalCancel}
                onAccept={handleModalAccept}
            >
                {modal.config.contentType === "guardar" && (
                    <>
                        <Typography>
                            La antiguedad definida es de{" "}
                            {formik.values.antiguedad} dias, deseas continuar?
                        </Typography>
                        <Divider sx={{ marginY: 2 }} />
                        <Typography>
                            Por seguridad te pedimos ingreses tu contraseña para
                            poder continuar con el proceso de eliminación.
                        </Typography>
                        <InputField
                            type="password"
                            label="Contraseña administrador"
                            name="passwordAdmin"
                            value={formik2.values.passwordAdmin}
                            error={formik2.errors.passwordAdmin}
                            touched={formik2.touched.passwordAdmin}
                            onChange={formik2.handleChange}
                            onBlur={formik2.handleBlur}
                        />
                    </>
                )}
                {modal.config.contentType === "error" && modal.config.error}
            </CustomModal>
            <Toast
                open={toast.config.open}
                message={toast.config.message}
                handleClose={toast.handleClose}
            />
        </>
    );
};

const Lista = () => {
    const registros = useSelector((state) => state.liberarEspacio);
    const { cargandoDatos } = registros;
    const [nombreRegistros, setNombreRegistros] = useState("");
    const formik = useFormik({
        initialValues: {
            passwordAdmin: "",
        },
        validationSchema: Yup.object().shape({
            passwordAdmin: Yup.string().required("Este campo es requerido"),
        }),
        onSubmit: (validatedData) => {
            if (!modal.config.confirmBtnClicked) {
                eliminar();
            }
        },
    });
    const modal = useModal();
    const toast = useToast();
    const dispatch = useDispatch();

    const handleDelete = (registroName, numRegistros) => {
        const hayRegistros = numRegistros > 0;
        setNombreRegistros(registroName);
        modal.setConfig({
            open: true,
            type: "alert",
            alertTitle: hayRegistros ? "Eliminar registros" : "Mensaje",
            contentType: hayRegistros ? "eliminar" : "error",
            confirmBtnText: hayRegistros ? "aceptar" : "entendido",
            showCancelBtn: hayRegistros,
            error: hayRegistros ? "" : <span>Sin registros para eliminar</span>,
        });
    };

    const handleModalCancel = () => {
        modal.setConfig({ open: false });
        formik.handleReset();
    };

    const handleModalAccept = () => {
        if (modal.config.contentType === "eliminar") {
            formik.handleSubmit();
        }
        if (modal.config.contentType === "error") {
            handleModalCancel();
        }
    };

    const eliminar = async () => {
        try {
            modal.setConfig({
                type: "progress",
                progressTitle: "Eliminando",
                confirmBtnClicked: true,
            });
            const registrosEliminados = await eliminarRegistros(
                formik.values.passwordAdmin,
                nombreRegistros
            );
            // SI SE ELIMINARON
            if (registrosEliminados) {
                modal.setConfig({ open: false, confirmBtnClicked: false });
                toast.setConfig({
                    open: true,
                    message: "Registros eliminados",
                });
                dispatch(setDatosIndividual(nombreRegistros));
                formik.handleReset();
            }
        } catch ({ message }) {
            modal.setConfig({
                type: "alert",
                alertTitle: "Mensaje",
                contentType: "error",
                error: <span>{message}</span>,
                confirmBtnText: "Entendido",
                showCancelBtn: false,
                confirmBtnClicked: false,
            });
        }
    };

    return (
        <>
            <Paper sx={{ marginY: 2 }}>
                <ListaItem
                    label="Sorteos"
                    value={
                        cargandoDatos
                            ? "cargando..."
                            : "Registros: " + registros.sorteos
                    }
                    onClick={() => handleDelete("sorteos", registros.sorteos)}
                    disabled={cargandoDatos}
                />
                <ListaItem
                    label="Boletos"
                    value={
                        cargandoDatos
                            ? "cargando..."
                            : "Registros: " + registros.boletos
                    }
                    onClick={() => handleDelete("boletos", registros.boletos)}
                    disabled={cargandoDatos}
                />
                <ListaItem
                    label="Cancelados"
                    value={
                        cargandoDatos
                            ? "cargando..."
                            : "Registros: " + registros.cancelados
                    }
                    onClick={() =>
                        handleDelete("cancelados", registros.cancelados)
                    }
                    disabled={cargandoDatos}
                />
                <ListaItem
                    label="Premios Pagados"
                    value={
                        cargandoDatos
                            ? "cargando..."
                            : "Registros: " + registros.premiosPagados
                    }
                    onClick={() =>
                        handleDelete(
                            "premios-pagados",
                            registros.premiosPagados
                        )
                    }
                    disabled={cargandoDatos}
                />
                <ListaItem
                    label="Abonos"
                    value={
                        cargandoDatos
                            ? "cargando..."
                            : "Registros: " + registros.abonos
                    }
                    onClick={() => handleDelete("abonos", registros.abonos)}
                    disabled={cargandoDatos}
                />
                <ListaItem
                    label="Números Ganadores"
                    value={
                        cargandoDatos
                            ? "cargando..."
                            : "Registros: " + registros.numerosGanadores
                    }
                    onClick={() =>
                        handleDelete(
                            "numeros-ganadores",
                            registros.numerosGanadores
                        )
                    }
                    disabled={cargandoDatos}
                />
            </Paper>
            <CustomModal
                open={modal.config.open}
                type={modal.config.type}
                alertTitle={modal.config.alertTitle}
                progressTitle={modal.config.progressTitle}
                showCancelBtn={modal.config.showCancelBtn}
                confirmBtnText={modal.config.confirmBtnText}
                onCancel={handleModalCancel}
                onAccept={handleModalAccept}
            >
                {modal.config.contentType === "eliminar" && (
                    <>
                        <Typography>
                            Se eliminarán los registros de más de{" "}
                            {registros.antiguedad} dias de antiguedad, deseas
                            continuar?
                        </Typography>
                        <Divider sx={{ marginY: 2 }} />
                        <Typography>
                            Por seguridad te pedimos ingreses tu contraseña para
                            poder continuar con el proceso de eliminación.
                        </Typography>
                        <InputField
                            type="password"
                            label="Contraseña administrador"
                            name="passwordAdmin"
                            value={formik.values.passwordAdmin}
                            error={formik.errors.passwordAdmin}
                            touched={formik.touched.passwordAdmin}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                    </>
                )}
                {modal.config.contentType === "error" && modal.config.error}
            </CustomModal>
            <Toast
                open={toast.config.open}
                message={toast.config.message}
                handleClose={toast.handleClose}
            />
        </>
    );
};

const ListaItem = ({ label, value, onClick, disabled }) => {
    return (
        <ListItem
            secondaryAction={
                <IconButton onClick={onClick} disabled={disabled}>
                    <DeleteIcon />
                </IconButton>
            }
        >
            <ListItemText primary={label} secondary={value} />
        </ListItem>
    );
};

const EliminarTodoBtn = () => {
    const registros = useSelector((state) => state.liberarEspacio);
    const {
        cargandoDatos,
        sorteos,
        boletos,
        cancelados,
        premiosPagados,
        abonos,
        numerosGanadores,
    } = registros;
    const total =
        sorteos +
        boletos +
        cancelados +
        premiosPagados +
        abonos +
        numerosGanadores;
    const formik = useFormik({
        initialValues: {
            passwordAdmin: "",
        },
        validationSchema: Yup.object().shape({
            passwordAdmin: Yup.string().required("Este campo es requerido"),
        }),
        onSubmit: (validatedData) => {
            if (!modal.config.confirmBtnClicked) {
                eliminar();
            }
        },
    });
    const modal = useModal();
    const toast = useToast();
    const dispatch = useDispatch();

    const handleDelete = () => {
        // SI NO HAY REGISTROS PARA ELIMINAR
        const hayRegistros = total > 0;
        modal.setConfig({
            open: true,
            type: "alert",
            alertTitle: hayRegistros ? "Eliminar registros" : "Mensaje",
            contentType: hayRegistros ? "eliminar" : "error",
            error: hayRegistros ? "" : <span>Sin registros para eliminar</span>,
            confirmBtnText: hayRegistros ? "aceptar" : "Entendido",
            showCancelBtn: hayRegistros,
        });
    };

    const handleModalCancel = () => {
        modal.setConfig({ open: false });
        formik.handleReset();
    };

    const handleModalAccept = () => {
        if (modal.config.contentType === "eliminar") {
            formik.handleSubmit();
        }
        if (modal.config.contentType === "error") {
            handleModalCancel();
        }
    };

    const eliminar = async () => {
        try {
            const _registros = [
                { nombre: "sorteos", texto: "sorteos" },
                { nombre: "boletos", texto: "boletos" },
                { nombre: "cancelados", texto: "cancelados" },
                { nombre: "abonos", texto: "abonos" },
                { nombre: "premios-pagados", texto: "premios pagados" },
                { nombre: "numeros-ganadores", texto: "numeros ganadores" },
            ];
            let deleted_counter = 0;
            modal.setConfig({
                type: "progress",
                progressTitle: "Eliminando",
                confirmBtnClicked: true,
            });
            // ELIMINAR CADA REGISTRO
            for (let i = 0; i < _registros.length; i++) {
                const _registro = _registros[i];
                modal.setConfig({
                    progressTitle: "Eliminando " + _registro.texto,
                });
                const registrosEliminados = await eliminarRegistros(
                    formik.values.passwordAdmin,
                    _registro.nombre
                );
                // SI SE ELIMINARON
                if (registrosEliminados) {
                    deleted_counter++;
                    dispatch(setDatosIndividual(_registro.nombre));
                }
                // SI SE ELIMINARON TODOS
                if (deleted_counter === _registros.length) {
                    modal.setConfig({
                        open: false,
                        confirmBtnClicked: false,
                    });
                    toast.setConfig({
                        open: true,
                        message: "Registros eliminados",
                    });
                    formik.handleReset();
                }
            }
        } catch ({ message }) {
            modal.setConfig({
                type: "alert",
                alertTitle: "Mensaje",
                contentType: "error",
                error: <span>{message}</span>,
                confirmBtnText: "Entendido",
                showCancelBtn: false,
                confirmBtnClicked: false,
            });
        }
    };

    return (
        <>
            <Paper>
                <Button
                    color="error"
                    fullWidth
                    onClick={handleDelete}
                    disabled={cargandoDatos}
                >
                    Eliminar todo
                </Button>
            </Paper>
            <CustomModal
                open={modal.config.open}
                type={modal.config.type}
                alertTitle={modal.config.alertTitle}
                progressTitle={modal.config.progressTitle}
                showCancelBtn={modal.config.showCancelBtn}
                confirmBtnText={modal.config.confirmBtnText}
                onCancel={handleModalCancel}
                onAccept={handleModalAccept}
            >
                {modal.config.contentType === "eliminar" && (
                    <>
                        <Typography>
                            Se eliminará un total de {total} registros de más de{" "}
                            {registros.antiguedad} dias de antiguedad, deseas
                            continuar?
                        </Typography>
                        <Divider sx={{ marginY: 2 }} />
                        <Typography>
                            Por seguridad te pedimos ingreses tu contraseña para
                            poder continuar con el proceso de eliminación.
                        </Typography>
                        <InputField
                            type="password"
                            label="Contraseña administrador"
                            name="passwordAdmin"
                            value={formik.values.passwordAdmin}
                            error={formik.errors.passwordAdmin}
                            touched={formik.touched.passwordAdmin}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                    </>
                )}
                {modal.config.contentType === "error" && modal.config.error}
            </CustomModal>
            <Toast
                open={toast.config.open}
                message={toast.config.message}
                handleClose={toast.handleClose}
            />
        </>
    );
};

const styles = {
    antiguedadCol: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        height: "100%",
    },
};
