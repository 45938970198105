import { useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setFiltros } from "../../../features/boletos/boletosSlice";

const RadioGroupFiltros = () => {
  const [values, setValues] = useState({
    cifras: "tres",
    orden: "asc",
    venta: "todos",
  });

  const { cargando } = useSelector((state) => state.boletos);
  const dispatch = useDispatch();

  const handleRadioChange = (tipoFiltro) => (e) => onChange(e, tipoFiltro);

  const onChange = ({ target }, tipoFiltro) => {
    const { value } = target;
    setValues((prevState) => ({
      ...prevState,
      [tipoFiltro]: value,
    }));
    setTimeout(() => {
      dispatch(setFiltros({ tipoFiltro, value }));
    }, 100);
  };

  return (
    <Card>
      <CardHeader
        subheader={<Typography sx={{ fontSize: 16 }}>Filtros</Typography>}
      />
      <CardContent>
        <RadioGroupOrden
          value={values.orden}
          onChange={handleRadioChange("orden")}
          cargando={cargando}
        />
        <RadioGroupVentas
          value={values.venta}
          onChange={handleRadioChange("venta")}
          cargando={cargando}
        />
        <RadioGroupCifras
          value={values.cifras}
          onChange={handleRadioChange("cifras")}
          cargando={cargando}
        />
      </CardContent>
    </Card>
  );
};

const RadioGroupOrden = ({ cargando, value, onChange }) => {
  return (
    <FormControl fullWidth>
      <FormLabel sx={styles.formlabel}>Ordenar Números</FormLabel>
      <RadioGroup value={value} name="radio-group-orden" onChange={onChange}>
        <FormControlLabel
          value="asc"
          control={<Radio size="small" disabled={cargando} />}
          label="Menor a Mayor"
        />
        <FormControlLabel
          value="desc"
          control={<Radio size="small" disabled={cargando} />}
          label="Mayor a Menor"
        />
      </RadioGroup>
    </FormControl>
  );
};

const RadioGroupVentas = ({ cargando, value, onChange }) => {
  return (
    <FormControl fullWidth>
      <FormLabel sx={styles.formlabel} id="radio-group-venta">
        Por Venta
      </FormLabel>
      <RadioGroup
        aria-labelledby="radio-group-venta"
        value={value}
        name="radio-group-venta"
        onChange={onChange}
      >
        <FormControlLabel
          value="todos"
          control={<Radio size="small" disabled={cargando} />}
          label="Todos"
        />
        <FormControlLabel
          value="menor-50"
          control={<Radio size="small" disabled={cargando} />}
          label="Menor a $50"
        />
        <FormControlLabel
          value="mayor-50-menor-100"
          control={<Radio size="small" disabled={cargando} />}
          label="Mayor a $50 Menor a $100"
        />
        <FormControlLabel
          value="mayor-100-menor-500"
          control={<Radio size="small" disabled={cargando} />}
          label="Mayor a $100 Menor a $500"
        />
        <FormControlLabel
          value="sin-vender"
          control={<Radio size="small" disabled={cargando} />}
          label="Sin Vender"
        />
      </RadioGroup>
    </FormControl>
  );
};

const RadioGroupCifras = ({ cargando, value, onChange }) => {
  return (
    <FormControl fullWidth>
      <FormLabel sx={styles.formlabel} id="radio-group-cifras">
        Por Cifras
      </FormLabel>
      <RadioGroup
        aria-labelledby="radio-group-cifras"
        value={value}
        name="radio-group-cifras"
        onChange={onChange}
      >
        <FormControlLabel
          value="tres"
          control={<Radio size="small" disabled={cargando} />}
          label="3 Cifras"
        />
        <FormControlLabel
          value="dos"
          control={<Radio size="small" disabled={cargando} />}
          label="2 Cifras"
        />
        <FormControlLabel
          value="una"
          control={<Radio size="small" disabled={cargando} />}
          label="1 Cifra"
        />
      </RadioGroup>
    </FormControl>
  );
};

const styles = {
  formlabel: { color: "#fff", fontWeight: "bold", marginY: 1 },
};

export default RadioGroupFiltros;
