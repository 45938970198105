import { useEffect, useState } from "react";
import { useCustomParams } from "../../../hooks";
import { obtenerReporteTransacciones } from "../../../services/reportes";
import InformeIndividualLayout from "./InformeIndividualLayout";
import InformeGeneralLayout from "./InformeGeneralLayout";
import TablaGeneral from "./TablaGeneral";
import RecargasServiciosPDF from "./RecargasServiciosPDF";
import { useSelector } from "react-redux";
import ViewPDF from "../../../components/ViewPDF";
const tableHeadData =
  "USUARIO.LUNES.MARTES.MIERCOLES.JUEVES.VIERNES.SABADO.DOMINGO.TOTAL".split(
    "."
  );

export default function RecargasServicios() {
  const customParams = useCustomParams();
  const [paramsError, setParamsError] = useState(false);
  const [cargando, setCargando] = useState(true);
  const [periodo, setPeriodo] = useState("");
  const [reporte, setReporte] = useState(null);
  const { showPDFButton } = useSelector((state) => state.reportes);
  const params = {
    range: customParams.get("range"),
  };

  useEffect(() => {
    verififyParams();
  }, [params.range]);

  const verififyParams = async () => {
    try {
      setCargando(true);
      if (!params.range) {
        setParamsError(true);
        setCargando(false);
        return;
      }
      const res = await obtenerReporteTransacciones(params.range);
      setPeriodo(res.periodo);
      setReporte(res);
      setCargando(false);
    } catch ({ message }) {
      console.error(message);
      setCargando(false);
      setParamsError(true);
    }
  };

  if (paramsError) return <div>Error al cargar los parametros</div>;

  return (
    <>
      {cargando && <div>cargando</div>}
      {!cargando && (
        <>
          <InformeGeneralLayout
            title="Estado semanal"
            subtitle={periodo ? periodo.toUpperCase() : ""}
            action={
              <>
                {showPDFButton && (
                  <ViewPDF>
                    <RecargasServiciosPDF reporte={reporte} />
                  </ViewPDF>
                )}
              </>
            }
          >
            <TablaGeneral reporte={reporte} />
          </InformeGeneralLayout>
          <InformeIndividualLayout
            title="Recargas y Paquetes"
            tableHeadData={tableHeadData}
            tableData={reporte.ventaUsuarios.recargas}
          />
          <InformeIndividualLayout
            title="Servicios y Giftcards"
            tableHeadData={tableHeadData}
            tableData={reporte.ventaUsuarios.servicios}
          />
        </>
      )}
    </>
  );
}
