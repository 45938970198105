import React, { useEffect } from "react";
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Typography,
} from "@mui/material";
import CustomTable from "../../../../components/CustomTable";
import { useDispatch, useSelector } from "react-redux";
import { Money } from "../../../../utils";
import {
  setCargandoSorteosSemana,
  setSorteosSemana,
} from "../../../../features/administracion/administracionSlice";
import Database from "../../../../database";
import { DATABASE_TABLES } from "../../../../constants";
import StatusCircle from "../../components/StatusCircle";
import RangoSelector from "../../components/RangoSelector";

export default function TablaVentaPorSorteo() {
  const { sorteosSemana, cargandoSorteosSemana, periodoSeleccionado } =
    useSelector((state) => state.administracion);
  const dispatch = useDispatch();

  useEffect(() => {
    if (periodoSeleccionado) {
      cargarSorteosSemana();
    }
  }, [periodoSeleccionado]);

  const cargarSorteosSemana = async () => {
    try {
      dispatch(setCargandoSorteosSemana(true));
      const _sorteosSemana = await Database.getItemsInRange(
        DATABASE_TABLES.SORTEOS,
        "fecha",
        periodoSeleccionado.inicial,
        periodoSeleccionado.final
      );
      let sorteos = [];
      for (let i = 0; i < _sorteosSemana.length; i++) {
        const item = _sorteosSemana[i];
        const totalVendido = await obtenerTotalVentaSorteo(item);
        const _sorteo = {
          sorteo: item.sorteo,
          fecha: item.fecha,
          venta: totalVendido,
        };
        sorteos.push(_sorteo);
      }
      dispatch(setSorteosSemana(sorteos));
      dispatch(setCargandoSorteosSemana(false));
    } catch ({ message }) {
      alert(message);
    }
  };

  const obtenerTotalVentaSorteo = async (sorteo) => {
    try {
      const boletosSorteo = await Database.getItemsByProp(
        DATABASE_TABLES.BOLETOS,
        "fechaSorteo",
        sorteo.fecha
      );
      let total = 0;
      boletosSorteo.forEach((boleto) => {
        total += parseInt(boleto.totalApostado);
      });
      return total;
    } catch ({ message }) {
      alert(message);
    }
  };

  return (
    <Card>
      <CardHeader
        title={<Header />}
        subheader={
          <StatusCircle
            text={`Total(${sorteosSemana.length}): ${Money(
              sorteosSemana.reduce((acc, el) => acc + parseInt(el.venta), 0)
            )}`}
          />
        }
      />
      <CardContent>
        <>
          {cargandoSorteosSemana && <div>cargando tabla</div>}
          {!cargandoSorteosSemana && (
            <CustomTable
              stickyHeader
              scrollBoth
              maxHeight={180}
              headerData={["#", "Sorteo", "Fecha", "Venta"]}
              tableData={sorteosSemana}
              dataProps={["#", "sorteo", "fecha", "$:venta"]}
            />
          )}
        </>
      </CardContent>
    </Card>
  );
}

function Header() {
  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div>Sorteos celebrados</div>
      <RangoSelector />
    </div>
  );
}
