import MAIN_MENU_ITEMS from "./MainMenuItems";
import DATABASE_TABLES from "./DatabaseTables";
import USUARIO_FORM_FIELDS from "./UsuarioFormFields";
import Colors from "./Colors";
import COMISIONES from "./Comisiones";
import CATEGORIAS_TRANSACCIONES from "./CategoriasTransacciones";
// DESARROLLO
// const BASE_URL = "http://127.0.0.1:8000/";
// const API_URL = BASE_URL + "api/";
// const LOGOS_URL = BASE_URL + "storage/logos/";
// PRODUCCION
const BASE_URL = "https://rym-api.recargasymas.com.mx/";
const API_URL = BASE_URL + "api/";
const LOGOS_URL = BASE_URL + "storage/logos/";
// EVENTS FOR AUTOLOGOUT FEATURE
const WINDOW_EVENTS = [
  "load",
  "mousemove",
  "mousedown",
  "click",
  "scroll",
  "keypress",
  "touchstart",
  "touchmove",
  "touchend",
];

export {
  MAIN_MENU_ITEMS,
  DATABASE_TABLES,
  API_URL,
  LOGOS_URL,
  USUARIO_FORM_FIELDS,
  Colors,
  COMISIONES,
  CATEGORIAS_TRANSACCIONES,
  WINDOW_EVENTS,
};
