import React from "react";
import { Outlet, useParams } from "react-router-dom";
import { Grid, Paper } from "@mui/material";
import ListaUsuarios from "./components/ListaUsuarios";
import PROFILE_IMG from "../../assets/profile.png";
import SectionTitle from "../../components/SectionTitle";

export default function Usuarios() {
  const { usuarioKey } = useParams();

  return (
    <>
      <Grid container rowSpacing={2} columnSpacing={2}>
        <Grid item xs={12}>
          <SectionTitle title="Usuarios" />
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper>
            <ListaUsuarios />
          </Paper>
        </Grid>
        <Grid item xs={12} md={8}>
          {!usuarioKey && <UsuarioPlaceholder />}
          <Outlet />
        </Grid>
      </Grid>
    </>
  );
}

const UsuarioPlaceholder = () => {
  return (
    <div
      style={{
        width: "100%",
        minHeight: 400,
        // background: "#fff",
        paddingBottom: 15,
        textAlign: "center",
      }}
    >
      <img
        style={{ width: "70%", maxWidth: "100%" }}
        src={PROFILE_IMG}
        alt="perfil"
      />
      <div style={{ textAlign: "center" }}>
        <h1 style={{ margin: 0 }}>Perfil de Usuario</h1>
        <p style={{ margin: 0 }}>
          Aqui se mostrará la información del usuario seleccionado
        </p>
      </div>
    </div>
  );
};
