// REDUX
import { useDispatch, useSelector } from "react-redux";
// MUI COMPONENTS
import { Card, CardContent, CardHeader, Typography } from "@mui/material";
// CUSTOM COMPONENTS
import { ScrollView } from "../../../components";
import ListaSorteosPendientesLoader from "./ListaSorteosPendientesLoader";
// UTILS
import { Helpers, Moment } from "../../../utils";
// CONSTANTS
import { Colors, DATABASE_TABLES } from "../../../constants";
import Database from "../../../database";
// ACTIONS
import {
  setCargandoSorteosPendientes,
  setSorteosPendientes,
} from "../../../features/sorteos/sorteosSlice";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function ListaSorteosPendientes() {
  const { cargandoSorteos, cargandoSorteosPendientes, todos } = useSelector(
    (state) => state.sorteos
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (!cargandoSorteos) {
      obtenerPendientes();
    }
  }, [cargandoSorteos]);

  useEffect(() => {
    obtenerPendientes();
  }, [todos]);

  const obtenerPendientes = async () => {
    try {
      const timestamp = await Database.getServerDate();
      const horaCierre = await Database.getObject(DATABASE_TABLES.HORA_CIERRE);
      const momentHoraCierre = Moment(horaCierre.hora, "HH:mm");
      const hoy = {
        hora: Moment(timestamp).format("HH:mm"),
        fecha: Moment(timestamp).format("YYYY-MM-DD"),
      };
      let _sorteosPendientes = [];
      // OBTENER LOS SORTEOS PENDIENTES
      todos.forEach((s) => {
        //   SI LA FECHA ES LA MISMA O POSTERIOR
        if (Moment(s.fecha).isSameOrAfter(hoy.fecha)) {
          _sorteosPendientes.push({ ...s });
        }
      });
      // ELIMINAR SI YA CERRO EL SORTEO ACTUAL
      _sorteosPendientes.forEach((s, index) => {
        if (
          Moment(s.fecha).isSame(hoy.fecha) &&
          Moment(hoy.hora, "HH:mm").isSameOrAfter(momentHoraCierre)
        ) {
          _sorteosPendientes.splice(index, 1);
        }
      });
      dispatch(
        setSorteosPendientes(
          Helpers.ordenarLista(_sorteosPendientes, "fecha", "asc")
        )
      );
      dispatch(setCargandoSorteosPendientes(false));
    } catch ({ message }) {
      console.log(message);
    }
  };

  return (
    <Card>
      <CardHeader subheader={<Typography>Sorteos Por Celebrar</Typography>} />
      <CardContent>
        {cargandoSorteosPendientes && <ListaSorteosPendientesLoader />}
        {!cargandoSorteosPendientes && <ListaSorteos />}
      </CardContent>
    </Card>
  );
}

const ListaSorteos = () => {
  const { pendientes } = useSelector((state) => state.sorteos);

  return (
    <ScrollView horizontal>
      <div
        style={{
          whiteSpace: "nowrap",
        }}
      >
        {pendientes.map((s) => (
          <Sorteo key={s.id} sorteo={s} />
        ))}
      </div>
    </ScrollView>
  );
};

const Sorteo = ({ sorteo }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(sorteo.key);
  };

  return (
    <div style={styles.sorteoPendienteBox}>
      <div style={styles.sorteoPendiente} onClick={handleClick}>
        <div style={styles.sorteoPendienteImgBox}>
          <img
            style={styles.sorteoPendienteImg}
            src={Helpers.setImgSrc(sorteo.codigoSorteo)}
            alt={sorteo.sorteo}
          />
        </div>
        <p style={styles.sorteoPendienteText}>
          {Moment(sorteo.fecha).format("ddd DD MMM")}
        </p>
      </div>
    </div>
  );
};

const styles = {
  sorteoPendienteBox: {
    display: "inline-block",
  },
  sorteoPendiente: {
    width: "100px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginRight: "10px",
    cursor: "pointer",
  },
  sorteoPendienteImgBox: {
    width: "90px",
    height: "90px",
    borderRadius: "50%",
    border: `2px solid ${Colors.smoke}`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
  },
  sorteoPendienteImg: {
    maxWidth: "100%",
  },
  sorteoPendienteText: {
    margin: 0,
    marginTop: "5px",
    color: Colors.smoke,
    fontWeight: "700",
    fontSize: "14px",
  },
};
