import { useState } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  FormControl,
  TextField,
  Button,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import AccountCircle from "@mui/icons-material/AccountCircle";
import LockIcon from "@mui/icons-material/Lock";
import CustomAvatar from "../../components/CustomAvatar";
import { useAuthContext } from "../../context/authContext";
import { Colors } from "../../constants";
const LOGO = require("../../assets/logo.png");

export default function Login() {
  const { login } = useAuthContext();
  const [inputs, setInputs] = useState({
    user: "",
    password: "",
  });
  const [accederBtnClicked, setAccederBtnClicked] = useState(false);
  const [accediendo, setAccediendo] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [buttonText, setButtonText] = useState("Acceder");

  const handleInputChange = (target, inputName) => {
    setInputs((prevState) => ({
      ...prevState,
      [inputName]: target.value,
    }));
  };

  const handleAcceder = async () => {
    try {
      if (
        inputs.user.trim().length > 0 &&
        inputs.password.trim().length > 0 &&
        !accederBtnClicked
      ) {
        setAccederBtnClicked(true);
        setButtonText("Verificando credenciales...");
        setAccediendo(true);
        setLoginError(false);
        const isUserLoggedIN = await login(inputs.user, inputs.password);
        setAccediendo(false);
        // SI LAS CREDENCIALES SON INCORRECTAS
        if (!isUserLoggedIN) {
          setAccederBtnClicked(false);
          setLoginError(true);
          setButtonText("Acceder");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const _disableBtn = () => {
    if (
      inputs.user.trim().length === 0 ||
      inputs.password.trim().length === 0 ||
      accediendo
    ) {
      return true;
    }
    return false;
  };

  const disableBtn = _disableBtn();

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        background: Colors.dark,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card sx={{ width: "90%", maxWidth: 350 }}>
        <CardHeader
          avatar={
            <CustomAvatar border={false} borderRadius={false} src={LOGO} />
          }
          title={<Typography sx={{ fontSize: 24 }}>Inicia sesión</Typography>}
        />
        <CardContent>
          <FormControl fullWidth>
            <TextField
              label="Usuario"
              type="email"
              value={inputs.user}
              onChange={({ target }) => handleInputChange(target, "user")}
              InputProps={{
                autoComplete: "off",
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle />
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
          <FormControl fullWidth style={{ marginTop: 20 }}>
            <TextField
              label="Contraseña"
              value={inputs.password}
              type="password"
              onChange={({ target }) => handleInputChange(target, "password")}
              InputProps={{
                autoComplete: "off",
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon />
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
          {loginError && <LoginError />}
        </CardContent>
        <CardActions sx={{ justifyContent: "flex-end" }}>
          <Button
            disabled={disableBtn}
            variant="contained"
            color="primary"
            onClick={handleAcceder}
          >
            {buttonText}
          </Button>
        </CardActions>
      </Card>
    </div>
  );
}

const LoginError = () => {
  return (
    <div style={{ margin: "10px 0" }}>
      <Typography sx={{ fontStyle: "italic", color: "tomato" }}>
        Credenciales incorrectas.
      </Typography>
    </div>
  );
};
