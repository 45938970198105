import React, { useEffect, useState } from "react";
import { IconButton, TableCell, TableRow, Typography } from "@mui/material";
import Tabla from "../../../components/Tabla";
import { DeleteIcon } from "../../../icons";
import ProtectedActionModal from "../../../components/ProtectedActionModal";
import { useModal } from "../../../hooks";
import {
  eliminarDispositivo,
  obtenerDispositivosRegistrados,
} from "../../../services/bola";
import { useDispatch, useSelector } from "react-redux";
import { setDispositivos } from "../../../features/usuarios/usuariosSlice";

export default function DispositivosTable() {
  const { selectedUsuario, dispositivos } = useSelector(
    (state) => state.usuarios
  );
  const dispatch = useDispatch();

  useEffect(() => {
    cargarDispositivosRegistrados();
  }, []);

  const cargarDispositivosRegistrados = async () => {
    try {
      const devices = await obtenerDispositivosRegistrados(selectedUsuario.id);
      dispatch(setDispositivos(devices));
    } catch ({ message }) {
      alert(message);
    }
  };

  return (
    <div style={{ marginTop: 15, paddingLeft: 15, paddingRight: 15 }}>
      <Typography style={{ marginBottom: 15 }}>
        {dispositivos.length === 0
          ? "No se encontraron dispsitivos registrados"
          : "Dispositivos registrados"}
      </Typography>
      {dispositivos.length > 0 && (
        <Tabla
          headData={["Nombre", "Sistema", "Version", "Registrado", "Accion"]}
          data={dispositivos}
          renderItem={(item) => <FilaTabla device={item} />}
        />
      )}
    </div>
  );
}

function FilaTabla({ device }) {
  const { dispositivos } = useSelector((state) => state.usuarios);
  const dispatch = useDispatch();
  const modal = useModal();

  const handleClick = () => {
    modal.setConfig({
      open: true,
      type: "alert",
      alertTitle: "Mensaje",
      contentType: "content",
      showCancelBtn: true,
      confirmBtnText: "aceptar",
    });
  };

  const handleAccept = async (password) => {
    try {
      modal.setConfig({
        type: "progress",
        progressTitle: "Eliminando dispositivo",
      });
      await eliminarDispositivo(password, device);
      modal.setConfig({ open: false });
      dispatch(
        setDispositivos(
          [...dispositivos].filter((item) => item.key !== device.key)
        )
      );
    } catch ({ message }) {
      modal.setConfig({
        open: true,
        type: "alert",
        alertTitle: "Mensaje",
        contentType: "error",
        error: <Typography>{message}</Typography>,
        showCancelBtn: false,
        confirmBtnText: "entendido",
      });
    }
  };

  return (
    <>
      <TableRow>
        <TableCell>{device.name}</TableCell>
        <TableCell>{device.systemName}</TableCell>
        <TableCell>{device.systemVersion}</TableCell>
        <TableCell>{device.created}</TableCell>
        <TableCell>
          <IconButton size="small" onClick={handleClick}>
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      <ProtectedActionModal modal={modal} onAccept={handleAccept} />
    </>
  );
}
