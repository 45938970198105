import axios from "axios";
import Database from "../../database";
import { authenticate } from "../auth";
import { API_URL, DATABASE_TABLES } from "../../constants";
import { Helpers, Moment, Storage, uuid, Bcrypt, Money } from "../../utils";

export const guardarSorteo = async (sorteo, fecha, password) => {
  try {
    // VERIFICAR AUTENTICACION
    await userAuthentication(password);
    // VERIFICAMOS LA FECHA
    const fechaSorteo = Moment(fecha);
    const item = await Database.getItem(
      DATABASE_TABLES.SORTEOS,
      "fecha",
      fechaSorteo.format("YYYY-MM-DD")
    );
    // SI LA FECHA EXISTE
    if (item)
      throw new Error(
        "Ya existe un sorteo para el " +
          fechaSorteo.format("llll") +
          ". Intenta con otra fecha."
      );
    // GUARDAR SORTEO
    const timestamp = await Database.getServerDate();
    const sorteoID = uuid();
    const concurso =
      Moment(timestamp).format("YY") +
      Moment(timestamp).format("MM") +
      Moment(timestamp).format("DD");
    await Database.save(DATABASE_TABLES.SORTEOS, {
      id: sorteoID,
      codigoSorteo: sorteo.codigo,
      sorteo: sorteo.nombre,
      concurso: concurso,
      numLugares: sorteo.numLugares,
      fecha: fechaSorteo.format("YYYY-MM-DD"),
      activo: true, // SE UTILIZARA PARA PODER DESACTIVAR/ACTIVAR EL SORTEO
    });
    // RECUPERAR SORTEO GUARDADO
    const newSorteo = await Database.getItemsByProp(
      DATABASE_TABLES.SORTEOS,
      "id",
      sorteoID
    );
    return newSorteo[0];
  } catch (error) {
    throw new Error(error.message);
  }
};

export const guardarOpcionListaSorteos = async ({
  nombre,
  codigo,
  imagen,
  numLugares,
  password,
}) => {
  try {
    // VERIFICAR AUTENTICACION
    await userAuthentication(password);
    // SI EL CODIGO NO ESTA DISPONIBLE
    const _codigoDisponible = await codigoDisponible(codigo);
    if (!_codigoDisponible)
      throw new Error(
        `El código ${codigo} no esta disponible, intenta con otro.`
      );
    // SI TIENE PERMISO
    const formData = new FormData();
    formData.append("codigo", codigo);
    formData.append("imagen", imagen);
    const res = await axios.post(API_URL + "guardarLogo", formData);
    const { data } = res;
    // SI HAY ERROR
    if (data.error) throw new Error(data.message);
    // SI NO HUBO ERRORES GUARDAR REGISTRO
    const opcionID = uuid();
    const saved = Database.save(DATABASE_TABLES.LISTA_SORTEOS, {
      id: opcionID,
      nombre,
      codigo,
      logo: data.data,
      numLugares,
    });
    // RECUPERAMOS LA OPCION RECIEN GUARDADA
    const newOpcion = await Database.getItem(
      DATABASE_TABLES.LISTA_SORTEOS,
      "id",
      opcionID
    );
    // SI SE GUARDO EL REGISTRO
    return newOpcion;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const eliminarElementoLista = async ({ elemento, password }) => {
  try {
    // VERIFICAR AUTENTICACION
    await userAuthentication(password);
    const opcionDB = await Database.getItemByKey(
      DATABASE_TABLES.LISTA_SORTEOS,
      elemento.key
    );
    // SI NO EXISTE LA OPCION EN LISTA
    if (!opcionDB) throw new Error("El elemento que solicitas no existe");
    // VERIFICAR SI YA HAY SORTEOS REGISTRADOS
    const existenSorteos = await Database.getItemsByProp(
      DATABASE_TABLES.SORTEOS,
      "codigoSorteo",
      opcionDB.codigo
    );
    if (existenSorteos.length > 0)
      throw new Error(
        "Esta opcion no se puede eliminar porque ya cuenta con sorteos registrados."
      );
    // ELIMINAR ELEMENTO
    const { logo, key } = opcionDB;
    // ELIMINAR IMAGEN
    const formData = new FormData();
    formData.append("nombreImagen", logo);
    const { data } = await axios.post(API_URL + "eliminarImagen", formData);
    // SI NO SE ELIMINO LA IMAGEN
    if (data.error) throw new Error(data.message);
    // SI SE ELIMINO LA IMAGEN ELIMINAMOS REGISTRO
    const registroEliminado = await Database.deleteItem(
      DATABASE_TABLES.LISTA_SORTEOS,
      key
    );
    return registroEliminado;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const eliminarSorteo = async (sorteo, password) => {
  try {
    // VERIFICAR AUTENTICACION
    await userAuthentication(password);
    // OBTENER BOLETOS
    const boletos = await Database.getItemsByProp(
      DATABASE_TABLES.BOLETOS,
      "fechaSorteo",
      sorteo.fecha
    );
    // SI YA HAY BOLETOS REGISTRADOS
    if (boletos.length > 0)
      throw new Error(
        "Lo sentimos, pero este sorteo ya cuenta con boletos registrados, por lo tanto no se puede eliminar. En su lugar te sugerimos desactivar el sorteo."
      );
    // ELIMINAR SORTEO
    const eliminado = await Database.deleteItem(
      DATABASE_TABLES.SORTEOS,
      sorteo.key
    );
    return eliminado;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const obtenerSorteo = async (sorteoKey) => {
  try {
    const item = await Database.getItemByKey(
      DATABASE_TABLES.SORTEOS,
      sorteoKey
    );
    let numerosGanadores = [];
    let numLugares = 0;
    let numBoletos = 0;
    let ventaTotal = 0;
    // SI EL SORTEO NO EXISTE
    if (!item) throw new Error("El sorteo que solicitas no existe");
    // SI EL SORTEO EXISTE
    // OBTENER NUMEROS GANADORES
    const _numerosGanadores = await Database.getItemsByProp(
      DATABASE_TABLES.NUMEROS_GANADORES,
      "fechaSorteo",
      item.fecha
    );
    numerosGanadores =
      _numerosGanadores.length > 0 ? _numerosGanadores[0].numeros : [];
    // OBTENER NUMERO DE LUGARES
    const listaOpciones = await Database.getItemsByProp(
      DATABASE_TABLES.LISTA_SORTEOS,
      "codigo",
      item.codigoSorteo
    );
    numLugares = listaOpciones.length > 0 ? listaOpciones[0].numLugares : 0;
    // OBTENER BOLETOS REGISTRADOS
    const boletosRegistrados = await Database.getItemsByProp(
      DATABASE_TABLES.BOLETOS,
      "fechaSorteo",
      item.fecha
    );
    const numerosJugados = obtenerNumerosJugados(boletosRegistrados);
    // PAGOS PENDIENTES
    const totalPagos = await obtenerTotalPagos(
      _numerosGanadores,
      boletosRegistrados
    );
    numBoletos = boletosRegistrados.length;
    ventaTotal = boletosRegistrados.reduce(
      (accum, el) => accum + parseInt(el.totalApostado),
      0
    );
    // RETORNAR ELEMENTO CON LAS PROPIEDADES AÑADIDAS
    return {
      ...item,
      numerosJugados,
      numerosGanadores,
      numLugares,
      numBoletos,
      ventaTotal,
      numeroPagosPendientes: totalPagos.length,
      pagosPendientes: totalPagos.reduce(
        (acc, el) => acc + parseFloat(el.premio),
        0
      ),
    };
  } catch ({ message }) {
    throw new Error(message);
  }
};

function obtenerNumerosJugados(boletos) {
  const CIFRAS_ENUM = {
    LETRAS: {
      tres: "3 cifras",
      dos: "2 cifras",
      una: "1 cifra",
    },
    NUMEROS: {
      tres: 3,
      dos: 2,
      una: 1,
    },
  };
  let _jugadas = [];
  // MEZCLAR TODAS LAS JUGADAS DE CADA BOLETO
  boletos.forEach((b) => {
    b.jugadas.forEach((j) => _jugadas.push(j));
  });
  let NUMEROS_ENUM = {};
  _jugadas.forEach((j) => {
    // VERIFICAR SI EL NUMERO NO SE HA ALMACENADO
    if (NUMEROS_ENUM[j.numero] === undefined) {
      NUMEROS_ENUM[j.numero] = {
        lugares: j.lugares,
      };
    } else {
      // SI YA SE ALMACENO ENTONCES SUMAR SUS LUGARES CON LA ITERACION ACTUAL
      let _lugares = [];
      NUMEROS_ENUM[j.numero].lugares.forEach((l, index) => {
        _lugares.push(parseInt(l) + parseInt(j.lugares[index]));
      });
      // REEMPLAZAR LOS LUGARES
      NUMEROS_ENUM[j.numero].lugares = _lugares;
    }
  });
  // VOLVER A CREAR LAS JUGADAS APARTIR DE LOS NUMEROS_ENUM CREADOS
  let _numerosJugados = [];
  for (const key in NUMEROS_ENUM) {
    if (Object.hasOwnProperty.call(NUMEROS_ENUM, key)) {
      let numero_enum = NUMEROS_ENUM[key];
      _numerosJugados.push({
        numero: key,
        lugares: numero_enum.lugares,
        totalApostado: numero_enum.lugares.reduce(
          (acc, monto) => parseInt(acc) + parseInt(monto)
        ),
      });
    }
  }
  const unaCifra = [..._numerosJugados].filter(
    (item) => item.numero.length === 1
  );
  const dosCifras = [..._numerosJugados].filter(
    (item) => item.numero.length === 2
  );
  const tresCifras = [..._numerosJugados].filter(
    (item) => item.numero.length === 3
  );
  Helpers.sortList(unaCifra, "numero", "asc");
  Helpers.sortList(dosCifras, "numero", "asc");
  Helpers.sortList(tresCifras, "numero", "asc");

  return unaCifra.concat(dosCifras, tresCifras);
}

export const eliminarNumerosPublicados = async (password, fechaSorteo) => {
  try {
    // VERIFICAR AUTENTICACION
    await userAuthentication(password);
    // OBTENER NUMEROS GANADORES
    const items = await Database.getItemsByProp(
      DATABASE_TABLES.NUMEROS_GANADORES,
      "fechaSorteo",
      fechaSorteo
    );
    const elemento = items.length > 0 ? items[0] : null;
    // SI EL ELEMENTO EXISTE ENTONCES BORRARLO
    if (elemento) {
      await Database.deleteItem(
        DATABASE_TABLES.NUMEROS_GANADORES,
        elemento.key
      );
      return true;
    }
    return false;
  } catch ({ message }) {
    throw new Error(message);
  }
};

export const publicarNumerosGanadores = async (password, sorteo, numeros) => {
  try {
    // VERIFICAR AUTENTICACION
    await userAuthentication(password);
    // VERIFICAR SI YA EXISTE EL ELEMENTO PUBLICADO
    const item = await Database.getItem(
      DATABASE_TABLES.NUMEROS_GANADORES,
      "fechaSorteo",
      sorteo.fecha
    );
    const timestamp = await Database.getServerDate();
    // SI EL ELEMENTO EXISTE ACTUALIZAMOS
    if (item) {
      await Database.update(DATABASE_TABLES.NUMEROS_GANADORES, item.key, {
        numeros: numeros,
        fechaPublicacion: Moment(timestamp).format("YYYY-MM-DD"),
      });
      return true;
    }
    // PUBLICAR NUEVO ELEMENTO
    await Database.save(DATABASE_TABLES.NUMEROS_GANADORES, {
      codigoSorteo: sorteo.codigoSorteo,
      concurso: sorteo.concurso,
      fechaPublicacion: Moment(timestamp).format("YYYY-MM-DD"),
      fechaSorteo: sorteo.fecha,
      id_sorteo: sorteo.id,
      numeros: numeros,
    });
    return true;
  } catch ({ message }) {
    throw new Error(message);
  }
};

export const cambiarEstadoSorteo = async (password, sorteo) => {
  try {
    // VERIFICAR AUTENTICACION
    await userAuthentication(password);
    // SI TIENE PROPIEDAD ACTIVO
    if (sorteo.activo !== undefined) {
      await Database.update(DATABASE_TABLES.SORTEOS, sorteo.key, {
        activo: !sorteo.activo,
      });
      return true;
    }
    // SI NO TIENE LA PROPIEDAD ACTIVO
    await Database.update(DATABASE_TABLES.SORTEOS, sorteo.key, {
      activo: true,
    });
    return true;
  } catch ({ message }) {
    throw new Error(message);
  }
};

export const actualizarLogoSorteo = async (password, opcionLista, file) => {
  try {
    // VERIFICAR AUTENTICACION
    await userAuthentication(password);
    // SI TIENE PERMISO
    const formData = new FormData();
    formData.append("codigo", opcionLista.codigo);
    formData.append("imagen", file);
    const res = await axios.post(API_URL + "actualizarLogo", formData);
    const { data } = res;
    // SI HAY ERROR
    if (data.error) throw new Error(data.message);
    // SI SE ACTUALIZO CORRECTAMENTE
    return true;
  } catch ({ message }) {
    throw new Error(message);
  }
};

export const actualizarOpcionInfoLista = async (
  password,
  opcionKey,
  nombreSorteo,
  numLugares
) => {
  try {
    // VERIFICAR AUTENTICACION
    await userAuthentication(password);
    // OBTENER ELEMENTO
    const elemento = await Database.getItemByKey(
      DATABASE_TABLES.LISTA_SORTEOS,
      opcionKey
    );
    // SI NO EXISTE EL ELEMENTO
    if (!elemento)
      throw new Error("Lo sentimos, el elemento que solicitas no existe");
    // SI EL ELEMENTO EXISTE LO ACTUALIZAMOS
    await Database.update(DATABASE_TABLES.LISTA_SORTEOS, opcionKey, {
      nombre: nombreSorteo,
      numLugares,
    });
    // RECUPERAMOS TODOS LOS SORTEOS CON EL CODIGO ESPECIFICADO
    const sorteosDB = await Database.getItemsByProp(
      DATABASE_TABLES.SORTEOS,
      "codigoSorteo",
      elemento.codigo
    );
    // RENOMBRAMOS LA PROP SORTEO DE CADA ELEMENTO
    sorteosDB.forEach(async (el) => {
      await Database.update(DATABASE_TABLES.SORTEOS, el.key, {
        sorteo: nombreSorteo,
      });
    });
    // RETORNAMOS TRUE SI TODO SALIO BIEN
    return true;
  } catch ({ message }) {
    throw new Error(message);
  }
};

export const obtenerSorteos = async () => {
  try {
    return await Database.getItems(DATABASE_TABLES.SORTEOS);
  } catch ({ message }) {
    throw new Error(message);
  }
};

export const obtenerUsuarios = async () => {
  try {
    const items = await Database.getItems(DATABASE_TABLES.AGENCIAS);
    const usuarios = items.filter(
      (u) => !"admin.dev".split(".").includes(u.tipoUsuario)
    );
    return usuarios;
  } catch ({ message }) {
    throw new Error(message);
  }
};

export const actualizarUsuario = async (password, data) => {
  try {
    // VERIFICAR AUTENTICACION
    await userAuthentication(password);
    // OBTENER USUARIO
    const usuario = await Database.getItemByKey(
      DATABASE_TABLES.AGENCIAS,
      data.usuarioKey
    );
    // SI EL USUARIO NO EXISTE
    if (!usuario) throw new Error("El usuario no fue encontrado.");
    // SI EL USUARIO EXISTE
    const timestamp = await Database.getServerDate();
    await Database.update(DATABASE_TABLES.AGENCIAS, usuario.key, {
      nombre: data.nombre,
      apellidos: data.apellidos,
      domicilio: data.domicilio,
      nomComercial: data.nomComercial,
      limiteVenta: parseInt(data.limiteVenta),
      devicesAllowed: parseInt(data.devicesAllowed),
      modified: timestamp,
    });
    // ACTUALIZAR CREDITO
    const credito = await Database.getItem(
      DATABASE_TABLES.CREDITOS,
      "usuario",
      usuario.usuario
    );
    if (credito) {
      await Database.update(DATABASE_TABLES.CREDITOS, credito.key, {
        saldo: parseFloat(data.creditoDisponible),
      });
    }
    // RETORNAR USUARIO ACTUALIZADO
    const updatedUsuario = await Database.getItemByKey(
      DATABASE_TABLES.AGENCIAS,
      usuario.key
    );
    return {
      ...updatedUsuario,
      creditoDisponible: parseFloat(data.creditoDisponible),
    };
  } catch ({ message }) {
    throw new Error(message);
  }
};

export const obtenerBoletosSorteo = async (sorteoKey) => {
  try {
    const sorteo = await Database.getItemByKey(
      DATABASE_TABLES.SORTEOS,
      sorteoKey
    );
    let boletos = await Database.getItemsByProp(
      DATABASE_TABLES.BOLETOS,
      "fechaSorteo",
      sorteo.fecha
    );
    if (boletos.length > 0) {
      return Helpers.ordenarElementos(
        boletos,
        (a, b) =>
          Moment(b.fechaExp + " " + b.horaImpresion).valueOf() -
          Moment(a.fechaExp + " " + a.horaImpresion).valueOf()
      );
    }
    return boletos;
  } catch ({ message }) {
    throw new Error(message);
  }
};

export const sorteoCancelable = async (fechaSorteo) => {
  try {
    const timestamp = await Database.getServerDate();
    const cierreSorteo = await Database.getObject(DATABASE_TABLES.HORA_CIERRE);
    const fecha = {
      actual: Moment(timestamp).format("YYYY-MM-DD"),
      sorteo: fechaSorteo,
    };
    const hora = {
      cierre: cierreSorteo.hora,
      actual: Moment(timestamp).format("HH:mm"),
    };
    //SI EL SORTEO YA SE JUGO
    if (Moment(fecha.actual).isAfter(fecha.sorteo)) {
      return false;
    }
    //SI EL SORTEO SE JUEGA HOY Y YA CERRO
    if (
      Moment(fecha.sorteo).isSame(fecha.actual) &&
      Moment(hora.actual, "HH:mm").isSameOrAfter(Moment(hora.cierre, "HH:mm"))
    ) {
      return false;
    }
    return true;
  } catch ({ message }) {
    console.log(message);
  }
};

export async function verificarYcancelarBoleto(password, { numeroBoleto }) {
  try {
    // VERIFICAR AUTENTICACION
    await userAuthentication(password);
    const existeBoletoCancelado = await Database.getItem(
      DATABASE_TABLES.BOLETOS_CANCELADOS,
      "numeroBoleto",
      numeroBoleto
    );
    // SI YA FUE CANCELADO PREVIAMENTE
    if (existeBoletoCancelado)
      throw new Error("Error: boleto previamente cancelado");
    // RECUPERAMOS EL BOLETO
    const boleto = await Database.getItem(
      DATABASE_TABLES.BOLETOS,
      "numeroBoleto",
      numeroBoleto
    );
    // SI NO SE ENCONTRO EL BOLETO
    if (!boleto) throw new Error("Error: boleto no encontrado");
    // OBTENEMOS LA FECHA DEL SERVIDOR
    const timestamp = await Database.getServerDate();
    const fecha = {
      servidor: timestamp,
      actual: Moment(timestamp).format("YYYY-MM-DD"),
      sorteo: boleto.fechaSorteo,
    };
    // OBTENEMOS LA HORA DE CIERRE DEL SORTEO
    const horaCierre = await Database.getObject(DATABASE_TABLES.HORA_CIERRE);
    const hora = {
      cierre: horaCierre.hora,
      actual: Moment(timestamp).format("HH:mm"),
    };
    // SI EL SORTEO YA CERRO
    if (
      Moment(fecha.actual).isAfter(boleto.fechaSorteo) ||
      (Moment(fecha.sorteo).isSame(fecha.actual) &&
        Moment(hora.actual, "HH:mm").isSameOrAfter(
          Moment(hora.cierre, "HH:mm")
        ))
    ) {
      throw new Error(
        "Error: sorteo cerrado, el boleto ya no se puede cancelar"
      );
    }
    // ESTRUCTURA BOLETO CANCELADO
    const boletoCanceladoInfo = {
      numeroBoleto: boleto.numeroBoleto,
      agencia: boleto.numeroAgencia,
      agenciaId: boleto.agenteId,
      fechaCancelacion: fecha.actual,
      fechaExp: boleto.fechaExp,
      reembolso: boleto.totalApostado,
      horaCancelacion: Moment(timestamp).format("HH:mm:ss"),
      tipoConcurso: "bolita",
      timestamp: fecha.servidor,
      boleto,
    };
    // ELIMINAR BOLETO DE LA BASE DE DATOS
    await Database.deleteItem(DATABASE_TABLES.BOLETOS, boleto.key);
    // GUARDAMOS BOLETO CANCELADO
    await Database.save(
      DATABASE_TABLES.BOLETOS_CANCELADOS,
      boletoCanceladoInfo
    );
    // USUARIO QUE VENDIO EL BOLETO
    const usuarioDB = await Database.getItem(
      DATABASE_TABLES.AGENCIAS,
      "usuario",
      boleto.numeroAgencia
    );
    // ACTUALIZAR CREDITO DISPONIBLE
    const credito = await Database.getItem(
      DATABASE_TABLES.CREDITOS,
      "usuario",
      usuarioDB.usuario
    );
    // VERIFICAMOS SI EXISTE EL CREDITO
    let saldoNuevo = 0;
    if (credito) {
      // SUMAMOS EL TOTAL APOSTADO DEL BOLETO AL CREDITO DISPONIBLE
      const totalVentaBoleto = Helpers.totalMenosComisionTicket(
        boleto.totalApostado
      );
      const nuevoCredito = credito.saldo + parseFloat(totalVentaBoleto);
      saldoNuevo =
        nuevoCredito >= parseInt(usuarioDB.limiteVenta)
          ? parseInt(usuarioDB.limiteVenta)
          : nuevoCredito;
      await actualizarCredito(saldoNuevo, credito.key);
    }
    // canceledTickets: {userId, fechaSorteo, fechaExp, ticket}
    return true;
  } catch ({ message }) {
    throw new Error(message);
  }
}
// FUNCION DEPRECADA
export const cancelarBoleto = async (password, { numeroBoleto }) => {
  try {
    // VERIFICAR AUTENTICACION
    await userAuthentication(password);
    const existeBoletoCancelado = await Database.getItem(
      DATABASE_TABLES.BOLETOS_CANCELADOS,
      "numeroBoleto",
      numeroBoleto
    );
    // SI YA FUE CANCELADO PREVIAMENTE
    if (existeBoletoCancelado)
      throw new Error("El boleto fue previamente cancelado");
    // RECUPERAMOS EL BOLETO
    const boleto = await Database.getItem(
      DATABASE_TABLES.BOLETOS,
      "numeroBoleto",
      numeroBoleto
    );
    // SI NO SE ENCONTRO EL BOLETO
    if (!boleto) throw new Error("El boleto no fue encontrado");
    // RECUPERAMOS AL USUARIO
    // const usuario = await Storage.get("usuario", true);
    // SI LOS NUMEROS DE USUARIO NO COINCIDEN
    // if (usuario.usuario !== boleto.numeroAgencia)
    //   throw new Error(
    //     "El boleto es de otra agencia por lo tanto se puede cancelar"
    //   );
    // OBTENEMOS LA FECHA DEL SERVIDOR
    const timestamp = await Database.getServerDate();
    const fecha = {
      servidor: timestamp,
      actual: Moment(timestamp).format("YYYY-MM-DD"),
      sorteo: boleto.fechaSorteo,
    };
    // OBTENEMOS LA HORA DE CIERRE DEL SORTEO
    const horaCierre = await Database.getObject(DATABASE_TABLES.HORA_CIERRE);
    const hora = {
      cierre: horaCierre.hora,
      actual: Moment(timestamp).format("HH:mm"),
    };
    // SI EL SORTEO YA CERRO
    if (
      Moment(fecha.actual).isAfter(boleto.fechaSorteo) ||
      (Moment(fecha.sorteo).isSame(fecha.actual) &&
        Moment(hora.actual, "HH:mm").isSameOrAfter(
          Moment(hora.cierre, "HH:mm")
        ))
    ) {
      throw new Error("Sorteo cerrado, el boleto ya no se puede cancelar");
    }
    // ESTRUCTURA BOLETO CANCELADO
    const boletoCanceladoInfo = {
      numeroBoleto: boleto.numeroBoleto,
      agencia: boleto.numeroAgencia,
      agenciaId: boleto.agenteId,
      fechaCancelacion: fecha.actual,
      fechaExp: boleto.fechaExp,
      reembolso: boleto.totalApostado,
      horaCancelacion: Moment(timestamp).format("HH:mm:ss"),
      tipoConcurso: "bolita",
      timestamp: fecha.servidor,
      boleto,
    };
    // ELIMINAR BOLETO DE LA BASE DE DATOS
    await Database.deleteItem(DATABASE_TABLES.BOLETOS, boleto.key);
    // GUARDAMOS BOLETO CANCELADO
    await Database.save(
      DATABASE_TABLES.BOLETOS_CANCELADOS,
      boletoCanceladoInfo
    );
    return true;
  } catch ({ message }) {
    throw new Error(message);
  }
};

export const obtenerCancelados = async () => {
  const items = await Database.getItems(DATABASE_TABLES.BOLETOS_CANCELADOS);
  return Helpers.ordenarElementos(
    items,
    (a, b) =>
      Moment(b.fechaCancelacion + " " + b.horaCancelacion).valueOf() -
      Moment(a.fechaCancelacion + " " + a.horaCancelacion).valueOf()
  );
};

export const obtenerListaOpciones = async () => {
  try {
    const items = Database.getItems(DATABASE_TABLES.LISTA_SORTEOS);
    return items;
  } catch ({ message }) {
    throw new Error(message);
  }
};

export const guardarAbono = async (password, abono) => {
  try {
    // VERIFICAR AUTENTICACION
    await userAuthentication(password);
    // OBTENER FECHA SERVIDOR
    const timestamp = await Database.getServerDate();
    // GUARDAR ABONO
    abono.id = uuid();
    abono.created = Moment(timestamp).format("YYYY-MM-DD");
    abono.modified = Moment(timestamp).format("YYYY-MM-DD");
    await Database.save(DATABASE_TABLES.ABONOS, abono);
    const newAbono = await Database.getItem(
      DATABASE_TABLES.ABONOS,
      "id",
      abono.id
    );
    return newAbono;
  } catch ({ message }) {
    throw new Error(message);
  }
};

export const obtenerAbonos = async (usuarioKey) => {
  // const todosAbonos = await Database.getItems(DATABASE_TABLES.ABONOS);
  // for (let i = 0; i < todosAbonos.length; i++) {
  //   const _abono = todosAbonos[i];
  //   console.log(_abono.tipo);
  //   await Database.update(DATABASE_TABLES.ABONOS, _abono.key, {
  //     tipo: "abono",
  //   });
  // }
  const usuario = await Database.getItemByKey(
    DATABASE_TABLES.AGENCIAS,
    usuarioKey
  );
  const abonos = await Database.getItemsByProp(
    DATABASE_TABLES.ABONOS,
    "agenciaId",
    usuario.id
  );
  // return Helpers.ordenarElementos(
  //   abonos,
  //   (a, b) =>
  //     Moment(b.fechaExp + " " + b.horaImpresion).valueOf() -
  //     Moment(a.fechaExp + " " + a.horaImpresion).valueOf()
  // )
  return Helpers.ordenarLista(abonos, "fechaAbono");
};

export const cambiarEstadoUsuario = async (password, usuario) => {
  try {
    // VERIFICAR AUTENTICACION
    await userAuthentication(password);
    //  CAMBIAR ESTADO
    await Database.update(DATABASE_TABLES.AGENCIAS, usuario.key, {
      activo: !usuario.activo,
      disableAccountReason:
        "Su cuenta ha sido desactivada, por favor contacte al administrador.",
    });
    const updatedUsuario = await Database.getItemByKey(
      DATABASE_TABLES.AGENCIAS,
      usuario.key
    );
    return updatedUsuario;
  } catch ({ message }) {
    throw new Error(message);
  }
};

export const eliminarAbonoDB = async (password, abono) => {
  try {
    // VERIFICAR AUTENTICACION
    await userAuthentication(password);
    // ELIMINAR DE BASE DE DATOS
    await Database.deleteItem(DATABASE_TABLES.ABONOS, abono.key);
    return true;
  } catch ({ message }) {
    throw new Error(message);
  }
};

export const crearUsuario = async ({
  nombre,
  apellidos,
  correo,
  telefono,
  domicilio,
  nomComercial,
  usuario,
  limiteVenta,
  usuarioPassword,
  passwordAdmin,
}) => {
  try {
    // VERIFICAR AUTENTICACION
    await userAuthentication(passwordAdmin);
    // VERIFICAR SI EXISTE EL NUMERO DE USUARIO QUE SE VA A INGRESAR
    const existeUsuario = await Database.getItemsByProp(
      DATABASE_TABLES.AGENCIAS,
      "usuario",
      usuario
    );
    // SI EL USUARIO EXISTE ARROJAR ERROR
    if (existeUsuario.length > 0)
      throw new Error(
        "El número de usuario ya existe, intenta con otro número."
      );
    // REGISTRAR EN TAECEL
    // const res = await registroCuenta({
    //   nombre,
    //   apellidos,
    //   correo,
    //   telefono,
    //   nomComercial,
    //   forzarActivacion: true,
    // });
    // SI NO SE REGISTRO LA CUENTA
    // if (!res.data.success) throw new Error(res.data.message);
    // REGISTRAMOS EL USUARIO EN LA BD CON LA INFORMACION OBTENIDA COMO RESPUESTA DE TAECEL
    const timestamp = await Database.getServerDate();
    await Database.save(DATABASE_TABLES.AGENCIAS, {
      id: uuid(),
      usuario: usuario,
      nombre: nombre,
      apellidos: apellidos,
      nomComercial: nomComercial,
      limiteVenta: limiteVenta,
      tipoUsuario: "agente",
      domicilio: domicilio,
      // password: , ESTA PROPIEDAD YA NO ES NECESARIA
      bpassword: Bcrypt.hashSync(usuarioPassword, Bcrypt.genSaltSync(10)),
      registrationDate: Moment(timestamp).format("YYYY-MM-DD"),
      created: timestamp,
      modified: timestamp,
      puedeVender: { bola: true, futbol: false },
      suspendido: false,
      bloqueado: false,
      activo: true,
      loginAttempts: 0,
      reimprimir: false,
      // taecel: res.data.data,
    });
    // CREAMOS Y GUARDAMOS EL CREDITO DISPONIBLE
    await Database.save(DATABASE_TABLES.CREDITOS, {
      fecha: Moment(timestamp).format("YYYY-MM-DD HH:mm:ss"),
      saldo: limiteVenta,
      usuario: usuario,
    });
    // RECUPERAMOS EL NUEVO REGISTRO
    const nuevoUsuario = await Database.getItemsByProp(
      DATABASE_TABLES.AGENCIAS,
      "usuario",
      usuario
    );
    // RETORNAMOS EL NUEVO USUARIO REGISTRADO
    return nuevoUsuario[0];
  } catch ({ message }) {
    throw new Error(message);
  }
};

export const eliminarUsuario = async (password, usuario) => {
  try {
    // VERIFICAR AUTENTICACION
    await userAuthentication(password);
    // SI NO EXISTE
    const usuarioDB = await Database.getItemByKey(
      DATABASE_TABLES.AGENCIAS,
      usuario.key
    );
    if (!usuarioDB) throw new Error("Usuario no encontrado");
    // ELIMINAMOS USUARIO
    await Database.deleteItem(DATABASE_TABLES.AGENCIAS, usuarioDB.key);
    // RETORNAR USUARIO PARA ELIMINARLO DEL STORE
    return usuarioDB;
  } catch ({ message }) {
    throw new Error(message);
  }
};

export const obtenerReporteDiario = async (dia, usuario) => {
  try {
    const timestamp = await Database.getServerDate();
    const periodo = obtenerPeriodo(timestamp, dia);
    // OBTENER BOLETOS POR USUARIO
    const boletos = await Database.getItemsByProp(
      DATABASE_TABLES.BOLETOS,
      "fechaExp",
      periodo.inicial,
      (b) => b.numeroAgencia === usuario.usuario
    );
    // OBTENER TRANSACCIONES POR USUARIO
    const transacciones = await Database.getItemsByProp(
      DATABASE_TABLES.TRANSACCIONES,
      "_fecha",
      periodo.inicial,
      (t) => t._usuario === usuario.usuario && t.Status === "Exitosa"
    );
    // OBTENER PAGOS REALIZADOS POR USUARIO
    const pagosRealiados = await Database.getItemsByProp(
      DATABASE_TABLES.PREMIOS_PAGADOS,
      "fechaPago",
      periodo.inicial,
      (p) => p.pagadoPor === usuario.usuario
    );
    // OBTENER ABONOS POR USUARIO
    const abonos = await Database.getItemsByProp(
      DATABASE_TABLES.ABONOS,
      "fechaAbono",
      periodo.inicial,
      (p) => p.agenciaId === usuario.id
    );
    const ventaTicketPlus = boletos.reduce(
      (acc, b) => acc + parseInt(b.totalApostado),
      0
    );
    const comisionVentaTickets = Helpers.obtenerComision(ventaTicketPlus);
    const totalAbonos = abonos.reduce(
      (acc, el) => acc + parseInt(el.cantidad),
      0
    );
    const totalTransacciones = transacciones.reduce(
      (acc, t) =>
        acc +
        parseFloat(Money(t.Monto, false)) +
        parseFloat(Money(t.Comision, false)),
      0
    );
    const comisionVentaTransacciones =
      Helpers.obtenerComisionTransacciones(transacciones).total;
    const totalVentaTransacciones =
      totalTransacciones - comisionVentaTransacciones;
    const totalPagosRealizados = pagosRealiados.reduce(
      (acc, el) => acc + parseInt(el.premio),
      0
    );
    const totalVentaTickets =
      ventaTicketPlus +
      totalAbonos -
      (comisionVentaTickets + totalPagosRealizados);
    // SUMA TOTALES
    const sumaTotales = totalVentaTickets + totalVentaTransacciones;
    return {
      periodo,
      venta: {
        ticketPlus: ventaTicketPlus,
        transacciones: totalTransacciones,
      },
      comisiones: {
        ticketPlus: comisionVentaTickets,
        transacciones: comisionVentaTransacciones,
      },
      pagos: {
        realizados: totalPagosRealizados,
        abonos: totalAbonos,
      },
      importe: sumaTotales < 0 ? Math.abs(sumaTotales) : sumaTotales,
    };
  } catch ({ message }) {
    throw new Error(message);
  }
};

export const obtenerReporteSemanal = async (periodo, usuario) => {
  try {
    // OBTENER BOLETOS POR USUARIO
    const boletos = await Database.getItemsInRange(
      DATABASE_TABLES.BOLETOS,
      "fechaExp",
      periodo.inicial,
      periodo.final,
      (b) => b.numeroAgencia === usuario.usuario
    );
    // OBTENER TRANSACCIONES POR USUARIO
    const transacciones = await Database.getItemsInRange(
      DATABASE_TABLES.TRANSACCIONES,
      "_fecha",
      periodo.inicial,
      periodo.final,
      (t) => t._usuario === usuario.usuario && t.Status === "Exitosa"
    );
    // OBTENER PAGOS REALIZADOS POR USUARIO
    const pagosRealiados = await Database.getItemsInRange(
      DATABASE_TABLES.PREMIOS_PAGADOS,
      "fechaPago",
      periodo.inicial,
      periodo.final,
      (p) => p.pagadoPor === usuario.usuario
    );
    // OBTENER ABONOS POR USUARIO
    const abonos = await Database.getItemsInRange(
      DATABASE_TABLES.ABONOS,
      "fechaAbono",
      periodo.inicial,
      periodo.final,
      (p) => p.agenciaId === usuario.id
    );
    // NUMEROS GANADORES PUBLICADOS
    const publicacionesNumerosGanadores = await Database.getItemsInRange(
      DATABASE_TABLES.NUMEROS_GANADORES,
      "fechaSorteo",
      periodo.inicial,
      periodo.final
    );
    // PAGOS PENDIENTES
    const pagosPendientes = await obtenerPagosPendientes(
      publicacionesNumerosGanadores,
      boletos
    );
    // DEPOSITOS
    const deposito = await Database.getItem(
      DATABASE_TABLES.DEPOSITOS,
      "referencia",
      periodo.inicial + "#" + periodo.final + "#" + usuario.usuario
    );
    const ventaTicketPlus = boletos.reduce(
      (acc, b) => acc + parseFloat(b.totalApostado),
      0
    );
    const comisionVentaTickets = Helpers.obtenerComision(ventaTicketPlus);
    const totalAbonos = abonos.reduce(
      (acc, el) => acc + parseFloat(el.cantidad),
      0
    );
    const totalTransacciones = transacciones.reduce(
      (acc, t) =>
        acc +
        parseFloat(Money(t.Monto, false)) +
        parseFloat(Money(t.Comision, false)) +
        parseFloat(Money(t.Cargo, false)),
      0
    );
    const comisionVentaTransacciones =
      Helpers.obtenerComisionTransacciones(transacciones).total;
    const totalVentaTransacciones =
      totalTransacciones - comisionVentaTransacciones;
    const totalPagosRealizados = pagosRealiados.reduce(
      (acc, el) => acc + parseInt(el.premio),
      0
    );
    const totalVentaTickets =
      ventaTicketPlus +
      totalAbonos -
      totalPagosRealizados -
      comisionVentaTickets;
    // SUMA TOTALES
    const sumaTotales = totalVentaTickets + totalVentaTransacciones;
    return {
      periodo,
      venta: {
        ticketPlus: ventaTicketPlus,
        transacciones: totalTransacciones,
      },
      comisiones: {
        ticketPlus: comisionVentaTickets,
        transacciones: comisionVentaTransacciones,
      },
      pagos: {
        realizados: totalPagosRealizados,
        pendientes: pagosPendientes.reduce(
          (acc, el) => acc + parseInt(el.premio),
          0
        ),
        abonos: totalAbonos,
      },
      importe: sumaTotales,
      deposito: deposito ? deposito.monto : 0,
    };
  } catch ({ message }) {
    throw new Error(message);
  }
};

export const guardarMontoDepositado = async (periodo, usuario, monto) => {
  try {
    const timestamp = await Database.getServerDate();
    const deposito = {
      referencia: periodo.inicial + "#" + periodo.final + "#" + usuario.usuario,
      fecha: Moment(timestamp).format("YYYY-MM-DD"),
      hora: Moment(timestamp).format("HH:mm:ss"),
      usuario: usuario.usuario,
      periodo: { inicial: periodo.inicial, final: periodo.final },
      monto: monto,
    };
    // VERIFICAR SI EXISTE EL DEPOSITO
    const depositoDB = await Database.getItem(
      DATABASE_TABLES.DEPOSITOS,
      "referencia",
      deposito.referencia
    );
    // SI EXISTE ACTUALIZAR
    if (depositoDB) {
      await Database.update(
        DATABASE_TABLES.DEPOSITOS,
        depositoDB.key,
        deposito
      );
      return true;
    }
    // SI NO EXISTE CREAR
    await Database.save(DATABASE_TABLES.DEPOSITOS, deposito);
    return true;
  } catch ({ message }) {
    throw new Error(message);
  }
};

export const obtenerPagosPendientes = async (numerosGanadores, boletos) => {
  let boletosPremiados = [];
  let pagosPendientes = [];
  numerosGanadores.forEach((publicacion) => {
    const boletosSorteo = boletos.filter((b) =>
      Moment(b.fechaSorteo).isSame(Moment(publicacion.fechaSorteo))
    );
    const premiados = encontrarBoletosPremiados(
      publicacion.numeros,
      boletosSorteo
    );
    boletosPremiados = boletosPremiados.concat(premiados);
  });
  // VERIFICAR SI YA FUERON PAGADOS
  for (let i = 0; i < boletosPremiados.length; i++) {
    const elemento = boletosPremiados[i];
    const boletoPagado = await Database.getItem(
      DATABASE_TABLES.PREMIOS_PAGADOS,
      "numeroBoleto",
      elemento.numeroBoleto
    );
    if (!boletoPagado) {
      pagosPendientes.push(elemento);
    }
  }
  return pagosPendientes;
};

export const obtenerBoletoPremiado = async (boletoKey) => {
  try {
    const boleto = await Database.getItemByKey(
      DATABASE_TABLES.BOLETOS,
      boletoKey
    );
    const publicacionNumerosGanadores = await Database.getItemsByProp(
      DATABASE_TABLES.NUMEROS_GANADORES,
      "fechaSorteo",
      boleto.fechaSorteo
    );
    const premiados = encontrarBoletosPremiados(
      publicacionNumerosGanadores[0].numeros,
      [boleto]
    );
    let pendientes = [];
    // VERIFICAR SI YA FUERON PAGADOS
    for (let i = 0; i < premiados.length; i++) {
      const elemento = premiados[i];
      const boletoPagado = await Database.getItem(
        DATABASE_TABLES.PREMIOS_PAGADOS,
        "numeroBoleto",
        elemento.numeroBoleto
      );
      if (!boletoPagado) {
        pendientes.push(elemento);
      }
    }

    return { ...boleto, infoPremio: premiados[0] };
  } catch ({ message }) {
    throw new Error(message);
  }
};

export const obtenerTotalPagos = async (numerosGanadores, boletos) => {
  let boletosPremiados = [];

  numerosGanadores.forEach((publicacion) => {
    const boletosSorteo = boletos.filter((b) =>
      Moment(b.fechaSorteo).isSame(Moment(publicacion.fechaSorteo))
    );
    const premiados = encontrarBoletosPremiados(
      publicacion.numeros,
      boletosSorteo
    );
    boletosPremiados = boletosPremiados.concat(premiados);
  });

  return boletosPremiados;
};

const encontrarBoletosPremiados = (numeros, boletos) => {
  let boletosPremiados = [];
  boletos.forEach((boleto) => {
    numeros.forEach((numero, ngIndex) => {
      const { jugadas } = boleto;
      jugadas.forEach((jugada) => {
        const ngCifras = {
          tres: numero,
          dos: numero.slice(1),
          una: numero.slice(2),
        };
        let cantidadApostada = parseInt(jugada.lugares[ngIndex]);
        let boletoGuardadoIndex = boletosPremiados.findIndex(
          (el) => el.boletoKey === boleto.key
        );
        // SI YA SE GUARDO EL BOLETO
        if (boletoGuardadoIndex !== -1) {
          // TRES CIFRAS
          if (ngCifras.tres == jugada.numero && cantidadApostada) {
            boletosPremiados[boletoGuardadoIndex].premio +=
              cantidadApostada * 550;
          }
          // DOS CIFRAS
          if (ngCifras.dos == jugada.numero && cantidadApostada) {
            boletosPremiados[boletoGuardadoIndex].premio +=
              cantidadApostada * 70;
          }
          // DOS CIFRAS
          if (ngCifras.una == jugada.numero && cantidadApostada) {
            boletosPremiados[boletoGuardadoIndex].premio +=
              cantidadApostada * 7;
          }
        } else {
          // TRES CIFRAS
          if (
            ngCifras.tres == jugada.numero &&
            cantidadApostada &&
            boletoGuardadoIndex === -1
          ) {
            boletosPremiados.push({
              premio: cantidadApostada * 550,
              fechaSorteo: boleto.fechaSorteo,
              boletoKey: boleto.key,
              numeroBoleto: boleto.numeroBoleto,
              usuario: boleto.numeroAgencia,
            });
          }
          // DOS CIFRAS
          if (ngCifras.dos == jugada.numero && cantidadApostada) {
            boletosPremiados.push({
              premio: cantidadApostada * 70,
              fechaSorteo: boleto.fechaSorteo,
              boletoKey: boleto.key,
              numeroBoleto: boleto.numeroBoleto,
              usuario: boleto.numeroAgencia,
            });
          }
          // UNA CIFRA
          if (ngCifras.una == jugada.numero && cantidadApostada) {
            boletosPremiados.push({
              premio: cantidadApostada * 7,
              fechaSorteo: boleto.fechaSorteo,
              boletoKey: boleto.key,
              numeroBoleto: boleto.numeroBoleto,
              usuario: boleto.numeroAgencia,
            });
          }
        }
      });
    });
  });

  return boletosPremiados;
};

export const obtenerReporte = async (usuario, periodo) => {
  try {
    const timestamp = await Database.getServerDate();
    const fecha = periodo.inicial
      ? periodo
      : obtenerPeriodo(timestamp, periodo);
    // OBTENER BOLETOS POR USUARIO
    const boletosPorUsuario = await Database.getItemsInRange(
      DATABASE_TABLES.BOLETOS,
      "fechaExp",
      fecha.inicial,
      fecha.final,
      (b) => b.numeroAgencia === usuario.usuario
    );
    const registroNumGanadores = await Database.getItemsInRange(
      DATABASE_TABLES.NUMEROS_GANADORES,
      "fechaSorteo",
      fecha.inicial,
      fecha.final
    );
    // OBTENER REPORTE PREMIADOS
    const reportePremiados = obtenerReportePremiados(
      boletosPorUsuario,
      registroNumGanadores
    );
    // OBTENER BOLETOS CANCELADOS POR USUARIO
    const canceladosPorUsuario = await Database.getItemsInRange(
      DATABASE_TABLES.BOLETOS_CANCELADOS,
      "fechaCancelacion",
      fecha.inicial,
      fecha.final,
      (c) => c.agencia === usuario.usuario
    );
    // OBTENER ABONOS POR USUARIO
    const abonosPorUsuario = await Database.getItemsInRange(
      DATABASE_TABLES.ABONOS,
      "fechaAbono",
      fecha.inicial,
      fecha.final,
      (a) => a.agenciaId === usuario.id
    );
    return {
      boletos: boletosPorUsuario,
      reportePremiados: reportePremiados,
      cancelados: canceladosPorUsuario,
      abonos: abonosPorUsuario,
      periodo: fecha,
    };
  } catch ({ message }) {
    throw new Error(message);
  }
};

export const obtenerBoletosReimpresion = async (usuario) => {
  const timestamp = await Database.getServerDate();
  const boletos = await Database.getItemsByProp(
    DATABASE_TABLES.BOLETOS,
    "fechaExp",
    Moment(timestamp).format("YYYY-MM-DD"),
    (b) => b.numeroAgencia === usuario.usuario
  );
  return boletos;
};

export const cambiarPermisoReimpresion = async (password, usuario, boleto) => {
  try {
    // VERIFICAR AUTENTICACION
    await userAuthentication(password);
    const usuarioDB = await Database.getItemByKey(
      DATABASE_TABLES.AGENCIAS,
      usuario.key
    );
    // SI EL USUARIO NO EXISTE
    if (!usuarioDB) throw new Error("No se encontró el usuario");
    // CAMBIAR PERMISO
    await Database.update(DATABASE_TABLES.AGENCIAS, usuarioDB.key, {
      reimprimir: true,
      boleto: boleto,
    });
    return true;
  } catch ({ message }) {
    throw new Error(message);
  }
};

export const guardarHoraCierre = async (passwordAdmin, horaMoment) => {
  try {
    // VERIFICAR AUTENTICACION
    await userAuthentication(passwordAdmin);
    // ACTUALIZAR HORA
    const newHora = Moment(horaMoment).format("HH:mm");
    const item = await Database.getObject(DATABASE_TABLES.HORA_CIERRE);
    // SI NO EXISTE
    if (!item) throw new Error("El elemento hora no existe");
    // ACTUALIZAMOS HORA
    const horaCierreRef = Database.getRef(DATABASE_TABLES.HORA_CIERRE);
    await horaCierreRef.update({ hora: newHora });
    return true;
  } catch ({ message }) {
    throw new Error(message);
  }
};

export const guardarLimiteApuestas = async (passwordAdmin, limite) => {
  try {
    // VERIFICAR AUTENTICACION
    await userAuthentication(passwordAdmin);
    // ACTUALIZAR INFORMACION
    const limiteApuestasRef = Database.getRef(DATABASE_TABLES.LIMITE_APUESTAS);
    await limiteApuestasRef.update({
      tresCifras: limite.tres,
      dosCifras: limite.dos,
      unaCifra: limite.una,
    });
    return true;
  } catch ({ message }) {
    throw new Error(message);
  }
};

export const guardarAntiguedad = async (passwordAdmin, antiguedad) => {
  try {
    // VERIFICAR AUTENTICACION
    await userAuthentication(passwordAdmin);
    // ACTUALIZAR ANTIGUEDAD
    const liberarEspacioRef = Database.getRef(DATABASE_TABLES.LIBERAR_ESPACIO);
    await liberarEspacioRef.update({ antiguedad });
    return true;
  } catch ({ message }) {
    throw new Error(message);
  }
};

export const obtenerDatosLiberarEspacio = async () => {
  try {
    // OBTENER FECHA
    const timestamp = await Database.getServerDate();
    // ANTIGUEDAD
    const liberarEspacio = await Database.getObject(
      DATABASE_TABLES.LIBERAR_ESPACIO
    );
    // FECHA ANTIGUEDAD
    const fechaAntiguedad = Moment(timestamp)
      .subtract(liberarEspacio.antiguedad, "days")
      .format("YYYY-MM-DD");
    // OBTENER REGISTROS
    const registros = await obtenerRegistrosEliminables(fechaAntiguedad);
    return {
      sorteos: registros.sorteos.length,
      boletos: registros.boletos.length,
      cancelados: registros.cancelados.length,
      premiosPagados: registros.premiosPagados.length,
      abonos: registros.abonos.length,
      numerosGanadores: registros.numerosGanadores.length,
      antiguedad: liberarEspacio.antiguedad,
      fechaAntiguedad,
    };
  } catch ({ message }) {
    throw new Error(message);
  }
};

export const eliminarRegistros = async (passwordAdmin, nombreRegistros) => {
  try {
    // VERIFICAR AUTENTICACION
    await userAuthentication(passwordAdmin);
    // OBTENER FECHA
    const timestamp = await Database.getServerDate();
    // ANTIGUEDAD
    const liberarEspacio = await Database.getObject(
      DATABASE_TABLES.LIBERAR_ESPACIO
    );
    // FECHA ANTIGUEDAD
    const fechaAntiguedad = Moment(timestamp)
      .subtract(liberarEspacio.antiguedad, "days")
      .format("YYYY-MM-DD");
    // REGISTROS
    const NOMBRE_TABLA = {
      sorteos: DATABASE_TABLES.SORTEOS,
      boletos: DATABASE_TABLES.BOLETOS,
      cancelados: DATABASE_TABLES.BOLETOS_CANCELADOS,
      "premios-pagados": DATABASE_TABLES.PREMIOS_PAGADOS,
      abonos: DATABASE_TABLES.ABONOS,
      "numeros-ganadores": DATABASE_TABLES.NUMEROS_GANADORES,
    };
    // PROPIEDADES FECHA
    const PROP_FECHA = {
      sorteos: "fecha",
      boletos: "fechaExp",
      cancelados: "fechaCancelacion",
      "premios-pagados": "fechaPago",
      abonos: "fechaAbono",
      "numeros-ganadores": "fechaPublicacion",
    };
    const refName = NOMBRE_TABLA[nombreRegistros];
    const propName = PROP_FECHA[nombreRegistros];
    // OBTENER REGISTROS
    const _registros = await Database.getItemsEndingAt(
      refName,
      propName,
      fechaAntiguedad
    );
    // ELIMINAR CADA REGISTRO ENCONTRADO
    for (let i = 0; i < _registros.length; i++) {
      const registro = _registros[i];
      if (registro.key) {
        await Database.deleteItem(refName, registro.key);
      }
    }
    return true;
  } catch ({ message }) {
    throw new Error(message);
  }
};

export const eliminarRegistrosTodos = async (passwordAdmin) => {
  try {
    // VERIFICAR AUTENTICACION
    await userAuthentication(passwordAdmin);
    // OBTENER FECHA
    const timestamp = await Database.getServerDate();
    // ANTIGUEDAD
    const liberarEspacio = await Database.getObject(
      DATABASE_TABLES.LIBERAR_ESPACIO
    );
    // FECHA ANTIGUEDAD
    const fechaAntiguedad = Moment(timestamp)
      .subtract(liberarEspacio.antiguedad, "days")
      .format("YYYY-MM-DD");
    // OBTENER REGISTROS
    const registros = await obtenerRegistrosEliminables(fechaAntiguedad);
    // ELIMINAR SORTEOS
    registros.sorteos.forEach(async (element) => {
      try {
        if (element.key) {
          // console.log("sorteos: ", element);
          await Database.deleteItem(DATABASE_TABLES.SORTEOS, element.key);
        }
      } catch ({ message }) {
        throw new Error(message);
      }
    });
    // ELIMINAR BOLETOS
    registros.boletos.forEach(async (element) => {
      try {
        if (element.key) {
          // console.log("boletos: ", element);
          await Database.deleteItem(DATABASE_TABLES.BOLETOS, element.key);
        }
      } catch ({ message }) {
        throw new Error(message);
      }
    });
    // ELIMINAR CANCELADOS
    registros.cancelados.forEach(async (element) => {
      try {
        if (element.key) {
          // console.log("cancelados: ", element);
          await Database.deleteItem(
            DATABASE_TABLES.BOLETOS_CANCELADOS,
            element.key
          );
        }
      } catch ({ message }) {
        throw new Error(message);
      }
    });
    // ELIMINAR PREMIOS PAGADOS
    registros.premiosPagados.forEach(async (element) => {
      try {
        if (element.key) {
          // console.log("premios pagados: ", element);
          await Database.deleteItem(
            DATABASE_TABLES.PREMIOS_PAGADOS,
            element.key
          );
        }
      } catch ({ message }) {
        throw new Error(message);
      }
    });
    // ELIMINAR ABONOS
    registros.abonos.forEach(async (element) => {
      try {
        if (element.key) {
          // console.log("abonos: ", element);
          await Database.deleteItem(DATABASE_TABLES.ABONOS, element.key);
        }
      } catch ({ message }) {
        throw new Error(message);
      }
    });
    // ELIMINAR NUMEROS GANADORES
    registros.numerosGanadores.forEach(async (element) => {
      try {
        if (element.key) {
          // console.log("numeros ganadores: ", element);
          await Database.deleteItem(
            DATABASE_TABLES.NUMEROS_GANADORES,
            element.key
          );
        }
      } catch ({ message }) {
        throw new Error(message);
      }
    });
    return true;
  } catch ({ message }) {
    throw new Error(message);
  }
};

async function obtenerRegistrosEliminables(fechaAntiguedad) {
  try {
    // OBTENER SORTEOS
    const sorteos = await Database.getItemsEndingAt(
      DATABASE_TABLES.SORTEOS,
      "fecha",
      fechaAntiguedad
    );
    // OBTENER BOLETOS
    const boletos = await Database.getItemsEndingAt(
      DATABASE_TABLES.BOLETOS,
      "fechaExp",
      fechaAntiguedad
    );
    // OBTENER CANCELADOS
    const cancelados = await Database.getItemsEndingAt(
      DATABASE_TABLES.BOLETOS_CANCELADOS,
      "fechaCancelacion",
      fechaAntiguedad
    );
    // OBTENER CANCELADOS
    const premiosPagados = await Database.getItemsEndingAt(
      DATABASE_TABLES.PREMIOS_PAGADOS,
      "fechaPago",
      fechaAntiguedad
    );
    // OBTENER ABONOS
    const abonos = await Database.getItemsEndingAt(
      DATABASE_TABLES.ABONOS,
      "fechaAbono",
      fechaAntiguedad
    );
    // OBTENER NUMEROS GANADORES
    const numerosGanadores = await Database.getItemsEndingAt(
      DATABASE_TABLES.NUMEROS_GANADORES,
      "fechaPublicacion",
      fechaAntiguedad
    );

    return {
      sorteos: sorteos,
      boletos: boletos,
      cancelados: cancelados,
      premiosPagados: premiosPagados,
      abonos: abonos,
      numerosGanadores: numerosGanadores,
    };
  } catch ({ message }) {
    throw new Error(message);
  }
}

async function userAuthentication(password) {
  try {
    const usuarioStorage = Storage.get("usuario", true);
    const autenticacionValida = await authenticate(
      usuarioStorage.usuario,
      password
    );
    // SI NO TIENE PERMISO
    if (!autenticacionValida)
      throw new Error("Contraseña incorrecta, intenta nuevamente.");
  } catch ({ message }) {
    throw new Error(message);
  }
}

export async function getPagosPendientes() {
  try {
    const timestamp = await Database.getServerDate();
    // CONTAR HACIA ATRAS 60 DIAS
    let counter = 0;
    while (counter < 60) {
      counter++;
    }
    const periodo = {
      inicial: Moment(timestamp).subtract(counter, "days").format("YYYY-MM-DD"),
      final: Moment(timestamp).format("YYYY-MM-DD"),
    };
    const boletos = await Database.getItemsInRange(
      DATABASE_TABLES.BOLETOS,
      "fechaSorteo",
      periodo.inicial,
      periodo.final
    );
    const numerosGanadores = await Database.getItemsInRange(
      DATABASE_TABLES.NUMEROS_GANADORES,
      "fechaSorteo",
      periodo.inicial,
      periodo.final
    );
    const premiosPagados = await Database.getItemsInRange(
      DATABASE_TABLES.PREMIOS_PAGADOS,
      "fechaSorteo",
      periodo.inicial,
      periodo.final
    );
    // BUSCAR BOLETOS PREMIADOS
    let boletosPremiados = await buscarBoletosPremiados(
      boletos,
      numerosGanadores
    );
    let premiosSinPagar = obtenerBoletosPremiadosSinPagar(
      boletosPremiados,
      premiosPagados
    );
    // ORDENAR BOLETOS PREMIADOS SIN PAGAR
    Helpers.ordenarElementos(
      premiosSinPagar,
      (a, b) =>
        Moment(b.fechaSorteo).valueOf() - Moment(a.fechaSorteo).valueOf()
    );

    return { premiosSinPagar, periodo };
  } catch ({ message }) {
    throw new Error(message);
  }
}

export function obtenerBoletosPremiadosSinPagar(premiados, pagados) {
  let sinPagar = [];
  premiados.forEach((premiado) => {
    const pagado = pagados.find(
      (pagado) => pagado.numeroBoleto === premiado.numeroBoleto
    );
    if (pagado === undefined) {
      sinPagar.push(premiado);
    }
  });
  return sinPagar;
}

export async function buscarBoletosPremiados(boletos, numerosGanadores) {
  try {
    let boletosPremiados = [];
    numerosGanadores.forEach((publicacion) => {
      const boletosPorSorteo = boletos.filter((b) =>
        Moment(b.fechaSorteo).isSame(Moment(publicacion.fechaSorteo))
      );
      const premiados = encontrarBoletosPremiados(
        publicacion.numeros,
        boletosPorSorteo
      );
      boletosPremiados = boletosPremiados.concat(premiados);
    });

    return boletosPremiados;
  } catch ({ message }) {
    throw new Error(message);
  }
}

function obtenerPeriodo(timestamp, periodo) {
  const dias = "domingo.lunes.martes.miercoles.jueves.viernes.sabado".split(
    "."
  );
  if (periodo === "hoy") {
    return {
      inicial: Moment(timestamp).format("YYYY-MM-DD"),
      final: Moment(timestamp).format("YYYY-MM-DD"),
    };
  }
  // DIAS DE LA SEMANA
  if (dias.includes(periodo)) {
    let counter =
      Moment(timestamp).subtract(0, "days").format("dddd") === periodo ? 1 : 0;
    while (
      Moment(timestamp).subtract(counter, "days").format("dddd") !== periodo
    ) {
      counter++;
    }
    return {
      inicial: Moment(timestamp).subtract(counter, "days").format("YYYY-MM-DD"),
      final: Moment(timestamp).subtract(counter, "days").format("YYYY-MM-DD"),
    };
  }
}

function obtenerReportePremiados(boletos, registroNumGanadores) {
  // SI NO HAY REGISTROS DE NUMEROS GANADORES
  if (registroNumGanadores.length === 0)
    return { premiados: [], totalPremios: 0 };
  // INICIAMOS BUSQUEDA DE GANADORES
  let premiadosKeys = [];
  let premiados = [];
  let totalPremios = 0;
  registroNumGanadores.forEach((registroNG) => {
    const boletosSorteo = boletos.filter((b) =>
      Moment(b.fechaSorteo).isSame(registroNG.fechaSorteo)
    );
    // ITERAMOS CADA BOLETO
    boletosSorteo.forEach((boleto) => {
      // ITERAMOS LAS JUGADAS DE CADA BOLETO
      boleto.jugadas.forEach((jugada) => {
        // DESCOMPONER NUMEROS GANADORES EN CIFRAS
        registroNG.numeros.forEach((numeroGanador, indexNumeroGanador) => {
          const cifras = {
            tres: numeroGanador,
            dos: numeroGanador.substr(1, numeroGanador.length),
            una: numeroGanador.substr(2, numeroGanador.length),
          };
          // VERIFICAR GANADORES TRES CIFRAS
          if (jugada.numero === cifras.tres) {
            totalPremios += Number(jugada.lugares[indexNumeroGanador]) * 500;
            // AGRAGAMOS EL BOLETO PREMIADO
            if (!premiadosKeys.includes(boleto.key)) {
              premiadosKeys.push(boleto.key);
              premiados.push(boleto);
            }
          }
          // VERIFICAR GANADORES DOS CIFRAS
          if (jugada.numero === cifras.dos) {
            totalPremios += Number(jugada.lugares[indexNumeroGanador]) * 70;
            // AGRAGAMOS EL BOLETO PREMIADO
            if (!premiadosKeys.includes(boleto.key)) {
              premiadosKeys.push(boleto.key);
              premiados.push(boleto);
            }
          }
          // VERIFICAR GANADORES UNA CIFRA
          if (jugada.numero === cifras.una) {
            totalPremios += Number(jugada.lugares[indexNumeroGanador]) * 7;
            // AGRAGAMOS EL BOLETO PREMIADO
            if (!premiadosKeys.includes(boleto.key)) {
              premiadosKeys.push(boleto.key);
              premiados.push(boleto);
            }
          }
        });
      });
    });
  });
  // RETORNAR REPORTE
  return { premiados, totalPremios };
}

const codigoDisponible = async (codigo) => {
  try {
    const items = await Database.getItemsByProp(
      DATABASE_TABLES.LISTA_SORTEOS,
      "codigo",
      codigo
    );
    // console.log(items);
    return items.length === 0;
  } catch ({ message }) {
    throw new Error(message);
  }
};

export const registrarPago = async (
  usuario,
  passwordAdmin,
  boletoOrig,
  premio
) => {
  try {
    // VERIFICAR AUTENTICACION
    await userAuthentication(passwordAdmin);
    const boleto = { ...boletoOrig };
    // VERIFICAR SI YA SE PAGO EL PREMIO
    const premioPagado = await Database.getItem(
      DATABASE_TABLES.PREMIOS_PAGADOS,
      "numeroBoleto",
      boleto.numeroBoleto
    );
    if (premioPagado) throw new Error("El premio ya fue pagado anteriormente");
    // COMPLETAR INFORMACION PAGO
    const timestamp = await Database.getServerDate();
    // GUARDAR PAGO
    await Database.save(DATABASE_TABLES.PREMIOS_PAGADOS, {
      fechaSorteo: boleto.fechaSorteo,
      fechaPago: Moment(timestamp).format("YYYY-MM-DD"),
      horaPago: Moment(timestamp).format("HH:mm:ss"),
      id: uuid(),
      numeroBoleto: boleto.numeroBoleto,
      pagadoPor: usuario,
      premio: premio,
      vendidoPor: boleto.numeroAgencia,
      boleto: boleto,
      timestamp,
    });
    return true;
  } catch ({ message }) {
    throw new Error(message);
  }
};
// OBTENER DISPOSITIVOS REGISTRADOS
export async function obtenerDispositivosRegistrados(userId) {
  try {
    const devices = await Database.getItemsByProp(
      DATABASE_TABLES.DISPOSITIVOS_REGISTRADOS,
      "userId",
      userId
    );
    return devices;
  } catch ({ message }) {
    throw new Error(message);
  }
}
// ELIMINAR DISPOSITIVO
export async function eliminarDispositivo(password, device) {
  try {
    // VERIFICAR AUTENTICACION
    await userAuthentication(password);
    await Database.deleteItem(
      DATABASE_TABLES.DISPOSITIVOS_REGISTRADOS,
      device.key
    );
    return true;
  } catch ({ message }) {
    throw new Error(message);
  }
}
// RESETEAR INTENTOS DE SESION
export async function resetLoginAttempts(password, userKey) {
  try {
    // VERIFICAR AUTENTICACION
    await userAuthentication(password);
    await Database.update(DATABASE_TABLES.AGENCIAS, userKey, {
      loginAttempts: 0,
    });
    const usuario = await Database.getItemByKey(
      DATABASE_TABLES.AGENCIAS,
      userKey
    );
    return usuario;
  } catch ({ message }) {
    throw new Error(message);
  }
}
// OBTENER PREMIOS PAGADOS
export async function obtenerPremiosPagados() {
  try {
    const timestamp = await Database.getServerDate();
    const periodo = {
      inicial: Moment(timestamp).subtract(15, "days").format("YYYY-MM-DD"),
      final: Moment(timestamp).format("YYYY-MM-DD"),
    };
    const premiosPagados = await Database.getItemsInRange(
      DATABASE_TABLES.PREMIOS_PAGADOS,
      "fechaPago",
      periodo.inicial,
      periodo.final
    );

    return {
      premiosPagados: Helpers.ordenarElementos(
        premiosPagados,
        (a, b) =>
          Moment(b.fechaPago + " " + b.horaPago).valueOf() -
          Moment(a.fechaPago + " " + a.horaPago).valueOf()
      ),
      periodo,
    };
  } catch ({ message }) {
    throw new Error(message);
  }
}

export async function buscarPremioPagado(numeroBoleto) {
  try {
    const premioPagado = await Database.getItem(
      DATABASE_TABLES.PREMIOS_PAGADOS,
      "numeroBoleto",
      numeroBoleto
    );
    return premioPagado;
  } catch ({ message }) {
    throw new Error(message);
  }
}
// ACTUALIZAR CREDITO
export const actualizarCredito = async (nuevoSaldo, creditoKey) => {
  try {
    await Database.update(DATABASE_TABLES.CREDITOS, creditoKey, {
      saldo: nuevoSaldo <= 0 ? 0 : nuevoSaldo,
    });
  } catch ({ message }) {
    throw new Error(message);
  }
};
