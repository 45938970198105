import { Paper, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import TrashImg from "../../../assets/trash.png";
import { Colors } from "../../../constants";

export default function TotalRegistros() {
    const registros = useSelector((state) => state.liberarEspacio);
    const {
        cargandoDatos,
        sorteos,
        boletos,
        cancelados,
        premiosPagados,
        abonos,
        numerosGanadores,
    } = registros;
    const total =
        sorteos +
        boletos +
        cancelados +
        premiosPagados +
        abonos +
        numerosGanadores;
    return (
        <Paper sx={styles.imgBox}>
            <img src={TrashImg} alt="trash image" style={styles.img} />
            <Typography
                component="h2"
                sx={{ fontSize: 48, fontWeight: "bold" }}
            >
                {cargandoDatos
                    ? "cargando total..."
                    : "Total registros: " + total}
            </Typography>
            <Typography sx={styles.imgCaption}>
                Elimina elementos antiguos de la base de datos para incrementar
                el espacio en memoria.
            </Typography>
        </Paper>
    );
}

const styles = {
    imgBox: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: 5,
    },
    img: {
        width: "100%",
        maxWidth: 450,
    },
    imgCaption: {
        fontSize: 16,
        color: Colors.smoke,
    },
};
