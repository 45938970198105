import { FormControl, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    buscarBoleto,
    filtrarCanceladosUsuario,
} from "../../../features/cancelados/canceladosSlice";

const Buscador = () => {
    const canceladosState = useSelector((state) => state.cancelados);
    const usuariosState = useSelector((state) => state.usuarios);
    const dispatch = useDispatch();
    const [value, setValue] = useState("");

    useEffect(() => {
        setValue("");
    }, [usuariosState.usuarioKey]);

    const inputChange = (text) => {
        setValue(text);
        if (text === "") {
            const usuario = obtenerUsuario();
            dispatch(
                filtrarCanceladosUsuario(
                    usuario !== undefined ? usuario.usuario : ""
                )
            );
        }
    };

    const onKeyDown = ({ keyCode }) => {
        if (keyCode === 13) {
            const usuario = obtenerUsuario();
            dispatch(
                buscarBoleto({
                    busqueda: value,
                    numeroUsuario: usuario !== undefined ? usuario.usuario : "",
                })
            );
        }
    };

    const obtenerUsuario = () => {
        return usuariosState.lista.find(
            (u) => u.key === usuariosState.usuarioKey
        );
    };

    return (
        <FormControl fullWidth>
            <TextField
                type="search"
                disabled={canceladosState.cargando}
                placeholder="Buscar boleto..."
                size="small"
                value={value}
                onChange={({ target }) => inputChange(target.value)}
                inputProps={{
                    onKeyDown: (e) => {
                        onKeyDown(e);
                    },
                }}
            />
        </FormControl>
    );
};

export default Buscador;
