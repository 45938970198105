import { useDispatch } from "react-redux";
import {
    setCargandoDatos,
    setDatos,
} from "../features/liberarEspacio/liberarEspacioSlice";
import { obtenerDatosLiberarEspacio } from "../services/bola";

export const useLiberarEspacio = () => {
    const dispatch = useDispatch();

    const cargarDatosLiberarEspacio = async () => {
        dispatch(setCargandoDatos(true));
        const datos = await obtenerDatosLiberarEspacio();
        dispatch(setDatos(datos));
        dispatch(setCargandoDatos(false));
    };

    return {
        cargarDatosLiberarEspacio,
    };
};
