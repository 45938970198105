import { useSelector } from "react-redux";
import { Moment, Money } from "../../../../../utils";
import { Divider } from "@mui/material";

export default function SorteoDescripcion() {
  const { sorteoSeleccionado } = useSelector((state) => state.sorteos);

  return (
    <>
      <Divider sx={{ marginY: 1.8 }} />
      <InfoRow label="Nombre" value={sorteoSeleccionado.sorteo} />
      <InfoRow label="Código" value={sorteoSeleccionado.codigoSorteo} />
      <InfoRow
        label="Fecha celebración"
        value={Moment(sorteoSeleccionado.fecha).format("llll")}
      />
      <InfoRow
        label="Número de lugares"
        value={sorteoSeleccionado.numLugares}
      />
      <InfoRow
        label="Boletos registrados"
        value={sorteoSeleccionado.numBoletos}
      />
      <InfoRow
        label="Total vendido"
        value={Money(sorteoSeleccionado.ventaTotal) + " MXN"}
      />
      <InfoRow
        label="Total ganadores"
        value={sorteoSeleccionado.numeroPagosPendientes}
      />
      <InfoRow
        label="Total premios"
        value={Money(sorteoSeleccionado.pagosPendientes) + " MXN"}
      />
    </>
  );
}

const InfoRow = ({ label, value }) => {
  return (
    <div
      style={{
        marginBottom: 5,
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <p
        style={{
          margin: 0,
          fontSize: 18,
          fontWeight: "bold",
        }}
      >
        {label}
      </p>
      <p
        style={{
          margin: 0,
          fontSize: 16,
          fontWeight: "400",
        }}
      >
        {value}
      </p>
    </div>
  );
};
