import { Card, CardContent, CardHeader, Grid } from "@mui/material";

export default function AccountStatusesLayout({
  sectionTitle,
  sectionSubTitle,
  action,
  children,
}) {
  return (
    <Grid container rowSpacing={2} columnSpacing={2}>
      <Grid item xs={12}>
        <Card>
          <CardHeader
            title={sectionTitle}
            subheader={sectionSubTitle}
            action={action}
          />
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardContent>{children}</CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
