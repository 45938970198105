import { Card, CardHeader, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import TablaPremiosPagados from "./components/TablaPremiosPagados";
import { obtenerPremiosPagados } from "../../../services/bola";
import { Helpers } from "../../../utils";
import { setPremiosPagados } from "../../../features/administracion/administracionSlice";
import { useDispatch } from "react-redux";

export default function PremiosPagados() {
  const [cargando, setCargando] = useState(false);
  const [fecha, setFecha] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    cargarPremiosPagados();
  }, []);

  const cargarPremiosPagados = async () => {
    setCargando(true);
    const res = await obtenerPremiosPagados();
    dispatch(setPremiosPagados(res.premiosPagados));
    setFecha(Helpers.obtenerPeriodoTexto(res.periodo));
    setCargando(false);
  };

  return (
    <Grid container rowSpacing={2} columnSpacing={2}>
      <Grid item xs={12}>
        <Card>
          <CardHeader
            title={
              cargando ? "cargando registros" : "Lista de premios registrados"
            }
            subheader={cargando ? "" : fecha}
          />
        </Card>
      </Grid>
      {!cargando && (
        <>
          <Grid item xs={12}>
            <TablaPremiosPagados />
          </Grid>
        </>
      )}
    </Grid>
  );
}
