import { createSlice } from "@reduxjs/toolkit";
import { Helpers } from "../../utils";

const initialState = {
  cargandoSorteos: true,
  cargandoSorteo: false,
  cargandoSorteosPendientes: true,
  cargandoListaOpciones: false,
  todos: [],
  pendientes: [],
  celebrados: [],
  listaOpciones: [],
  listaOpcionKey: "",
  sorteos: [],
  sorteoSeleccionado: null,
  sorteoKey: "",
  sorteoFecha: "",
};

export const sorteosSlice = createSlice({
  name: "sorteos",
  initialState,
  reducers: {
    setCargandoSorteos: (state, { payload }) => {
      state.cargandoSorteos = payload;
    },
    setCargandoSorteosPendientes: (state, { payload }) => {
      state.cargandoSorteosPendientes = payload;
    },
    setCargandoListaOpciones: (state, { payload }) => {
      state.cargandoListaOpciones = payload;
    },
    setListaOpciones: (state, action) => {
      state.listaOpciones = [...action.payload];
    },
    setListaOpcionKey: (state, { payload }) => {
      state.listaOpcionKey = payload;
    },
    setSorteos: (state, { payload }) => {
      state.todos = [...payload];
    },
    setCargandoSorteo: (state, { payload }) => {
      state.cargandoSorteo = payload;
    },
    setSorteoSeleccionado: (state, { payload }) => {
      state.sorteoSeleccionado = { ...payload };
    },
    setSorteosPendientes: (state, { payload }) => {
      state.pendientes = [...payload];
    },
    setSorteosCelebrados: (state, { payload }) => {
      state.celebrados = [...payload];
    },
    setSorteoKey: (state, { payload }) => {
      state.sorteoKey = payload;
    },
    setSorteoFecha: (state, { payload }) => {
      state.sorteoFecha = payload;
    },
    guardarOpcionListaStore: (state, { payload }) => {
      const newLista = [...state.listaOpciones, payload];
      state.listaOpciones = newLista;
    },
    guardarSorteoStore: (state, { payload }) => {
      const newLista = [payload, ...state.todos];
      state.todos = Helpers.ordenarLista(newLista, "fecha");
    },
    actualizarOpcionInfoListaStore: (state, { payload }) => {
      const newListaOpciones = state.listaOpciones.map((opt) => {
        if (opt.key === payload.opcionKey) {
          return {
            ...opt,
            nombre: payload.nombreSorteo,
            numLugares: payload.numLugares,
          };
        }
        return { ...opt };
      });
      // ACTUALIZAR NOMBRE A LOS SORTEOS
      const newListaSorteos = state.todos.map((s) => {
        if (s.codigoSorteo === payload.codigoSorteo) {
          return { ...s, sorteo: payload.nombreSorteo };
        }
        return { ...s };
      });
      state.listaOpciones = newListaOpciones;
      state.todos = Helpers.ordenarLista(newListaSorteos, "fecha");
    },
    actualizarImagenStore: (state, { payload }) => {
      const newTodos = [...state.todos];
      state.todos = Helpers.ordenarLista(newTodos, "fecha");
    },
    eliminarElementoListaStore: (state, { payload }) => {
      const newListaOpciones = state.listaOpciones.filter(
        (item) => item.key !== payload
      );
      state.listaOpciones = newListaOpciones;
    },
  },
});

export const {
  setCargandoSorteos,
  setCargandoSorteo,
  setCargandoSorteosPendientes,
  setCargandoListaOpciones,
  setListaOpciones,
  setListaOpcionKey,
  setSorteos,
  setSorteoSeleccionado,
  setSorteosPendientes,
  setSorteosCelebrados,
  setSorteoKey,
  setSorteoFecha,
  guardarOpcionListaStore,
  guardarSorteoStore,
  actualizarOpcionInfoListaStore,
  eliminarElementoListaStore,
  actualizarImagenStore,
} = sorteosSlice.actions;
export default sorteosSlice.reducer;
