import { useState } from "react";
import { useDispatch } from "react-redux";
import { DATABASE_TABLES } from "../constants";
import Database from "../database";
import { setSelectedUsuario } from "../features/usuarios/usuariosSlice";
import { Moment } from "../utils";

export const useUsuario = () => {
  const [usuario, setUsuario] = useState(null);
  const [cargandoUsuario, setCargandoUsuario] = useState(true);
  const dispatch = useDispatch();

  const obtenerUsuario = async (itemKey) => {
    setCargandoUsuario(true);
    const item = await Database.getItemByKey(DATABASE_TABLES.AGENCIAS, itemKey);
    let credito = await Database.getItem(
      DATABASE_TABLES.CREDITOS,
      "usuario",
      item.usuario
    );
    if (credito == null) {
      await Database.save(DATABASE_TABLES.CREDITOS, {
        fecha: Moment().format("YYYY-MM-DD HH:m:s"),
        saldo: item.limiteVenta != undefined ? item.limiteVenta : 3000,
        usuario: item.usuario,
      });
      credito = await Database.getItem(
        DATABASE_TABLES.CREDITOS,
        "usuario",
        item.usuario
      );
    }
    const _usuario = { ...item, creditoDisponible: credito.saldo };
    setUsuario(_usuario);
    dispatch(setSelectedUsuario(_usuario));
    setCargandoUsuario(false);
  };

  return {
    usuario,
    cargandoUsuario,
    obtenerUsuario,
  };
};
