import { useState } from "react";
import { IconButton, Typography } from "@mui/material";
import { PictureAsPdfIcon } from "../icons";
import { useModal } from "../hooks";
import CustomPDFViewer from "./CustomPDFViewer";
import ProtectedActionModal from "./ProtectedActionModal";
import { verifyUser } from "../services/auth";

export default function ViewPDF({ children }) {
  const [openViewer, setOpenViewer] = useState(false);
  const modal = useModal();

  const handleClick = () => {
    modal.setConfig({
      open: true,
      type: "alert",
      alertTitle: "Mensaje",
      contentType: "content",
      showCancelBtn: true,
      confirmBtnText: "aceptar",
    });
  };

  const handleAccept = async (password) => {
    try {
      modal.setConfig({
        type: "progress",
        progressTitle: "verificando usuario",
      });
      const isValidUser = await verifyUser(password);
      if (isValidUser) {
        modal.setConfig({ open: false });
        setOpenViewer(true);
      }
    } catch ({ message }) {
      modal.setConfig({
        open: true,
        type: "alert",
        alertTitle: "Mensaje",
        contentType: "error",
        error: <Typography>{message}</Typography>,
        showCancelBtn: false,
        confirmBtnText: "entendido",
      });
    }
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <PictureAsPdfIcon color="success" />
      </IconButton>
      <CustomPDFViewer open={openViewer} onClick={() => setOpenViewer(false)}>
        {children}
      </CustomPDFViewer>
      <ProtectedActionModal modal={modal} onAccept={handleAccept} />
    </>
  );
}
