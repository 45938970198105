import {
  Document,
  PDFViewer,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";

export default function TestComponent() {
  return (
    // <PDFDownloadLink document={<RecargasServiciosPDF reporte={null} />}>
    //   <Button variant="contained">PDF</Button>
    // </PDFDownloadLink>
    <PDFViewer style={{ width: "100vw", height: "100vh" }}>
      <RecargasServiciosPDF reporte={null} />
    </PDFViewer>
  );
}

function RecargasServiciosPDF({ reporte }) {
  return (
    <Document>
      <Page>
        <View style={styles.page}>
          <DocumentHeader
            title="ESTADO SEMANAL"
            subtitle="DEL LUNES 29 MAYO AL VIERNES 02 JUNIO 2023"
          />
          <DocumentMainTable />
        </View>
      </Page>
    </Document>
  );
}

function DocumentHeader({ title, subtitle }) {
  return (
    <View style={styles.documentHeader}>
      <Text style={styles.headerText}>{title}</Text>
      <Text style={styles.headerText}>{subtitle}</Text>
    </View>
  );
}

function DocumentMainTable() {
  return (
    <>
      <View style={styles.table}>
        <CustomRow
          header
          data={[
            "",
            "TRANSACCIONES",
            "TIEMPO AIRE",
            "TRANSACCIONES",
            "SERVICIOS Y GIFT CARDS",
          ]}
        />
        <CustomRow data={["AL CIERRE", "", "$2,465.10", "", "$7,909.58"]} />
        <CustomRow data={["DEPOSITO", "1", "$6,432.05", "1", "$6,000.00"]} />
        <CustomRow data={["COMISIÓN GENERADA", "", "$418.08", "", "$0.00"]} />
        <CustomRow data={["INICIAL", "", "$9,315.23", "", "$13,909.58"]} />
        <CustomRow data={["VENTA", "143", "$7,925.00", "9", "$4,386.00"]} />
        <CustomRow data={["CARGO DE SERVICIO", "", "$0.00", "", "$45.00"]} />
        <CustomRow data={["COMISIÓN USUARIO", "", "$583.65", "", "$31.50"]} />
        <CustomRow data={["COMISIÓN ADMIN", "", "$186.04", "", "$31.50"]} />
        <CustomRow data={["ABONO USUARIO", "", "$0.00", "", "$0.00"]} />
        <CustomRow data={["ABONO ADMIN", "", "$0.00", "", "$0.00"]} />
        <CustomRow data={["FINAL", "", "$1,390.23", "", "$9,523.58"]} />
        <CustomRow data={["RECUPERACIÓN", "", "$7,441.31", "", "$4,431.00"]} />
      </View>
      <View style={styles.table}>
        <TableTitle title="RECARGAS Y PAQUETES" />
        <CustomRow
          cellWidth="11.11%"
          data={"USUARIO.LUNES.MARTES.MIERCOLES.JUEVES.VIERNES.SABADO.DOMINGO.TOTAL".split(
            "."
          )}
        />
        <CustomRow
          cellWidth="11.11%"
          data={[
            "37001",
            "$160.00",
            "$50.00",
            "$0.00",
            "$0.00",
            "$180.00",
            "$70.00",
            "$0.00",
            "$460.00",
          ]}
        />
      </View>
    </>
  );
}

function CustomRow({ data = [], cellWidth = "25%", header = false }) {
  const renderText = (text) => {
    return (
      <View style={[styles.col, { width: cellWidth }]}>
        <Text style={[styles.text, { fontWeight: header ? "bold" : "normal" }]}>
          {text}
        </Text>
      </View>
    );
  };

  return <View style={styles.row}>{data.map(renderText)}</View>;
}

function TableTitle({ title }) {
  return (
    <View style={styles.tableTitle}>
      <Text style={styles.tableTitleText}>{title}</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  page: {
    margin: 20,
  },
  documentHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  headerText: {
    fontSize: 12,
  },
  table: {
    marginVertical: 10,
  },
  tableTitle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginVertical: 10,
  },
  tableTitleText: {
    fontSize: 12,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    borderBottomWidth: 1,
    padding: 5,
  },
  col: {},
  text: {
    fontSize: 8,
  },
});
