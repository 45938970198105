import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

export default function InformeIndividualLayout({
  title,
  tableHeadData,
  tableData,
}) {
  return (
    <Paper sx={{ marginTop: 2 }}>
      <Grid sx={{ padding: 2 }} container>
        <Grid item xs={12}>
          <Typography
            style={{ textAlign: "center", textTransform: "uppercase" }}
          >
            {title}
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ paddingTop: 2 }}>
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                {tableHeadData.map((item, index) => (
                  <TableCell key={item + index} align="right">
                    {item}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.map((item) => (
                <TableRow key={item.usuario}>
                  <TableCell align="right">{item.usuario}</TableCell>
                  {tableHeadData.slice(1).map((dia) => (
                    <TableCell align="right" key={dia}>
                      {item[dia.toLowerCase()]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </Paper>
  );
}
