import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { CircularProgress, DialogContentText } from "@mui/material";

export default function CustomModal({
  children,
  open,
  type = "progress", // alert || progress
  alertTitle = "",
  progressTitle = "",
  showConfirmBtn = true,
  showCancelBtn = true,
  confirmBtnText = "Entendido",
  cancelBtnText = "Cancelar",
  disableConfirmBtn = false,
  showActions = true,
  onCancel,
  onAccept,
  fullWidth = false,
  maxWidth = "sm",
}) {
  return (
    <Dialog
      open={open}
      disableEscapeKeyDown
      fullWidth={fullWidth}
      maxWidth={maxWidth}
    >
      {type === "progress" && (
        <Progress open={open} progressTitle={progressTitle} />
      )}
      {type === "alert" && (
        <Alert
          open={open}
          alertTitle={alertTitle}
          showConfirmBtn={showConfirmBtn}
          showCancelBtn={showCancelBtn}
          confirmBtnText={confirmBtnText}
          cancelBtnText={cancelBtnText}
          disableConfirmBtn={disableConfirmBtn}
          showActions={showActions}
          onCancel={onCancel}
          onAccept={onAccept}
        >
          {children}
        </Alert>
      )}
    </Dialog>
  );
}

const Progress = ({ progressTitle }) => {
  return (
    <DialogContent
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
      <DialogContentText sx={{ marginLeft: 1.5 }}>
        {progressTitle}
      </DialogContentText>
    </DialogContent>
  );
};

const Alert = ({
  children,
  alertTitle,
  showConfirmBtn,
  showCancelBtn,
  confirmBtnText,
  cancelBtnText,
  disableConfirmBtn,
  showActions,
  onCancel,
  onAccept,
}) => {
  return (
    <>
      <DialogTitle>{alertTitle}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      {showActions && (
        <DialogActions>
          {showCancelBtn && <Button onClick={onCancel}>{cancelBtnText}</Button>}
          {showConfirmBtn && (
            <Button
              disabled={disableConfirmBtn}
              variant="contained"
              color="primary"
              onClick={onAccept}
            >
              {confirmBtnText}
            </Button>
          )}
        </DialogActions>
      )}
    </>
  );
};
