import { useState } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { uuid } from "../utils";

export default function SelectControl({
  label,
  disabled = false,
  options = [],
  optionLabel,
  optionValue,
  onChange,
  value,
}) {
  const handleChange = ({ target }) => {
    onChange(target.value);
  };

  return (
    <FormControl sx={{ width: "100%" }} size="small">
      <InputLabel>{label}</InputLabel>
      <Select disabled={disabled} value={value} onChange={handleChange}>
        {options.map((option) => (
          <MenuItem key={uuid()} value={option[optionValue]}>
            {option[optionLabel]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
