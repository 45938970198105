import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Money } from "../../../utils";
import DataRow from "../../../components/DataRow";

export default function TablaGeneral({ reporte }) {
  return (
    <Table stickyHeader size="small">
      <TableHead>
        <TableRow>
          <TableCell></TableCell>
          <TableCell align="right">TRANSACCIONES</TableCell>
          <TableCell align="right">TIEMPO AIRE</TableCell>
          <TableCell align="right">TRANSACCIONES</TableCell>
          <TableCell align="right">SERVICIOS Y GIFT CARDS</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <DataRow
          data={[
            "AL CIERRE",
            "-",
            Money(reporte?.alCierre.recargas),
            "-",
            Money(reporte?.alCierre.servicios),
          ]}
        />
        <DataRow
          data={[
            "DEPOSITO",
            reporte?.depositos.recargas.registros,
            Money(reporte?.depositos.recargas.total),
            reporte?.depositos.servicios.registros,
            Money(reporte?.depositos.servicios.total),
          ]}
        />
        <DataRow
          data={[
            "COMISION GENERADA",
            "",
            Money(reporte?.comisionGenerada.recargas),
            "",
            "$0.00",
          ]}
        />
        <DataRow
          data={[
            "INICIAL",
            "-",
            Money(reporte?.inicial.recargas),
            "-",
            Money(reporte?.inicial.servicios),
          ]}
        />
        <DataRow
          data={[
            "VENTA",
            reporte?.venta.recargas.registros,
            Money(reporte?.venta.recargas.total),
            reporte?.venta.servicios.registros,
            Money(reporte?.venta.servicios.total),
          ]}
        />
        <DataRow
          data={[
            "CARGO DE SERVICIO",
            "",
            // Money(reporte?.comisionServicio.recargas),
            "$0.00",
            "",
            Money(reporte?.comisionServicio.servicios),
          ]}
        />
        <DataRow
          data={[
            "COMISIÓN USUARIO",
            "",
            Money(
              reporte?.comision.usuario.recargas
              // reporte?.comisionServicio.recargas
            ),
            "",
            Money(reporte?.comision.usuario.servicios),
          ]}
        />
        <DataRow
          data={[
            "COMISIÓN ADMIN",
            "",
            Money(reporte?.comision.admin.recargas),
            "",
            Money(reporte?.comision.admin.servicios),
          ]}
        />
        <DataRow
          data={[
            "ABONO USUARIO",
            "",
            "$0.00",
            "",
            Money(reporte?.abono.usuario),
          ]}
        />
        <DataRow
          data={["ABONO ADMIN", "", "$0.00", "", Money(reporte?.abono.admin)]}
        />
        <DataRow
          data={[
            "FINAL",
            "",
            Money(reporte?.cantidadFinal.recargas),
            "",
            Money(reporte?.cantidadFinal.servicios),
          ]}
        />
        <DataRow
          data={[
            "RECUPERACIÓN",
            "",
            Money(reporte?.recuperacionRecargas),
            "",
            Money(reporte?.recuperacionServicios),
          ]}
        />
      </TableBody>
    </Table>
  );
}
