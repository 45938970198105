import React, { useEffect, useState } from "react";
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { DATABASE_TABLES } from "../../../constants";
import { AdminPasswordField, CustomModal, Toast } from "../../../components";
import { guardarSorteo } from "../../../services/bola";
import { useDispatch, useSelector } from "react-redux";
import Database from "../../../database";
import {
    guardarSorteoStore,
    setCargandoListaOpciones,
    setListaOpciones,
    setListaOpcionKey,
    setSorteoFecha,
    setSorteoKey,
} from "../../../features/sorteos/sorteosSlice";
import { Moment } from "../../../utils";
import { useModal, useToast } from "../../../hooks";

export default function AgregarSorteo() {
    const dispatch = useDispatch();

    useEffect(() => {
        cargarListaOpciones();
    }, []);

    const cargarListaOpciones = async () => {
        try {
            dispatch(setCargandoListaOpciones(true));
            const items = await Database.getItems(
                DATABASE_TABLES.LISTA_SORTEOS
            );
            dispatch(setListaOpciones(items));
            dispatch(setCargandoListaOpciones(false));
        } catch ({ message }) {
            console.log(message);
        }
    };

    return (
        <>
            <Card>
                <CardHeader
                    subheader={<Typography>Agregar Sorteo</Typography>}
                />
                <CardContent>
                    <SelectSorteo />
                    <SelectFecha />
                </CardContent>
                <CardActions sx={{ justifyContent: "flex-end" }}>
                    <GuardarButton />
                </CardActions>
            </Card>
        </>
    );
}

const SelectSorteo = () => {
    const { cargandoListaOpciones, listaOpciones, listaOpcionKey } =
        useSelector((state) => state.sorteos);
    const dispatch = useDispatch();

    return (
        <FormControl fullWidth>
            <InputLabel id="sorteo-select-label">
                Selecciona un sorteo
            </InputLabel>
            <Select
                disabled={cargandoListaOpciones}
                labelId="sorteo-select-label"
                value={listaOpcionKey}
                label="Selecciona un sorteo"
                onChange={({ target: { value } }) =>
                    dispatch(setListaOpcionKey(value))
                }
            >
                {listaOpciones.map((item) => (
                    <MenuItem key={item.key} value={item.key}>
                        {item.nombre}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

const SelectFecha = () => {
    const [fecha, setFecha] = useState(new Date());
    const { sorteoKey } = useSelector((state) => state.sorteos);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setSorteoFecha(Moment().format("YYYY-MM-DD")));
    }, []);

    useEffect(() => {
        setFecha(new Date());
    }, [sorteoKey]);

    const handleSelectFecha = (date) => {
        setFecha(new Date(date));
        dispatch(setSorteoFecha(Moment(date).format("YYYY-MM-DD")));
    };
    return (
        <FormControl fullWidth style={{ marginTop: 20 }}>
            <DesktopDatePicker
                label="Fecha Del Sorteo"
                inputFormat="YYYY/MM/DD"
                value={fecha}
                onChange={handleSelectFecha}
                renderInput={(params) => <TextField {...params} />}
            />
        </FormControl>
    );
};

const GuardarButton = () => {
    const [password, setPassword] = useState("");
    const { listaOpcionKey, sorteoFecha, listaOpciones } = useSelector(
        (state) => state.sorteos
    );
    const modal = useModal();
    const toast = useToast();
    const dispatch = useDispatch();

    const handleGuardar = () => {
        modal.setConfig({
            open: true,
            type: "alert",
            alertTitle: "Agregar sorteo",
            contentType: "input-password",
            showCancelBtn: true,
        });
    };

    const handleCancel = () => {
        modal.setConfig({ open: false });
        setPassword("");
    };

    const handleModalAccept = () => {
        const actions = {
            "input-password": () => guardar(),
            error: () => handleCancel(),
        };
        actions[modal.config.contentType]();
    };

    const guardar = async () => {
        try {
            if (
                password.trim() !== "" &&
                listaOpcionKey.trim() !== "" &&
                sorteoFecha.trim() !== ""
            ) {
                modal.setConfig({
                    type: "progress",
                    progressTitle: "Guardando",
                });
                const sorteo = listaOpciones.find(
                    (item) => item.key === listaOpcionKey
                );
                const sorteoGuardado = await guardarSorteo(
                    sorteo,
                    sorteoFecha,
                    password
                );
                // SI SE GUARDO EL SORTEO
                if (sorteoGuardado) {
                    modal.setConfig({
                        open: false,
                    });
                    setPassword("");
                    toast.setConfig({
                        open: true,
                        severity: "success",
                        message: "Sorteo agregado correctamente",
                    });
                    dispatch(setListaOpcionKey(""));
                    dispatch(setSorteoFecha(Moment().format("YYYY-MM-DD")));
                    dispatch(guardarSorteoStore(sorteoGuardado));
                }
            }
        } catch ({ message }) {
            modal.setConfig({
                type: "alert",
                alertTitle: "Mensaje",
                showCancelBtn: false,
                confirmBtnText: "Entendido",
                contentType: "error",
                error: <Typography>{message}</Typography>,
            });
            setPassword("");
        }
    };

    const closeToast = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        toast.setConfig({ open: false });
    };

    const disableConfirmBtn = () => {
        if (
            modal.config.contentType === "input-password" &&
            password.trim() === ""
        ) {
            return true;
        }
        return false;
    };

    return (
        <>
            <Button
                disabled={
                    listaOpcionKey.trim() === "" || sorteoFecha.trim() === ""
                }
                variant="contained"
                color="primary"
                onClick={() => handleGuardar()}
            >
                Guardar
            </Button>
            <CustomModal
                open={modal.config.open}
                type={modal.config.type}
                alertTitle={modal.config.alertTitle}
                progressTitle={modal.config.progressTitle}
                showCancelBtn={modal.config.showCancelBtn}
                confirmBtnText={modal.config.confirmBtnText}
                onCancel={handleCancel}
                onAccept={handleModalAccept}
                disableConfirmBtn={disableConfirmBtn()}
            >
                {modal.config.contentType === "input-password" && (
                    <AdminPasswordField
                        password={password}
                        message="Por seguridad te pedimos que ingreses tu contraseña para poder
                continuar con el proceso de adición."
                        onChange={({ target }) => setPassword(target.value)}
                    />
                )}
                {modal.config.contentType === "error" && modal.config.error}
            </CustomModal>
            <Toast
                open={toast.config.open}
                message={toast.config.message}
                severity={toast.config.severity}
                handleClose={closeToast}
            />
        </>
    );
};
