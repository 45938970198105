import { Box } from "@mui/material";

export default function ScrollView({
  children,
  horizontal = false,
  vertical = false,
  scrollBoth = false,
  maxWidth = "85vw",
  maxHeight = 500,
  onScroll,
}) {
  if (horizontal) {
    return (
      <Box
        sx={{
          overflowX: "auto",
          maxWidth: maxWidth,
        }}
      >
        {children}
      </Box>
    );
  }

  if (vertical) {
    return (
      <Box
        sx={{
          overflowY: "auto",
          maxWidth: maxWidth,
          maxHeight: maxHeight,
        }}
      >
        {children}
      </Box>
    );
  }
  if (scrollBoth) {
    return (
      <Box
        sx={{
          overflowX: "auto",
          overflowY: "auto",
          maxWidth: maxWidth,
          maxHeight: maxHeight,
        }}
        onScroll={onScroll}
      >
        {children}
      </Box>
    );
  }
}
