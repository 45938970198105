const Colors = {
    red: "#e30613",
    dark: "#000",
    darkLight: "#3C3C3C",
    smoke: "whitesmoke",
    gray: "#f0f2f5",
    green: "#1ED760",
    darkGray: "#3F4E4F",
};

export default Colors;
