import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  obtenerBoletoPremiado,
  obtenerUsuarios,
  registrarPago,
} from "../../../../services/bola";
import { Button, Grid, Paper, Typography } from "@mui/material";
import {
  AdminPasswordField,
  CustomModal,
  Ticket,
} from "../../../../components";
import Database from "../../../../database";
import { Colors, DATABASE_TABLES } from "../../../../constants";
import { Money } from "../../../../utils";
import { useModal } from "../../../../hooks";
import CustomSelect from "../../../../components/CustomSelect";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import SectionTitle from "../../../../components/SectionTitle";

export default function PagoPendiente() {
  const { boletoKey } = useParams();
  const [boleto, setBoleto] = useState(null);
  const [cargando, setCargando] = useState(true);
  const [sorteoOpcion, setSorteoOpcion] = useState(null);
  const [usuarios, setUsuarios] = useState([]);
  const [usuario, setUsuario] = useState(null);
  const navigate = useNavigate();
  const modal = useModal();
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      password: "",
    },
    validationSchema: Yup.object().shape({
      password: Yup.string().required("Este campo es requerido"),
    }),
    onSubmit: (formData) => {
      pagarPremio(formData);
    },
  });

  useEffect(() => {
    _obtenerBoletoPremiado();
  }, []);

  const _obtenerBoletoPremiado = async () => {
    try {
      setCargando(true);
      const boletoPremiado = await obtenerBoletoPremiado(boletoKey);
      const _sorteoOpcion = await Database.getItemsByProp(
        DATABASE_TABLES.LISTA_SORTEOS,
        "codigo",
        boletoPremiado.codigoSorteo
      );
      const _usuarios = await obtenerUsuarios();
      setUsuarios(_usuarios);
      setBoleto(boletoPremiado);
      setSorteoOpcion(_sorteoOpcion[0]);
      setCargando(false);
    } catch ({ message }) {
      alert(message);
    }
  };

  const handlePagar = () => {
    modal.setConfig({
      open: true,
      contentType: "content",
      type: "alert",
      alertTitle: "Registrar pago",
      confirmBtnText: "Si, continuar",
      action: "aceptar",
      showCancelBtn: true,
    });
  };

  const handleChange = (value) => {
    setUsuario(usuarios.find((usuario) => usuario.usuario === value));
  };

  const modalOnCancel = () => {
    modal.setConfig({ open: false });
    formik.handleReset();
  };

  const modalOnAccept = () => {
    if (modal.config.action === "aceptar") {
      formik.handleSubmit();
    }
    if (modal.config.action === "error") {
      modalOnCancel();
    }
    if (modal.config.action === "notify") {
      navigate("../../../panel/administracion/pagosPendientes", {
        replace: true,
      });
    }
  };

  const pagarPremio = async ({ password }) => {
    try {
      modal.setConfig({ type: "progress", progressTitle: "Regisrando pago" });
      const res = await registrarPago(usuario, password, boleto);
      // return;
      modal.setConfig({
        type: "alert",
        alertTitle: "Mensaje",
        showCancelBtn: false,
        confirmBtnClicked: false,
        confirmBtnText: "Entendido",
        contentType: "notify",
        content: (
          <span>{`El pago de ${Money(
            res.premio
          )} se registró correctamente a nombre de ${
            usuario.nomComercial
          }`}</span>
        ),
        action: "notify",
      });
    } catch ({ message }) {
      modal.setConfig({
        type: "alert",
        alertTitle: "Mensaje",
        showCancelBtn: false,
        confirmBtnClicked: false,
        confirmBtnText: "Entendido",
        contentType: "error",
        error: <span>{message}</span>,
        action: "error",
      });
    }
  };

  return (
    <>
      <Grid container rowSpacing={2} columnSpacing={2}>
        <Grid item xs={12}>
          <SectionTitle title="Registro de pago" />
        </Grid>
        {cargando && <div>cargando boleto</div>}
        {!cargando && (
          <>
            <Grid item xs={12} md={3}>
              <Paper sx={{ padding: 2 }}>
                <div style={styles.premioBox}>
                  <div>
                    <div>Premio</div>
                    <div style={styles.premio}>
                      {Money(boleto.infoPremio.premio)}
                    </div>
                  </div>
                </div>
                <CustomSelect
                  label="Usuario"
                  options={usuarios}
                  optionLabel="nomComercial"
                  optionValue="usuario"
                  marginY={1}
                  onChange={handleChange}
                />
                <Button
                  disabled={usuario === null}
                  fullWidth
                  variant="outlined"
                  onClick={handlePagar}
                >
                  Pagar
                </Button>
                <Button
                  fullWidth
                  sx={{ marginTop: 3 }}
                  variant="outlined"
                  color="info"
                  onClick={() =>
                    navigate("../../../panel/administracion/pagosPendientes", {
                      replace: true,
                    })
                  }
                >
                  Volver
                </Button>
              </Paper>
            </Grid>
            <Grid item xs={12} md={9}>
              <Paper sx={{ padding: 2, maxWidth: 300 }}>
                <Ticket.Header boleto={boleto} />
                <Ticket.Body boleto={boleto} sorteoOpcion={sorteoOpcion} />
              </Paper>
            </Grid>
          </>
        )}
      </Grid>
      <CustomModal
        open={modal.config.open}
        type={modal.config.type}
        alertTitle={modal.config.alertTitle}
        progressTitle={modal.config.progressTitle}
        showCancelBtn={modal.config.showCancelBtn}
        confirmBtnText={modal.config.confirmBtnText}
        onCancel={modalOnCancel}
        onAccept={modalOnAccept}
      >
        {modal.config.contentType === "content" && (
          <>
            <Typography>
              El pago de {Money(boleto.infoPremio.premio)} quedará registrado a
              nombre de {usuario?.nomComercial}, desea continuar?
            </Typography>
            <AdminPasswordField
              message="Por seguridad, te pedimos ingresar tu contraseña para poder continuar con el proceso."
              password={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.errors.password && formik.touched.password}
              errormessage={formik.errors.password}
              divider
            />
          </>
        )}
        {modal.config.contentType === "notify" && modal.config.content}
        {modal.config.contentType === "error" && modal.config.error}
      </CustomModal>
    </>
  );
}

const styles = {
  premioBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: 100,
    textAlign: "center",
    fontSize: 20,
  },
  premio: {
    color: Colors.green,
    fontSize: 24,
  },
};
