import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    cargando: true,
    todos: [],
    filtrados: [],
    total: 0,
    registrados: 0,
    filtros: {
        cifras: "tres", // tres | dos | una
        orden: "asc", // asc | desc
        venta: "todos", // todos | menor-50 | mayor-50-menor-100 | mayor-100-menor-500 | sin-venta
    },
    numerosJugados: [],
    totalNumerosJugados: 0,
};

export const boletosSlice = createSlice({
    name: "boletos",
    initialState: initialState,
    reducers: {
        setCargandoBoletos: (state, { payload }) => {
            state.cargando = payload;
        },
        setBoletosTodos: (state, { payload }) => {
            const boletos = [...payload];
            state.todos = boletos;
            state.filtrados = boletos;
            state.registrados = boletos.length;
            state.total = boletos.reduce(
                (acc, b) => parseInt(acc) + parseInt(b.totalApostado),
                0
            );
        },
        filtrarBoletosUsuario: (state, { payload }) => {
            let numeroUsuario = payload;
            // SI NUMERO USUARIO ESTA VACIO MOSTRAR TODOS LOS BOLETOS
            if (numeroUsuario !== "") {
                state.filtrados = state.todos.filter(
                    (b) => b.numeroAgencia === numeroUsuario
                );
                state.registrados = state.filtrados.length;
                state.total = state.filtrados.reduce(
                    (acc, b) => parseInt(acc) + parseInt(b.totalApostado),
                    0
                );
                return;
            }
            state.filtrados = [...state.todos];
            state.registrados = [...state.todos].length;
            state.total = [...state.todos].reduce(
                (acc, b) => parseInt(acc) + parseInt(b.totalApostado),
                0
            );
        },
        setBuscarBoleto: (state, { payload: { busqueda, numeroUsuario } }) => {
            let copiaTodos = [...state.todos];
            // SI NO EXISTE EL NUMERO DE USUARIO FILTRAR SOLO POR BUSQUEDA
            if (numeroUsuario === "") {
                const resultadoBusqueda = copiaTodos.filter(
                    (b) =>
                        b.numeroBoleto.match(busqueda) ||
                        b.fechaSorteo.match(busqueda) ||
                        b.horaImpresion.match(busqueda)
                );
                state.filtrados = resultadoBusqueda;
                state.totalRegistros = resultadoBusqueda.length;
                return;
            }
            // SI EXISTE EL NUMERO DE USUARIO FILTRAR POR USUARIO Y BUSQUEDA
            const resultadoBusqueda = copiaTodos.filter(
                (b) =>
                    b.numeroAgencia === numeroUsuario &&
                    (b.numeroBoleto.match(busqueda) ||
                        b.fechaSorteo.match(busqueda) ||
                        b.horaImpresion.match(busqueda))
            );
            state.filtrados = resultadoBusqueda;
            state.totalRegistros = resultadoBusqueda.length;
        },
        setFiltros: (state, { payload: { tipoFiltro, value } }) => {
            const filtros = { ...state.filtros, [tipoFiltro]: value };
            state.filtros = filtros;
        },
        setNumerosJugados: (state, { payload }) => {
            const numerosJugados = [...payload];
            state.numerosJugados = numerosJugados;
            state.totalNumerosJugados = numerosJugados.reduce(
                (acc, n) => parseInt(acc) + parseInt(n.totalApostado),
                0
            );
        },
    },
});

export const {
    setBoletosTodos,
    setCargandoBoletos,
    setBuscarBoleto,
    setFiltros,
    filtrarBoletosUsuario,
    setNumerosJugados,
} = boletosSlice.actions;
export default boletosSlice.reducer;
