import _Storage from "./Storage";
import _Helpers from "./Helpers";
import moment from "./Moment";
import { v4 } from "uuid";
import money from "./Money";
import Utils from "./Utils";

const Bcrypt = require("bcryptjs");
const Storage = _Storage();
const Helpers = _Helpers();
const Moment = moment;
const uuid = v4;
const Money = money;

export { Storage, Helpers, Utils, Moment, uuid, Money, Bcrypt };
