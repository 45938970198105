import { Card, CardMedia } from "@mui/material";
import { CircleLoading, TextLoading } from "./";

const UsuarioCard = () => {
    return (
        <Card>
            <CardMedia
                sx={{
                    background: "rgba(55, 71, 79, 0.4)",
                    minHeight: 200,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <CircleLoading size={130} />
                <TextLoading height={15} marginTop={10} />
            </CardMedia>
        </Card>
    );
};

export default UsuarioCard;
