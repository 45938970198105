import { ListItem, ListItemText } from "@mui/material";

const InfoRow = ({ label, value }) => {
    return (
        <ListItem>
            <ListItemText primary={label} secondary={value} />
        </ListItem>
    );
};

export default InfoRow;
