import { ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import { CircleLoading, TextLoading } from "../placeholders";

function ListItemLoader() {
    return (
        <>
            <ListItem>
                <ListItemAvatar>
                    <CircleLoading />
                </ListItemAvatar>
                <ListItemText
                    primary={<TextLoading />}
                    secondary={<TextLoading marginTop={10} width={50} />}
                />
            </ListItem>
            <ListItem>
                <ListItemAvatar>
                    <CircleLoading />
                </ListItemAvatar>
                <ListItemText
                    primary={<TextLoading />}
                    secondary={
                        <TextLoading TextLoading marginTop={10} width={50} />
                    }
                />
            </ListItem>
        </>
    );
}

export default ListItemLoader;
