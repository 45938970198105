import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormControl, MenuItem, Select } from "@mui/material";
import {
    setListaUsuarios,
    setUsuarioKey,
} from "../../../features/usuarios/usuariosSlice";
import { uuid } from "../../../utils";
import { filtrarBoletosUsuario } from "../../../features/boletos/boletosSlice";
import { obtenerUsuarios } from "../../../services/bola";

const SelectUsuario = () => {
    const [cargando, setCargando] = useState(true);
    const [opcionKey, setOpcionKey] = useState("");
    const boletosState = useSelector((state) => state.boletos);
    const usuariosState = useSelector((state) => state.usuarios);
    const { sorteoKey } = useSelector((state) => state.sorteos);
    const dispatch = useDispatch();

    useEffect(() => {
        cargarUsuarios();
    }, []);
    // PONER EN ESTADO INICIAL AL CAMBIAR EL SORTEO
    useEffect(() => {
        setOpcionKey("");
    }, [sorteoKey]);

    const cargarUsuarios = async () => {
        setCargando(true);
        const items = await obtenerUsuarios();
        dispatch(setListaUsuarios(items));
        setCargando(false);
    };

    const onSelect = ({ target }) => {
        const usuarioKey = target.value;
        setOpcionKey(usuarioKey);
        setTimeout(() => {
            dispatch(setUsuarioKey(usuarioKey));
            const usuario = usuariosState.lista.find(
                (u) => u.key === usuarioKey
            );
            dispatch(
                filtrarBoletosUsuario(
                    usuario !== undefined ? usuario.usuario : ""
                )
            );
        }, 100);
    };

    return (
        <FormControl fullWidth>
            <Select
                disabled={cargando || boletosState.cargando}
                size="small"
                displayEmpty
                value={opcionKey}
                onChange={onSelect}
            >
                <MenuItem value="">
                    <span style={{ fontSize: 14 }}>Todos</span>
                </MenuItem>
                {usuariosState.lista.map((item) => (
                    <MenuItem key={uuid()} value={item.key}>
                        <span style={{ fontSize: 14 }}>
                            {item.nomComercial}
                        </span>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default SelectUsuario;
